import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { Button, DatePicker } from 'antd';
import moment from 'moment';
import Select from 'react-select';
import queryString from 'query-string';
import Pagination from 'rc-pagination';


import ToolTipWrapper from '../../../Misc/ToolTipWrapper';

import SingleReview from './SingleReview';
import ReviewRatingChartFaux from '../../Charts/ReviewRatingChartFaux';

import { setCommonParams } from '../../../../redux/actions/appActions';
import { getDataAction } from '../../../../redux/actions/getDataActions';

import { DATE_DURATION_OPTIONS_MAP, OTA_ORDER, OTA_NAME_TO_SOURCE_MAP, OTA_ID_TO_NAME_FORMAL_MAP,
  renderOTALogo, REVIEW_TABS_TO_RS_MAP, OTA_SOURCE_TO_NAME_MAP, getReviewState,
  ratingToInt, RS, RATING_TO_STRING_MAP, REVIEW_TABS_MAP,
  getReputationAccess, getUserGAId } from '../../../../helpers';

import searchIcon from '../../../../img/icons/search.svg';
import closeIcon from '../../../../img/icons/close.svg';
import downloadIcon from '../../../../img/icons/download.svg';

import './style.css';

var Scroll   = require('react-scroll');
var scroll = Scroll.animateScroll;

const { RangePicker } = DatePicker;

const gtconfig = require('../../../../gtconfig');

const lastMonthOptions = [];
lastMonthOptions.push({ label: 'Select', value: 'select' });
lastMonthOptions.push({ label: 'Last 7 days', value: 'last-7-days' });
lastMonthOptions.push({ label: 'Last 15 days', value: 'last-15-days' });
lastMonthOptions.push({ label: 'Last 1 Month', value: 'last-1-month' });
lastMonthOptions.push({ label: 'Last 3 Months', value: 'last-3-months' });
lastMonthOptions.push({ label: 'Last 6 Months', value: 'last-6-months' });
lastMonthOptions.push({ label: 'Last 12 Months', value: 'last-12-months' });

function ReviewTabButton(props) {
  const onButtonClick = (e) => { props.onButtonClick(props.data); }
  return (
    <span className={'reviewTabButton ' + (props.active ? 'active' : '')} onClick={onButtonClick}>
      {props.data.title} { props.data.count ? `(${props.data.count})` : ''}
    </span>
  );
}

function FilterRow(props) {
  const [selected, setSelected] = useState(false);
  useEffect(() => {
    setSelected(props.selected);
  }, [props.selected]);
  const onCheckBoxChange = (e) => {
    if (props.data.id === 'all' && selected) return;
    setSelected(selected => !selected);
    props.onRowClick(props.type, props.data);
  };
  const onLabelClick = (e) => {
    if (props.data.id === 'all' && selected) return;
    setSelected(selected => !selected);
    props.onRowClick(props.type, props.data);
  };
  return (
    <div className="dRow">
      <div className="left">
        <input type="checkbox" name={`filter_ota_${props.data.id}`} onChange={onCheckBoxChange} value={selected ? 'on' : 'off'} checked={selected} />
        <div className="label" onClick={onLabelClick}>
          {props.data.title}
        </div>
      </div>
      <div className="right">
        <div className="count">
          ({props.data.count || 0})
        </div>
      </div>
    </div>
  );
}

function Reviews(props) {
  const appState = useSelector((state) => state.appReducer);
  const userState = useSelector((state) => state.userReducer);
  const windowState = useSelector((state) => state.windowReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  const location = useLocation();
  let navigate = useNavigate();
  let urlParams = useParams();

  const [dateRangeLastMonthValue, setDateRangeLastMonthValue] = useState(
    { label: 'Last 1 Month', value: 'last-1-month' }
  );
  const [dateFilterStartDate, setDateFilterStartDate] = useState(new Date(moment().subtract(1, 'month')));
  const [dateFilterEndDate, setDateFilterEndDate] = useState(new Date());

  const [tabOptions, setTabOptions] = useState([]);
  const [selectedTab, setSelectedTab] = useState('all');

  const [showTrends, setShowTrends] = useState(false);

  const [filterOtaOptions, setFilterOtaOptions] = useState([]);
  const [otaFilterValues, setOtaFilterValues] = useState({'all': true});

  const [filterRatingOptions, setFilterRatingOptions] = useState([]);
  const [ratingFilterValues, setRatingFilterValues] = useState({'all': true});

  const [filterKeyword, setFilterKeyword] = useState('');
  const [filterKeywordActive, setFilterKeywordActive] = useState('');

  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);

  const getReviewsData = (reviewState, startDate, endDate, keyword, otaFilter, ratingFilter, pageNumber) => {
    if (props.isGroup) {
      dispatch(getDataAction({
        op: 'get_group_reviews_data',
        params: {
          group_id: urlParams.groupidentifier,
          review_state: reviewState,
          start_date: moment(startDate).format('YYYY-MM-DD'),
          end_date: moment(endDate).format('YYYY-MM-DD'),
          keyword,
          ota_filter: otaFilter,
          rating_filter: ratingFilter,
          skip: pageSize * (pageNumber - 1),
          limit: pageSize,
        },
      }));
    } else {
      dispatch(getDataAction({
        op: 'get_reviews_data',
        params: {
          location__id: urlParams.locationidentifier,
          review_state: reviewState,
          start_date: moment(startDate).format('YYYY-MM-DD'),
          end_date: moment(endDate).format('YYYY-MM-DD'),
          keyword,
          ota_filter: otaFilter,
          rating_filter: ratingFilter,
          skip: pageSize * (pageNumber - 1),
          limit: pageSize,
        },
      }));
    }
    setTimeout(() => {
      scroll.scrollToTop({
        duration: 100,
        delay: 0,
        smooth: true,
        // containerId: 'app',
        // offset: 50, // Scrolls to element + 50 pixels down the page
      });
    }, 300);
  };

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : ''
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: `Reputation - Reviews - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('useEffect locationidentifier: ', urlParams.locationidentifier);
    }
    const { commonParams } = appState;
    let startDate = commonParams && commonParams.startDate ? new Date(commonParams.startDate) : new Date(moment().subtract(1, 'month'));
    let endDate = commonParams && commonParams.endDate ? new Date(commonParams.endDate) : new Date();
    let rangeKeyword = commonParams && commonParams.rangeKeyword ? commonParams.rangeKeyword : 'last-1-month';
    setDateFilterStartDate(startDate);
    setDateFilterEndDate(endDate);
    setDateRangeLastMonthValue({ label: DATE_DURATION_OPTIONS_MAP[rangeKeyword], value: rangeKeyword });
    // getReviewsData(selectedTab, startDate, endDate, filterKeyword, otaFilterValues, ratingFilterValues, 1);

    const { has_self_response, has_gt_response, otas } = props.locationInfo;
    const { locationidentifier, reviewstate } = urlParams;

    let tempTabOptions = [];
    tempTabOptions.push({ id: 'all', title: 'All Reviews' });
    // if (props.isGroup) {
    //   const { reviews } = props.reputation;
    //   tempTabOptions.push({ id: 'replied', title: 'Replied' });
    //   if (reviews && reviews.meta && reviews.meta.has_self_response) {
    //     tempTabOptions.push({ id: 'response-required', title: 'Response Required' });
    //   }
    //   if (reviews && reviews.meta && reviews.meta.has_gt_response) {
    //     tempTabOptions.push({ id: 'pending', title: 'Pending' });
    //     tempTabOptions.push({ id: 'approval', title: 'Approval Required' });
    //     tempTabOptions.push({ id: 'need-info', title: 'Need Info' });
    //     // tempTabOptions.push({ id: 'posted', title: 'Response Posted' });
    //   }
    // } else {
      if (has_self_response) {
        tempTabOptions.push({ id: 'replied', title: 'Replied' });
        tempTabOptions.push({ id: 'response-required', title: 'Response Required' });
        // tempTabOptions.push({ id: 'posted', title: 'Response Posted' });
      }
      if (has_gt_response) {
        tempTabOptions.push({ id: 'replied', title: 'Replied' });
        tempTabOptions.push({ id: 'pending', title: 'Pending' });
        tempTabOptions.push({ id: 'approval', title: 'Approval Required' });
        tempTabOptions.push({ id: 'need-info', title: 'Need Info' });
        // tempTabOptions.push({ id: 'posted', title: 'Response Posted' });
      }
    // }
    setTabOptions(tempTabOptions);

    const parsed = queryString.parse(location.search);
    if (process.env.NODE_ENV !== 'production') {
      console.log('Reviews parsed: ', parsed);
    }

    let localTab = 'all';
    if (reviewstate) {
      // const { tab } = queryString.parse(location.search);
      const tab = reviewstate;
      if (tab && REVIEW_TABS_MAP[tab]) {
        if (
          (has_self_response && (tab === 'all' || tab === 'replied' || tab === 'response-required' || tab === 'posted')) ||
          (has_gt_response && (tab === 'all' || tab === 'replied' || tab === 'pending' || tab === 'approval' || tab === 'need-info' || tab === 'posted'))
        ) {
          setSelectedTab(tab);
          localTab = tab;
        }
      }
    }
    if (process.env.NODE_ENV !== 'production') {
      console.log('localTab: ', localTab);
    }

    if (parsed['keyword']) {
      // const { tab } = queryString.parse(location.search);
      const keyword = parsed['keyword'];
      if (keyword) {
        setFilterKeyword(keyword);
      }
    }
    let tempOtaFilterValues = {};
    if (parsed['ota']) {
      const ota = parsed['ota'];
      let otaList = ota.split(',');
      otaList.forEach((item) => {
        if (item && OTA_NAME_TO_SOURCE_MAP[item]) {
          tempOtaFilterValues[item] = true;
        }
      });
    } else {
      tempOtaFilterValues = { 'all': true };
    }
    setOtaFilterValues(tempOtaFilterValues);

    let tempRatingFilterValues = {};
    if (parsed['rating']) {
      const rating = parsed['rating'];
      let ratingList = rating.split(',');
      ratingList.forEach((item) => {
        if (item && RATING_TO_STRING_MAP[item]) {
          tempRatingFilterValues[item] = true;
        }
      });
    } else {
      tempRatingFilterValues = { 'all': true };
    }
    setRatingFilterValues(tempRatingFilterValues);

    let tempFilterOtaOptions = [];
    tempFilterOtaOptions.push({ id: 'all', title: 'All' });
    // if (props.isGroup) {
    //   const { reviews } = props.reputation;
    //   if (reviews && reviews.meta && reviews.meta.otas) {
    //     let { otas } = reviews.meta;
    //     if (otas.tripadvisor)
    //     if (otas.tripadvisor) tempFilterOtaOptions.push({ id: 'tripadvisor', title: 'Tripadvisor' });
    //     if (otas && otas.booking) tempFilterOtaOptions.push({ id: 'booking', title: 'Booking' });
    //     if (otas && otas.expedia) tempFilterOtaOptions.push({ id: 'expedia', title: 'Expedia' });
    //     if (otas && otas.google) tempFilterOtaOptions.push({ id: 'google', title: 'Google' });
    //     if (otas && otas.hotelscom) tempFilterOtaOptions.push({ id: 'hotelscom', title: 'Hotels.com' });
    //     if (otas && otas.hostelworld) tempFilterOtaOptions.push({ id: 'hostelworld', title: 'Hosterworld' });
    //     if (otas && otas.agoda) tempFilterOtaOptions.push({ id: 'agoda', title: 'Agoda' });
    //     if (otas && otas.mmt) tempFilterOtaOptions.push({ id: 'mmt', title: 'MMT' });
    //     if (otas && otas.airbnb) tempFilterOtaOptions.push({ id: 'airbnb', title: 'Airbnb' });
    //     if (otas && otas.vrbo) tempFilterOtaOptions.push({ id: 'vrbo', title: 'Vrbo' });
    //     if (otas && otas.holidaycheck) tempFilterOtaOptions.push({ id: 'holidaycheck', title: 'HolidayCheck' });
    //     if (otas && otas.yelp) tempFilterOtaOptions.push({ id: 'yelp', title: 'Yelp' });
    //     if (otas && otas.opentable) tempFilterOtaOptions.push({ id: 'opentable', title: 'Opentable' });
    //   }
    // } else {
      if (otas && otas.tripadvisor && otas.tripadvisor.is_enabled) tempFilterOtaOptions.push({ id: 'tripadvisor', title: 'Tripadvisor' });
      if (otas && otas.booking && otas.booking.is_enabled) tempFilterOtaOptions.push({ id: 'booking', title: 'Booking' });
      if (otas && otas.expedia && otas.expedia.is_enabled) tempFilterOtaOptions.push({ id: 'expedia', title: 'Expedia' });
      if (otas && otas.google && otas.google.is_enabled) tempFilterOtaOptions.push({ id: 'google', title: 'Google' });
      if (otas && otas.hotelscom && otas.hotelscom.is_enabled) tempFilterOtaOptions.push({ id: 'hotelscom', title: 'Hotels.com' });
      if (otas && otas.hostelworld && otas.hostelworld.is_enabled) tempFilterOtaOptions.push({ id: 'hostelworld', title: 'Hosterworld' });
      if (otas && otas.agoda && otas.agoda.is_enabled) tempFilterOtaOptions.push({ id: 'agoda', title: 'Agoda' });
      if (otas && otas.mmt && otas.mmt.is_enabled) tempFilterOtaOptions.push({ id: 'mmt', title: 'MMT' });
      if (otas && otas.airbnb && otas.airbnb.is_enabled) tempFilterOtaOptions.push({ id: 'airbnb', title: 'Airbnb' });
      if (otas && otas.vrbo && otas.vrbo.is_enabled) tempFilterOtaOptions.push({ id: 'vrbo', title: 'Vrbo' });
      if (otas && otas.holidaycheck && otas.holidaycheck.is_enabled) tempFilterOtaOptions.push({ id: 'holidaycheck', title: 'HolidayCheck' });
      if (otas && otas.yelp && otas.yelp.is_enabled) tempFilterOtaOptions.push({ id: 'yelp', title: 'Yelp' });
      if (otas && otas.opentable && otas.opentable.is_enabled) tempFilterOtaOptions.push({ id: 'opentable', title: 'Opentable' });
    // }
    setFilterOtaOptions(tempFilterOtaOptions);

    let tempFilteRatingOptions = [];
    tempFilteRatingOptions.push({ id: 'all', title: 'All' });
    tempFilteRatingOptions.push({ id: '5', title: '5 Stars' });
    tempFilteRatingOptions.push({ id: '4', title: '4 Stars' });
    tempFilteRatingOptions.push({ id: '3', title: '3 Stars' });
    tempFilteRatingOptions.push({ id: '2', title: '2 Stars' });
    tempFilteRatingOptions.push({ id: '1', title: '1 Stars' });
    setFilterRatingOptions(tempFilteRatingOptions);

    getReviewsData(localTab, startDate, endDate, parsed['keyword'] || '', tempOtaFilterValues, tempRatingFilterValues, 1);

  }, [urlParams.locationidentifier]);


  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('useEffect props.reputation.reviews: ', props.reputation.reviews);
    }

    let tempTabOptions = [];
    tempTabOptions.push({ id: 'all', title: 'All Reviews' });
    if (props.isGroup) {
      const { reviews } = props.reputation;
      tempTabOptions.push({ id: 'replied', title: 'Replied' });
      if (reviews && reviews.meta && reviews.meta.has_self_response) {
        tempTabOptions.push({ id: 'response-required', title: 'Response Required' });
      }
      if (reviews && reviews.meta && reviews.meta.has_gt_response) {
        tempTabOptions.push({ id: 'pending', title: 'Pending' });
        tempTabOptions.push({ id: 'approval', title: 'Approval Required' });
        tempTabOptions.push({ id: 'need-info', title: 'Need Info' });
        // tempTabOptions.push({ id: 'posted', title: 'Response Posted' });
      }
      setTabOptions(tempTabOptions);
    }

    if (props.isGroup) {
      let tempFilterOtaOptions = [];
      tempFilterOtaOptions.push({ id: 'all', title: 'All' });
      const { reviews } = props.reputation;
      if (reviews && reviews.meta && reviews.meta.otas) {
        let { otas } = reviews.meta;
        if (otas.tripadvisor)
        if (otas.tripadvisor) tempFilterOtaOptions.push({ id: 'tripadvisor', title: 'Tripadvisor' });
        if (otas && otas.booking) tempFilterOtaOptions.push({ id: 'booking', title: 'Booking' });
        if (otas && otas.expedia) tempFilterOtaOptions.push({ id: 'expedia', title: 'Expedia' });
        if (otas && otas.google) tempFilterOtaOptions.push({ id: 'google', title: 'Google' });
        if (otas && otas.hotelscom) tempFilterOtaOptions.push({ id: 'hotelscom', title: 'Hotels.com' });
        if (otas && otas.hostelworld) tempFilterOtaOptions.push({ id: 'hostelworld', title: 'Hosterworld' });
        if (otas && otas.agoda) tempFilterOtaOptions.push({ id: 'agoda', title: 'Agoda' });
        if (otas && otas.mmt) tempFilterOtaOptions.push({ id: 'mmt', title: 'MMT' });
        if (otas && otas.airbnb) tempFilterOtaOptions.push({ id: 'airbnb', title: 'Airbnb' });
        if (otas && otas.vrbo) tempFilterOtaOptions.push({ id: 'vrbo', title: 'Vrbo' });
        if (otas && otas.holidaycheck) tempFilterOtaOptions.push({ id: 'holidaycheck', title: 'HolidayCheck' });
        if (otas && otas.yelp) tempFilterOtaOptions.push({ id: 'yelp', title: 'Yelp' });
        if (otas && otas.opentable) tempFilterOtaOptions.push({ id: 'opentable', title: 'Opentable' });
      }
      setFilterOtaOptions(tempFilterOtaOptions);
    }

  }, [props.reputation.reviews]);

  const onDateRangeLastMonthChange = (value) => {
    setDateRangeLastMonthValue(value);
    const rangeValue = value.value;
    if (rangeValue.indexOf('-day') > -1) {
      let period = rangeValue.split('-day')[0];
      if (period.indexOf('last-') > -1) period = period.split('last-')[1];
      setDateFilterStartDate(new Date(moment().subtract(period, 'days')))
      setDateFilterEndDate(new Date());
      dispatch(setCommonParams({
        params: {
          rangeKeyword: rangeValue,
          startDate: new Date(moment().subtract(period, 'days')),
          endDate: new Date(),
        },
      }));
      // getReviewsData(new Date(moment().subtract(period, 'days')), new Date());
      getReviewsData(selectedTab, new Date(moment().subtract(period, 'days')), new Date(), filterKeyword, otaFilterValues, ratingFilterValues, 1);
    }
    if (rangeValue.indexOf('-month') > -1) {
      let period = rangeValue.split('-month')[0];
      if (period.indexOf('last-') > -1) period = period.split('last-')[1];
      setDateFilterStartDate(new Date(moment().subtract(period, 'month')))
      setDateFilterEndDate(new Date());
      dispatch(setCommonParams({
        params: {
          rangeKeyword: rangeValue,
          startDate: new Date(moment().subtract(period, 'month')),
          endDate: new Date(),
        },
      }));
      // getReviewsData(new Date(moment().subtract(period, 'month')), new Date());
      getReviewsData(selectedTab, new Date(moment().subtract(period, 'month')), new Date(), filterKeyword, otaFilterValues, ratingFilterValues, 1);
    }
    // analytics event reporting
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : ''
      window.gtag('event', 'gt_click', {
        'event_category': `Reputation - Reviews - Date Change Last`,
        'event_label': `Date Change Last - ${rangeValue}`,
        page_path: `${location.pathname}`,
        page_title: `Reputation - Reviews - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  };

  const onDateRangeChange = (dates, dateStrings) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onDateRangeChange dates: ', dates);
      console.log('onDateRangeChange dateStrings: ', dateStrings);
    }
    const { reviews } = props.reputation;
    if (!reviews || reviews.getInProgress) return;
    // setDateRangeActive(true);
    setDateFilterStartDate(new Date(dates[0]));
    setDateFilterEndDate(new Date(dates[1]));
    setDateRangeLastMonthValue({ label: 'Select', value: 'select' });
    dispatch(setCommonParams({
      params: {
        rangeKeyword: 'select',
        startDate: new Date(dates[0]),
        endDate: new Date(dates[1]),
      },
    }));
    // getReviewsData(dates[0], dates[1]);
    getReviewsData(selectedTab, dates[0], dates[1], filterKeyword, otaFilterValues, ratingFilterValues, 1);
    // analytics event reporting
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : ''
      window.gtag('event', 'gt_click', {
        'event_category': `Reputation - Reviews - Date Change Range`,
        'event_label': `Date Change Range - ${dateStrings}`,
        page_path: `${location.pathname}`,
        page_title: `Reputation - Reviews - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  };

  const onReviewTabClick = (tab) => {
    setSelectedTab(tab.id);
    getReviewsData(tab.id, dateFilterStartDate, dateFilterEndDate, filterKeyword, otaFilterValues, ratingFilterValues, 1);
    const { locationidentifier, groupidentifier } = urlParams;
    if (props.isGroup) {
      navigate(`/reputation/groups/${groupidentifier}/reviews/${tab.id}`);
    } else {
      navigate(`/reputation/locations/${locationidentifier}/reviews/${tab.id}`);
    }
    setPageNumber(1);
  };

  const onShowTrendsClick = (e) => {
    setShowTrends(showTrends => !showTrends);
  };

  const onDownloadClick = (e) => {
    const { reviews } = props.reputation;
    if (reviews) {
      if (reviews.getReviewsDumpInProgress) return;
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : ''
      let filename = "Reviews";
      if (locationName) { filename += "-" + locationName.replace(/ /g, ''); }
      if (dateFilterStartDate) { filename += "-" + moment(dateFilterStartDate).format('YYYY-MM-DD') }
      if (dateFilterEndDate) { filename += "-to-" + moment(dateFilterEndDate).format('YYYY-MM-DD') }
      filename += ".csv";
      dispatch(getDataAction({
        op: 'get_reviews_dump',
        params: {
          location__id: urlParams.locationidentifier,
          review_state: selectedTab,
          start_date: moment(dateFilterStartDate).format('YYYY-MM-DD'),
          end_date: moment(dateFilterEndDate).format('YYYY-MM-DD'),
          keyword: filterKeyword,
          ota_filter: otaFilterValues,
          rating_filter: ratingFilterValues,
          filename,
        },
      }));
      // props.dispatch(getReviewsDumpAction({
      //   params: {
      //     location__id: locationidentifier,
      //     start_date: moment(dateFilterStartDate).format('YYYY-MM-DD'),
      //     end_date: moment(dateFilterEndDate).format('YYYY-MM-DD'),
      //     keyword: filterKeyword,
      //     filename,
      //     review_state: selectedTab,
      //     ota_filter: otaFilterValues,
      //     rating_filter: ratingFilterValues,
      //   },
      // }));
      // gtag('event', 'gt_click', {
      //   'event_category': `Reviews - Download CSV`,
      //   'event_label': `Download CSV`,
      //   'value': '',
      //   user_id: props.userprops.user.uid,
      // });
      window.gtag('event', 'gt_click', {
        'event_category': `Reviews - Download CSV`,
        'event_label': `Download CSV`,
        page_path: `${location.pathname}`,
        page_title: `Reputation - Reviews - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    }
  };

  const onFilterRowClick = (type, row) => {
    if (type === 'ota') {
      let tempOtaFilterValues = { ...otaFilterValues };
      if (row.id !== 'all') {
        if (tempOtaFilterValues[row.id]) {
          delete tempOtaFilterValues[row.id];
          if (!Object.keys(tempOtaFilterValues).length) tempOtaFilterValues = { 'all': true };
        } else {
          tempOtaFilterValues[row.id] = true;
          delete tempOtaFilterValues['all'];
        }
      } else {
        tempOtaFilterValues = { 'all': true };
      }
      getReviewsData(selectedTab, dateFilterStartDate, dateFilterEndDate, filterKeyword, tempOtaFilterValues, ratingFilterValues, 1);
      setOtaFilterValues(tempOtaFilterValues);
      const parsed = queryString.parse(location.search);
      parsed['ota'] = Object.keys(tempOtaFilterValues).reduce((accumulator, currentValue) => {
        return `${currentValue},${accumulator}`;
      }, '');
      if (parsed['ota']) {
        if (parsed['ota'][parsed['ota'].length-1] === ',') {
          parsed['ota'] = parsed['ota'].slice(0, parsed['ota'].length-1);
        }
      }
      const stringified = queryString.stringify(parsed);
      navigate(`${location.pathname}?${stringified}`);
      setPageNumber(1);
    }
    if (type === 'rating') {
      let tempRatingFilterValues = { ...ratingFilterValues };
      if (row.id !== 'all') {
        if (tempRatingFilterValues[row.id]) {
          delete tempRatingFilterValues[row.id];
          if (!Object.keys(tempRatingFilterValues).length) tempRatingFilterValues = { 'all': true };
        } else {
          tempRatingFilterValues[row.id] = true;
          delete tempRatingFilterValues['all'];
        }
      } else {
        tempRatingFilterValues = { 'all': true };
      }
      getReviewsData(selectedTab, dateFilterStartDate, dateFilterEndDate, filterKeyword, otaFilterValues, tempRatingFilterValues, 1);
      setRatingFilterValues(tempRatingFilterValues);
      const parsed = queryString.parse(location.search);
      parsed['rating'] = Object.keys(tempRatingFilterValues).reduce((accumulator, currentValue) => {
        return `${currentValue},${accumulator}`;
      }, '');
      if (parsed['rating']) {
        if (parsed['rating'][parsed['rating'].length-1] === ',') {
          parsed['rating'] = parsed['rating'].slice(0, parsed['rating'].length-1);
        }
      }
      const stringified = queryString.stringify(parsed);
      navigate(`${location.pathname}?${stringified}`);
      setPageNumber(1);
    }
    // analytics event reporting
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : ''
      window.gtag('event', 'gt_click', {
        'event_category': `Reputation - Reviews - Filter ${type}`,
        'event_label': `Filter ${type} - ${row.id}`,
        page_path: `${location.pathname}`,
        page_title: `Reputation - Reviews - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  };

  const onSearchChange = (e) => {
    const { name, value } = e.target;
    setFilterKeyword(value);
  };

  const onFilterResetClick = (e) => {
    setOtaFilterValues({ 'all': true });
    setRatingFilterValues({ 'all': true });
    setSelectedTab('all');
    setFilterKeyword('');
    getReviewsData('all', dateFilterStartDate, dateFilterEndDate, '', { 'all': true }, { 'all': true }, pageNumber);
    const parsed = queryString.parse(location.search);
    delete parsed['keyword'];
    delete parsed['rating'];
    delete parsed['tab'];
    const stringified = queryString.stringify(parsed);
    // props.history.push(`${location.pathname}?${stringified}`); // nkcheck
    setFilterKeywordActive('');
    // analytics event reporting
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : ''
      window.gtag('event', 'gt_click', {
        'event_category': `Reputation - Reviews - Filter Reset`,
        'event_label': `Filter Reset`,
        page_path: `${location.pathname}`,
        page_title: `Reputation - Reviews - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }

  const onFilterSearchClick = (e) => {
    if (filterKeyword) {
      getReviewsData('all', dateFilterStartDate, dateFilterEndDate, filterKeyword, { 'all': true }, { 'all': true }, pageNumber);
      const parsed = queryString.parse(location.search);
      parsed['keyword'] = filterKeyword;
      const stringified = queryString.stringify(parsed);
      // props.history.push(`${location.pathname}?${stringified}`); // nkcheck
      setFilterKeywordActive(filterKeyword);
      // analytics event reporting
      try {
        const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
          && locationState.locations[urlParams.locationidentifier].settings
          && locationState.locations[urlParams.locationidentifier].settings.info
          ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
          : ''
        window.gtag('event', 'gt_click', {
          'event_category': `Reputation - Reviews - Filter keyword`,
          'event_label': `Filter keyword - ${filterKeyword}`,
          page_path: `${location.pathname}`,
          page_title: `Reputation - Reviews - ${locationName}`,
          gt_user_id: getUserGAId(userState.user),
          gt_location_id: urlParams.locationidentifier,
          gt_location: locationName,
        });
      } catch (e) {}
    }
  };
  const onFilterSearchClearClick = (e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onFilterSearchClearClick');
    }
    setFilterKeyword('');
  };
  const onFilterSearchInputKeyDown = (e) => {
    if (e.keyCode === 13) {
      if (filterKeyword) {
        const parsed = queryString.parse(location.search);
        parsed['keyword'] = filterKeyword;
        const stringified = queryString.stringify(parsed);
        // props.history.push(`${location.pathname}?${stringified}`); // nkcheck
        getReviewsData('all', dateFilterStartDate, dateFilterEndDate, filterKeyword, { 'all': true }, { 'all': true }, pageNumber);
        setFilterKeywordActive(filterKeyword);
      }
    }
    if (e.keyCode === 27) {
      setFilterKeyword('');
    }
  };

  const onPageChange = (page) => {
    setPageNumber(page);
    getReviewsData(selectedTab, dateFilterStartDate, dateFilterEndDate, filterKeyword, otaFilterValues, ratingFilterValues, page);
  };
  const onPaginationShowSizeChange = (current, pageSize) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onPaginationShowSizeChange: ', current, ' # ', pageSize);
    }
  };


  const renderDateFilterRow = (reviews) => {
    const { has_csv_download_reviews } = props.locationInfo;
    return (
      <div className="dtFltBx">
        <div className="left">
          <div className="title">
            {
              props.isGroup && urlParams.groupidentifier && props.groupName &&
              <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'}
                overlay={"Group Name, click to go to Group Overview"}
              >
                <Link to={`/reputation/groups/${urlParams.groupidentifier}/dashboard`}><span>{props.groupName}</span></Link>
              </ToolTipWrapper>
            }
            Reviews
          </div>
        </div>
        <div className="dateFilter">
          { has_csv_download_reviews &&
            <div className="dwnldCsv" onClick={onDownloadClick}>
              <img src={downloadIcon} />
              <p className="download">Download CSV</p>
              {
                reviews && reviews.getReviewsDumpInProgress &&
                <span>
                  <i className="fa fa-spinner fa-spin" />
                </span>
              }
            </div>
          }
          <div className="dateFilterLastMonth">
            <Select
              className="dateFilterLastMonthSelect"
              options={lastMonthOptions}
              onChange={onDateRangeLastMonthChange}
              value={dateRangeLastMonthValue}
            />
          </div>
          <div className="dateFilterRange">
            <RangePicker
              value={[moment(dateFilterStartDate), moment(dateFilterEndDate)]}
              onChange={onDateRangeChange}
              format="DD MMM YYYY"
            />
          </div>
        </div>
      </div>
    );
  };

  const renderResponseStateOptionsTab = (reviews) => {
    return (
      <div className="rpOptions">
        <div className="rpTabs">
          {
            tabOptions.map((item) => {
              if (reviews && reviews.review_state_split && reviews.review_state_split) {
                if (reviews.review_state_split[REVIEW_TABS_TO_RS_MAP[item.id]]) {
                  item.count = reviews.review_state_split[REVIEW_TABS_TO_RS_MAP[item.id]];
                } else {
                  item.count = 0;
                }
                if (item.id === 'all') {
                  item.count = reviews.overall_count || 0;
                }
              }
              return <ReviewTabButton data={item} onButtonClick={onReviewTabClick} active={item.id === selectedTab}/>
            })
          }
        </div>
        {/*<div className="rpSort">
      </div>*/}
      </div>
    );
  }

  const renderTrendsChart = (reviews) => {
    if (props.isGroup) return null;
    return (
      <div className="revsTrndBx">
        <div className="title">
          <div className="lt">
            <span onClick={onShowTrendsClick}>{ showTrends ? 'Hide Trends' : 'Show Trends' }</span>
            <i className="fas fa-chart-line"></i>
          </div>
        </div>
        {
          showTrends &&
          <div className="chartBox">
            <ReviewRatingChartFaux
              windowState={windowState}
              width={(windowState.window.windowWidth - 196 - 1 - 40 - 60 - 285 + 16 )}
              height={160}
              data={reviews.trend_data}
              hideRating={!ratingFilterValues['all']}
              showOnlyDaily={dateRangeLastMonthValue['value'] === 'last-7-days' || dateRangeLastMonthValue['value'] === 'last-15-days'}
            />
          </div>
        }
      </div>
    )
  };


  const renderFilterOptions = (reviews) => {
    return (
      <div className="fltrPnl">
        <div className="header">
          <p>Filters</p>
          <p className="reset" onClick={onFilterResetClick}>
            <span><i className="fa fa-redo-alt" /></span>
            Reset
          </p>
        </div>
        <div className="search">
          <img className="label" src={searchIcon} />
          <input type="text"
            placeholder="Type any keyword..."
            onChange={onSearchChange}
            name="searchKeyword"
            onKeyDown={onFilterSearchInputKeyDown}
            value={filterKeyword}
          />
          {
            filterKeyword &&
            <img className="clear" src={closeIcon} onClick={onFilterSearchClearClick} />
          }
          <span className="submit" onClick={onFilterSearchClick}><i className="fa fa-arrow-right" /></span>
        </div>
        {/*<div className="chartBox">
          <ReviewRatingChart
            width={240}
            height={200}
          />
        </div>*/}
        <div className="filterBox otaFilter">
          <div className="title">
            OTA
          </div>
          <div className="dRows">
            {
              filterOtaOptions.map((item) => {
                if (selectedTab === 'all') {
                  if (!filterKeyword) {
                    if (item.id === 'all') {
                      item.count = reviews.overall_count || 0;
                    } else {
                      if (reviews && reviews.overall_split && reviews.overall_split.ota) {
                        item.count = reviews.overall_split.ota[OTA_NAME_TO_SOURCE_MAP[item.id]] || 0;
                      } else {
                        item.count = 0;
                      }
                    }
                  } else {
                    if (item.id === 'all') {
                      item.count = reviews.total_count || 0;
                    } else {
                      if (reviews && reviews.split && reviews.split.ota) {
                        item.count = reviews.split.ota[OTA_NAME_TO_SOURCE_MAP[item.id]] || 0;
                      } else {
                        item.count = 0;
                      }
                    }
                  }
                } else {
                  if (item.id === 'all') {
                    // item.count = reviews.filter_count || 0;
                    item.count = reviews && reviews.review_state_split ? reviews.review_state_split[REVIEW_TABS_TO_RS_MAP[selectedTab]] || 0 : 0;
                  } else {
                    // if (reviews && reviews.split && reviews.split.ota) {
                    //   item.count = reviews.split.ota[OTA_NAME_TO_SOURCE_MAP[item.id]] || 0;
                    // } else {
                    //   item.count = 0;
                    // }
                    if (reviews && reviews.review_state_ota_split && reviews.review_state_ota_split[REVIEW_TABS_TO_RS_MAP[selectedTab]]) {
                      item.count = reviews.review_state_ota_split[REVIEW_TABS_TO_RS_MAP[selectedTab]][OTA_NAME_TO_SOURCE_MAP[item.id]] || 0;
                    } else {
                      item.count = 0;
                    }
                  }
                }
                return (
                  <FilterRow type="ota" data={item} onRowClick={onFilterRowClick} selected={otaFilterValues[item.id]} />
                )
              })
            }
          </div>
        </div>
        <div className="lineBreak" />
        <div className="filterBox ratingFilter">
          <div className="title">
            Ratings
          </div>
          <div className="dRows">
            {
              filterRatingOptions.map((item) => {
                // item.count = ratingSplitValues[item.id];
                if (item.id === 'all') {
                  item.count = reviews.total_count || 0;
                } else {
                  if (reviews && reviews.rating_split) {
                    item.count = reviews.rating_split[parseInt(item.id)];
                  }
                }
                return (
                  <FilterRow type="rating" data={item} onRowClick={onFilterRowClick} selected={ratingFilterValues[item.id]} />
                )
              })
            }
          </div>
        </div>
      </div>
    );
  };

  const renderReviews = (reviews) => {
    if (!reviews || !reviews.list || !reviews.list.length) return null;
    const { locationidentifier } = urlParams;
    // const { has_self_response, has_gt_response } = props.locationInfo;
    return reviews.list
      .filter((reviewId) => {
        const review = reviews.keyValue[reviewId];
        if (!review) return false;
        if (otaFilterValues['all']) return true;
        if (otaFilterValues[OTA_SOURCE_TO_NAME_MAP[review.source]]) return true;
        if (process.env.NODE_ENV !== 'production') {
          console.log('Reviews list filtered by ota: ', review);
        }
      })
      .filter((reviewId) => {
        const review = reviews.keyValue[reviewId];
        if (!review) return false;
        if (ratingFilterValues['all']) return true;
        if (ratingFilterValues[ratingToInt(review.rating_out_of_5)]) return true;
        if (process.env.NODE_ENV !== 'production') {
          console.log('Reviews list filtered by rating: ', review);
        }
      })
      .map((reviewId) => {
        const review = reviews.keyValue[reviewId];
        if (!review) return null;
        if (process.env.NODE_ENV !== 'production') {
          console.log('Reviews list map: ', review);
        }
        let newRepAccess = props.repAccess || null;
        if (!newRepAccess) {
          const { hasAccessToRunFeedbackCampaign, hasAccessToRespondToReviews } = getReputationAccess(userState.user.user_info, review.location__id);
          newRepAccess = { hasAccessToRespondToReviews, hasAccessToRunFeedbackCampaign };
        }
        let locationId = locationidentifier;
        let { has_self_response, has_gt_response } = props.locationInfo;
        let locationInfo = props.locationInfo ? { ...props.locationInfo } : {};
        if (props.isGroup) {
          has_self_response = review.l_has_self_response;
          has_gt_response = review.l_has_gt_response;
          locationId = review.location__id;
          locationInfo = reviews.location_by_hotel_id && reviews.location_by_hotel_id[review.hotel_id]
            ? reviews.location_by_hotel_id[review.hotel_id] : {};
        }
        if (selectedTab !== 'all') {
          const reviewState = getReviewState(review, has_self_response);
          if (process.env.NODE_ENV !== 'production') {
            console.log('reviewState: ', reviewState, ' # ', reviewState);
          }
          if (selectedTab === 'response-required' &&
            (reviewState === RS.RS_SELF_RESPONSE_REQUIRED)
          ) {
            return <SingleReview key={`sr_${review._id}`} reviewId={review._id} review={review} locationId={locationId} hasSelfResponse={has_self_response}
              filterKeyword={filterKeywordActive}
              userState={userState}
              locationInfo={locationInfo}
              reviews={props.reputation.reviews}
              settings={props.settings}
              // repAccess={props.repAccess}
              repAccess={newRepAccess}
              isReadOnly={props.isReadOnly}
              accessLevel={props.accessLevel}
              isGroup={props.isGroup}
              groupId={urlParams.groupidentifier}
            />
          }
          if (selectedTab === 'approval' &&
            (reviewState === RS.RS_WAITING_FOR_APPROVAL)
          ) {
            return <SingleReview key={`sr_${review._id}`} reviewId={review._id} review={review} locationId={locationId} hasSelfResponse={has_self_response}
              filterKeyword={filterKeywordActive}
              userState={userState}
              locationInfo={locationInfo}
              reviews={props.reputation.reviews}
              settings={props.settings}
              // repAccess={props.repAccess}
              repAccess={newRepAccess}
              isReadOnly={props.isReadOnly}
              accessLevel={props.accessLevel}
              isGroup={props.isGroup}
              groupId={urlParams.groupidentifier}
            />
          }
          if (selectedTab === 'need-info' &&
            (reviewState === RS.RS_NEED_MORE_INFO)
          ) {
            return <SingleReview key={`sr_${review._id}`} reviewId={review._id} review={review} locationId={locationId} hasSelfResponse={has_self_response}
              filterKeyword={filterKeywordActive}
              userState={userState}
              locationInfo={locationInfo}
              reviews={props.reputation.reviews}
              settings={props.settings}
              // repAccess={props.repAccess}
              repAccess={newRepAccess}
              isReadOnly={props.isReadOnly}
              accessLevel={props.accessLevel}
              isGroup={props.isGroup}
              groupId={urlParams.groupidentifier}
            />
          }
          if (selectedTab === 'posted' &&
            (reviewState === RS.RS_RESPONSE_POSTED ||
              reviewState === RS.RS_HAS_MANAGER_RESPONSE
            )
          ) {
            return <SingleReview key={`sr_${review._id}`} reviewId={review._id} review={review} locationId={locationId} hasSelfResponse={has_self_response}
              filterKeyword={filterKeywordActive}
              userState={userState}
              locationInfo={locationInfo}
              reviews={props.reputation.reviews}
              settings={props.settings}
              // repAccess={props.repAccess}
              repAccess={newRepAccess}
              isReadOnly={props.isReadOnly}
              accessLevel={props.accessLevel}
              isGroup={props.isGroup}
              groupId={urlParams.groupidentifier}
            />
          }
          if (selectedTab === 'replied' &&
            (reviewState === RS.RS_RESPONSE_POSTED ||
              reviewState === RS.RS_HAS_MANAGER_RESPONSE
            )
          ) {
            return <SingleReview key={`sr_${review._id}`} reviewId={review._id} review={review} locationId={locationId} hasSelfResponse={has_self_response}
              filterKeyword={filterKeywordActive}
              userState={userState}
              locationInfo={locationInfo}
              reviews={props.reputation.reviews}
              settings={props.settings}
              // repAccess={props.repAccess}
              repAccess={newRepAccess}
              isReadOnly={props.isReadOnly}
              accessLevel={props.accessLevel}
              isGroup={props.isGroup}
              groupId={urlParams.groupidentifier}
            />
          }
          if (selectedTab === 'pending' &&
            (
              reviewState !== RS.RS_HAS_MANAGER_RESPONSE &&
              reviewState !== RS.RS_RESPONSE_POSTED &&
              reviewState !== RS.RS_WAITING_FOR_APPROVAL &&
              reviewState !== RS.RS_NEED_MORE_INFO &&
              reviewState !== RS.RS_NOT_RESPONDABLE
            )
          ) {
            return <SingleReview key={`sr_${review._id}`} reviewId={review._id} review={review} locationId={locationId} hasSelfResponse={has_self_response}
              filterKeyword={filterKeywordActive}
              userState={userState}
              locationInfo={locationInfo}
              reviews={props.reputation.reviews}
              settings={props.settings}
              // repAccess={props.repAccess}
              repAccess={newRepAccess}
              isReadOnly={props.isReadOnly}
              accessLevel={props.accessLevel}
              isGroup={props.isGroup}
              groupId={urlParams.groupidentifier}
            />
          }
          return null;
        }
        return <SingleReview key={`sr_${review._id}`} reviewId={review._id} review={review} locationId={locationId} hasSelfResponse={has_self_response}
          filterKeyword={filterKeywordActive}
          userState={userState}
          locationInfo={locationInfo}
          reviews={props.reputation.reviews}
          settings={props.settings}
          // repAccess={props.repAccess}
          repAccess={newRepAccess}
          isReadOnly={props.isReadOnly}
          accessLevel={props.accessLevel}
          isGroup={props.isGroup}
          groupId={urlParams.groupidentifier}
        />
      });
  };

  const renderPagination = (reviews) => {
    if (!reviews || !reviews.filter_count) return null;
    let totalCount = 0;
    totalCount = reviews.filter_count || 0;
    if (process.env.NODE_ENV !== 'production') {
      console.log('totalCount final: ', totalCount);
    }
    if (!totalCount) return null;
    return (
      <div className="rpPagination">
        <Pagination
          onChange={onPageChange}
          current={pageNumber}
          total={totalCount}
          pageSize={pageSize}
          hideOnSinglePage={true}
          showSizeChanger
          onShowSizeChange={onPaginationShowSizeChange}
        />
      </div>
    );
  };

  if (process.env.NODE_ENV !== 'production') {
    console.log('Reviews props: ', props);
  }
  if (process.env.NODE_ENV !== 'production') {
    console.log('Reviews otaFilterValues: ', otaFilterValues);
    console.log('Reviews filterKeyword: ', filterKeyword);
    console.log('Reviews pageNumber: ', pageNumber);
    console.log('Reviews pageSize: ', pageSize);
  }

  const { reviews } = props.reputation;
  if (!reviews) return null;


  return (
    <>
      {/*
        reviews.getInProgress &&
        <div className="gPnlLdng">
          <i className="fa fa-spinner fa-spin" />
        </div>
      */}
      {
        reviews.getInProgress &&
        <div className="gPnlSplLdng">
          <div className="innerWrap">
            <i className="fa fa-spinner fa-spin" />
            <img src={gtconfig.REPUTATION_LOADER_URL} />
          </div>
        </div>
      }
      <div className="gPScrlWrp flHt mk">
        <div className="gPg revsPg">
          { renderDateFilterRow(reviews) }
          { renderResponseStateOptionsTab(reviews) }
          <div className="revsBd">
            <div className="lt">
              { renderTrendsChart(reviews) }
              { renderReviews(reviews) }
              { renderPagination(reviews) }
            </div>
            <div className="rt">
              { renderFilterOptions(reviews) }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Reviews;
