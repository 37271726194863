// @flow

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from "react-redux";
import ReactDOM from 'react-dom';
import Mousetrap from 'mousetrap';
import moment from 'moment';
import DOMPurify from 'dompurify';

import EmailEditor from 'react-email-editor';

import TemplateSelectorModal from '../TemplateSelectorModal';
import SendTestEmailModal from '../SendTestEmailModal';
import EmailBuilderTipsModal from '../EmailBuilderTipsModal';

import ToolTipWrapper from '../../../Misc/ToolTipWrapper';

import { editDataAction, editDataClearAction } from '../../../../redux/actions/editDataActions';

import { TRANSACTION_EMAIL_TYPE_TO_LABEL } from '../../../../helpers';

import editIcon from '../../../../img/icons/edit.svg';
import closeIcon from '../../../../img/icons/close.svg';

import sample from '../sample.json';

import './style.css';

const mergeTagsG = {
  first_name: {
    name: "First Name",
    value: "{{first_name}}",
  },
  last_name: {
    name: "Last Name",
    value: "{{last_name}}",
  },
  guest_check_in_date: {
    name: "Guest Check-In Date",
    value: "{{guest_check_in_date}}",
  },
  guest_check_out_date: {
    name: "Guest Check-Out Date",
    value: "{{guest_check_out_date}}",
  },
  // booking_confirmation_code: {
  //   name: "Booking Confirmation Code",
  //   value: "{{booking_confirmation_code}}",
  // },
  number_of_adults: {
    name: "Number of adults",
    value: "{{number_of_adults}}",
  },
  number_of_children: {
    name: "Number of children",
    value: "{{number_of_children}}",
  },
  room_number: {
    name: "Room Number",
    value: "{{room_number}}",
  },
  room_type: {
    name: "Room Type",
    value: "{{room_type}}",
  },
  // rate_code: {
  //   name: "Rate Code",
  //   value: "{{rate_code}}",
  // },
  // rate_per_night: {
  //   name: "Rate Per Night",
  //   value: "{{rate_per_night}}",
  // },
  // total_price: {
  //   name: "Total Charge",
  //   value: "{{total_charge}}",
  // },
  wifi_code: {
    name: "Wifi Code",
    value: "{{wifi_code}}",
  },
  check_in_time: {
    name: "Check-In Time",
    value: "{{check_in_time}}",
  },
  check_out_time: {
    name: "Check-Out Time",
    value: "{{check_out_time}}",
  },
  breakfast_start_time: {
    name: "Breakfast Start Time",
    value: "{{breakfast_start_time}}",
  },
  breakfast_end_time: {
    name: "Breakfast End Time",
    value: "{{breakfast_end_time}}",
  },
  hotel_name: {
    name: "Hotel Name",
    value: "{{hotel_name}}",
  },
  hotel_address: {
    name: "Hotel Address",
    value: "{{hotel_address}}",
  },
  hotel_number: {
    name: "Hotel Number",
    value: "{{hotel_number}}",
  },
  hotel_engage_number: {
    name: "Hotel Engage Number",
    value: "{{hotel_engage_number}}",
  },
  hotel_website: {
    name: "Hotel Website",
    value: "{{hotel_website}}",
  },
  hotel_logo_url: {
    name: "Hotel Logo Url",
    value: "{{hotel_logo_url}}",
  },
  unsubscribe_url: {
    name: "Unsubscribe Url",
    value: "{{unsubscribe_url}}",
  },
  avg_rate_nightly: {
    name: "Avg Rate (Nightly)",
    value: "{{avg_rate_nightly}}",
  },
};


function EmailTemplate(props) {
  const windowState = useSelector((state) => state.windowReducer);
  const userState = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();

  const [name, setName] = useState('');
  const [nameEditEnabled, setNameEditEnabled] = useState(false);
  const [nameChanged, setNameChanged] = useState(false);
  const [nameError, setNameError] = useState('');
  const nameRef = useRef(null);

  const [desc, setDesc] = useState('');
  const [descEditEnabled, setDescEditEnabled] = useState(false);
  const [descChanged, setDescChanged] = useState(false);
  const [descError, setDescError] = useState('');
  const descRef = useRef(null);

  const [emailBuilderChanged, setEmailBuilderChanged] = useState(false);
  const [someChange, setSomeChange] = useState(false);

  const [showTemplateSelectorModal, setShowTemplateSelectorModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [changeTemplateTouched, setChangeTemplateTouched] = useState(false);

  const [showEmailBuilderTipsModal, setShowEmailBuilderTipsModal] = useState(false);

  const [testEmailError, setTestEmailError] = useState('');

  const [showSendTestEmailModal, setShowSendTestEmailModal] = useState(false);

  const [showInProgress, setShowInProgress] = useState(false);

  const [mergeTagValues, setMergeTagValues] = useState({});

  const refTemplateId = useRef('');

  const modalRef = useRef();

  const [showEditor, setShowEditor] = useState(false);
  const [templateLoading, setTemplateLoading] = useState(false);

  let emailEditorRef = useRef(null);

  const clearAction = () => {
    const { email_templates } = props.transactionEmails;
    if (selectedTemplate && selectedTemplate._id) {
      if (
        email_templates &&
        email_templates.keyValue &&
        email_templates.keyValue[selectedTemplate._id] &&
        email_templates.keyValue[selectedTemplate._id].editStatus &&
        Object.keys(email_templates.keyValue[selectedTemplate._id].editStatus).length) {
        const params = {
          location__id: props.locationInfo._id,
          email_template_id: selectedTemplate._id,
          mode: 'edit',
        };
        dispatch(editDataClearAction({
          op: 'edit_email_template',
          params,
        }));
      }
    } else {
      if (
        email_templates && email_templates.addStatus && Object.keys(email_templates.addStatus).length
      ) {
        const params = {
          location__id: props.locationInfo._id,
          mode: 'add',
        };
        dispatch(editDataClearAction({
          op: 'edit_email_template',
          params,
        }));
      }
    }
  };

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('EmailTemplate useEffect []');
    }
    // props.dispatch(getEmailTemplatesAction({
    //   params: {
    //     uid: props.userprops.user.uid,
    //     location_id: props.locationprops.location.location_id,
    //   },
    // }));
    setTimeout(() => {
      setShowEditor(true);
      setTemplateLoading(true);
    }, 100);
    setName('');
    setDesc('');
    // props.dispatch(clearEmailTemplateAction({
    //   params: {
    //     location_id: props.locationprops.location.location_id,
    //     mode: 'add',
    //   },
    // }));
    if (!props.emailTemplateId) {
      setShowTemplateSelectorModal(true);
    }
    setMergeTagValues(mergeTagsG);
  }, []);

  useEffect(() => {
    const { email_templates } = props.transactionEmails;
    if (props.emailTemplateId &&
      email_templates &&
      email_templates.keyValue &&
      email_templates.keyValue[props.emailTemplateId] &&
      email_templates.keyValue[props.emailTemplateId]
    ) {
      setName(email_templates.keyValue[props.emailTemplateId].name || '');
      setDesc(email_templates.keyValue[props.emailTemplateId].desc || '');
      setSelectedTemplate(email_templates.keyValue[props.emailTemplateId]);
    }
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('props.transactionEmails.email_templates changed');
    }
    // if (props.transactionEmails.email_templates &&
    //   !props.transactionEmails.email_templates.getInProgress
    // ) {
    //   if (props.emailTemplateId &&
    //     props.transactionEmails.email_templates &&
    //     props.transactionEmails.email_templates.keyValue &&
    //     props.transactionEmails.email_templates.keyValue[props.emailTemplateId] &&
    //     props.transactionEmails.email_templates.keyValue[props.emailTemplateId].name &&
    //     !nameChanged &&
    //     !changeTemplateTouched
    //   ) {
    //     setName(props.transactionEmails.email_templates.keyValue[props.emailTemplateId].name || '');
    //     setDesc(props.transactionEmails.email_templates.keyValue[props.emailTemplateId].desc || '');
    //     setSelectedTemplate(props.transactionEmails.email_templates.keyValue[props.emailTemplateId]);
    //   }
    // }
    if (selectedTemplate && selectedTemplate._id &&
      props.transactionEmails.email_templates &&
      props.transactionEmails.email_templates.keyValue &&
      props.transactionEmails.email_templates.keyValue[selectedTemplate._id] &&
      props.transactionEmails.email_templates.keyValue[selectedTemplate._id].editStatus &&
      props.transactionEmails.email_templates.keyValue[selectedTemplate._id].editStatus.success
    ) {
      setSomeChange(false);
      props.onSaveClick(selectedTemplate);
      // const params = {
      //   location__id: props.locationInfo._id,
      //   email_template_id: selectedTemplate._id,
      //   mode: 'edit',
      // };
      // dispatch(editDataClearAction({
      //   op: 'edit_email_template',
      //   params,
      // }));
    }
    if (selectedTemplate && selectedTemplate.new &&
      props.transactionEmails.email_templates &&
      !props.transactionEmails.email_templates.addInProgress &&
      props.transactionEmails.email_templates.addStatus &&
      props.transactionEmails.email_templates.addStatus.success &&
      props.transactionEmails.email_templates.addStatus.data
    ) {
      setSomeChange(false);
      setSelectedTemplate(props.transactionEmails.email_templates.addStatus.data);
      props.onSaveClick(props.transactionEmails.email_templates.addStatus.data);
      // const params = {
      //   location__id: props.locationInfo._id,
      //   mode: 'add',
      // };
      // dispatch(editDataClearAction({
      //   op: 'edit_email_template',
      //   params,
      // }));
    }
  }, [props.transactionEmails.email_templates]);

  const exportHtml = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      if (process.env.NODE_ENV !== 'production') {
        console.log('exportHtml', html);
      }
    });
  };

  const onDesignLoad = (data) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onDesignLoad', data);
    }
    setTemplateLoading(false);
  };

  // const onLoad = () => {
  //   console.log('EmailTemplate onLoad');
  //
  //   // emailEditorRef.current.editor.addEventListener(
  //   //   'design:loaded',
  //   //   onDesignLoad
  //   // );
  //
  //   if (emailEditorRef.current) {
  //     emailEditorRef.current.editor.loadDesign(sample);
  //   } else {
  //     console.log('emailEditorRef.current is null');
  //   }
  // }

  // const onLoad = () => {
  //   // editor instance is created
  //   // you can load your template here;
  //   // if (emailEditorRef.current) {
  //   //   emailEditorRef.current.editor.loadDesign(email_templates_json);
  //   // }
  // };
  const onLoadEditor = useCallback(() => {
    const timer = setInterval(function() {
      if (emailEditorRef && emailEditorRef.current && emailEditorRef.current.editor) {
        emailEditorRef.current.editor.addEventListener(
          'design:loaded',
          onDesignLoad,
        );
        if (process.env.NODE_ENV !== 'production') {
          console.log('EmailTemplate onLoadEditor emailTemplateId: ', props.emailTemplateId);
        }
        if (props.emailTemplateId) {
          if (props.transactionEmails.email_templates && props.transactionEmails.email_templates.keyValue &&
            props.transactionEmails.email_templates.keyValue[props.emailTemplateId] &&
            props.transactionEmails.email_templates.keyValue[props.emailTemplateId].json
          ) {
            emailEditorRef.current.editor.loadDesign(props.transactionEmails.email_templates.keyValue[props.emailTemplateId].json);
          } else {
            // emailEditorRef.current.editor.loadDesign(sample);
          }
        } else {
          if (process.env.NODE_ENV !== 'production') {
            console.log('EmailTemplate onLoadEditor refTemplateId.current: ', refTemplateId.current);
          }
          // emailEditorRef.current.editor.loadDesign(sample);
          if (refTemplateId && refTemplateId.current &&
            props.transactionEmails.email_templates && props.transactionEmails.email_templates.keyValue &&
            props.transactionEmails.email_templates.keyValue[refTemplateId.current] &&
            props.transactionEmails.email_templates.keyValue[refTemplateId.current].json
          ) {
            emailEditorRef.current.editor.loadDesign(props.transactionEmails.email_templates.keyValue[refTemplateId.current].json);
          }
        }
        // emailEditorRef.current.addEventListener('design:updated', () => emailEditorRef.current.exportHtml(onChange));
        emailEditorRef.current.addEventListener('design:updated', () => {
          setSomeChange(true); setEmailBuilderChanged(true);
          // clearAction();
          if (process.env.NODE_ENV !== 'production') {
            console.log('emailEditorRef design:updated');
          }
        });
        clearInterval(timer);
        emailEditorRef.current.editor.registerProvider('userUploads', function (params, done) {
          const page = params.page || 1;
          const perPage = params.perPage || 20;
          const total = 100;
          const hasMore = total > page * perPage;

          // Load images from your database here...
          let images = [];
          const { engage } = props.settings;
          if (engage && engage.hotel_logo_url) {
            let temp = {
              id: Date.now(),
              location: engage.hotel_logo_url,
              source: 'user'
            }
            temp.width = engage.hotel_logo_width || 500;
            temp.height = engage.hotel_logo_height || 500;
            temp.contentType = engage.hotel_logo_content_type || 'image/jpg';
            images.push(temp);
          }
        
          done(images, { hasMore, page, perPage, total });
        });
        emailEditorRef.current.editor.setBodyValues({
          preheaderText: "{{pre_header_text}}",
        });
      }
    }, 500);
  }, [ emailEditorRef ]);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('useEffect emailEditorRef: ', emailEditorRef);
    }
    if (emailEditorRef.current && emailEditorRef.current.editor) {
      if (selectedTemplate && selectedTemplate.json) {
        if (process.env.NODE_ENV !== 'production') {
          console.log('useEffect emailEditorRef selectedTemplate.json');
        }
        emailEditorRef.current.editor.loadDesign(selectedTemplate.json);
      }
      // emailEditorRef.current.editor.loadDesign(sample);
    }
  }, [emailEditorRef]);

  const onReady = () => {
    // editor is ready
    if (process.env.NODE_ENV !== 'production') {
      console.log('onReady');
    }
  };

  const onCloseClick = (e) => {
    // emailEditorRef = null;
    props.onCancelClick();
  };
  const onEditNameClick = (e) => {
    setNameEditEnabled(true);
    setTimeout(() => {
      if (nameRef && nameRef.current) {
        nameRef.current.focus();
      }
    }, 100);
  };
  const onNameChange = (e) => {
    const { name, value } = e.target;
    setName(value);
    setNameChanged(true);
    setSomeChange(true);
    setNameError(!value);
    clearAction();
  };

  const onEditDescClick = (e) => {
    setDescEditEnabled(true);
    setTimeout(() => {
      if (descRef && descRef.current) {
        descRef.current.focus();
      }
    }, 100);
  };
  const onDescChange = (e) => {
    const { name, value } = e.target;
    setDesc(value);
    setDescChanged(true);
    setSomeChange(true);
    setDescError(!value);
    clearAction();
  };

  const onTemplateChangeClick = (e) => {
    if (props.isReadOnly) return;
    // if (emailEditorRef && emailEditorRef.current && emailEditorRef.current.editor) {
    //   emailEditorRef.current.editor.loadBlank({
    //     backgroundColor: '#e7e7e7',
    //   });
    // }
    setShowTemplateSelectorModal(true);
    setChangeTemplateTouched(true);
  };
  const onShowTemplateSelectorCloseModal = (e) => {
    setShowTemplateSelectorModal(false);
  };
  const onTemplateSelectorConfirmClick = (data) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onTemplateSelectorConfirmClick data: ', data);
    }
    setSelectedTemplate(data);
    if (data && data.new) {
      setName('');
      setDesc('');
      setEmailBuilderChanged(true);
      setNameEditEnabled(true);
      setDescEditEnabled(true);
      if (emailEditorRef && emailEditorRef.current && emailEditorRef.current.editor) {
        emailEditorRef.current.editor.loadBlank({
          backgroundColor: '#e7e7e7',
        });
      }
      // const params = {
      //   location__id: props.locationInfo._id,
      //   mode: 'add',
      // };
      // dispatch(editDataClearAction({
      //   op: 'edit_email_template',
      //   params,
      // }));
      clearAction();
    }
    if (data && data._id) {
      refTemplateId.current = data._id;
      setName(data.name || '');
      setNameEditEnabled(true);
      setDesc(data.desc || '');
      setDescEditEnabled(true);
      setSomeChange(true);
      if (process.env.NODE_ENV !== 'production') {
        console.log('loadDesign data._id: ', data._id);
      }
      if (emailEditorRef && emailEditorRef.current && emailEditorRef.current.editor) {
        if (process.env.NODE_ENV !== 'production') {
          console.log('loadDesign data.json');
        }
        emailEditorRef.current.editor.loadDesign(data.json)
      }
      const params = {
        location__id: props.locationInfo._id,
        email_template_id: data._id,
        mode: 'edit',
      };
      dispatch(editDataClearAction({
        op: 'edit_email_template',
        params,
      }));
    }
    setShowTemplateSelectorModal(false);

  };

  const onShowEmailBuilderTipsCloseModal = (e) => {
    setShowEmailBuilderTipsModal(false);
  };
  const onEmailBuilderTipsConfirmClick = (data) => {
    setShowEmailBuilderTipsModal(false);
  };

  const onSaveClick = (e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onSaveClick someChange: ', someChange);
      console.log('onSaveClick name: ', name);
      console.log('onSaveClick nameChanged: ', nameChanged);
      console.log('onSaveClick desc: ', desc);
      console.log('onSaveClick descChanged: ', descChanged);
      console.log('onSaveClick emailBuilderChanged: ', emailBuilderChanged);
    }
    if (!someChange) return;
    if (!name) {
      setNameError(true);
      return;
    }
    if (!desc) {
      setDescError(true);
      return;
    }
    // on template selection changed
    if (!nameChanged && !descChanged && !emailBuilderChanged) {
      if (selectedTemplate && selectedTemplate._id) {
        props.onSaveClick(selectedTemplate);
        setShowInProgress(true);
        setTimeout(() => {
          setSomeChange(false);
          setShowInProgress(false);
        }, 1000);
      }
      return;
    }
    // only name is updated
    if ((nameChanged || descChanged) && !emailBuilderChanged) {
      if (selectedTemplate && selectedTemplate._id) {
        const params = {
          location__id: props.locationInfo._id,
          template_data: {
            name,
            desc,
          },
        };
        if (selectedTemplate.new) {
          params.mode = 'add';
        } else {
          params.mode = 'edit';
          params.email_template_id = selectedTemplate._id;
        }
        dispatch(editDataAction({
          op: 'edit_email_template',
          params,
        }));
        setSomeChange(false);
      }
      return;
    }
    // email format has changed
    if (emailBuilderChanged) {
      emailEditorRef.current.editor.exportHtml((data) => {
        const { design, html } = data;
        if (process.env.NODE_ENV !== 'production') {
          console.log('exportHtml html: ', html);
          console.log('exportHtml design: ', design);
        }
        const params = {
          location__id: props.locationInfo._id,
          template_data: {
            name,
            desc,
            json: design,
            html: DOMPurify.sanitize(html),
          },
        };
        if (selectedTemplate.new) {
          params.mode = 'add';
        } else {
          params.mode = 'edit';
          params.email_template_id = selectedTemplate._id;
        }
        dispatch(editDataAction({
          op: 'edit_email_template',
          params,
        }));
        setSomeChange(false);
      });
    }
  };
  const onSendTestEmail = (e) => {
    clearAction();
    if (!selectedTemplate._id) {
      setTestEmailError('Please save');
      return;
    }
    setTestEmailError('');
    const params = {
      location__id: props.locationInfo._id,
      email_template_id: selectedTemplate._id,
    };
    dispatch(editDataClearAction({
      op: 'email_template_send_test_email',
      params,
    }));
    setShowSendTestEmailModal(true);
  };

  const onShowSendTestEmailModalCloseModal = (e) => {
    setShowSendTestEmailModal(false);
  };
  const onSendTestEmailModalConfirmClick = (email) => {
    const params = {
      location__id: props.locationInfo._id,
      email_template_id: selectedTemplate._id,
      to: email,
    };
    dispatch(editDataAction({
      op: 'email_template_send_test_email',
      params,
    }));
  };

  const onBuilderInstructionsClick = (e) => {
    setShowEmailBuilderTipsModal(true);
  };

  const renderEmailBuilder = () => {
    const editorStyle = {};
    editorStyle.height = windowState.window.windowHeight - (20 + 20 + 15 + 90 + 40);
    return (
      <div className="emailBuilder">
        {
          showEditor &&
          <React.StrictMode>
          <EmailEditor ref={emailEditorRef} onLoad={onLoadEditor} onReady={onReady}
            projectId="80039"
            style={editorStyle}
            options={{
              tabs: {
                blocks: {
                  enabled: true,
                },
              },
              user: {
                id: props.userState.user.user_id,
                // name: props.userprops.user.uid,
                // email: props.userprops.user.uid
              },
              mergeTags: mergeTagValues,
              customCSS: [
                `
                .blockbuilder-preview {
                  background-color: rgb(249, 249, 249);
                  background-image: none;
                }
                `
              ],
              appearance: {
                loader: {
                  url: 'https://d3hz2dasjf76zw.cloudfront.net/images/gt/loading_icon.gif',
                  // html: '<div class="custom-spinner"></div>',
                  // css: '.custom-spinner { color: red; }'
                },
              },
              specialLinks: [
                {
                  name: 'Unsubscribe',
                  href: '{{unsubscribe_url}}',
                  target: '_blank',
                }
              ],
            }}
            // templateId="95735"
          />
          </React.StrictMode>
        }
      </div>
    );
  };

  const renderContent = () => {
    // let locationInfo = null;
    // if (props.locationprops.location.location_id &&
    //   props.locationprops.location.location__id &&
    //   props.userprops.user.locations.keyValue[props.locationprops.location.location__id]
    // ) {
    //   locationInfo = props.userprops.user.locations.keyValue[props.locationprops.location.location__id];
    // }
    // if (!locationInfo) return null;

    const { email_templates } = props.transactionEmails;
    let sendTestEmailInProgress = selectedTemplate && selectedTemplate._id &&
      email_templates &&
      email_templates.keyValue &&
      email_templates.keyValue[selectedTemplate._id] &&
      email_templates.keyValue[selectedTemplate._id].sendTestEmailInProgress;
    let sendTestEmailStatus = selectedTemplate && selectedTemplate._id &&
        email_templates &&
        email_templates.keyValue &&
        email_templates.keyValue[selectedTemplate._id] &&
        email_templates.keyValue[selectedTemplate._id].sendTestEmailStatus || {};

    if (process.env.NODE_ENV !== 'production') {
      console.log('EmailTemplate sendTestEmailInProgress: ', sendTestEmailInProgress);
      console.log('EmailTemplate sendTestEmailStatus: ', sendTestEmailStatus);
    }

    let cStyle = {
      height: windowState.window.windowHeight,
    }

    let addEditStatus = {};
    addEditStatus = email_templates.addStatus;
    if (selectedTemplate && selectedTemplate._id) {
      if (
        email_templates &&
        email_templates.keyValue &&
        email_templates.keyValue[selectedTemplate._id]
      ) {
        addEditStatus = email_templates.keyValue[selectedTemplate._id].editStatus;
      }
    }

    if (email_templates && email_templates.getInProgress) {
      return (
        <div className="emailTemplateBox loading" ref={modalRef}>
          <div className="content">
            <div className="closeBar">
              <p className="title">Loading...</p>
              <span className="close" onClick={onCloseClick}><img src={closeIcon} />Close</span>
            </div>
            <div className="etBdy">
              <span><i className="fa fa-spinner fa-spin" /></span>
            </div>
          </div>
        </div>
      );
    }

    return (
      <>
        <div className="emailTemplateBox" ref={modalRef} >
          <div className="content">
            {/*<div className="closeBar">
              <p className="title">{props.title}</p>
              <span className="close" onClick={onCloseClick}><img src={closeIcon} />Close</span>
            </div>*/}
            <div className="templateTypeBox">
              <span>{TRANSACTION_EMAIL_TYPE_TO_LABEL[props.type]}</span>
            </div>
            {
              templateLoading &&
              <div className="templateLoading">
                <span><i className="fa fa-spinner fa-spin" /></span>
              </div>
            }
            <div className="top">
              <div className="left">
                <div className="nameSection">
                  <div className="nameBox">
                    <div className="top">
                      <input className={(nameError ? 'inputError' : '')} type="text" value={name}
                        onChange={onNameChange} readOnly={!nameEditEnabled} ref={nameRef}
                        placeholder="Name"
                      />
                      <span className="enableEdit" onClick={onEditNameClick}><img src={editIcon} /></span>
                    </div>
                    {/*
                      nameError &&
                      <div className="error">
                        Name is required
                      </div>
                    */}
                  </div>
                  <span className="changeTemplate" onClick={onTemplateChangeClick}>
                    {
                      (selectedTemplate && selectedTemplate._id)
                      ? 'Change Template'
                      : 'Choose Template'
                    }
                  </span>
                </div>
                <div className="nameSection desc">
                  <div className="nameBox">
                    <div className="top">
                      <input className={(descError ? 'inputError' : '')} type="text" value={desc}
                        onChange={onDescChange} readOnly={!descEditEnabled} ref={descRef}
                        placeholder="Description"
                      />
                      <span className="enableEdit" onClick={onEditDescClick}><img src={editIcon} /></span>
                    </div>
                    {/*<div className="error">
                      { descError ? 'Desc is required' : '' }
                    </div>*/}
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="top">
                  {
                    (selectedTemplate.new || selectedTemplate._id)
                    ?
                      <div className="saveSection">
                        <div className="section save">
                          {
                            !props.isReadOnly &&
                            <span className={'save ' + (someChange ? '' : 'disabled ')} onClick={onSaveClick}>
                              Save
                              { (
                                  (selectedTemplate && selectedTemplate._id &&
                                    email_templates &&
                                    email_templates.keyValue &&
                                    email_templates.keyValue[selectedTemplate._id] &&
                                    email_templates.keyValue[selectedTemplate._id].editInProgress
                                  ) ||
                                  (
                                    selectedTemplate && selectedTemplate.new &&
                                    email_templates &&
                                    email_templates.addInProgress
                                  ) ||
                                  showInProgress
                                ) &&
                                <span><i className="fa fa-spinner fa-spin" /></span> }
                            </span>
                          }
                          <div className="error">
                            {
                              (
                                (
                                  selectedTemplate && selectedTemplate._id &&
                                  email_templates &&
                                  email_templates.keyValue &&
                                  email_templates.keyValue[selectedTemplate._id] &&
                                  email_templates.keyValue[selectedTemplate._id].editStatus &&
                                  email_templates.keyValue[selectedTemplate._id].editStatus.error
                                ) ||
                                (
                                  selectedTemplate && selectedTemplate.new &&
                                  email_templates &&
                                  email_templates.addStatus &&
                                  email_templates.addStatus.error
                                )
                              ) &&
                              <span>Error in saving</span>
                            }
                          </div>
                        </div>
                        <div className="section send">
                          <span className="sendTest" onClick={onSendTestEmail}>Send test email</span>
                          <div className="error">
                            {
                              testEmailError && <span>{testEmailError}</span>
                            }
                          </div>
                        </div>
                        <div className="section close">
                          <span className="close" onClick={onCloseClick}><img src={closeIcon} />Close</span>
                        </div>
                      </div>
                    :
                    <div className="saveSection">
                      <div className="section close">
                        <span className="close" onClick={onCloseClick}><img src={closeIcon} />Close</span>
                      </div>
                    </div>
                  }
                </div>
                {
                  addEditStatus && addEditStatus.success
                  ?
                    <div className="bottom">
                      {
                        selectedTemplate && selectedTemplate._id
                        ? 'Updated successfully'
                        : 'Added successfully'
                      }

                    </div>
                  : null
                }
              </div>
            </div>
            <div className="builderInstructions">
              <p onClick={onBuilderInstructionsClick}>Tips for building emails efficiently</p>
            </div>
            <div className="etBdy">
              {
                (selectedTemplate.new || selectedTemplate._id) &&
                renderEmailBuilder()
              }
            </div>
          </div>
          { showTemplateSelectorModal &&
            <TemplateSelectorModal
              modalType="templateSelector"
              title={(selectedTemplate._id || selectedTemplate.new) ? 'Change Template' : 'Choose Template'}
              bodyRowOne="Choose a template or create a new one."
              bodyRowNote=""
              setModalVisible={onShowTemplateSelectorCloseModal}
              onAffirmativeClick={onTemplateSelectorConfirmClick}
              inProgress={false}
              // currentStatus={guest.checkOutStatus}
              showCancel={true}
              affirmativeButtonLabel={'Proceed'}
              disableOutsideClick={true}
              selectedTemplate={selectedTemplate}
              transactionEmails={props.transactionEmails}
              locationInfo={props.locationInfo}
            />
          }
          { showEmailBuilderTipsModal &&
            <EmailBuilderTipsModal
              modalType="templateSelector"
              title="Tips"
              bodyRowOne=""
              bodyRowNote=""
              setModalVisible={onShowEmailBuilderTipsCloseModal}
              onAffirmativeClick={onEmailBuilderTipsConfirmClick}
              inProgress={false}
              // currentStatus={guest.checkOutStatus}
              showCancel={true}
              affirmativeButtonLabel={'Close'}
              disableOutsideClick={true}
            />
          }
          { showSendTestEmailModal &&
            <SendTestEmailModal
              modalType="templateSelector"
              title={`Send Test Email`}
              bodyRowOne=""
              bodyRowNote="Do save first then enter email here to see your latest changes via email. Guest specific dynamic variables will be replaced in LIVE emails."
              setModalVisible={onShowSendTestEmailModalCloseModal}
              onAffirmativeClick={onSendTestEmailModalConfirmClick}
              inProgress={sendTestEmailInProgress}
              currentStatus={sendTestEmailStatus}
              showCancel={true}
              affirmativeButtonLabel={'Proceed'}
              disableOutsideClick={true}
              to={userState.user.user_info.uid}
            />
          }
        </div>
      </>
    );
  }

  if (process.env.NODE_ENV !== 'production') {
    console.log('EmailTemplate emailEditorRef: ', emailEditorRef);
    console.log('EmailTemplate selectedTemplate: ', selectedTemplate);
    console.log('EmailTemplate desc: ', desc);
  }

  return renderContent();

}

export default EmailTemplate;
