import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";

// import LocationWrapper from './LocationWrapper';
import ToolTipWrapper from '../../../Misc/ToolTipWrapper';

import infoIcon from '../../../../img/icons/info.svg';
import journeySmsIcon from '../../../../img/icons/settings/settings-chat.svg';
import journeySmsActiveIcon from '../../../../img/icons/settings/settings-chat-active.svg';
import journeyWhatsappIcon from '../../../../img/icons/settings/settings-whatsapp.svg';
import journeyWhatsappActiveIcon from '../../../../img/icons/settings/settings-whatsapp-active.svg';
import journeyEmailIcon from '../../../../img/icons/settings/sender-emails.svg';
import journeyEmailActiveIcon from '../../../../img/icons/settings/sender-emails-active.svg';
import journeyRulesIcon from '../../../../img/icons/settings/settings-rules.svg';
import journeyRulesActiveIcon from '../../../../img/icons/settings/settings-rules-active.svg';

import './style.css';

let MENU_OPTIONS = [];
MENU_OPTIONS.push({
  title: 'SMS',
  desc: 'Set pre-defined sms messages',
  id: 'sms',
  icon: journeySmsIcon,
  iconActive: journeySmsActiveIcon,
  ttText: 'SMS',
});
MENU_OPTIONS.push({
  title: 'WhatsApp',
  desc: 'Set pre-defined WhatsApp messages',
  id: 'whatsapp',
  icon: journeyWhatsappIcon,
  iconActive: journeyWhatsappActiveIcon,
  ttText: 'WhatsApp',
});
MENU_OPTIONS.push({
  title: 'Email',
  desc: 'Set pre-defined email messages',
  id: 'email',
  icon: journeyEmailIcon,
  iconActive: journeyEmailActiveIcon,
  ttText: 'Email',
});
MENU_OPTIONS.push({
  title: 'Rule Based (Optional)',
  desc: 'Set pre-defined guest journey messages based on rules for specific rooms',
  id: 'rule-based',
  icon: journeyRulesIcon,
  iconActive: journeyRulesActiveIcon,
  ttText: 'Rule Based',
});

const MenuEntry = ({ id, className, pathname, label, desc, link, linkState, icon, iconActive, ttText, menuOpen }) => {
  let active = pathname.indexOf(link) > -1;
  // if (link === '/reputation/locations') {
  //   if (pathname === '/reputation/locations' || pathname === '/reputation/locations/') { active = true; } else { active = false; }
  // }
  return (
    <li className={(className ? className : '') + (active ? ' active' : '')} key={id}>
      <ToolTipWrapper placement="right" overlayClassName={'sbTooltip ' + (menuOpen ? 'hide' : '')}
        overlay={ttText}
      >
      <Link to={link} state={linkState || {}}>
        <div className="lt">
          <img src={active ? iconActive : icon} />
        </div>
        <div className="rt">
          <p>{label}</p>
          <span>{desc}</span>
        </div>
      </Link>
      </ToolTipWrapper>
    </li>
  );
}

function ItemRow(props) {
  const onRowClick = (e) => {
    props.onRowClick(props.data);
  };
  if (process.env.NODE_ENV !== 'production') {
    console.log('ItemRow props: ', props);
  }
  const { active, icon, iconActive, link, linkState, defaultMessagingChannel } = props;
  return (
    <Link to={link} state={linkState || {}} className={'gLstRw' + (props.active ? ' active' : '')}>
      <div className="lt">
        <img src={active ? iconActive : icon} />
      </div>
      <div className="rt">
        <div className="tp">
          {props.data.title}
          {
            defaultMessagingChannel === props.data.id &&
            <span className="def">(Default)</span>
          }
        </div>
        <div className="bt">
          {props.data.desc}
        </div>
      </div>
    </Link>
  );
}
function ItemRow_Old(props) {
  const onRowClick = (e) => {
    props.onRowClick(props.data);
  };
  return (
    <div className={'gLstRw' + (props.active ? ' active' : '')} onClick={onRowClick}>
      <div className="lt">
        <img src={props.data.icon} />
      </div>
      <div className="rt">
        <div className="tp">
          {props.data.title}
        </div>
        <div className="bt">
          {props.data.desc}
        </div>
      </div>
    </div>
  );
}

function JMList(props) {
  const user = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [screenBeforeSettings, setScreenBeforeSettings] = useState({});

  useEffect(() => {
    // if (process.env.NODE_ENV !== 'production') {
    //   console.log('SideBarInner urlParams: ', urlParams);
    //   console.log('SideBarInner location: ', location);
    // }
    const { pathname, state } = location;
    // if (pathname.indexOf('/reputation') > -1) setProduct('reputation');
    // if (pathname.indexOf('/engage') > -1) setProduct('engage');
    // if (pathname.indexOf('/marketing') > -1) setProduct('marketing');
    // if (pathname.indexOf('/profile') > -1) setSettingFor('profile');
    // if (pathname.indexOf('/hotel-details') > -1) setSettingFor('hotel-details');
    // if (pathname.indexOf('/templates') > -1) setSettingFor('templates');
    // if (pathname.indexOf('/webchat') > -1) setSettingFor('webchat');
    // if (pathname.indexOf('/journey-messages') > -1) setSettingFor('journey-messages');
    // if (pathname.indexOf('/response-templates') > -1) setSettingFor('response-templates');
    // if (pathname.indexOf('/guestai-templates') > -1) setSettingFor('guestai-templates');
    // if (pathname.indexOf('/reviews-widget') > -1) setSettingFor('reviews-widget');

    setScreenBeforeSettings(state || {});

  }, [urlParams, location]);

  const onItemRowClick = (rowData) => {
    const { locationidentifier } = urlParams;
    // navigate(`/engage/locations/${locationidentifier}/journey-messages/${rowData.id}`);
    navigate(`/settings/locations/${locationidentifier}/journey-messages/${rowData.id}`);
  }

  const { pathname } = location;
  const { locationInfo, settings } = props;

  if (process.env.NODE_ENV !== 'production') {
    console.log('JMList props: ', props);
    console.log('JMList screenBeforeSettings: ', screenBeforeSettings);
  }

  return (
    <div className="gLstWrp jMLst">
      <div className="hdr">
      <div className="ttl">
        <h3>Journey Messages</h3>
        <ToolTipWrapper placement="bottom" overlayClassName={'infoTooltip'}
          overlay={"Manage Journey Messages"}
        >
          <img src={infoIcon} />
        </ToolTipWrapper>
      </div>
      </div>
      <div className="gLstBd">
        {
          MENU_OPTIONS.map((item) => {
            if (item.id === 'sms' && (!locationInfo || !locationInfo.has_engage_sms)) return null;
            if (item.id === 'whatsapp' && (!locationInfo || !locationInfo.has_engage_whatsapp)) return null;
            if (item.id === 'email' && (!locationInfo || !locationInfo.has_engage_email)) return null;
            return (
              <ItemRow data={item} onRowClick={onItemRowClick}
                // link={`/${settingFor}/${entry.id}`}
                link={`/settings/locations/${urlParams.locationidentifier}/journey-messages/${item.id}`}
                linkState={screenBeforeSettings}
                active={pathname.indexOf(`/journey-messages/${item.id}`) > -1 ? true : false}
                icon={item.icon}
                iconActive={item.iconActive}
                defaultMessagingChannel={settings.engage ? settings.engage.default_messaging_channel : ''}
              />
            )
          })
        }
      </div>
    </div>
  );
}

export default JMList;
