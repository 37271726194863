import { useSelector, useDispatch } from "react-redux";
import ReactDOM from 'react-dom';
import { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import { Interweave } from 'interweave';
import copy from 'copy-to-clipboard';
// import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';

import { editDataAction, editDataClearAction } from '../../../../../../redux/actions/editDataActions';
import { receiveSnack, removeSnack } from '../../../../../../redux/actions/snacksActions';

import ImagePreview from '../../../../../Misc/ImagePreview';
import SentEmailPreviewModal from '../../../../../Misc/SentEmailPreviewModal';

import { formatDateChange, formatMessageDate, TRANSACTION_EMAIL_TYPE_TO_LABEL } from '../../../../../../helpers';

import './style.css';

import whatsappIcon from '../../../../../../img/icons/whatsapp.png';
import smsIcon from '../../../../../../img/icons/sms.svg';
import emailIcon from '../../../../../../img/icons/email.svg';
import googleIcon from '../../../../../../img/icons/google-logo.png';
import IconDelivered from '../../../../../../img/icons/iconDelivered.png';

const modalRoot = document.getElementById('modal-root');
function Modal(props) {
  const [el, setEl] = useState(document.createElement('div'));
  useEffect(() => {
    // this.el = document.createElement('div');
    // setEl(document.createElement('div'));
    modalRoot.appendChild(el);
    return function cleanup() {
      modalRoot.removeChild(el);
    }
  }, []);
  return ReactDOM.createPortal(
    props.children,
    el,
  );
}

function MessageSent(props) {
  const userState = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let location = useLocation();
  let urlParams = useParams();

  const [imagePreviewUrl, setImagePreviewUrl] = useState('');
  const [showImagePreviewModal, setShowImagePreviewModal] = useState(false);
  const imagePreviewModalRef = useRef();

  const [showEmailPreviewModal, setShowEmailPreviewModal] = useState(false);

  const onSendMessageRetryClick = (e) => {
    let params = {
      location__id: urlParams.locationidentifier,
      location_id: props.message.location_id,
      guest_id: props.message.guest_id || undefined,
      outside_guest_id: props.message.outside_guest_id || undefined,
      type: props.guestType && props.guestType[props.guestType.length-1] === 's' ? props.guestType.substring(0, props.guestType.length-1) : props.guestType,
      msg: props.message.msg,
      lang: 'en',
      medium: props.message.medium,
      new_msg_id: props.message._id,
      retry: true,
      retry_message_id: props.message._id,
    };
    dispatch(editDataAction({
      op: 'send_message_retry',
      params,
    }));
    // gtag('event', 'gt_click', {
    //   'event_category': `Messaging - ${resultTypeToLabel[props.guestType] || props.guestType}`,
    //   'event_label': `Send Message - Retry`,
    //   // 'value': 1,
    //   user_id: props.userprops.user.uid,
    // });
  }

  const onCopyToClipboard = (e) => {
    copy(props.message.msg);
    const id = new Date().getTime();
    dispatch(receiveSnack({
      snack: {
        id,
        msg: 'Copied.',
      },
    }));
    // remove the snack
    setTimeout(() => {
      dispatch(removeSnack({
        id,
      }));
    }, 2000);
    // gtag('event', 'gt_click', {
    //   'event_category': `Messaging - ${resultTypeToLabel[props.guestType] || props.guestType}`,
    //   'event_label': `Copy - Sent Message`,
    //   // 'value': 1,
    //   user_id: props.userprops.user.uid,
    // });
  };

  const onEditJourneyMessage = (e) => {
    const { pathname } = location;
    const { locationidentifier } = urlParams;
    navigate(`/settings/locations/${locationidentifier}/journey-messages`,
      { state: { screenBeforeSettingsName: 'Guests', screenBeforeSettingsPath: pathname }}
    );
  };

  const onAddAsMessageTemplateClick = (e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onAddAsMessageTemplateClick');
    }
    props.onAddAsMessageTemplateClick();
  };

  const onShowImagePreviewClick = (url) => {
    setImagePreviewUrl(url);
    setShowImagePreviewModal(true);
  };
  const onImagePreviewModalCloseClick = (e) => {
    setImagePreviewUrl('')
    setShowImagePreviewModal(false);
  };

  const onPreviewOpenClick = (e) => {
    setShowEmailPreviewModal(true);
    // analytics reporting
    // try {
    //   const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
    //     && locationState.locations[urlParams.locationidentifier].settings
    //     && locationState.locations[urlParams.locationidentifier].settings.info
    //     ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
    //     : '';
    //   window.gtag('event', 'page_view', {
    //     page_path: `${location.pathname}`,
    //     page_title: `Engage - Messages - Transaction Email - Preview - ${locationName}`,
    //     gt_user_id: userState.user.uid,
    //     gt_location_id: urlParams.locationidentifier,
    //     gt_location: locationName,
    //   });
    // } catch (e) {}
  };

  const onEmailPreviewCloseModal = (e) => {
    setShowEmailPreviewModal(false);
  };
  const onEmailPreviewConfirmClick = (data) => {
    setShowEmailPreviewModal(false);
  };

  const renderMessageStatus = (message) => {
    if (message.medium === 'email') {
      if (message.date_email_status_open) {
        return (
          <span className="msgStatusText">Opened</span>
        );
      }
      if (message.date_email_status_delivery) {
        return (
          <span className="msgStatusText">Delivered</span>
        );
      }
    }
    if (message.sendingInProgress) {
      return ( <span><i className="fas fa-spinner fa-spin"></i></span> );
    }
    if (message.sendMessageStatus && message.sendMessageStatus.error) {
      return ( <span className="retry" onClick={onSendMessageRetryClick}>Retry <i className="fas fa-redo"></i></span> );
    }
    if (message.status_read_at) {
      return ( <span className="msgStatusText">Read</span> );
      // return ( <img src={IconDelivered} className="msgStatusIcon" /> );
    }
    if (message.status === "delivered") {
      return ( <span className="msgStatusText">Delivered</span> );
      // return ( <img src={IconDelivered} className="msgStatusIcon" /> );
    }
    if (message.is_transaction_email) {
      if (message.date_email_status_open) {
        return ( <span className="msgStatusText">Opened</span> );
      }
      if (message.date_email_status_delivery) {
        return ( <span className="msgStatusText">Delivered</span> );
      }
    }
    if (message.status === "error") {
      return ( <span className="retry" onClick={onSendMessageRetryClick}>Retry <i className="fas fa-redo"></i></span> );
    }
    return (
      <span className="msgStatusText">Sent</span>
    );
  };

  const renderImagePreviewModal = () => {
    return (
      <Modal>
        <div id="cModelId" className="cModal imgPrv">
          <div className="ctnt" ref={imagePreviewModalRef}>
            <ImagePreview
              image={imagePreviewUrl}
              onConfirmClick={onImagePreviewModalCloseClick}
              hideConfirm={{}}
              onCancelClick={onImagePreviewModalCloseClick}
              inProgress={false}
              uploadError={''}
            />
          </div>
        </div>
      </Modal>
    );
  };

  const renderContent = () => {
    const { message, dateChange, dateChangePosition, isLast } = props;
    if (!message) return null;

    const isTransactionEmail = message.is_transaction_email || false;

    let sentBy = 'Automated';
    if (message.sent_by) {
      if (message.sent_by === userState.user.user_id) {
        sentBy = `Sent by ${message.sent_by} (You)`;
      } else {
        if (message.sent_by_name) {
          sentBy = `Sent by ${message.sent_by_name}`;
        } else {
          sentBy = `Sent by ${message.sent_by}`;
        }
      }
    }
    if (message.is_broadcast) {
      sentBy = 'Broadcasted';
    }
    if (message.auto_reply) {
      sentBy = 'Auto Reply';
    }

    return (
      <>
        {
          dateChange && dateChangePosition === 'bottom' &&
          <div className="dtCh">
            <div className="line" /><p>{formatDateChange(dateChange)}</p><div className="line" />
          </div>
        }
        <div className={'stMB ' + (isLast ? 'latest' : '')} key={message._id}>
          <div className="loB">
          </div>
          <div className="msB">
            <div className="msg">
              { isTransactionEmail &&
                <p className="trnsEm">
                  { message.data.type &&
                    <><span>{TRANSACTION_EMAIL_TYPE_TO_LABEL[message.data.type]}</span> - Transaction Email</>
                  }
                </p>
              }
              <div className="top">
                <p className={message.medium === 'email' || isTransactionEmail ? 'email' : ''} id={message._id}>
                  {message.msg_subject && <span className="subject">Sub: {message.msg_subject}</span> }
                  {isTransactionEmail && message.sent_email.subject && <span className="subject">Sub: {message.sent_email.subject}</span> }
                  <Interweave content={message.msg} />
                  {isTransactionEmail && <span className="trnsEmPrv" onClick={onPreviewOpenClick}>Click to preview email</span>}
                </p>
                {
                  message.msg &&
                  <div className="dd">
                    <button className="dbtn"><i className="fa fa-caret-down" /></button>
                    <div className="ddc">
                      <p onClick={onCopyToClipboard}>Copy</p>
                      {
                        message.msg_type && !props.isReadOnly &&
                        <p onClick={onEditJourneyMessage}>Edit Journey Message</p>
                      }
                      {
                        !message.msg_type && !props.isReadOnly &&
                        <p onClick={onAddAsMessageTemplateClick}>Add as message template</p>
                      }
                    </div>
                  </div>
                }
              </div>
              <div className="bottom">
                {
                  message.mediaUrl && Array.isArray(message.mediaUrl) && message.mediaUrl.length
                  ?
                    message.mediaUrl.map((m, idx) => {
                      return (

                          <img key={`e_ms_img_${idx}`} className="incomingMedia" src={m} onClick={() => onShowImagePreviewClick(m)} />
                      );
                    })
                  : null
                }
                {
                  message.mediaUrl && !Array.isArray(message.mediaUrl)
                  ?
                    <img className="incomingMedia" src={message.mediaUrl} onClick={() => onShowImagePreviewClick(message.mediaUrl)} />
                  : null
                }
              </div>
            </div>
            <div className="mSntB">
              {sentBy}
              {
                message.medium && message.medium === 'whatsapp' && <img src={whatsappIcon} title="WhatsApp" />
              }
              {/*
                message.medium && message.medium === 'bm' && <img src={googleIcon} className="gbm" title="Google Business Message" />
              */}
              {
                (
                  (message.medium && message.medium === 'email') ||
                  isTransactionEmail
                ) && <div className="mdmBx"><i className="fa-solid fa-envelope"></i><span>Email</span></div>
              }
              {
                !isTransactionEmail && ((!message.medium || message.medium === 'sms')) && <div className="mdmBx"><img src={smsIcon} title="SMS" /><span>SMS</span></div>
              }
            </div>
            <div className="mstB">
              <div className="dateMessage">
                {formatMessageDate(message.date_created)}
              </div>
              { renderMessageStatus(message) }
            </div>
          </div>
        </div>
        {
          props.dateChange && props.dateChangePosition === 'top' &&
          <div className="dtCh">
            <div className="line" /><p>{formatDateChange(props.dateChange)}</p><div className="line" />
          </div>
        }
        { showImagePreviewModal && renderImagePreviewModal() }
        { showEmailPreviewModal &&
          <SentEmailPreviewModal
            modalType="templateSelector"
            title="Email Preview"
            setModalVisible={onEmailPreviewCloseModal}
            onAffirmativeClick={onEmailPreviewConfirmClick}
            showCancel={true}
            affirmativeButtonLabel={'Close'}
            disableOutsideClick={true}
            // emailData={sentEmailData}
            // inProgress={getPreviewInProgress}
            transactionEmailIdentifier={props.message._id}
            locationMId={props.locationidentifier}
          />
        }
      </>
    );
  };

  return renderContent();
}

export default MessageSent;
