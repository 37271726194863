
import { genericItemTypeToKeyName } from '../../helpers';

export const initSettings = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;

  let settings = state.locations[location__id] && state.locations[location__id].settings
    ? { ...state.locations[location__id].settings }
    : {
        reputation: {},
        engage: {},
        marketing: {},
      };
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: state.locations[location__id]
      ?
        {
          ...state.locations[location__id],
          settings: {
            ...settings,
          },
        }
      :
        {
          settings: {
            ...settings,
          }
        },
    },
  };
  return state;
};

/***************************************** Get Settings ****************************/

export const getSettings = (state, action) => {
  const { params: { location__id, type } } = action.payload;
  if (!location__id || !type) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: state.locations[location__id]
        ? {
          ...state.locations[location__id],
          settings: state.locations[location__id].settings
          ?
            {
              ...state.locations[location__id].settings,
              getInProgress: true, getStatus: {},
              [`type_${type}`]: true,
            }
          :
            {
              getInProgress: true, getStatus: {},
              [`type_${type}`]: true,
            },
          }
        : {
          settings: {
            getInProgress: true, getStatus: {},
            [`type_${type}`]: true,
          }
        }
    },
  };
};

export const getSettingsSuccess = (state, action) => {
  const { data, params: { location__id, type } } = action.payload;
  if (!location__id || !type) return state;
  const { settings } = data;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          ...settings,
          getInProgress: false,
          getStatus: {
            success: true,
          },
          [`type_${type}`]: false,
        },
      },
    },
  };
};

export const getSettingsError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id, type } = params;
  if (!location__id || !type) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          getInProgress: false,
          getStatus: {
            error: true,
          },
          [`type_${type}`]: false,
          error,
          errorMessage,
        },
      },
    },
  };
};


/***************************************** Edit Settings ****************************/

export const editSettingsClear = (state, action) => {
  const { params: { location__id, source } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          editInProgress: false, editStatus: {},
        },
      },
    },
  };
};

export const editSettings = (state, action) => {
  const { params: { location__id, source } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          editInProgress: true, editStatus: {},
        },
      },
    },
  };
};

export const editSettingsSuccess = (state, action) => {
  const { data, params } = action.payload;
  const { location__id, source, ...restParams } = params;
  if (!location__id) return state;
  if (params.whatsapp) {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            engage: source === 'engage'
            ? {
                ...state.locations[location__id].settings.engage,
                whatsapp: {
                  ...state.locations[location__id].settings.engage.whatsapp,
                  ...params.whatsapp,
                }
              }
            : {
              ...state.locations[location__id].settings.engage,
            },
            editInProgress: false,
            editStatus: {
              success: true,
            },
          },
        },
      },
    };
  }
  if (params.email) {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            engage: source === 'engage'
            ? {
                ...state.locations[location__id].settings.engage,
                email: {
                  ...state.locations[location__id].settings.engage.email,
                  ...params.email,
                }
              }
            : {
              ...state.locations[location__id].settings.engage,
            },
            editInProgress: false,
            editStatus: {
              success: true,
            },
          },
        },
      },
    };
  }
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          engage: source === 'engage'
          ? {
              ...state.locations[location__id].settings.engage,
              ...restParams,
            }
          : {
            ...state.locations[location__id].settings.engage,
          },
          editInProgress: false,
          editStatus: {
            success: true,
          },
        },
      },
    },
  };
};

export const editSettingsError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id } = params;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          editInProgress: false,
          editStatus: {
            error: true,
          },
          error,
          errorMessage,
        },
      },
    },
  };
};

/***************************************** End - Edit Settings ****************************/

/***************************************** Start - Refresh Remote Locks List  ****************************/

export const refreshRemoteLocksList = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          refreshRemoteLocksListInProgress: true, refreshRemoteLocksListStatus: {},
        },
      },
    },
  };
};

export const refreshRemoteLocksListSuccess = (state, action) => {
  const { data, params: { location__id } } = action.payload;
  if (!location__id) return state;
  const { remote_locks } = data;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          remote_locks,
          refreshRemoteLocksListInProgress: false,
          refreshRemoteLocksListStatus: {
            success: true,
          },
        },
      },
    },
  };
};

export const refreshRemoteLocksListError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id } = params;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          refreshRemoteLocksListInProgress: false,
          refreshRemoteLocksListStatus: {
            error: true,
          },
          error,
          errorMessage,
        },
      },
    },
  };
};

/************************* End - Refresh Remote Locks List  ***********************/


/************************ Message Template ********************/

export const initMessageTemplate = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;
  if (state.locations[location__id] &&
    state.locations[location__id].settings &&
    state.locations[location__id].settings.engage &&
    state.locations[location__id].settings.engage.message_templates &&
    state.locations[location__id].settings.engage.message_templates.keyValue
  ) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: state.locations[location__id]
        ? {
          ...state.locations[location__id],
          settings: state.locations[location__id].settings
          ?
            {
              ...state.locations[location__id].settings,
              engage: state.locations[location__id].settings.engage
              ?
                {
                  ...state.locations[location__id].settings.engage,
                  message_templates: state.locations[location__id].settings.engage.message_templates
                  ?
                    {
                      ...state.locations[location__id].settings.engage.message_templates,
                      keyValue: state.locations[location__id].settings.engage.message_templates.keyValue
                      ?
                        {
                          ...state.locations[location__id].settings.engage.message_templates.keyValue,
                        }
                      :
                        {}
                    } // message_templates
                  :
                    {
                      list: [],
                      keyValue: {},
                    }
                } // engage exists
              :
                {
                  message_templates: {
                    list: [],
                    keyValue: {},
                  },
                }
            } // settings exist
          :
            {
              engage: {
                message_templates: {
                  list: [],
                  keyValue: {},
                },
              },
            },
          } // location__id exists
        : {
          settings: {
            engage: {
              message_templates: {
                list: [],
                keyValue: {},
              },
            },
          },
        }
    },
  };
};


/************************ Edit Room ********************/

export const editRoomClear = (state, action) => {
  const { params: { location__id, mode, room_id } } = action.payload;
  if (!location__id) return state;
  if (mode === 'edit' || mode === 'remove') {
    if (!room_id) return state;
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            rooms: {
              ...state.locations[location__id].settings.rooms,
              keyValue: {
                ...state.locations[location__id].settings.rooms.keyValue,
                [room_id]: {
                  ...state.locations[location__id].settings.rooms.keyValue[room_id],
                  editInProgress: false,
                  editStatus: {},
                },
              },
            },
          },
        },
      },
    };
  }
  if (mode === 'add') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            rooms: {
              ...state.locations[location__id].settings.rooms,
              addInProgress: false,
              addStatus: {},
            },
          },
        },
      },
    };
  }
  return state;
};

export const editRoom = (state, action) => {
  const { params: { location__id, mode, room_id } } = action.payload;
  if (!location__id) return state;
  if (mode === 'edit' || mode === 'remove') {
    if (!room_id) return state;
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            rooms: {
              ...state.locations[location__id].settings.rooms,
              keyValue: {
                ...state.locations[location__id].settings.rooms.keyValue,
                [room_id]: {
                  ...state.locations[location__id].settings.rooms.keyValue[room_id],
                  editInProgress: true,
                  editStatus: {},
                },
              },
            },
          },
        },
      },
    };
  }
  if (mode === 'add') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            rooms: {
              ...state.locations[location__id].settings.rooms,
              addInProgress: true,
              addStatus: {},
            },
          },
        },
      },
    };
  }
  return state;
};

export const editRoomSuccess = (state, action) => {
  const { data, params: { location__id, mode, room_id } } = action.payload;
  if (!location__id) return state;
  const { room_data } = data;
  if (mode === 'edit' || mode === 'remove') {
    if (!room_id) return state;
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            rooms: {
              ...state.locations[location__id].settings.rooms,
              list: mode === 'remove'
              ? state.locations[location__id].settings.rooms.list.filter(el => el !== room_id)
              : [ ...state.locations[location__id].settings.rooms.list ],
              keyValue: {
                ...state.locations[location__id].settings.rooms.keyValue,
                [room_id]: {
                  ...state.locations[location__id].settings.rooms.keyValue[room_id],
                  ...room_data,
                  editInProgress: false,
                  editStatus: {
                    success: true,
                  },
                },
              },
            },
          },
        },
      },
    };
  }
  if (mode === 'add') {
    if (!room_data || !room_data._id) return state;
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            rooms: {
              ...state.locations[location__id].settings.rooms,
              list: [room_data._id, ...state.locations[location__id].settings.rooms.list],
              keyValue: {
                ...state.locations[location__id].settings.rooms.keyValue,
                [room_data._id]: {
                  ...room_data,
                },
              },
              addInProgress: false,
              addStatus: {
                success: true,
              },
            },
          },
        },
      },
    };
  }
  return state;
};

export const editRoomError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id, mode, room_id } = params;
  if (mode === 'edit' || mode === 'remove') {
    if (!room_id) return state;
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            rooms: {
              ...state.locations[location__id].settings.rooms,
              keyValue: {
                ...state.locations[location__id].settings.rooms.keyValue,
                [room_id]: {
                  ...state.locations[location__id].settings.rooms.keyValue[room_id],
                  editInProgress: false,
                  editStatus: {
                    error: true,
                  },
                  error,
                  errorMessage,
                },
              },
            },
          },
        },
      },
    };
  }
  if (mode === 'add') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            rooms: {
              ...state.locations[location__id].settings.rooms,
              addInProgress: false,
              addStatus: {
                error: true,
              },
              error,
              errorMessage,
            },
          },
        },
      },
    };
  }
  return state;
};

/************************ End of Edit Room ********************/

/***************** Generic Settings List Item **********************/

export const editGenericSettingsListItemClear = (state, action, itemType) => {
  const { params: { location__id, mode, room_id, rate_code_id, booking_source_id } } = action.payload;
  if (!location__id) return state;
  let keyName = genericItemTypeToKeyName[itemType]; // message_templates, labels
  if (!keyName) return state;
  let itemId = '';

  if (mode === 'edit' || mode === 'remove') {
    if (itemType === 'room') {
      itemId = room_id;
    }
    if (itemType === 'rate_code') {
      itemId = rate_code_id;
    }
    if (itemType === 'booking_source') {
      itemId = booking_source_id;
    }
    if (!itemId) return state;

    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            [keyName]: {
              ...state.locations[location__id].settings[keyName],
              keyValue: {
                ...state.locations[location__id].settings[keyName].keyValue,
                [itemId]: {
                  ...state.locations[location__id].settings[keyName].keyValue[itemId],
                  editInProgress: false,
                  editStatus: {},
                },
              },
            },
          },
        },
      },
    };
  }
  if (mode === 'add') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            [keyName]: {
              ...state.locations[location__id].settings[keyName],
              addInProgress: false,
              addStatus: {},
            },
          },
        },
      },
    };
  }
  return state;
};

export const editGenericSettingsListItem = (state, action, itemType) => {
  const { params: { location__id, mode, room_id, rate_code_id, booking_source_id } } = action.payload;
  if (!location__id) return state;
  let keyName = genericItemTypeToKeyName[itemType]; // message_templates, labels
  if (!keyName) return state;
  let itemId = '';

  if (mode === 'edit' || mode === 'remove') {
    if (itemType === 'room') {
      itemId = room_id;
    }
    if (itemType === 'rate_code') {
      itemId = rate_code_id;
    }
    if (itemType === 'booking_source') {
      itemId = booking_source_id;
    }
    if (!itemId) return state;

    if (process.env.NODE_ENV !== 'production') {
      console.log(`editGenericSettingsListItem itemId: `, itemId);
    }

    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            [keyName]: {
              ...state.locations[location__id].settings[keyName],
              keyValue: {
                ...state.locations[location__id].settings[keyName].keyValue,
                [itemId]: {
                  ...state.locations[location__id].settings[keyName].keyValue[itemId],
                  editInProgress: true,
                  editStatus: {},
                },
              },
            },
          },
        },
      },
    };
  }
  if (mode === 'add') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            [keyName]: {
              ...state.locations[location__id].settings[keyName],
              addInProgress: true,
              addStatus: {},
            },
          },
        },
      },
    };
  }
  return state;
};

export const editGenericSettingsListItemSuccess = (state, action, itemType) => {
  const { data, params: { location__id, mode, room_id, rate_code_id, booking_source_id } } = action.payload;
  if (!location__id) return state;
  let keyName = genericItemTypeToKeyName[itemType]; // message_templates, labels
  if (!keyName) return state;
  let itemId = '';

  const { room_data, rate_code_data, booking_source_data } = data;

  if (mode === 'edit' || mode === 'remove') {
    let return_data = null;
    if (itemType === 'room') {
      itemId = room_id;
      return_data = room_data;
    }
    if (itemType === 'rate_code') {
      itemId = rate_code_id;
      return_data = rate_code_data;
    }
    if (itemType === 'booking_source') {
      itemId = booking_source_id;
      return_data = booking_source_data;
    }
    if (!itemId) return state;

    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            [keyName]: {
              ...state.locations[location__id].settings[keyName],
              list: mode === 'remove'
              ? state.locations[location__id].settings[keyName].list.filter(el => el !== itemId)
              : [ ...state.locations[location__id].settings[keyName].list ],
              keyValue: {
                ...state.locations[location__id].settings[keyName].keyValue,
                [itemId]: {
                  ...state.locations[location__id].settings[keyName].keyValue[itemId],
                  ...return_data,
                  editInProgress: false,
                  editStatus: {
                    success: true,
                  },
                },
              },
            },
          },
        },
      },
    };
  }
  if (mode === 'add') {
    let return_data = null;
    if (itemType === 'room') {
      return_data = room_data;
    }
    if (itemType === 'rate_code') {
      return_data = rate_code_data;
    }
    if (itemType === 'booking_source') {
      return_data = booking_source_data;
    }
    if (!return_data || !return_data._id) return state;
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            [keyName]: {
              ...state.locations[location__id].settings[keyName],
              list: [return_data._id, ...state.locations[location__id].settings[keyName].list],
              keyValue: {
                ...state.locations[location__id].settings[keyName].keyValue,
                [return_data._id]: {
                  ...return_data,
                },
              },
              addInProgress: false,
              addStatus: {
                success: true,
              },
            },
          },
        },
      },
    };
  }
  return state;
};

export const editGenericSettingsListItemError = (state, action, itemType) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id, mode, room_id, rate_code_id, booking_source_id } = params;

  let keyName = genericItemTypeToKeyName[itemType]; // message_templates, labels
  if (!keyName) return state;
  let itemId = '';

  if (mode === 'edit' || mode === 'remove') {
    if (itemType === 'room') {
      itemId = room_id;
    }
    if (itemType === 'rate_code') {
      itemId = rate_code_id;
    }
    if (itemType === 'booking_source') {
      itemId = booking_source_id;
    }
    if (!itemId) return state;

    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            [keyName]: {
              ...state.locations[location__id].settings[keyName],
              keyValue: {
                ...state.locations[location__id].settings[keyName].keyValue,
                [itemId]: {
                  ...state.locations[location__id].settings[keyName].keyValue[itemId],
                  editInProgress: false,
                  editStatus: {
                    error: true,
                  },
                  error,
                  errorMessage,
                },
              },
            },
          },
        },
      },
    };
  }
  if (mode === 'add') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            [keyName]: {
              ...state.locations[location__id].settings[keyName],
              addInProgress: false,
              addStatus: {
                error: true,
              },
              error,
              errorMessage,
            },
          },
        },
      },
    };
  }
  return state;
};

/*************************** End of Generic Settings List Item *******************************/

/***************** Generic Engage List Item **********************/

export const editGenericEngageListItemClear = (state, action, itemType) => {
  const { params: { location__id, mode, message_template_id, label_id } } = action.payload;
  if (!location__id) return state;
  let keyName = genericItemTypeToKeyName[itemType]; // message_templates, labels
  if (!keyName) return state;
  let itemId = '';

  if (mode === 'edit' || mode === 'remove') {
    if (itemType === 'messageTemplate') {
      itemId = message_template_id;
    }
    if (itemType === 'label') {
      itemId = label_id;
    }
    if (!itemId) return state;

    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            engage: {
              ...state.locations[location__id].settings.engage,
              [keyName]: {
                ...state.locations[location__id].settings.engage[keyName],
                keyValue: {
                  ...state.locations[location__id].settings.engage[keyName].keyValue,
                  [itemId]: {
                    ...state.locations[location__id].settings.engage[keyName].keyValue[itemId],
                    editInProgress: false,
                    editStatus: {},
                  },
                },
              },
            },
          },
        },
      },
    };
  }
  if (mode === 'add') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            engage: {
              ...state.locations[location__id].settings.engage,
              [keyName]: {
                ...state.locations[location__id].settings.engage[keyName],
                addInProgress: false,
                addStatus: {},
              },
            },
          },
        },
      },
    };
  }
  return state;
};

export const editGenericEngageListItem = (state, action, itemType) => {
  const { params: { location__id, mode, message_template_id, label_id } } = action.payload;
  if (!location__id) return state;
  let keyName = genericItemTypeToKeyName[itemType]; // message_templates, labels
  if (!keyName) return state;
  let itemId = '';

  if (mode === 'edit' || mode === 'remove') {
    if (itemType === 'messageTemplate') {
      itemId = message_template_id;
    }
    if (itemType === 'label') {
      itemId = label_id;
    }
    if (!itemId) return state;

    if (process.env.NODE_ENV !== 'production') {
      console.log(`editGenericEngageListItem itemId: `, itemId);
    }

    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            engage: {
              ...state.locations[location__id].settings.engage,
              [keyName]: {
                ...state.locations[location__id].settings.engage[keyName],
                keyValue: {
                  ...state.locations[location__id].settings.engage[keyName].keyValue,
                  [itemId]: {
                    ...state.locations[location__id].settings.engage[keyName].keyValue[itemId],
                    editInProgress: true,
                    editStatus: {},
                  },
                },
              },
            },
          },
        },
      },
    };
  }
  if (mode === 'add') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            engage: {
              ...state.locations[location__id].settings.engage,
              [keyName]: {
                ...state.locations[location__id].settings.engage[keyName],
                addInProgress: true,
                addStatus: {},
              },
            },
          },
        },
      },
    };
  }
  return state;
};

export const editGenericEngageListItemSuccess = (state, action, itemType) => {
  const { data, params: { location__id, mode, message_template_id, label_id } } = action.payload;
  if (!location__id) return state;
  let keyName = genericItemTypeToKeyName[itemType]; // message_templates, labels
  if (!keyName) return state;
  let itemId = '';

  const { message_template_data, label_data } = data;
  if (mode === 'edit' || mode === 'remove') {
    let return_data = null;
    if (itemType === 'messageTemplate') {
      itemId = message_template_id;
      return_data = message_template_data;
    }
    if (itemType === 'label') {
      itemId = label_id;
      return_data = label_data;
    }
    if (!itemId) return state;

    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            engage: {
              ...state.locations[location__id].settings.engage,
              [keyName]: {
                ...state.locations[location__id].settings.engage[keyName],
                list: mode === 'remove'
                ? state.locations[location__id].settings.engage[keyName].list.filter(el => el !== itemId)
                : [ ...state.locations[location__id].settings.engage[keyName].list ],
                keyValue: {
                  ...state.locations[location__id].settings.engage[keyName].keyValue,
                  [itemId]: {
                    ...state.locations[location__id].settings.engage[keyName].keyValue[itemId],
                    ...return_data,
                    editInProgress: false,
                    editStatus: {
                      success: true,
                    },
                  },
                },
              },
            },
          },
        },
      },
    };
  }
  if (mode === 'add') {
    let return_data = null;
    if (itemType === 'messageTemplate') {
      return_data = message_template_data;
    }
    if (itemType === 'label') {
      return_data = label_data;
    }
    if (!return_data || !return_data._id) return state;
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            engage: {
              ...state.locations[location__id].settings.engage,
              [keyName]: {
                ...state.locations[location__id].settings.engage[keyName],
                list: [return_data._id, ...state.locations[location__id].settings.engage[keyName].list],
                keyValue: {
                  ...state.locations[location__id].settings.engage[keyName].keyValue,
                  [return_data._id]: {
                    ...return_data,
                  },
                },
                addInProgress: false,
                addStatus: {
                  success: true,
                },
              },
            },
          },
        },
      },
    };
  }
  return state;
};

export const editGenericEngageListItemError = (state, action, itemType) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id, mode, message_template_id, label_id } = params;

  let keyName = genericItemTypeToKeyName[itemType]; // message_templates, labels
  if (!keyName) return state;
  let itemId = '';

  if (mode === 'edit' || mode === 'remove') {
    if (itemType === 'messageTemplate') {
      itemId = message_template_id;
    }
    if (itemType === 'label') {
      itemId = label_id;
    }
    if (!itemId) return state;

    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            engage: {
              ...state.locations[location__id].settings.engage,
              [keyName]: {
                ...state.locations[location__id].settings.engage[keyName],
                keyValue: {
                  ...state.locations[location__id].settings.engage[keyName].keyValue,
                  [itemId]: {
                    ...state.locations[location__id].settings.engage[keyName].keyValue[itemId],
                    editInProgress: false,
                    editStatus: {
                      error: true,
                    },
                    error,
                    errorMessage,
                  },
                },
              },
            },
          },
        },
      },
    };
  }
  if (mode === 'add') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            engage: {
              ...state.locations[location__id].settings.engage,
              [keyName]: {
                ...state.locations[location__id].settings.engage[keyName],
                addInProgress: false,
                addStatus: {
                  error: true,
                },
                error,
                errorMessage,
              },
            },
          },
        },
      },
    };
  }
  return state;
};

/*************************** End of Generic List Item *******************************/


/***************** Generic Reputation List Item **********************/

export const editGenericReputationListItemClear = (state, action, itemType) => {
  const { params: { location__id, mode, response_template_id, response_template_category_id,
    prompt_template_id, prompt_template_category_id, feedback_text_template_id, comp_set_location_id } } = action.payload;
  if (process.env.NODE_ENV !== 'production') {
    console.log('editGenericReputationListItemClear location__id: ', location__id);
  }
  if (!location__id) return state;
  let keyName = genericItemTypeToKeyName[itemType]; // message_templates, labels
  if (process.env.NODE_ENV !== 'production') {
    console.log('editGenericReputationListItemClear keyName: ', keyName);
  }
  if (!keyName) return state;
  let itemId = '';

  if (mode === 'edit' || mode === 'remove') {
    if (itemType === 'response_template') {
      itemId = response_template_id;
    }
    if (itemType === 'response_template_category') {
      itemId = response_template_category_id;
    }
    if (itemType === 'prompt_template') {
      itemId = prompt_template_id;
    }
    if (itemType === 'prompt_template_category') {
      itemId = prompt_template_category_id;
    }
    if (itemType === 'feedback_text_template') {
      itemId = feedback_text_template_id;
    }
    if (itemType === 'comp_set_location') {
      itemId = comp_set_location_id;
    }
    if (process.env.NODE_ENV !== 'production') {
      console.log('editGenericReputationListItemClear itemId: ', itemId);
    }
    if (!itemId) return state;

    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            reputation: {
              ...state.locations[location__id].settings.reputation,
              [keyName]: {
                ...state.locations[location__id].settings.reputation[keyName],
                keyValue: {
                  ...state.locations[location__id].settings.reputation[keyName].keyValue,
                  [itemId]: {
                    ...state.locations[location__id].settings.reputation[keyName].keyValue[itemId],
                    editInProgress: false,
                    editStatus: {},
                  },
                },
              },
            },
          },
        },
      },
    };
  }
  if (mode === 'add') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            reputation: {
              ...state.locations[location__id].settings.reputation,
              [keyName]: {
                ...state.locations[location__id].settings.reputation[keyName],
                addInProgress: false,
                addStatus: {},
              },
            },
          },
        },
      },
    };
  }
  return state;
};

export const editGenericReputationListItem = (state, action, itemType) => {
  const { params: { location__id, mode, response_template_id, response_template_category_id,
    prompt_template_id, prompt_template_category_id, feedback_text_template_id, comp_set_location_id } } = action.payload;
  if (!location__id) return state;
  let keyName = genericItemTypeToKeyName[itemType]; // response_template, response_template_category
  if (!keyName) return state;
  let itemId = '';

  if (mode === 'edit' || mode === 'remove') {
    if (itemType === 'response_template') {
      itemId = response_template_id;
    }
    if (itemType === 'response_template_category') {
      itemId = response_template_category_id;
    }
    if (itemType === 'prompt_template') {
      itemId = prompt_template_id;
    }
    if (itemType === 'prompt_template_category') {
      itemId = prompt_template_category_id;
    }
    if (itemType === 'feedback_text_template') {
      itemId = feedback_text_template_id;
    }
    if (itemType === 'comp_set_location') {
      itemId = comp_set_location_id;
    }
    if (!itemId) return state;

    if (process.env.NODE_ENV !== 'production') {
      console.log(`editGenericReputationListItem itemId: `, itemId);
    }

    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            reputation: {
              ...state.locations[location__id].settings.reputation,
              [keyName]: {
                ...state.locations[location__id].settings.reputation[keyName],
                keyValue: {
                  ...state.locations[location__id].settings.reputation[keyName].keyValue,
                  [itemId]: {
                    ...state.locations[location__id].settings.reputation[keyName].keyValue[itemId],
                    editInProgress: true,
                    editStatus: {},
                  },
                },
              },
            },
          },
        },
      },
    };
  }
  if (mode === 'add') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            reputation: {
              ...state.locations[location__id].settings.reputation,
              [keyName]: {
                ...state.locations[location__id].settings.reputation[keyName],
                addInProgress: true,
                addStatus: {},
              },
            },
          },
        },
      },
    };
  }
  return state;
};

export const editGenericReputationListItemSuccess = (state, action, itemType) => {
  const { data, params: { location__id, mode, response_template_id, response_template_category_id, is_default,
    prompt_template_id, prompt_template_category_id, feedback_text_template_id, comp_set_location_id } } = action.payload;
  if (!location__id) return state;
  let keyName = genericItemTypeToKeyName[itemType];
  if (!keyName) return state;
  let itemId = '';

  const { response_template_data, response_template_category_data } = data;
  const { prompt_template_data, prompt_template_category_data } = data;
  const { feedback_text_template_data } = data;
  const { comp_set_location_data } = data;

  if (mode === 'edit' || mode === 'remove') {
    let return_data = null;
    if (itemType === 'response_template') {
      itemId = response_template_id;
      return_data = response_template_data;
    }
    if (itemType === 'response_template_category') {
      itemId = response_template_category_id;
      return_data = response_template_category_data;
    }
    if (itemType === 'prompt_template') {
      itemId = prompt_template_id;
      return_data = prompt_template_data;
    }
    if (itemType === 'prompt_template_category') {
      itemId = prompt_template_category_id;
      return_data = prompt_template_category_data;
    }
    if (itemType === 'feedback_text_template') {
      itemId = feedback_text_template_id;
      return_data = feedback_text_template_data;
    }
    if (itemType === 'comp_set_location') {
      itemId = comp_set_location_id;
      return_data = comp_set_location_data;
    }
    if (!itemId) return state;

    let tempKeyValue = state.locations[location__id].settings &&
      state.locations[location__id].settings.reputation &&
      state.locations[location__id].settings.reputation[keyName] &&
      state.locations[location__id].settings.reputation[keyName].keyValue
      ? { ...state.locations[location__id].settings.reputation[keyName].keyValue }
      : {};

    if ((itemType === 'prompt_template' || itemType === 'feedback_text_template') && is_default) {
      Object.keys(tempKeyValue).forEach((el) => {
        if (tempKeyValue[el].is_default) tempKeyValue[el].is_default = false;
      });
    }

    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            reputation: {
              ...state.locations[location__id].settings.reputation,
              [keyName]: {
                ...state.locations[location__id].settings.reputation[keyName],
                list: mode === 'remove'
                ? state.locations[location__id].settings.reputation[keyName].list.filter(el => el !== itemId)
                : [ ...state.locations[location__id].settings.reputation[keyName].list ],
                keyValue: {
                  // ...state.locations[location__id].settings.reputation[keyName].keyValue,
                  ...tempKeyValue,
                  [itemId]: {
                    ...state.locations[location__id].settings.reputation[keyName].keyValue[itemId],
                    ...return_data,
                    editInProgress: false,
                    editStatus: {
                      success: true,
                    },
                  },
                },
              },
            },
          },
        },
      },
    };
  }
  if (mode === 'add') {
    let return_data = null;
    if (itemType === 'response_template') {
      return_data = response_template_data;
    }
    if (itemType === 'response_template_category') {
      return_data = response_template_category_data;
    }
    if (itemType === 'prompt_template') {
      return_data = prompt_template_data;
    }
    if (itemType === 'prompt_template_category') {
      return_data = prompt_template_category_data;
    }
    if (itemType === 'feedback_text_template') {
      return_data = feedback_text_template_data;
    }
    if (itemType === 'comp_set_location') {
      return_data = comp_set_location_data;
    }
    if (!return_data || !return_data._id) return state;
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            reputation: {
              ...state.locations[location__id].settings.reputation,
              [keyName]: {
                ...state.locations[location__id].settings.reputation[keyName],
                list: state.locations[location__id].settings.reputation[keyName].list
                  ? [return_data._id, ...state.locations[location__id].settings.reputation[keyName].list]
                  : [return_data._id],
                keyValue: state.locations[location__id].settings.reputation[keyName].keyValue
                  ?
                    {
                      ...state.locations[location__id].settings.reputation[keyName].keyValue,
                      [return_data._id]: {
                        ...return_data,
                      },
                    }
                  : {
                    [return_data._id]: {
                      ...return_data,
                    },
                  },
                addInProgress: false,
                addStatus: {
                  success: true,
                },
              },
            },
          },
        },
      },
    };
  }
  return state;
};

export const editGenericReputationListItemError = (state, action, itemType) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id, mode, response_template_id, response_template_category_id,
    prompt_template_id, prompt_template_category_id, feedback_text_template_id, comp_set_location_id } = params;

  let keyName = genericItemTypeToKeyName[itemType];
  if (!keyName) return state;
  let itemId = '';

  if (mode === 'edit' || mode === 'remove') {
    if (itemType === 'response_template') {
      itemId = response_template_id;
    }
    if (itemType === 'response_template_category') {
      itemId = response_template_category_id;
    }
    if (itemType === 'prompt_template') {
      itemId = prompt_template_id;
    }
    if (itemType === 'prompt_template_category') {
      itemId = prompt_template_category_id;
    }
    if (itemType === 'feedback_text_template') {
      itemId = feedback_text_template_id;
    }
    if (itemType === 'comp_set_location') {
      itemId = comp_set_location_id;
    }
    if (!itemId) return state;

    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            reputation: {
              ...state.locations[location__id].settings.reputation,
              [keyName]: {
                ...state.locations[location__id].settings.reputation[keyName],
                keyValue: {
                  ...state.locations[location__id].settings.reputation[keyName].keyValue,
                  [itemId]: {
                    ...state.locations[location__id].settings.reputation[keyName].keyValue[itemId],
                    editInProgress: false,
                    editStatus: {
                      error: true,
                    },
                    error,
                    errorMessage,
                  },
                },
              },
            },
          },
        },
      },
    };
  }
  if (mode === 'add') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            reputation: {
              ...state.locations[location__id].settings.reputation,
              [keyName]: {
                ...state.locations[location__id].settings.reputation[keyName],
                addInProgress: false,
                addStatus: {
                  error: true,
                  message: 'Error in adding',
                },
                error,
                errorMessage,
              },
            },
          },
        },
      },
    };
  }
  return state;
};

/*************************** End of Generic Setting Reputation List Item *******************************/


/***************** Generic Settings Marketing List Item **********************/

export const editGenericSettingsMarketingListItemClear = (state, action, itemType) => {
  const { params: { location__id, mode, sender_name_id, sender_email_id } } = action.payload;
  if (!location__id) return state;
  let keyName = genericItemTypeToKeyName[itemType]; // message_templates, labels
  if (!keyName) return state;
  let itemId = '';

  if (mode === 'edit' || mode === 'remove') {
    if (itemType === 'sender_name') {
      itemId = sender_name_id;
    }
    if (itemType === 'sender_email') {
      itemId = sender_email_id;
    }
    if (!itemId) return state;

    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            marketing: {
              ...state.locations[location__id].settings.marketing,
              [keyName]: {
                ...state.locations[location__id].settings.marketing[keyName],
                keyValue: {
                  ...state.locations[location__id].settings.marketing[keyName].keyValue,
                  [itemId]: {
                    ...state.locations[location__id].settings.marketing[keyName].keyValue[itemId],
                    editInProgress: false,
                    editStatus: {},
                  },
                },
              },
            },
          },
        },
      },
    };
  }
  if (mode === 'add') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            marketing: {
              ...state.locations[location__id].settings.marketing,
              [keyName]: {
                ...state.locations[location__id].settings.marketing[keyName],
                addInProgress: false,
                addStatus: {},
              },
            },
          },
        },
      },
    };
  }
  return state;
};

export const editGenericSettingsMarketingListItem = (state, action, itemType) => {
  const { params: { location__id, mode, sender_name_id, sender_email_id } } = action.payload;
  if (!location__id) return state;
  let keyName = genericItemTypeToKeyName[itemType]; // message_templates, labels
  if (!keyName) return state;
  let itemId = '';

  if (mode === 'edit' || mode === 'remove') {
    if (itemType === 'sender_name') {
      itemId = sender_name_id;
    }
    if (itemType === 'sender_email') {
      itemId = sender_email_id;
    }
    if (!itemId) return state;

    if (process.env.NODE_ENV !== 'production') {
      console.log(`editGenericSettingsMarketingListItem itemId: `, itemId);
    }

    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            marketing: {
              ...state.locations[location__id].settings.marketing,
              [keyName]: {
                ...state.locations[location__id].settings.marketing[keyName],
                keyValue: {
                  ...state.locations[location__id].settings.marketing[keyName].keyValue,
                  [itemId]: {
                    ...state.locations[location__id].settings.marketing[keyName].keyValue[itemId],
                    editInProgress: true,
                    editStatus: {},
                  },
                },
              },
            },
          },
        },
      },
    };
  }
  if (mode === 'add') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            marketing: {
              ...state.locations[location__id].settings.marketing,
              [keyName]: {
                ...state.locations[location__id].settings.marketing[keyName],
                addInProgress: true,
                addStatus: {},
              },
            },
          },
        },
      },
    };
  }
  return state;
};

export const editGenericSettingsMarketingListItemSuccess = (state, action, itemType) => {
  const { data, params: { location__id, mode, sender_name_id, sender_email_id } } = action.payload;
  if (!location__id) return state;
  let keyName = genericItemTypeToKeyName[itemType]; // message_templates, labels
  if (!keyName) return state;
  let itemId = '';

  const { sender_name_data, sender_email_data } = data;
  if (mode === 'edit' || mode === 'remove') {
    let return_data = null;
    if (itemType === 'sender_name') {
      itemId = sender_name_id;
      return_data = sender_name_data;
    }
    if (itemType === 'sender_email') {
      itemId = sender_email_id;
      return_data = sender_email_data;
    }
    if (!itemId) return state;

    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            marketing: {
              ...state.locations[location__id].settings.marketing,
              [keyName]: {
                ...state.locations[location__id].settings.marketing[keyName],
                list: mode === 'remove'
                ? state.locations[location__id].settings.marketing[keyName].list.filter(el => el !== itemId)
                : [ ...state.locations[location__id].settings.marketing[keyName].list ],
                keyValue: {
                  ...state.locations[location__id].settings.marketing[keyName].keyValue,
                  [itemId]: {
                    ...state.locations[location__id].settings.marketing[keyName].keyValue[itemId],
                    ...return_data,
                    editInProgress: false,
                    editStatus: {
                      success: true,
                    },
                  },
                },
              },
            },
          },
        },
      },
    };
  }
  if (mode === 'add') {
    let return_data = null;
    if (itemType === 'sender_name') {
      return_data = sender_name_data;
    }
    if (itemType === 'sender_email') {
      return_data = sender_email_data;
    }
    if (!return_data || !return_data._id) return state;
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            marketing: {
              ...state.locations[location__id].settings.marketing,
              [keyName]: {
                ...state.locations[location__id].settings.marketing[keyName],
                list: [return_data._id, ...state.locations[location__id].settings.marketing[keyName].list],
                keyValue: {
                  ...state.locations[location__id].settings.marketing[keyName].keyValue,
                  [return_data._id]: {
                    ...return_data,
                  },
                },
                addInProgress: false,
                addStatus: {
                  success: true,
                },
              },
            },
          },
        },
      },
    };
  }
  return state;
};

export const editGenericSettingsMarketingListItemError = (state, action, itemType) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id, mode, sender_name_id, sender_email_id } = params;

  let keyName = genericItemTypeToKeyName[itemType]; // message_templates, labels
  if (!keyName) return state;
  let itemId = '';

  if (mode === 'edit' || mode === 'remove') {
    if (itemType === 'sender_name') {
      itemId = sender_name_id;
    }
    if (itemType === 'sender_email') {
      itemId = sender_email_id;
    }
    if (!itemId) return state;

    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            marketing: {
              ...state.locations[location__id].settings.marketing,
              [keyName]: {
                ...state.locations[location__id].settings.marketing[keyName],
                keyValue: {
                  ...state.locations[location__id].settings.marketing[keyName].keyValue,
                  [itemId]: {
                    ...state.locations[location__id].settings.marketing[keyName].keyValue[itemId],
                    editInProgress: false,
                    editStatus: {
                      error: true,
                    },
                    error,
                    errorMessage,
                  },
                },
              },
            },
          },
        },
      },
    };
  }
  if (mode === 'add') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            marketing: {
              ...state.locations[location__id].settings.marketing,
              [keyName]: {
                ...state.locations[location__id].settings.marketing[keyName],
                addInProgress: false,
                addStatus: {
                  error: true,
                },
                error,
                errorMessage,
              },
            },
          },
        },
      },
    };
  }
  return state;
};

/*************************** End of Generic Settings Marketing List Item *******************************/


/***************** Send Sender verification email **********************/

export const sendSenderEmailVerificationEmailClear = (state, action, itemType) => {
  const { params: { location__id, sender_email_id } } = action.payload;
  if (!location__id || !sender_email_id) return state;

  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          marketing: {
            ...state.locations[location__id].settings.marketing,
            sender_emails: {
              ...state.locations[location__id].settings.marketing.sender_emails,
              keyValue: {
                ...state.locations[location__id].settings.marketing.sender_emails.keyValue,
                [sender_email_id]: {
                  ...state.locations[location__id].settings.marketing.sender_emails.keyValue[sender_email_id],
                  sendInProgress: false,
                  sendStatus: {},
                },
              },
            },
          },
        },
      },
    },
  };
  return state;
};

export const sendSenderEmailVerificationEmail = (state, action, itemType) => {
  const { params: { location__id, sender_email_id } } = action.payload;
  if (!location__id || !sender_email_id) return state;

  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          marketing: {
            ...state.locations[location__id].settings.marketing,
            sender_emails: {
              ...state.locations[location__id].settings.marketing.sender_emails,
              keyValue: {
                ...state.locations[location__id].settings.marketing.sender_emails.keyValue,
                [sender_email_id]: {
                  ...state.locations[location__id].settings.marketing.sender_emails.keyValue[sender_email_id],
                  sendInProgress: true,
                  sendStatus: {},
                },
              },
            },
          },
        },
      },
    },
  };
  return state;
};

export const sendSenderEmailVerificationEmailSuccess = (state, action, itemType) => {
  const { data, params: { location__id, sender_email_id } } = action.payload;
  if (!location__id || !sender_email_id) return state;

  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          marketing: {
            ...state.locations[location__id].settings.marketing,
            sender_emails: {
              ...state.locations[location__id].settings.marketing.sender_emails,
              keyValue: {
                ...state.locations[location__id].settings.marketing.sender_emails.keyValue,
                [sender_email_id]: {
                  ...state.locations[location__id].settings.marketing.sender_emails.keyValue[sender_email_id],
                  sendInProgress: false,
                  sendStatus: {
                    success: true,
                  },
                },
              },
            },
          },
        },
      },
    },
  };
  return state;
};

export const sendSenderEmailVerificationEmailError = (state, action, itemType) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id, sender_email_id } = params;

  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          marketing: {
            ...state.locations[location__id].settings.marketing,
            sender_emails: {
              ...state.locations[location__id].settings.marketing.sender_emails,
              keyValue: {
                ...state.locations[location__id].settings.marketing.sender_emails.keyValue,
                [sender_email_id]: {
                  ...state.locations[location__id].settings.marketing.sender_emails.keyValue[sender_email_id],
                  sendInProgress: false,
                  sendStatus: {
                    error: true,
                    message: 'Error in sending!'
                  },
                  error,
                  errorMessage,
                },
              },
            },
          },
        },
      },
    },
  };
  return state;
};

/*************************** End of Send Sender verification email *******************************/


/***************** Get Sender email verification Status **********************/

export const getSenderEmailVerificationStatusClear = (state, action, itemType) => {
  const { params: { location__id, sender_email_id } } = action.payload;
  if (!location__id || !sender_email_id) return state;

  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          marketing: {
            ...state.locations[location__id].settings.marketing,
            sender_emails: {
              ...state.locations[location__id].settings.marketing.sender_emails,
              keyValue: {
                ...state.locations[location__id].settings.marketing.sender_emails.keyValue,
                [sender_email_id]: {
                  ...state.locations[location__id].settings.marketing.sender_emails.keyValue[sender_email_id],
                  getInProgress: false,
                  getStatus: {},
                },
              },
            },
          },
        },
      },
    },
  };
  return state;
};

export const getSenderEmailVerificationStatus = (state, action, itemType) => {
  const { params: { location__id, sender_email_id } } = action.payload;
  if (!location__id || !sender_email_id) return state;

  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          marketing: {
            ...state.locations[location__id].settings.marketing,
            sender_emails: {
              ...state.locations[location__id].settings.marketing.sender_emails,
              keyValue: {
                ...state.locations[location__id].settings.marketing.sender_emails.keyValue,
                [sender_email_id]: {
                  ...state.locations[location__id].settings.marketing.sender_emails.keyValue[sender_email_id],
                  getInProgress: true,
                  getStatus: {},
                },
              },
            },
          },
        },
      },
    },
  };
  return state;
};

export const getSenderEmailVerificationStatusSuccess = (state, action, itemType) => {
  const { data, params: { location__id, sender_email_id } } = action.payload;
  if (!location__id || !sender_email_id) return state;
  const { sender_email_data } = data;

  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          marketing: {
            ...state.locations[location__id].settings.marketing,
            sender_emails: {
              ...state.locations[location__id].settings.marketing.sender_emails,
              keyValue: {
                ...state.locations[location__id].settings.marketing.sender_emails.keyValue,
                [sender_email_id]: {
                  ...state.locations[location__id].settings.marketing.sender_emails.keyValue[sender_email_id],
                  ...sender_email_data,
                  getInProgress: false,
                  getStatus: {
                    success: true,
                  },
                },
              },
            },
          },
        },
      },
    },
  };
  return state;
};

export const getSenderEmailVerificationStatusError = (state, action, itemType) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id, sender_email_id } = params;

  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          marketing: {
            ...state.locations[location__id].settings.marketing,
            sender_emails: {
              ...state.locations[location__id].settings.marketing.sender_emails,
              keyValue: {
                ...state.locations[location__id].settings.marketing.sender_emails.keyValue,
                [sender_email_id]: {
                  ...state.locations[location__id].settings.marketing.sender_emails.keyValue[sender_email_id],
                  getInProgress: false,
                  getStatus: {
                    error: true,
                    message: 'Error in updating!'
                  },
                  error,
                  errorMessage,
                },
              },
            },
          },
        },
      },
    },
  };
  return state;
};

/*************************** End of Get Sender email verification Status *******************************/



/************************ Add Whatsapp Message Template ********************/

export const addWhatsappMessageTemplateClear = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          engage: {
            ...state.locations[location__id].settings.engage,
            added_whatsapp_templates: state.locations[location__id].settings.engage.added_whatsapp_templates
            ?
              {
                ...state.locations[location__id].settings.engage.added_whatsapp_templates,
                addInProgress: false,
                addStatus: {},
              }
            : {
                addInProgress: false,
                addStatus: {},
              }
          },
        },
      },
    },
  };
  return state;
};

export const addWhatsappMessageTemplate = (state, action) => {
  if (process.env.NODE_ENV !== 'production') {
    console.log(`addWhatsappMessageTemplate payload: `, action.payload);
  }
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;

  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          engage: {
            ...state.locations[location__id].settings.engage,
            added_whatsapp_templates: {
              ...state.locations[location__id].settings.engage.added_whatsapp_templates,
              addInProgress: true,
              addStatus: {},
            },
          },
        },
      },
    },
  };
  return state;
};

export const addWhatsappMessageTemplateSuccess = (state, action) => {
  const { data, params: { location__id } } = action.payload;
  if (!location__id) return state;
  const { message_template_data } = data;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          engage: {
            ...state.locations[location__id].settings.engage,
            added_whatsapp_templates: {
              ...state.locations[location__id].settings.engage.added_whatsapp_templates,
              list: state.locations[location__id].settings.engage.added_whatsapp_templates.list
              ?
                [...state.locations[location__id].settings.engage.added_whatsapp_templates.list, message_template_data._id]
              : [message_template_data._id],
              keyValue: state.locations[location__id].settings.engage.added_whatsapp_templates.keyValue
              ?
                {
                  ...state.locations[location__id].settings.engage.added_whatsapp_templates.keyValue,
                  [message_template_data._id]: { ...message_template_data },
                }
              : {
                  [message_template_data._id]: { ...message_template_data },
                },
              addInProgress: false,
              addStatus: {
                success: true,
              },
            },
          },
        },
      },
    },
  };
  return state;
};

export const addWhatsappMessageTemplateError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id } = params;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        settings: {
          ...state.locations[location__id].settings,
          engage: {
            ...state.locations[location__id].settings.engage,
            added_whatsapp_templates: {
              ...state.locations[location__id].settings.engage.added_whatsapp_templates,
              addInProgress: false,
              addStatus: {
                error: true,
              },
              error,
              errorMessage,
            },
          },
        },
      },
    },
  };
  return state;
};

/**************************** End - Add Whatsapp Message Template ******************************/


/**************************** Start - Edit guest journey rule ******************************/

export const editGuestJourneyRuleClear = (state, action) => {
  const { params: { location__id, mode, rule_id } } = action.payload;
  if (!location__id) return state;
  if (mode === 'edit' || mode === 'remove') {
    if (!rule_id) return state;
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            engage: {
              ...state.locations[location__id].settings.engage,
              journey_rules: {
                ...state.locations[location__id].settings.engage.journey_rules,
                keyValue: {
                  ...state.locations[location__id].settings.engage.journey_rules.keyValue,
                  [rule_id]: {
                    ...state.locations[location__id].settings.engage.journey_rules.keyValue[rule_id],
                    editInProgress: false,
                    editStatus: {},
                  },
                },
              },
            },
          },
        },
      },
    };
  }
  if (mode === 'add') {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            engage: {
              ...state.locations[location__id].settings.engage,
              journey_rules: {
                ...state.locations[location__id].settings.engage.journey_rules,
                addInProgress: false,
                addStatus: {},
              },
            },
          },
        },
      },
    };
  }
  return state;
};

export const editGuestJourneyRule = (state, action) => {
  const { params: { location__id, mode, rule_id, label, room_id } } = action.payload;
  if (!location__id) return state;
  if (mode === 'edit' || mode === 'remove') {
    if (!rule_id) return state;
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            engage: {
              ...state.locations[location__id].settings.engage,
              journey_rules: {
                ...state.locations[location__id].settings.engage.journey_rules,
                keyValue: {
                  ...state.locations[location__id].settings.engage.journey_rules.keyValue,
                  [rule_id]: {
                    ...state.locations[location__id].settings.engage.journey_rules.keyValue[rule_id],
                    editInProgress: true,
                    editStatus: {},
                  },
                },
              },
            },
          },
        },
      },
    };
  }
  if (mode === 'add') {
    if (!label || !room_id) return state;
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            engage: {
              ...state.locations[location__id].settings.engage,
              journey_rules: {
                ...state.locations[location__id].settings.engage.journey_rules,
                addInProgress: true,
                addStatus: {},
              },
            },
          },
        },
      },
    };
  }
  return state;
};

export const editGuestJourneyRuleSuccess = (state, action) => {
  const { data, params: { location__id, mode, rule_id, label, room_id } } = action.payload;
  if (!location__id) return state;
  const { rule_data } = data;
  if (mode === 'edit' || mode === 'remove') {
    if (!rule_id) return state;
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            engage: {
              ...state.locations[location__id].settings.engage,
              journey_rules: {
                ...state.locations[location__id].settings.engage.journey_rules,
                list: mode === 'remove'
                ? state.locations[location__id].settings.engage.journey_rules.list.filter(el => el._id !== rule_id)
                : [ ...state.locations[location__id].settings.engage.journey_rules.list ],
                keyValue: {
                  ...state.locations[location__id].settings.engage.journey_rules.keyValue,
                  [rule_id]: {
                    ...state.locations[location__id].settings.engage.journey_rules.keyValue[rule_id],
                    ...rule_data,
                    editInProgress: false,
                    editStatus: {
                      success: true,
                    },
                  },
                },
              },
            },
          },
        },
      },
    };
  }
  if (mode === 'add') {
    if (!label || !room_id) return state;
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            engage: {
              ...state.locations[location__id].settings.engage,
              journey_rules: {
                ...state.locations[location__id].settings.engage.journey_rules,
                list: [rule_data._id, ...state.locations[location__id].settings.engage.journey_rules.list],
                keyValue: {
                  ...state.locations[location__id].settings.engage.journey_rules.keyValue,
                  [rule_data._id]: {
                    ...state.locations[location__id].settings.engage.journey_rules.keyValue[rule_data._id],
                    ...rule_data,
                  },
                },
                addInProgress: false,
                addStatus: {
                  success: true,
                },
              },
            },
          },
        },
      },
    };
  }
  return state;
};

export const editGuestJourneyRuleError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id, mode, rule_id, label, room_id } = params;
  if (mode === 'edit' || mode === 'remove') {
    if (!rule_id) return state;
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            engage: {
              ...state.locations[location__id].settings.engage,
              journey_rules: {
                ...state.locations[location__id].settings.engage.journey_rules,
                keyValue: {
                  ...state.locations[location__id].settings.engage.journey_rules.keyValue,
                  [rule_id]: {
                    ...state.locations[location__id].settings.engage.journey_rules.keyValue[rule_id],
                    editInProgress: false,
                    editStatus: {
                      error: true,
                    },
                    error,
                    errorMessage,
                  },
                },
              },
            },
          },
        },
      },
    };
  }
  if (mode === 'add') {
    if (!label || !room_id) return state;
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          settings: {
            ...state.locations[location__id].settings,
            engage: {
              ...state.locations[location__id].settings.engage,
              journey_rules: {
                ...state.locations[location__id].settings.engage.journey_rules,
                addInProgress: false,
                addStatus: {
                  error: true,
                },
                error,
                errorMessage,
              },
            },
          },
        },
      },
    };
  }
  return state;
};

/**************************** End - Edit guest journey rule ******************************/
