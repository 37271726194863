import { useSelector, useDispatch } from "react-redux";
import ReactDOM from 'react-dom';
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import moment from 'moment';
import { Button, DatePicker } from 'antd';
import Select from 'react-select';

import ToolTipWrapper from '../../../Misc/ToolTipWrapper';
import ActionCard from '../../../Misc/ActionCard';
import NumberCard from '../../../Misc/NumberCard';
import MixedCard from '../../../Misc/MixedCard';
import OtaReviewSplitCard from '../../../Misc/OtaReviewSplitCard';

import GenericLocationList from '../GenericLocationList';
import LocationListCard from './LocationListCard';
import ManageGroup from '../ManageGroup';

import ReviewRatingChartFaux from '../../Charts/ReviewRatingChartFaux';

import { getDataAction, getDataClearAction } from '../../../../redux/actions/getDataActions';
import { setCommonParams } from '../../../../redux/actions/appActions';

import { isGTUser, DATE_DURATION_OPTIONS_MAP, OTA_ORDER, OTA_NAME_TO_SOURCE_MAP, OTA_ID_TO_NAME_FORMAL_MAP,
  OTA_SOURCE_TO_NAME_FORMAL_MAP,
  renderOTALogo, getUserGAId } from '../../../../helpers';

import closeIcon from '../../../../img/icons/close.svg';
import editIcon from '../../../../img/icons/edit.svg';

import './style.css';

const { RangePicker } = DatePicker;

const gtconfig = require('../../../../gtconfig');

const lastMonthOptions = [];
lastMonthOptions.push({ label: 'Select', value: 'select' });
lastMonthOptions.push({ label: 'Last 7 days', value: 'last-7-days' });
lastMonthOptions.push({ label: 'Last 15 days', value: 'last-15-days' });
lastMonthOptions.push({ label: 'Last 1 Month', value: 'last-1-month' });
lastMonthOptions.push({ label: 'Last 3 Months', value: 'last-3-months' });
lastMonthOptions.push({ label: 'Last 6 Months', value: 'last-6-months' });
lastMonthOptions.push({ label: 'Last 12 Months', value: 'last-12-months' });

const modalRoot = document.getElementById('modal-root');
function Modal(props) {
  const [el, setEl] = useState(document.createElement('div'));
  useEffect(() => {
    // this.el = document.createElement('div');
    // setEl(document.createElement('div'));
    modalRoot.appendChild(el);
    return function cleanup() {
      modalRoot.removeChild(el);
    }
  }, []);
  return ReactDOM.createPortal(
    props.children,
    el,
  );
}

function GroupDashboard(props) {
  const appState = useSelector((state) => state.appReducer);
  const userState = useSelector((state) => state.userReducer);
  const windowState = useSelector((state) => state.windowReducer);
  const groupState = useSelector((state) => state.groupReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [dateRangeLastMonthValue, setDateRangeLastMonthValue] = useState(
    { label: 'Last 1 Month', value: 'last-1-month' }
  );
  const [dateFilterStartDate, setDateFilterStartDate] = useState(new Date(moment().subtract(1, 'month')));
  const [dateFilterEndDate, setDateFilterEndDate] = useState(new Date());

  const [trendChartOtaOptions, setTrendChartOtaOptions] = useState([]);
  const [selectedOtaForTrend, setSelectedOtaForTrend] = useState('all');
  const [otaForTrendValue, setOtaForTrendValue] = useState(
    { label: 'All', value: 'all' }
  );

  const [showManageGroupModal, setShowManageGroupModal] = useState(false);

  const [genericListData, setGenericListData] = useState({});
  const [showGenericListModal, setShowGenericListModal] = useState(false);

  const [compareNow, setCompareNow] = useState(false);
  const [compareDateFilterStartDate, setCompareDateFilterStartDate] = useState(new Date(moment().subtract(1, 'month')));
  const [compareDateFilterEndDate, setCompareDateFilterEndDate] = useState(new Date());


  const getGroupDashboardData = (startDate, endDate, compareStartDate, compareEndDate) => {
    const params = {
      group_id: urlParams.groupidentifier,
      start_date: moment(startDate).format('YYYY-MM-DD'),
      end_date: moment(endDate).format('YYYY-MM-DD'),
    };
    if (compareStartDate && compareEndDate) {
      params.compare_start_date = moment(compareStartDate).format('YYYY-MM-DD');
      params.compare_end_date = moment(compareEndDate).format('YYYY-MM-DD');
    }
    dispatch(getDataAction({
      op: 'get_group_dashboard_data',
      params,
      // params: {
      //   group_id: urlParams.groupidentifier,
      //   start_date: moment(startDate).format('YYYY-MM-DD'),
      //   end_date: moment(endDate).format('YYYY-MM-DD'),
      //   compare_start_date: moment(startDate).subtract(1, 'month').format('YYYY-MM-DD'),
      //   compare_end_date: moment(endDate).subtract(1, 'month').format('YYYY-MM-DD'),
      // },
    }));
  };

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('GroupDashboard props: ', props);
    }
  }, []);

  // analytics reporting
  useEffect(() => {
    try {
      const { groups } = groupState;
      const groupName = groups[urlParams.groupidentifier]
        ? groups[urlParams.groupidentifier].name || ''
        : ''
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: `Reputation - Group Dashboard - ${groupName}`,
        gt_user_id: getUserGAId(userState.user),
      });
    } catch (e) {}
  }, []);

  useEffect(() => {
    const { commonParams } = appState;
    let startDate = commonParams && commonParams.startDate ? new Date(commonParams.startDate) : new Date(moment().subtract(1, 'month'));
    let endDate = commonParams && commonParams.endDate ? new Date(commonParams.endDate) : new Date();
    let rangeKeyword = commonParams && commonParams.rangeKeyword ? commonParams.rangeKeyword : 'last-1-month';
    setDateFilterStartDate(startDate);
    setDateFilterEndDate(endDate);
    setDateRangeLastMonthValue({ label: DATE_DURATION_OPTIONS_MAP[rangeKeyword], value: rangeKeyword });
    getGroupDashboardData(startDate, endDate, null, null);
  }, []);

  useEffect(() => {
    const { groups } = groupState;
    const { groupidentifier } = urlParams;
    if (groups && groups.keyValue && groups.keyValue[groupidentifier] &&
      groups.keyValue[groupidentifier].dashboard &&
      !groups.keyValue[groupidentifier].getDashboardDataInProgress &&
      groups.keyValue[groupidentifier].getDashboardDataStatus &&
      groups.keyValue[groupidentifier].getDashboardDataStatus.success
    ) {
      const tOptions = [];
      tOptions.push({ label: 'All', value: 'all' });
      if (groups.keyValue[groupidentifier].dashboard.trend_data && groups.keyValue[groupidentifier].dashboard.trend_data.otas) {
        Object.keys(groups.keyValue[groupidentifier].dashboard.trend_data.otas).forEach((source) => {
          // OTA_SOURCE_TO_NAME_FORMAL_MAP
          tOptions.push({ label: OTA_SOURCE_TO_NAME_FORMAL_MAP[source], value: source });
        });
      }
      setTrendChartOtaOptions(tOptions);
    }
  }, [groupState.groups]);

  const onDateRangeLastMonthChange = (value) => {
    setDateRangeLastMonthValue(value);
    const rangeValue = value.value;
    if (rangeValue.indexOf('-day') > -1) {
      let period = rangeValue.split('-day')[0];
      if (period.indexOf('last-') > -1) period = period.split('last-')[1];
      setDateFilterStartDate(new Date(moment().subtract(period, 'days')))
      setDateFilterEndDate(new Date());
      dispatch(setCommonParams({
        params: {
          rangeKeyword: rangeValue,
          startDate: new Date(moment().subtract(period, 'days')),
          endDate: new Date(),
        },
      }));
      if (compareNow) {
        const cmpDtStart = new Date(moment(moment().subtract(period, 'days')).subtract(period, 'days'));
        const cmpDtEnd = new Date(moment().subtract(period, 'days'));
        setCompareDateFilterStartDate(cmpDtStart)
        setCompareDateFilterEndDate(cmpDtEnd)
        getGroupDashboardData(new Date(moment().subtract(period, 'days')), new Date(), cmpDtStart, cmpDtEnd);
      } else {
        getGroupDashboardData(new Date(moment().subtract(period, 'days')), new Date(), null, null);
      }
    }
    if (rangeValue.indexOf('-month') > -1) {
      let period = rangeValue.split('-month')[0];
      if (period.indexOf('last-') > -1) period = period.split('last-')[1];
      setDateFilterStartDate(new Date(moment().subtract(period, 'month')))
      setDateFilterEndDate(new Date());
      dispatch(setCommonParams({
        params: {
          rangeKeyword: rangeValue,
          startDate: new Date(moment().subtract(period, 'month')),
          endDate: new Date(),
        },
      }));
      if (compareNow) {
        const cmpDtStart = new Date(moment(moment().subtract(period, 'month')).subtract(period, 'month'));
        const cmpDtEnd = new Date(moment().subtract(period, 'month'));
        setCompareDateFilterStartDate(cmpDtStart)
        setCompareDateFilterEndDate(cmpDtEnd)
        getGroupDashboardData(new Date(moment().subtract(period, 'month')), new Date(), cmpDtStart, cmpDtEnd);
      } else {
        getGroupDashboardData(new Date(moment().subtract(period, 'month')), new Date(), null, null);
      }
    }
    // analytics event reporting
    try {
      const { groups } = groupState;
      const groupName = groups[urlParams.groupidentifier]
        ? groups[urlParams.groupidentifier].name || ''
        : ''
      window.gtag('event', 'gt_click', {
        'event_category': `Reputation - Group Dashboard - ${groupName} - Date Change Last`,
        'event_label': `Date Change Last - ${rangeValue}`,
        // 'value': 1,
        'gt_user_id': getUserGAId(userState.user),
      });
    } catch (e) {}
  };

  const onDateRangeChange = (dates, dateStrings) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onDateRangeChange dates: ', dates);
      console.log('onDateRangeChange dateStrings: ', dateStrings);
    }
    // const { dashboard } = props.reputation;
    // if (!dashboard || dashboard.getInProgress) return;
    // setDateRangeActive(true);
    setDateFilterStartDate(new Date(dates[0]));
    setDateFilterEndDate(new Date(dates[1]));
    setDateRangeLastMonthValue({ label: 'Select', value: 'select' });
    dispatch(setCommonParams({
      params: {
        rangeKeyword: 'select',
        startDate: new Date(dates[0]),
        endDate: new Date(dates[1]),
      },
    }));
    if (compareNow) {
      getGroupDashboardData(dates[0], dates[1], compareDateFilterStartDate, compareDateFilterEndDate);
    } else {
      getGroupDashboardData(dates[0], dates[1], null, null);
    }
    // analytics event reporting
    try {
      const { groups } = groupState;
      const groupName = groups[urlParams.groupidentifier]
        ? groups[urlParams.groupidentifier].name || ''
        : ''
      window.gtag('event', 'gt_click', {
        'event_category': `Reputation - Group Dashboard - ${groupName} - Date Change Range`,
        'event_label': `Date Change Range - ${dateStrings}`,
        'gt_user_id': getUserGAId(userState.user),
      });
    } catch (e) {}
  };

  const onGenericListCloseClick = (e) => {
    setShowGenericListModal(false);
    // document.body.style.overflow = 'auto';
  };

  const onShowApprovalPendingReviews = (e) => {
    let lData = {};
    lData.title = "Approval Pending Reviews";
    lData.type = 'approvalPending';
    lData.list = [];
    const { groups } = groupState;
    const { groupidentifier } = urlParams;
    if (groups && groups.keyValue && groups.keyValue[groupidentifier] && groups.keyValue[groupidentifier].dashboard &&
      groups.keyValue[groupidentifier].dashboard.approval_pending_reviews
    ) {
      Object.keys(groups.keyValue[groupidentifier].dashboard.approval_pending_reviews).forEach((item) => {
        if (item !== 'all') {
          if (groups.keyValue[groupidentifier].dashboard.approval_pending_reviews[item].length &&
            groups.keyValue[groupidentifier].dashboard.locationMap
          ) {
            const { locationMap } = groups.keyValue[groupidentifier].dashboard;
            lData.list.push({
              _id: item,
              name: locationMap[item] && locationMap[item].name ? locationMap[item].name : '',
              logo: locationMap[item] && locationMap[item].logo ? locationMap[item].logo : '',
              address: locationMap[item] && locationMap[item].address ? locationMap[item].address : '',
              count: groups.keyValue[groupidentifier].dashboard.approval_pending_reviews[item].length,
            });
          }
        }
      });
    }
    setGenericListData(lData);
    setShowGenericListModal(true);
    // document.body.style.overflow = 'auto';
  };

  const onShowNeedInfoReviews = (e) => {
    let lData = {};
    lData.title = "Need Info Reviews";
    lData.type = 'needInfo';
    lData.list = [];
    const { groups } = groupState;
    const { groupidentifier } = urlParams;
    if (groups && groups.keyValue && groups.keyValue[groupidentifier] && groups.keyValue[groupidentifier].dashboard &&
      groups.keyValue[groupidentifier].dashboard.need_info_reviews
    ) {
      Object.keys(groups.keyValue[groupidentifier].dashboard.need_info_reviews).forEach((item) => {
        if (item !== 'all') {
          if (groups.keyValue[groupidentifier].dashboard.need_info_reviews[item].length &&
            groups.keyValue[groupidentifier].dashboard.locationMap
          ) {
            const { locationMap } = groups.keyValue[groupidentifier].dashboard;
            lData.list.push({
              _id: item,
              name: locationMap[item] && locationMap[item].name ? locationMap[item].name : '',
              logo: locationMap[item] && locationMap[item].logo ? locationMap[item].logo : '',
              address: locationMap[item] && locationMap[item].address ? locationMap[item].address : '',
              count: groups.keyValue[groupidentifier].dashboard.need_info_reviews[item].length,
            });
          }
        }
      });
    }
    setGenericListData(lData);
    setShowGenericListModal(true);
    // document.body.style.overflow = 'auto';
  };

  const onShowResponsePendingReviews = (e) => {
    let lData = {};
    lData.title = "Response Pending Reviews";
    lData.type = 'responsePending';
    lData.list = [];
    const { groups } = groupState;
    const { groupidentifier } = urlParams;
    if (groups && groups.keyValue && groups.keyValue[groupidentifier] && groups.keyValue[groupidentifier].dashboard &&
      groups.keyValue[groupidentifier].dashboard.response_pending_reviews
    ) {
      Object.keys(groups.keyValue[groupidentifier].dashboard.response_pending_reviews).forEach((item) => {
        if (item !== 'all') {
          if (groups.keyValue[groupidentifier].dashboard.response_pending_reviews[item].length &&
            groups.keyValue[groupidentifier].dashboard.locationMap
          ) {
            const { locationMap } = groups.keyValue[groupidentifier].dashboard;
            lData.list.push({
              _id: item,
              name: locationMap[item] && locationMap[item].name ? locationMap[item].name : '',
              logo: locationMap[item] && locationMap[item].logo ? locationMap[item].logo : '',
              address: locationMap[item] && locationMap[item].address ? locationMap[item].address : '',
              count: groups.keyValue[groupidentifier].dashboard.response_pending_reviews[item].length,
            });
          }
        }
      });
    }
    setGenericListData(lData);
    setShowGenericListModal(true);
    // document.body.style.overflow = 'auto';
  };

  const onTrendOtaChange = (value) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onTrendOtaChange: ', value);
    }
    setSelectedOtaForTrend(value.value);
    setOtaForTrendValue(value);
  };

  const onManageGroupCloseClick = (e) => {
    setShowManageGroupModal(false);
    // document.body.style.overflow = 'auto';
  };
  const onGroupEditClick = (groupData) => {
    // setSelectedGroup(groupData);
    setShowManageGroupModal(true);
    // document.body.style.overflow = 'hidden';
  };
  const onGroupUpdateSuccess = (e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onGroupUpdateSuccess');
    }
    getGroupDashboardData(dateFilterStartDate, dateFilterEndDate, null, null);
  };

  const handleCompareClick = (compareNowValue) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('handleCompareClick compareNowValue: ', compareNowValue);
    }
    if (compareNowValue) {
      const rangeValue = dateRangeLastMonthValue.value;
      if (rangeValue.indexOf('-day') > -1) {
        let period = rangeValue.split('-day')[0];
        if (period.indexOf('last-') > -1) period = period.split('last-')[1];
        setCompareDateFilterStartDate(new Date(moment(dateFilterStartDate).subtract(period, 'days')))
        setCompareDateFilterEndDate(new Date(moment(dateFilterEndDate).subtract(period, 'days')))
        getGroupDashboardData(dateFilterStartDate, dateFilterEndDate, new Date(moment(dateFilterStartDate).subtract(period, 'days')), new Date(moment(dateFilterEndDate).subtract(period, 'days')));
      }
      if (rangeValue.indexOf('-month') > -1) {
        let period = rangeValue.split('-month')[0];
        if (period.indexOf('last-') > -1) period = period.split('last-')[1];
        setCompareDateFilterStartDate(new Date(moment(dateFilterStartDate).subtract(period, 'month')))
        setCompareDateFilterEndDate(new Date(moment(dateFilterEndDate).subtract(period, 'month')))
        getGroupDashboardData(dateFilterStartDate, dateFilterEndDate, new Date(moment(dateFilterStartDate).subtract(period, 'month')), new Date(moment(dateFilterEndDate).subtract(period, 'month')));
      }
    }
  }

  const onCompareTextClick = (e) => {
    setCompareNow(compareNow => !compareNow);
    handleCompareClick(!compareNow);
  };

  const onCompareNowChange = (e) => {
    const { name, value } = e.target;
    if (process.env.NODE_ENV !== 'production') {
      console.log('onCompareNowChange: ', value);
      console.log('onCompareNowChange dateRangeLastMonthValue: ', dateRangeLastMonthValue);
    }
    setCompareNow(compareNow => !compareNow);
    handleCompareClick(!compareNow);    
  };

  const onCompareDateRangeChange = (dates, dateStrings) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onCompareDateRangeChange dates: ', dates);
      console.log('onCompareDateRangeChange dateStrings: ', dateStrings);
    }
    setCompareDateFilterStartDate(new Date(dates[0]));
    setCompareDateFilterEndDate(new Date(dates[1]));
    setDateRangeLastMonthValue({ label: 'Select', value: 'select' });
    getGroupDashboardData(dateFilterStartDate, dateFilterEndDate, dates[0], dates[1]);
    // analytics event reporting
    try {
      const { groups } = groupState;
      const groupName = groups[urlParams.groupidentifier]
        ? groups[urlParams.groupidentifier].name || ''
        : ''
      window.gtag('event', 'gt_click', {
        'event_category': `Reputation - Group Dashboard - ${groupName} - Compare Date Change Range`,
        'event_label': `Compare Date Change Range - ${dateStrings}`,
        'gt_user_id': getUserGAId(userState.user),
      });
    } catch (e) {}
  };

  const renderManageGroupModal = () => {
    const { groups } = groupState;
    const { groupidentifier } = urlParams;
    if (!groups || !groups.list || !groups.list.length) return null;
    const groupData = groups.keyValue && groups.keyValue[groupidentifier] ? groups.keyValue[groupidentifier] : null;
    if (!groupData) return null;
    return (
      <Modal>
        <div id="cModelId" className="cModal">
          <div className="content">
            <div className={'sdMdl mngGrpMdl'}>
              <div className="ctnt">
                <ManageGroup
                  onCancelClick={onManageGroupCloseClick}
                  mode="edit"
                  groupData={groupData}
                  onGroupUpdateSuccess={onGroupUpdateSuccess}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  const renderPendingActionsBox = (dashboardObj, getDashboardDataInProgress) => {
    let approvalPendingCount = 0;
    if (dashboardObj && dashboardObj.approval_pending_reviews && dashboardObj.approval_pending_reviews['all'] &&
      dashboardObj.approval_pending_reviews['all'].length
    ) {
      approvalPendingCount = dashboardObj.approval_pending_reviews['all'].length;
    }
    let needInfoCount = 0;
    if (dashboardObj && dashboardObj.need_info_reviews && dashboardObj.need_info_reviews['all'] &&
      dashboardObj.need_info_reviews['all'].length
    ) {
      needInfoCount = dashboardObj.need_info_reviews['all'].length;
    }
    let responsePendingCount = 0;
    if (dashboardObj && dashboardObj.response_pending_reviews && dashboardObj.response_pending_reviews['all'] &&
      dashboardObj.response_pending_reviews['all'].length
    ) {
      responsePendingCount = dashboardObj.response_pending_reviews['all'].length;
    }
    let numberOfCards = 1;
    if (dashboardObj && dashboardObj.has_response_needs_approval) numberOfCards++;
    if (dashboardObj && dashboardObj.has_response_needs_info) numberOfCards++;
    return (
      <div className={`pendingActionsBox numberOfCards_${numberOfCards}`}>
        {
          dashboardObj && dashboardObj.has_response_needs_approval &&
          <ActionCard
            data={{
              title: 'Approval Pending',
              value: approvalPendingCount,
              noValueLabel: getDashboardDataInProgress ? '-' : 'All caught up!',
              titleToolTip: 'Approval Pending',
              ctaLabel: 'See All',
            }}
            onCtaClick={onShowApprovalPendingReviews}
          />
        }
        {
          dashboardObj && dashboardObj.has_response_needs_info &&
          <ActionCard
            data={{
              title: 'Need Info',
              value: needInfoCount,
              noValueLabel: getDashboardDataInProgress ? '-' : 'All caught up!',
              titleToolTip: 'Approval Pending',
              ctaLabel: 'See All',
            }}
            onCtaClick={onShowNeedInfoReviews}
          />
        }
        <ActionCard
          data={{
            title: 'Response Pending',
            value: responsePendingCount,
            noValueLabel: getDashboardDataInProgress ? '-' : 'All caught up!',
            titleToolTip: 'Approval Pending',
            ctaLabel: 'See All',
          }}
          onCtaClick={onShowResponsePendingReviews}
        />
      </div>
    );
  };

  const renderDateFilterRow = (groupName, isSharedGroup, sharedBy) => {
    return (
      <div className="dtFltBx">
        <div className={'left' + (isSharedGroup ? ' shrGrp' : '')}>
          <div className="title">
            {
              groupName ? `${groupName} - Group Overview` : 'Group Overview'
            }
          </div>
          {
            isSharedGroup && Object.keys(sharedBy).length > 0 &&
            <div className="shrBy">
              shared by:
              {
                Object.keys(sharedBy).map((el) => {
                  return (
                    <span>{el}</span>
                  )
                })
              }
            </div>
          }
          {
            !isSharedGroup &&
            <>
              {
                urlParams.groupidentifier !== 'default' &&
                <div className="edit">
                  <span onClick={onGroupEditClick}>Edit Group <img src={editIcon} /></span>
                </div>
              }
            </>
          }
        </div>
        <div className="dateFilter">
          <div className="dtCmpr">
            <span onClick={onCompareTextClick}>Compare</span>
            <input type="checkbox" name="compareNow" onChange={onCompareNowChange} value={compareNow ? 'on' : 'off'} checked={compareNow} />
          </div>
          <div className="dateFilterLastMonth">
            <Select
              className="dateFilterLastMonthSelect"
              options={lastMonthOptions}
              onChange={onDateRangeLastMonthChange}
              value={dateRangeLastMonthValue}
            />
          </div>
          <div className="dateFilterRange">
            <RangePicker
              value={[moment(dateFilterStartDate), moment(dateFilterEndDate)]}
              onChange={onDateRangeChange}
              format="DD MMM YYYY"
            />
          </div>
        </div>
      </div>
    );
  };

  const renderCompareDateFilterRow = () => {
    if (!compareNow) return null;
    return (
      <div className="dtFltBx dtCmp">
        <div className="dateFilter">
          <div className="cmpTxt">
            Compare to
          </div>
          <div className="dateFilterRange">
            <RangePicker
              value={[moment(compareDateFilterStartDate), moment(compareDateFilterEndDate)]}
              onChange={onCompareDateRangeChange}
              format="DD MMM YYYY"
            />
          </div>
        </div>
      </div>
    );
  };

  const renderSplLoader = () => <span><i className="fa fa-circle-notch fa-spin" /></span>;

  const renderStatsBox = (dashboard) => {
    if (!dashboard || !dashboard.stats) return null;
    const { locationidentifier } = urlParams;
    return (
      <div className="dshStatBx">
        <NumberCard
          data={{
            label: 'Total Reviews',
            value: dashboard.getInProgress ? renderSplLoader() : dashboard.stats.all.review_count,
            compareNow,
            cmpValue: dashboard.getInProgress ? renderSplLoader() : dashboard.compare_stats && dashboard.compare_stats.all ? dashboard.compare_stats.all.review_count || '' : '',
            labelToolTip: 'Total Reviews',
            ctaLabel: 'See All',
            ctaLink: `/reputation/groups/${groupidentifier}/reviews`,
          }}
          // onCtaClick={onCheckinsClick}
        />
        <NumberCard
          data={{
            label: 'Overall Rating',
            value: dashboard.getInProgress ? renderSplLoader() : dashboard.stats.all.average_rating,
            compareNow,
            cmpValue: dashboard.getInProgress ? renderSplLoader() : dashboard.compare_stats && dashboard.compare_stats.all ? dashboard.compare_stats.all.average_rating || '' : '',
            labelToolTip: 'Overall rating across all OTAs for the selected period',
          }}
        />
        <NumberCard
          data={{
            label: 'Sentiment',
            value: dashboard.getInProgress ? renderSplLoader() : dashboard.stats.all.sentiment_score,
            compareNow,
            cmpValue: dashboard.getInProgress ? renderSplLoader() : dashboard.compare_stats && dashboard.compare_stats.all ? dashboard.compare_stats.all.sentiment_score || '' : '',
            labelToolTip: 'Sentiment score for the selected period',
            ctaLabel: 'See All',
            ctaLink: `/reputation/groups/${groupidentifier}/sentiments`,
          }}
          // onCtaClick={onRepeatsClick}
        />
        <NumberCard
          data={{
            label: 'Response Rate',
            value: dashboard.getInProgress ? renderSplLoader() : dashboard.stats.all.response_rate,
            labelToolTip: 'Percentage of reviews which have been responded',
            // ctaLabel: 'See All',
          }}
        />
      </div>
    );
  };

  const renderOtaSplitBox = (dashboardObj) => {
    if (!dashboardObj || !dashboardObj.stats || !dashboardObj.stats.all) return null;
    return (
      <div className="dshStatBx">
        <OtaReviewSplitCard
          total={dashboardObj.stats.all.review_count}
          split={dashboardObj.stats.all.review_count_split}
          compareNow={compareNow}
          cmpTotal={dashboardObj.compare_stats && dashboardObj.compare_stats.all ? dashboardObj.compare_stats.all.review_count || '' : ''}
          cmpSplit={dashboardObj.compare_stats && dashboardObj.compare_stats.all ? dashboardObj.compare_stats.all.review_count_split || {} : {}}
          cmpPercent={true}
          data={{
            label: 'OTA wise reviews breakdown',
            value: dashboardObj.getInProgress ? renderSplLoader() : dashboardObj.stats.all.review_count,
            labelToolTip: 'OTA wise reviews breakdown',
            ctaLabel: 'See All',
            ctaLink: ``,
          }}
          // onCtaClick={onCheckinsClick}
        />
        <OtaReviewSplitCard
          total={dashboardObj.stats.all.average_rating}
          split={dashboardObj.stats.all.average_rating_split}
          compareNow={compareNow}
          cmpTotal={dashboardObj.compare_stats && dashboardObj.compare_stats.all ? dashboardObj.compare_stats.all.average_rating || '' : ''}
          cmpSplit={dashboardObj.compare_stats && dashboardObj.compare_stats.all ? dashboardObj.compare_stats.all.average_rating_split || {} : {}}
          data={{
            label: 'OTA wise rating breakdown',
            value: dashboardObj.getInProgress ? renderSplLoader() : dashboardObj.stats.all.review_count,
            labelToolTip: 'OTA wise rating breakdown',
            ctaLabel: 'See All',
            ctaLink: ``,
          }}
          // onCtaClick={onCheckinsClick}
        />
      </div>
    );
  };

  const renderLocationListBox = (dashboardObj, csvFileName) => {
    if (!dashboardObj || !dashboardObj.stats || !dashboardObj.stats.all) return null;
    return (
      <div className="statBox">
        <LocationListCard
          csvFileName={csvFileName}
          locations={dashboardObj.locations || []}
          stats={dashboardObj.stats || {}}
          compareNow={compareNow}
          compareStats={dashboardObj.compare_stats || {}}
          data={{
            label: 'Locations',
            value: dashboardObj.getInProgress ? renderSplLoader() : dashboardObj.stats.all.review_count,
            labelToolTip: 'OTA wise reviews breakdown',
            ctaLabel: 'See All',
            ctaLink: ``,
          }}
        />
      </div>
    );
  };

  const renderReviewTrendBox = (dashboardObj) => {
    if (!dashboardObj || !dashboardObj.trend_data || !dashboardObj.trend_data.all) return null;
    let chartData = dashboardObj.trend_data.all;
    if (otaForTrendValue.value !== 'all') chartData = dashboardObj.trend_data.otas[otaForTrendValue.value];
    return (
      <div className="trendBox">
        <div className="title">
          Review Ratings Trend
          <ToolTipWrapper placement="right" overlayClassName={'sbTooltip'}
            overlay={"Review Ratings Trend"}
          >
            <i className="fas fa-info-circle" />
          </ToolTipWrapper>
        </div>
        <Select
          className="trendOtaSelect"
          options={trendChartOtaOptions}
          onChange={onTrendOtaChange}
          value={otaForTrendValue}
        />
        <ReviewRatingChartFaux
          className="noShadow"
          width={(windowState.window.windowWidth - 196 - 1 - 40 - 60 - 285 + 16 )}
          windowState={windowState}
          height={160}
          data={chartData}
          hideRating={false}
          showOnlyDaily={dateRangeLastMonthValue['value'] === 'last-7-days' || dateRangeLastMonthValue['value'] === 'last-15-days'}
        />
      </div>
    );
  }

  const renderGenericListModal = () => {
    // const { groups } = props.locationprops;
    // if (!groups || !groups.list || !groups.list.length) return null;
    // const groupData = groups.keyValue && groups.keyValue[groupidentifier] ? groups.keyValue[groupidentifier] : null;
    // if (!groupData) return null;
    return (
      <Modal>
        <div id="cModelId" className="cModal">
          <div className="content">
            <div className={'sdMdl '}>
              <div className="ctnt">
                <GenericLocationList
                  onCancelClick={onGenericListCloseClick}
                  data={genericListData}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  };


  const { groupidentifier } = urlParams;
  const { list, keyValue } = groupState.groups;
  const { shared_groups } = groupState;

  const dashboard = groupidentifier && keyValue[groupidentifier] && keyValue[groupidentifier].dashboard
    ? { ...keyValue[groupidentifier].dashboard } : null;
  const getDashboardDataInProgress = groupidentifier && keyValue[groupidentifier]
    ? keyValue[groupidentifier].getDashboardDataInProgress || false : false;
  let groupName = groupidentifier && keyValue[groupidentifier]
    ? keyValue[groupidentifier].name || ''
    : ''
  if (!groupName) {
    if (shared_groups.keyValue && shared_groups.keyValue[groupidentifier]) {
      groupName = shared_groups.keyValue[groupidentifier].name || '';
    }
  }
  if (process.env.NODE_ENV !== 'production') {
    console.log('getDashboardDataInProgress: ', getDashboardDataInProgress);
  }

  let isSharedGroup = false, sharedBy = {};
  if (shared_groups.keyValue && shared_groups.keyValue[groupidentifier]) {
    isSharedGroup = true;
    sharedBy = shared_groups.keyValue[groupidentifier].shared_by || {};
  }

  let csvFileName = "Group";
  if (groupName) { csvFileName += "-" + groupName.replace(/ /g, ''); } else { csvFileName += "-Default"; }
  if (dateFilterStartDate) { csvFileName += "-" + moment(dateFilterStartDate).format('YYYY-MM-DD') }
  if (dateFilterEndDate) { csvFileName += "-to-" + moment(dateFilterEndDate).format('YYYY-MM-DD') }
  csvFileName += ".csv";

  return (
    <>
      {
        getDashboardDataInProgress &&
        <div className="gPnlSplLdng">
          <div className="innerWrap">
            <i className="fa fa-spinner fa-spin" />
            <img src={gtconfig.REPUTATION_LOADER_URL} />
          </div>
        </div>
      }
      {/*
        getDashboardDataInProgress &&
        <div className="gPnlLdng">
          <i className="fa fa-spinner fa-spin" />
        </div>
      */}
      <div className="gPScrlWrp flHt mk">
        <div className="gPg grpDashPg">
          { renderDateFilterRow(groupName, isSharedGroup, sharedBy) }
          { renderCompareDateFilterRow() }
          { renderPendingActionsBox(dashboard, getDashboardDataInProgress) }
          { renderStatsBox(dashboard) }
          { renderOtaSplitBox(dashboard) }
          { renderLocationListBox(dashboard, csvFileName) }
          { renderReviewTrendBox(dashboard) }
        </div>
        { showManageGroupModal && renderManageGroupModal() }
        { showGenericListModal && renderGenericListModal() }
      </div>
    </>
  );
}

export default GroupDashboard;
