import { useSelector, useDispatch } from "react-redux";
import ReactDOM from 'react-dom';
import { useState, useEffect, useCallback, useRef } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
// import { Picker } from 'emoji-mart';
import emojiData from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { Rnd } from "react-rnd";
import moment from 'moment';
import Dropzone, {useDropzone} from 'react-dropzone';
import axios from 'axios';

import { editDataAction, editDataClearAction } from '../../../../../../redux/actions/editDataActions';

import ToolTipWrapper from '../../../../../Misc/ToolTipWrapper';
import useOnClickOutside from '../../../../../Misc/OutsideClickHook';
import useDebounce from '../../../../../Misc/DebounceHook';
import ImagePreviewModal from '../../../../../Misc/ImagePreviewModal';
import GenericAlertModal from '../../../../../Misc/GenericAlertModal';
import GenericSideModal from '../../../../../Misc/GenericSideModal';

import { LANGUAGE_LIST_KEYS, replaceDynamicTerms, ENGAGE_MEDIUM_TO_LABEL,
  productIdToLabels, getCurrentProduct, sendEmailAlert, guestListLabelByCategory, getUserGAId } from '../../../../../../helpers';

import closeIcon from '../../../../../../img/icons/close.svg';
import moreIcon from '../../../../../../img/icons/more.svg';
import viewMoreIcon from '../../../../../../img/icons/view-more.svg';
import viewLessIcon from '../../../../../../img/icons/view-more.svg';
import searchIcon from '../../../../../../img/icons/search.svg';
import whatsappIcon from '../../../../../../img/icons/whatsapp.png';
import emailIcon from '../../../../../../img/icons/email.svg';
import gbmIcon from '../../../../../../img/icons/google-logo.png';
import smsIcon from '../../../../../../img/icons/sms.svg';
import translateIcon from '../../../../../../img/icons/translate.svg';
import emojiIcon from '../../../../../../img/icons/emoji.svg';
import caretDown from '../../../../../../img/icons/caret-down.svg';

import './style.css';

var Scroll = require('react-scroll');
var scroll = Scroll.animateScroll;

const Filter = require('bad-words'),
  filter = new Filter();

const gtconfig = require('../../../../../../gtconfig');

const modalRoot = document.getElementById('modal-root');
function Modal(props) {
  const [el, setEl] = useState(document.createElement('div'));
  useEffect(() => {
    // this.el = document.createElement('div');
    // setEl(document.createElement('div'));
    modalRoot.appendChild(el);
    return function cleanup() {
      modalRoot.removeChild(el);
    }
  }, []);
  return ReactDOM.createPortal(
    props.children,
    el,
  );
}

const MessageTemplateButton = (props) => {
  const onButtonClick = (e) => {
    if (props.isReadOnly) return;
    props.onButtonClick(props.template);
  };
  let tooltipText = props.template.msg;
  if (props.medium === 'whatsapp') {
    if (!props.conversationStarted) {
      tooltipText = 'Initiate WhatsApp conversation first. Templates can be sent only within the message window ( within 24 hrs ).';
    } else {
      if (!props.withinWhatsappWindow) {
        tooltipText = 'Conversation outside message window. Please use WhatsApp starter button to re-initiate conversation.';
      }
    }
  }
  if (props.templateType && props.templateType === "whatsapp") {
    tooltipText = props.template.msg;
  }
  return (
    <ToolTipWrapper placement="top" overlayClassName="mtTooltip"
      overlay={tooltipText}
    >
    <div className="mtOpIt" onClick={onButtonClick}>
      {
        props.templateType === 'whatsapp' &&
        <img src={whatsappIcon} />
      }
      {props.template.title}

      {/*<ReactTooltip id={`tt_mt_${props.template._id}`} data-html={true}
            insecure={true}
            multiline={true} className="messageTemplateTT">
        <span>{tooltipText}</span>
      </ReactTooltip>*/}
    </div>
    </ToolTipWrapper>
  );
};

const MessageTemplateRow = (props) => {
  const onButtonClick = (e) => { props.onButtonClick(props.template); };
  const onEditClick = (e) => { props.onEditClick(props.template); }
  let tooltipText = props.template.msg;
  if (props.medium === 'whatsapp') {
    if (!props.conversationStarted) {
      tooltipText = 'Initiate whatsapp conversation first. Templates can be sent only within the message window ( within 24 hrs ).';
    } else {
      if (!props.withinWhatsappWindow) {
        tooltipText = 'Conversation outside message window. Please use whatsapp starter button to re-initiate conversation.';
      }
    }
  }
  if (props.templateType && props.templateType === "whatsapp") {
    tooltipText = props.template.msg;
  }
  return (
    <div className="mtMnRw">
      <div className="lt" onClick={onButtonClick}>
        {
          props.templateType === 'whatsapp' &&
          <img src={whatsappIcon} />
        }
        <p>{props.template.title}</p>
        <span>{props.template.msg}</span>
      </div>
      <div className="rt">
        <ToolTipWrapper placement="top" overlayClassName="mtTooltip"
          overlay={props.template.msg}
        >
          <span><i className="fas fa-circle-info"></i></span>
        </ToolTipWrapper>
        {
          !props.isReadOnly && props.medium !== 'whatsapp' &&
          <ToolTipWrapper placement="top" overlayClassName="mtTooltip"
            overlay="Edit"
          >
            <span onClick={onEditClick}><i className="fas fa-pen-to-square"></i></span>
          </ToolTipWrapper>
        }
      </div>

      {/*<ReactTooltip id={`tt_mt_${props.template._id}`} data-html={true}
            insecure={true}
            multiline={true} className="messageTemplateTT">
        <span>{tooltipText}</span>
      </ReactTooltip>*/}
    </div>
  );
};

var handlerReSendTypingStatus = null;

function SendMessageBox(props) {
  const windowState = useSelector((state) => state.windowReducer);
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  // let navigate = useNavigate();
  let location = useLocation();
  let urlParams = useParams();

  const [product, setProduct] = useState('');

  const [messageText, setMessageText] = useState('');
  const messageEl = useRef(null);
  const [numberOfLines, setNumberOfLines] = useState(1);

  const [inputExpanded, setInputExpanded] = useState('');

  const [typingStatus, setTypingStatus] = useState(null);
  const debouncedTypingStatus = useDebounce(typingStatus, 1000);

  const [emojiSelectorOpen, setEmojiSelectorOpen] = useState(false);
  const emojiSelectorRef = useRef();
  useOnClickOutside(emojiSelectorRef, useCallback(() => setEmojiSelectorOpen(false)));

  const [pickerStyle, setPickerStyle] = useState({
    height: 0,
  });

  const [languageSelectorOpen, setLanguageSelectorOpen] = useState(false);
  const [langaugeSearchTerm, setLangaugeSearchTerm] = useState('');
  const [translateInProgress, setTranslateInProgress] = useState(false);

  const languageSelectorRef = useRef();
  useOnClickOutside(languageSelectorRef, useCallback(() => setLanguageSelectorOpen(false)));

  const [mostRecentMessage, setMostRecentMessage] = useState(null);
  const [mostRecentlyReceievdMessage, setMostRecentlyReceievdMessage] = useState(null);
  const [mostRecentlyReceievdMessageMedium, setMostRecentlyReceievdMessageMedium] = useState('');
  const [medium, setMedium] = useState('');

  const [mediumSelectorOpen, setMediumSelectorOpen] = useState(false);
  const mediumSelectorRef = useRef();
  useOnClickOutside(mediumSelectorRef, useCallback(() => setMediumSelectorOpen(false)));

  const [mtMenuOpen, setMtMenuOpen] = useState(false);
  const mtMenuRef = useRef();
  useOnClickOutside(mtMenuRef, useCallback(() => setMtMenuOpen(false)));
  const [mtSearchTerm, setMtSearchTerm] = useState('');

  const [templateList, setTemplateList] = useState([]);


  const [selectedTemplateId, setSelectedTemplateId] = useState('');
  const [selectedTemplateType, setSelectedTemplateType] = useState('');

  const [withinWhatsappWindow, setWithinWhatsappWindow] = useState(false);
  const [whatsappStarterNeeded, setWhatsappStarterNeeded] = useState(false);
  const [whatsappStarterMessageActive, setWhatsappStarterMessageActive] = useState(false);
  const [whatsappRestartTemplates, setWhatsappRestartTemplates] = useState([]);

  const [showUploadedPreview, setShowUploadedPreview] = useState(false);
  const [uploadedPath, setUploadedPath] = useState('');
  const [uploadedFileType, setUploadedFileType] = useState('');
  const [uploadedFileName, setUploadedFileName] = useState('');
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [uploadError, setUploadError] = useState('');

  const [showImagePreviewModal, setShowImagePreviewModal] = useState(false);
  const imagePreviewModalRef = useRef();
  const [imagePreviewModalHideConfirm, setImagePreviewModalHideConfirm] = useState(false);

  const [showProfaneCheckModal, setShowProfaneCheckModal] = useState(false);

  const [boxHeight, setBoxHeight] = useState(220);
  const [refBoxHeight, setRefBoxHeight] = useState(220);

  const [showTestSideModal, setShowTestSideModal] = useState(false);
  const [sideModalData, setSideModalData] = useState(null);

  const [inputPrepInProgress, setInputPrepInProgress] = useState(false);

  useEffect(() => {
    const { pathname } = location;
    setProduct(getCurrentProduct(pathname));

    const { engage: { default_messaging_channel, approved_whatsapp_templates } } = props.settings;
    if (approved_whatsapp_templates && approved_whatsapp_templates.length) {
      setWhatsappRestartTemplates(approved_whatsapp_templates.filter(el => el.type === 'starter'));
    }
    if (default_messaging_channel === 'whatsapp' || default_messaging_channel === 'sms' ||
      default_messaging_channel === 'email' || default_messaging_channel === 'webapp'
    ) setMedium(default_messaging_channel);
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('SendMessageBox commChannels / hasSms / hasWhatsapp changed: ', props.commChannels, ' # ', props.hasSms, ' # ', props.hasWhatsapp);
      console.log('mostRecentMessage: ', mostRecentMessage);
    }
    if (!mostRecentMessage) {
      const { engage: { default_messaging_channel } } = props.settings;
      if (props.hasSms) {
        if (default_messaging_channel === 'sms') setMedium('sms');
      } else {
        if (props.hasWhatsapp) {
          if (default_messaging_channel === 'whatsapp') setMedium('whatsapp');
        } else {
          if (props.hasEmail) {
            if (default_messaging_channel === 'email') setMedium('email');
          } else {
            if (props.hasWebApp) {
              if (default_messaging_channel === 'webapp') setMedium('webapp');
            }
          }
        }
      }
    }
  }, [props.commChannels, props.hasSms, props.hasWhatsapp]);

  useEffect(() => {
    if (props.suggestedResponse) {
      setMessageText(props.suggestedResponse);
    }
  }, [props.suggestedResponse]);

  useEffect(() => {
    setBoxHeight(refBoxHeight);
    if (refBoxHeight === '400px') setInputExpanded('inputExpanded');
    if (refBoxHeight === '220px') setInputExpanded('');
  }, [refBoxHeight]);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('SendMessageBox props: ', props);
    }
    setMessageText('');
    setTypingStatus(null);

    if (props.guest.source === 'bm') {
      setMedium('bm');
    } else {
      const { engage: { default_messaging_channel } } = props.settings;
      if (props.hasSms) {
        if (default_messaging_channel === 'sms') setMedium('sms');
      } else {
        if (props.hasWhatsapp) {
          if (default_messaging_channel === 'whatsapp') setMedium('whatsapp');
        } else {
          if (props.hasEmail) {
            if (default_messaging_channel === 'email') setMedium('email');
          } else {
            if (props.hasWebApp) {
              if (default_messaging_channel === 'webapp') setMedium('webapp');
            }
          }
        }
      }
    }

    setMostRecentMessage(null);
    setMostRecentlyReceievdMessage(null);
    setMostRecentlyReceievdMessageMedium(mostRecentlyReceievdMessageMedium => props.guest.source === 'bm' ? 'bm' : '');

    setEmojiSelectorOpen(false);
    setLanguageSelectorOpen(false);
    setLangaugeSearchTerm('');
    setMtSearchTerm('');
    setTranslateInProgress(false);
    setMediumSelectorOpen(false);

    setShowUploadedPreview(false);
    setUploadedPath('');
    setUploadedFileType('');
    setUploadedFileName('');
    setUploadInProgress(false);
    setUploadError('');

    return () => {
      // clear typing status, if running
      if (process.env.NODE_ENV !== 'production') {
        console.log('existing SendMessageBox now');
      }
      if (typingStatus) {
        let name = '';
        if (userState.user.user_info && userState.user.user_info.profile) {
          name = userState.user.user_info.profile.first_name || '';
          if (userState.user.user_info.profile.last_name) {
            name = `${name} ${userState.user.user_info.profile.last_name}`;
          }
        }
        const params = {
          location__id: urlParams.locationidentifier,
          uid: userState.user.user_info.uid,
          name,
          date_typing_status: new Date(),
          typing_status: 'stopped',
          guest_type: props.guestType,
        };
        if (props.guestType === 'guest' || props.guestType === 'past-guest' || props.guestType === 'upcoming-guest') {
          params['guest_id'] = props.guest._id;
        }
        if (props.guestType === 'outside-guest' || props.guestType === 'staff' || props.guestType === 'webtext' || props.guestType === 'gbm') {
          params['outside_guest_id'] = props.guest._id;
        }
        if (!props.guest.updateTypingStatusInProgress) {
          // props.dispatch(updateTypingStatusAction({ // nkcheck needed
          //   params,
          // }));
        } else {
          // if (process.env.NODE_ENV !== 'production') {
          //   console.log('updateTypingStatusAction updateTypingStatusInProgress: ', updateTypingStatusInProgress);
          // }
        }
        if (process.env.NODE_ENV !== 'production') {
          console.log('existing SendMessageBox finally');
        }
      }
    };

  }, [props.guest._id]);

  useEffect(() => {
    if (props.guest.messages && props.guest.messages.length) {
      for(let i = 0; i < props.guest.messages.length; i++) {
        if (props.guest.messages[i].type === 1) {
          setMostRecentlyReceievdMessage(props.guest.messages[i]);
          if (props.guest.messages[i].medium === 'whatsapp' && props.hasWhatsapp) {
            setMostRecentlyReceievdMessageMedium(props.guest.messages[i].medium);
            // check whatsapp messaging window
            if (props.guest.messages[i] && props.guest.messages[i].date_created) {
              const secondsSince = moment(new Date()).diff(moment(props.guest.messages[i].date_created), 'seconds');
              if (process.env.NODE_ENV !== 'production') {
                console.log('props.guest.messages[i] secondsSince: ', secondsSince);
              }
              if (secondsSince < 60*60*24) {
                // setMedium('whatsapp');
                setWithinWhatsappWindow(true);
              } else {
                setWithinWhatsappWindow(false);
              }
            }
          }
          if (props.guest.messages[i].medium === 'email' && props.hasEmail) {
            setMostRecentlyReceievdMessageMedium(props.guest.messages[i].medium);
          }
          if (props.guest.messages[i].from_webapp && props.hasWebApp) {
            setMostRecentlyReceievdMessageMedium('webapp');
          }
          break;
        }
      }
      setMostRecentMessage(props.guest.messages[0]);
    } else {
      setMostRecentMessage(null);
      setMostRecentlyReceievdMessageMedium('');
      setWithinWhatsappWindow(false);
    }
  }, [props.guest.messages]);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('mostRecentMessage changed: ', mostRecentMessage);
    }
    if (!mostRecentMessage) return;
    if (mostRecentMessage.medium === 'whatsapp') {
      if (props.hasWhatsapp) {
        setMedium('whatsapp');
        if (process.env.NODE_ENV !== 'production') {
          console.log('used mostRecentMessage medium: ', mostRecentMessage.medium);
        }
      }
    }
    if (mostRecentMessage.medium === 'sms') {
      if (props.hasSms) {
        setMedium('sms');
        if (process.env.NODE_ENV !== 'production') {
          console.log('used mostRecentMessage medium: ', mostRecentMessage.medium);
        }
      }
    }
    if (mostRecentMessage.medium === 'email') {
      if (props.hasEmail) {
        setMedium('email');
        if (process.env.NODE_ENV !== 'production') {
          console.log('used mostRecentMessage medium: ', mostRecentMessage.medium);
        }
      }
    }
    if (mostRecentMessage.medium === 'bm') {
      setMedium('bm');
      if (process.env.NODE_ENV !== 'production') {
        console.log('used mostRecentMessage medium: ', mostRecentMessage.medium);
      }
    }
    if (!mostRecentMessage.medium) {
      if (props.hasSms) {
        setMedium('sms');
        if (process.env.NODE_ENV !== 'production') {
          console.log('used mostRecentMessage medium not set: ', mostRecentMessage.medium);
        }
      }
    }
    if (mostRecentMessage.from_webapp) {
      if (props.hasWebApp) {
        setMedium('webapp');
        if (process.env.NODE_ENV !== 'production') {
          console.log('used mostRecentMessage from_webapp: ', mostRecentMessage.from_webapp);
        }
      }
    }
  }, [mostRecentMessage]);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('mostRecentlyReceievdMessageMedium changed: ', mostRecentlyReceievdMessageMedium);
    }
    if (props.hasSms) {
      setMedium(mostRecentlyReceievdMessageMedium || 'sms');
    } else {
      if (props.hasWhatsapp) {
        setMedium(mostRecentlyReceievdMessageMedium || 'whatsapp');
      } else {
        if (props.hasEmail) {
          setMedium(mostRecentlyReceievdMessageMedium || 'email');
        } else {
          if (props.hasWebApp) {
            setMedium(mostRecentlyReceievdMessageMedium || 'webapp');
          }
        }
      }
    }
    // setMedium(mostRecentlyReceievdMessageMedium || 'sms');
    if (mostRecentlyReceievdMessageMedium === 'whatsapp') {
      if (props.hasWhatsapp) {
        if (mostRecentlyReceievdMessage && mostRecentlyReceievdMessage.date_created) {
          const secondsSince = moment(new Date()).diff(moment(mostRecentlyReceievdMessage.date_created), 'seconds');
          if (process.env.NODE_ENV !== 'production') {
            console.log('mostRecentlyReceievdMessage secondsSince: ', secondsSince);
          }
          if (secondsSince < 60*60*24) {
            setMedium('whatsapp');
            setWithinWhatsappWindow(true);
          } else {
            setWithinWhatsappWindow(false);
          }
        }
      } else {
        if (props.hasSms) {
          setMedium('sms');
        } else {
          if (props.hasEmail) {
            setMedium('email');
          } else {
            if (props.hasWebApp) {
              setMedium('webapp');
            }
          }
        }
      }
    } else {
      setWithinWhatsappWindow(false);
    }
    if (process.env.NODE_ENV !== 'production') {
      console.log('mostRecentMessage mostRecentMessage: ', mostRecentMessage);
    }
    // check with most recently sent message
    if (mostRecentlyReceievdMessage && mostRecentMessage) {
      if (mostRecentlyReceievdMessage.date_created < mostRecentMessage.date_created) {
        if ((!mostRecentMessage.medium || mostRecentMessage.medium === 'sms') && props.hasSms) setMedium('sms');
        if ((mostRecentMessage.medium === 'whatsapp') && props.hasWhatsapp) setMedium('whatsapp');
        if ((mostRecentMessage.medium === 'email') && props.hasEmail) setMedium('email');
        if ((mostRecentMessage.medium === 'webapp') && props.hasWebApp) setMedium('webapp');
      }
    }
  }, [mostRecentlyReceievdMessageMedium]);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('useEffect withinWhatsappWindow: ', withinWhatsappWindow);
      console.log('useEffect medium: ', medium);
      console.log('useEffect whatsappRestartTemplates: ', whatsappRestartTemplates);
    }
    setWhatsappStarterNeeded(medium === 'whatsapp' && !withinWhatsappWindow && whatsappRestartTemplates.length ? true : false);
  }, [withinWhatsappWindow, medium, whatsappRestartTemplates]);

  
  useEffect(() => {
    if (medium === 'whatsapp') {
      updateTemplateList('whatsapp');
    }
    if (medium === 'sms') {
      updateTemplateList('sms');
    }
  }, [medium]);


  useEffect(() => {
    // const { message_templates, approved_whatsapp_templates } = props.settings.engage;
    // let templateListLocal = [];
    // if (!whatsappStarterNeeded) {
    //   if (message_templates && message_templates.list && message_templates.list.length > 0) {
    //     message_templates.list.filter(el => !message_templates.keyValue[el].is_removed)
    //     .forEach((item) => {
    //       const mt = message_templates.keyValue[item];
    //       if (mt) templateListLocal.push({ ...mt, templateType: 'generic' });
    //     });
    //   }
    // }
    // // if (whatsappStarterNeeded || withinWhatsappWindow) {
    //   if (props.hasWhatsapp && approved_whatsapp_templates && approved_whatsapp_templates.length > 0) {
    //     if (medium === 'whatsapp') {
    //       approved_whatsapp_templates
    //       .filter(elem => elem && elem.type === 'starter')
    //       .forEach((item) => {
    //         if (item) templateListLocal.push({ ...item, templateType: 'whatsapp' });
    //       })
    //     }
    //   }
    // // }
    // if (process.env.NODE_ENV !== 'production') {
    //   console.log('SendMessage templateListLocal: ', templateListLocal);
    // }
    // templateListLocal = templateListLocal.sort((a, b) => {
    //   if (a.count && b.count) { return b.count - a.count; };
    //   if (a.count && !b.count) { return -1; };
    //   if (!a.count && b.count) { return 1; };
    //   return 0;
    // })
    // setTemplateList(templateListLocal);
    // if (!templateListLocal.length) {
    //   setBoxHeight(165);
    //   setRefBoxHeight(165);
    // } else {
    //   setBoxHeight(220);
    //   setRefBoxHeight(220);
    // }
    updateTemplateList(medium);
  }, [props.settings.engage]);

  useEffect(() => {
    if (debouncedTypingStatus !== null) {
      sendTypingStatus();
    }
  }, [debouncedTypingStatus]);


  const updateTemplateList = (curMedium) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('SendMessage updateTemplateList curMedium: ', curMedium);
      console.log('SendMessage updateTemplateList whatsappStarterNeeded: ', whatsappStarterNeeded);
    }
    const { message_templates, approved_whatsapp_templates } = props.settings.engage;
    if (process.env.NODE_ENV !== 'production') {
      console.log('SendMessage updateTemplateList props: ', props);
      console.log('SendMessage updateTemplateList approved_whatsapp_templates: ', approved_whatsapp_templates);
    }
    let templateListLocal = [];
    let whatsappStarterNeededLocal = curMedium === 'whatsapp' && !withinWhatsappWindow && whatsappRestartTemplates.length ? true : false;
    if (process.env.NODE_ENV !== 'production') {
      console.log('SendMessage updateTemplateList whatsappStarterNeededLocal: ', whatsappStarterNeededLocal);
    }
    if (curMedium === 'sms' || (curMedium === 'whatsapp' && !whatsappStarterNeededLocal)) {
      if (message_templates && message_templates.list && message_templates.list.length > 0) {
        message_templates.list.filter(el => !message_templates.keyValue[el].is_removed)
        .forEach((item) => {
          const mt = message_templates.keyValue[item];
          if (mt) templateListLocal.push({ ...mt, templateType: 'generic' });
        });
      }
    }
    if (process.env.NODE_ENV !== 'production') {
      console.log('SendMessage templateListLocal: ', templateListLocal);
    }
    // if (whatsappStarterNeeded || withinWhatsappWindow) {
      if (props.hasWhatsapp && approved_whatsapp_templates && approved_whatsapp_templates.length > 0) {
        if (curMedium === 'whatsapp') {
          approved_whatsapp_templates
          .filter(elem => elem && elem.type === 'starter')
          .forEach((item) => {
            if (item) templateListLocal.push({ ...item, templateType: 'whatsapp' });
          })
        }
      }
    // }
    if (process.env.NODE_ENV !== 'production') {
      console.log('SendMessage templateListLocal:: ', templateListLocal);
    }
    templateListLocal = templateListLocal.sort((a, b) => {
      if (a.count && b.count) { return b.count - a.count; };
      if (a.count && !b.count) { return -1; };
      if (!a.count && b.count) { return 1; };
      return 0;
    })
    setTemplateList(templateListLocal);
    if (!templateListLocal.length) {
      setBoxHeight(165);
      setRefBoxHeight(165);
    } else {
      setBoxHeight(220);
      setRefBoxHeight(220);
    }
  };

  const scrollToBottom = () => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('scrollToBottom');
    }
    scroll.scrollToBottom({
      duration: 100,
      delay: 100,
      smooth: true,
      containerId: 'gcBId',
      // offset: 50, // Scrolls to element + 50 pixels down the page
    });
  };

  const sendTypingStatus = () => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('sendTypingStatus');
    }
    let name = '';
    if (userState.user.user_info && userState.user.user_info.profile) {
      name = userState.user.user_info.profile.first_name || '';
      if (userState.user.user_info.profile.last_name) {
        name = `${name} ${userState.user.user_info.profile.last_name}`;
      }
    }
    const params = {
      location__id: urlParams.locationidentifier,
      uid: userState.user.user_info.uid,
      name,
      date_typing_status: new Date(),
      typing_status: debouncedTypingStatus ? 'started' : 'stopped',
      guest_type: props.guestType,
    };
    if (props.guestType === 'guest' || props.guestType === 'past-guest' || props.guestType === 'upcoming-guest') {
      params['guest_id'] = props.guest._id;
    }
    if (props.guestType === 'outside-guest' || props.guestType === 'staff' || props.guestType === 'webtext' || props.guestType === 'gbm') {
      params['outside_guest_id'] = props.guest._id;
    }
    // props.dispatch(updateTypingStatusAction({ // nkcheck needed
    //   params,
    // }));
    if (process.env.NODE_ENV !== 'production') {
      console.log('handlerReSendTypingStatus created');
    }
    if (handlerReSendTypingStatus) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('clear handlerReSendTypingStatus before creating new one');
      }
      clearTimeout(handlerReSendTypingStatus);
    }
    if (debouncedTypingStatus) {
      handlerReSendTypingStatus = setTimeout(() => {
        sendTypingStatus();
      }, 5000);
    }
  };

  const onTemplateClick = (template) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onTemplateClick: ', template);
    }
    if (medium === 'whatsapp' && !withinWhatsappWindow) return;

    setInputPrepInProgress(true);
    // setMessageText(replaceDynamicTerms(template.msg, props.guest, props.settings));
    replaceDynamicTerms(template.msg, props.guest, props.guestType, props.settings)
      .then((data) => { setMessageText(data); setInputPrepInProgress(false); });
    setSelectedTemplateId(template._id);
    setSelectedTemplateType('generic');
    setTypingStatus(template.msg ? true : false);
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : ''
      window.gtag('event', 'gt_click', {
        'event_category': `Engage - Messaging - ${guestListLabelByCategory[urlParams.categoryidentifier]} - Template Selected`,
        'event_label': `Select Template - ${template.title || ''}`,
        page_path: `${location.pathname}`,
        page_title: `Engage - Messaging - ${guestListLabelByCategory[urlParams.categoryidentifier]} - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  };

  const onWhatsappTemplateClick = (template) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onWhatsappTemplateClick: ', template);
    }

    // setMessageText(replaceDynamicTerms(template.msg, props.guest, props.settings));
    replaceDynamicTerms(template.msg, props.guest, props.guestType, props.settings)
      .then((data) => { setMessageText(data); setInputPrepInProgress(false); });
    setSelectedTemplateId(template._id);
    setSelectedTemplateType('whatsapp');
    setTypingStatus(template.msg ? true : false);
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : ''
      window.gtag('event', 'gt_click', {
        'event_category': `Engage - Messaging - ${guestListLabelByCategory[urlParams.categoryidentifier]} - Whatsapp Template Selected`,
        'event_label': `Select Whatsapp Template - ${template.title || ''}`,
        page_path: `${location.pathname}`,
        page_title: `Engage - Messaging - ${guestListLabelByCategory[urlParams.categoryidentifier]} - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  };

  const onChange = (e) => {
    // do not register anything if outside whatsapp message window
    if (medium === 'whatsapp' && !withinWhatsappWindow) return;

    if (medium === 'whatsapp') {
      setSelectedTemplateId('');
      setSelectedTemplateType('');
    }

    const { name, value } = e.target;
    setMessageText(value);
    setTypingStatus(true);
    var lines = value.split(/\r|\r\n|\n/);
    var count = lines.length;
    if (process.env.NODE_ENV !== 'production') {
      console.log('onChange number of lines: ', count);
    }
    setNumberOfLines(count);
    // setInputExpanded(count > 3 ? 'inputExpanded' : '');
  };
  const onBlur = (e) => {
    setTypingStatus(messageText ? true : false);
  };
  const onFocus = (e) => {
  };
  const onMessageTextKeyDown = (e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onMessageTextKeyDown: ', e.keyCode);
      console.log('onMessageTextKeyDown shiftKey: ', e.shiftKey);
    }
    // nkcheck
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      if (messageText || uploadedPath) {
        processSendMessage();
        setUploadedPath('');
        setUploadedFileType('');
        setUploadedFileName('');
      }
    }
    // // if (e.keyCode === 27) {
    // //   setMtSearchTerm('');
    // // }
  };
  const onExpandInputClick = (e) => {
    setInputExpanded(inputExpanded ? '' : 'inputExpanded');
    setBoxHeight(boxHeight === 220 ? 300 : refBoxHeight);
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : ''
      window.gtag('event', 'gt_click', {
        'event_category': `Engage - Messaging - ${guestListLabelByCategory[urlParams.categoryidentifier]} - Expand Message Area`,
        'event_label': `Expand Message Area`,
        page_path: `${location.pathname}`,
        page_title: `Engage - Messaging - ${guestListLabelByCategory[urlParams.categoryidentifier]} - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  };

  const onContractInputClick = (e) => {
    setInputExpanded('');
    setBoxHeight(220);
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : ''
      window.gtag('event', 'gt_click', {
        'event_category': `Engage - Messaging - ${guestListLabelByCategory[urlParams.categoryidentifier]} - Contract Message Area`,
        'event_label': `Contract Message Area`,
        page_path: `${location.pathname}`,
        page_title: `Engage - Messaging - ${guestListLabelByCategory[urlParams.categoryidentifier]} - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  };

  const onOpenEmojiSelectorClick = (e) => {
    if (whatsappStarterNeeded) return;
    if (whatsappStarterMessageActive) return;
    setPickerStyle(pickerStyle => ({
      ...pickerStyle,
      height: emojiSelectorOpen ? 0 : 200,
    }));
    // props.onOpenEmojiSelectorClick(!emojiSelectorOpen);
    setEmojiSelectorOpen(emojiSelectorOpen => !emojiSelectorOpen);
  };
  const onEmojiClick = (emoji, e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onEmojiClick: ', emoji, ' # ', e);
    }
    if (props.isReadOnly) return;
    if (whatsappStarterMessageActive) return;
    if (emoji && emoji.native) {
      setMessageText(messageText => `${messageText.substring(0,messageEl.current.selectionStart)}${emoji.native}${messageText.substring(messageEl.current.selectionStart)}`);
      setTypingStatus(true);
      try {
        const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
          && locationState.locations[urlParams.locationidentifier].settings
          && locationState.locations[urlParams.locationidentifier].settings.info
          ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
          : ''
        window.gtag('event', 'gt_click', {
          'event_category': `Engage - Messaging - ${guestListLabelByCategory[urlParams.categoryidentifier]} - Emoji Selected`,
          'event_label': `Select Emoji`,
          page_path: `${location.pathname}`,
          page_title: `Engage - Messaging - ${guestListLabelByCategory[urlParams.categoryidentifier]} - ${locationName}`,
          gt_user_id: getUserGAId(userState.user),
          gt_location_id: urlParams.locationidentifier,
          gt_location: locationName,
        });
      } catch (e) {}
    }
  };


  const onTranslateLanguageSelectorOpenClick = (e) => {
    if (props.isReadOnly) return;
    if (whatsappStarterNeeded) return;
    if (whatsappStarterMessageActive) return;
    setLanguageSelectorOpen(languageSelectorOpen => !languageSelectorOpen);
    // gtag('event', 'gt_click', {
    //   'event_category': `Messaging - ${resultTypeToLabel[props.guestType] || props.guestType}`,
    //   'event_label': `Open - Language Selector`,
    //   // 'value': 1,
    //   user_id: props.userprops.user.uid,
    // });
  };

  const onLanguageSelectorInputChange = (e) => {
    const { name, value } = e.target;
    setLangaugeSearchTerm(value);
    // gtag('event', 'gt_click', {
    //   'event_category': `Messaging - ${resultTypeToLabel[props.guestType] || props.guestType}`,
    //   'event_label': `Language Selector - Input - ${value}`,
    //   // 'value': 1,
    //   user_id: props.userprops.user.uid,
    // });
  };

  const translateText = (text, lang) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('translateText: ', lang, ' # ', text)
    }
    // return; // nkcheck
    let url = `https://translation.googleapis.com/language/translate/v2?key=${gtconfig.GOOGLE_TRANSLATE_API_KEY}`;
    url += '&q=' + encodeURI(text);
    url += `&target=${lang}`;
    setTranslateInProgress(true);
    fetch(url, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      }
    })
      .then(res => res.json())
      .then((response) => {
        setTranslateInProgress(false);
        if (process.env.NODE_ENV !== 'production') {
          console.log('translateText response: ', response.data);
        }
        if (response && response.data && response.data.translations && response.data.translations[0].translatedText) {
          setMessageText(response.data.translations[0].translatedText);
          const params = {
            lang_location__id: urlParams.locationidentifier,
          };
          params['recent_lang'] = lang;

          if (process.env.NODE_ENV !== 'production') {
            console.log('edit_profile params: ', params);
          }
          dispatch(editDataAction({
            op: 'edit_profile',
            params,
          }));
          sendEmailAlert({
            app: gtconfig.APP_NAME,
            subject: `${gtconfig.APP_NAME}(${gtconfig.APP_ID}) - translateText - ${userState.user.user_info.uid}`,
            message: JSON.stringify({ url, responseData: response.data }),
            // error: msg,
            // store: JSON.stringify(getState()),
          });
        } else {
          if (process.env.NODE_ENV !== 'production') {
            console.log('error in translation');
          }
          sendEmailAlert({
            app: gtconfig.APP_NAME,
            subject: `${gtconfig.APP_NAME}(${gtconfig.APP_ID}) - translateText Error - ${userState.user.user_info.uid}`,
            message: JSON.stringify({ url, responseData: response.data }),
            error: JSON.stringify(response.data),
            // store: JSON.stringify(getState()),
          });
        }
      })
      .catch((error) => {
        setTranslateInProgress(false);
        if (process.env.NODE_ENV !== 'production') {
          console.log('translateText error: ', error);
        }
        sendEmailAlert({
          app: gtconfig.APP_NAME,
          subject: `${gtconfig.APP_NAME}(${gtconfig.APP_ID}) - translateText Error - fetch - ${userState.user.user_info.uid}`,
          message: JSON.stringify({ url }),
          error: JSON.stringify({ error }),
          // store: JSON.stringify(getState()),
        });
      });
  };

  const onLanguageSelect = (lang) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onLanguageSelect: ', lang);
    }
    if (whatsappStarterMessageActive) return;
    setLanguageSelectorOpen(false);
    if (messageText) {
      translateText(messageText, lang);
      try {
        const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
          && locationState.locations[urlParams.locationidentifier].settings
          && locationState.locations[urlParams.locationidentifier].settings.info
          ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
          : ''
        window.gtag('event', 'gt_click', {
          'event_category': `Engage - Messaging - ${guestListLabelByCategory[urlParams.categoryidentifier]} - Language Selected`,
          'event_label': `Select Language - ${lang || ''}`,
          page_path: `${location.pathname}`,
          page_title: `Engage - Messaging - ${guestListLabelByCategory[urlParams.categoryidentifier]} - ${locationName}`,
          gt_user_id: getUserGAId(userState.user),
          gt_location_id: urlParams.locationidentifier,
          gt_location: locationName,
        });
      } catch (e) {}
      // gtag('event', 'gt_click', {
      //   'event_category': `Messaging - ${resultTypeToLabel[props.guestType] || props.guestType}`,
      //   'event_label': `Selected Language - ${lang}`,
      //   // 'value': 1,
      //   user_id: props.userprops.user.uid,
      // });
    }
  };

  const onOpenMessageTemplatesMenu = (e) => {
    setMtMenuOpen(mtMenuOpen => !mtMenuOpen);
  };
  const onMtSelectorInputChange = (e) => {
    const { name, value } = e.target;
    setMtSearchTerm(value.replace(/[/\\*]/g, ""));
    // gtag('event', 'gt_click', {
    //   'event_category': `Messaging - ${resultTypeToLabel[props.guestType] || props.guestType}`,
    //   'event_label': `Language Selector - Input - ${value}`,
    //   // 'value': 1,
    //   user_id: props.userprops.user.uid,
    // });
  };
  const onMtSearchTermKeyDown = (e) => {
    if (e.keyCode === 27) {
      setMtSearchTerm('');
    }
  };
  const onMtMenuManageAllClick = (e) => {

  };
  const onMtMenuSelect = (mt) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onMtMenuSelect: ', mt);
    }
    if (medium === 'whatsapp' && whatsappStarterNeeded && mt.type !== 'starter') return; // nkcheck
    //
    // setMessageText(replaceDynamicTerms(mt.msg, props.guest, props.settings));
    replaceDynamicTerms(mt.msg, props.guest, props.guestType, props.settings)
      .then((data) => { setMessageText(data); setInputPrepInProgress(false); });
    setSelectedTemplateId(mt._id);
    setSelectedTemplateType('generic');
    setTypingStatus(mt.msg ? true : false);
    // gtag('event', 'gt_click', {
    //   'event_category': `Messaging - ${resultTypeToLabel[props.guestType] || props.guestType}`,
    //   'event_label': `Selected Template`,
    //   // 'value': 1,
    //   user_id: props.userprops.user.uid,
    // });
    setMtMenuOpen(false);
    // if (messageText) {
    //   translateText(messageText, lang);
    //   gtag('event', 'gt_click', {
    //     'event_category': `Messaging - ${resultTypeToLabel[props.guestType] || props.guestType}`,
    //     'event_label': `Selected Language - ${lang}`,
    //     // 'value': 1,
    //     user_id: props.userprops.user.uid,
    //   });
    // }
  };


  const onMediumSelectorOpenClick = (e) => {
    e.preventDefault();
    if (props.commChannels && props.commChannels.length > 1) {
      setMediumSelectorOpen(mediumSelectorOpen => !mediumSelectorOpen);
      // gtag('event', 'gt_click', {
      //   'event_category': `Messaging - ${resultTypeToLabel[props.guestType] || props.guestType}`,
      //   'event_label': `Open - Medium Selector`,
      //   // 'value': 1,
      //   user_id: props.userprops.user.uid,
      // });
    }
  };

  const onMediumSelectorOptionSMSClick = (e) => {
    setMedium('sms'); setMediumSelectorOpen(false);
    updateTemplateList('sms');
    setWhatsappStarterMessageActive(false);
    setSelectedTemplateId('');
    setSelectedTemplateType('');
    // gtag('event', 'gt_click', {
    //   'event_category': `Messaging - ${resultTypeToLabel[props.guestType] || props.guestType}`,
    //   'event_label': `Selected Medium - SMS`,
    //   // 'value': 1,
    //   user_id: props.userprops.user.uid,
    // });
  };
  const onMediumSelectorOptionWhatsappClick = (e) => {
    setMedium('whatsapp'); setMediumSelectorOpen(false);
    updateTemplateList('whatsapp');
    const whatsappStarterNeeded = !withinWhatsappWindow && whatsappRestartTemplates.length;
    if (whatsappStarterNeeded) setMessageText('');
    setSelectedTemplateId('');
    setSelectedTemplateType('');
    // gtag('event', 'gt_click', {
    //   'event_category': `Messaging - ${resultTypeToLabel[props.guestType] || props.guestType}`,
    //   'event_label': `Selected Medium - Whatsapp`,
    //   // 'value': 1,
    //   user_id: props.userprops.user.uid,
    // });
  };
  const onMediumSelectorOptionEmailClick = (e) => {
    setMedium('email'); setMediumSelectorOpen(false);
    setSelectedTemplateId('');
    setSelectedTemplateType('');
    // gtag('event', 'gt_click', {
    //   'event_category': `Messaging - ${resultTypeToLabel[props.guestType] || props.guestType}`,
    //   'event_label': `Selected Medium - Email`,
    //   // 'value': 1,
    //   user_id: props.userprops.user.uid,
    // });
  };

  const onMediumSelectorOptionWebAppClick = (e) => {
    setMedium('webapp'); setMediumSelectorOpen(false);

    // gtag('event', 'gt_click', {
    //   'event_category': `Messaging - ${resultTypeToLabel[props.guestType] || props.guestType}`,
    //   'event_label': `Selected Medium - Email`,
    //   // 'value': 1,
    //   user_id: props.userprops.user.uid,
    // });
  };

  /* Start - Image Modal */

  const onAttachMediaClick = (e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onAttachMediaClick');
    }
  };

  const onDrop = useCallback((acceptedFiles) => {

    // Do something with the files
    if (process.env.NODE_ENV !== 'production') {
      console.log('acceptedFiles: ',acceptedFiles);
      console.log('acceptedFiles medium: ',medium);
    }
    if (acceptedFiles.length===0) {
      return;
    }
    setUploadedPath('');
    setUploadedFileType('');
    setUploadedFileName('');
    setImagePreviewModalHideConfirm(false);

    if (acceptedFiles.length === 1) {
      acceptedFiles.forEach((file) => {
        // check file size

        // if (medium !== 'sms' && medium !== 'whatsapp') return;

        let fileType = '';
        if (!medium || medium === 'sms' || medium === 'webapp') {
          if ( /\.(jpe?g|png|gif)$/i.test(file.name) === false ) {
            setUploadError(`Supported image types: JPG, JPEG, PNG and GIF. Please try with a different image.`);
            setUploadInProgress(false);
            // setShowUploadErrorModal(true);
            return false;
          }
          if ( /\.(jpe?g|png|gif)$/i.test(file.name)) {
            fileType = 'image';
          }
        }
        if (medium === 'whatsapp') {
          if ( /\.(jpe?g|png)$/i.test(file.name) === false ) {
            setUploadError(`Supported image types: JPG, JPEG and PNG. Please try with a different image.`);
            setUploadInProgress(false);
            // setShowUploadErrorModal(true);
            return false;
          }
          if ( /\.(jpe?g|png)$/i.test(file.name)) {
            fileType = 'image';
          }
        }

        if ( /\.(pdf)$/i.test(file.name)) {
          fileType = 'pdf';
        }
        setUploadedFileType(fileType);
        setUploadedFileName(file.name);
        setUploadError('');

        if (file.size > 1024 * 1024) {
          setUploadError('Size exceeds 1MB! Please try with a file with a size smaller than 1MB.');
          setUploadInProgress(false);
          setUploadedFileType('');
          setUploadedFileName('');
          return false;
        }
        setUploadError('');

        const reader = new FileReader();
        reader.onload = (e) => {
          const fileAsBinaryString = reader.result;
          // do whatever you want with the file content
          if (process.env.NODE_ENV !== 'production') {
            // console.log('fileAsBinaryString: ',fileAsBinaryString);
            console.log('fileType: ',fileType);
            console.log('e.target.result: ',e.target.result);
          }
          if (fileType === 'image') {
            //Initiate the JavaScript Image object.
            var image = new Image();

            //Set the Base64 string return from FileReader as source.


            //Validate the File Height and Width.
            image.onload = function () {
              var height = this.height;
              var width = this.width;
              if (process.env.NODE_ENV !== 'production') {
                console.log('height: ', height);
                console.log('width: ', width);
              }
              // if (height !== 1024 || width != 1024) {
              //   setUploadError(`Image must be 1024px X 1024px. Tried one is ${height}px X ${width}px`);
              //   setShowUploadErrorModal(true);
              //   return false;
              // }
              uploadFile(file);
              return true;
            };
            image.src = e.target.result;
          }
          if (fileType === 'pdf') {
            uploadFile(file);
            return true;
          }
        };
        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');

        reader.readAsDataURL(file);
      });
    } else {
      // swal('One at a time.', 'Please upload one file at a time.', 'warning');

    }
  }, []);

  const uploadFile = (file) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('uploadFile: ', file);
    }
    setShowUploadedPreview(true);
    setUploadedPath('');
    setUploadInProgress(true);
    setShowImagePreviewModal(true);

    let filename = file.name.replace(/\s/g,'');
    let fileExtension = 'jpg';
    let tArr = filename.split('.');
    if (tArr.length >= 2) {
      fileExtension = tArr[tArr.length-1];
    }
    const onlyFileName = filename.split(`.${fileExtension}`)[0];

    if (process.env.NODE_ENV !== 'production') {
      console.log('fileExtension: ', fileExtension);
      console.log('onlyFileName: ', onlyFileName);
    }

    let postData = new FormData();
    postData.append('file', file, file.name);
    postData.append('name', filename);
    postData.append('new_name', `${onlyFileName}.${fileExtension}`);
    // postData.append('uid', props.userprops.user.uid);
    postData.append('location_id', userState.user.user_info.locations[urlParams.locationidentifier].location_id);
    postData.append('identifier', file.name);
    postData.append('source', 'gtengage-web');

    // //get data from database
    let url = "https://xooz4w4zi5.guesttouch.com/dapi/pubg8dh9i6ncy4c73awy";
    const header = { 'gt-access-token' : "o4LmjvNtU15A8a2FMzikK3cNhpVXFGEK3V78zAIa", 'Content-Type': 'multipart/form-data' };
    // const headers = {
    //   'Content-type': 'multipart/form-data',
    //   'x-api-key': "o4LmjvNtU15A8a2FMzikK3cNhpVXFGEK3V78zAIa",
    // };
    if (process.env.NODE_ENV !== 'production') {
      console.log('uploadFile called api');
      console.log('uploadFile postData: ', postData);
    }
    axios.post(url, postData, { headers: header }).then((response) => {
      // setUploadInProgress(false);
      if (process.env.NODE_ENV !== 'production') {
        console.log('uploadFile response: ', response['data']);
      }
      const { status } = response.data;
      const { data } = response.data;
      if (status === 'success' && data && data.name && data.path) {
        setShowUploadedPreview(true);
        setUploadedPath(data.path);
        setShowImagePreviewModal(true);
        // setForm(prevForm => ({
        //   ...prevForm,
        //   [data.identifier]: data.name, //feedbackEmailHotelLogoUrl
        //   [`${data.identifier}Error`]: 'success',
        //   [`${data.identifier}UploadInProgress`]: false,
        // }));
        try {
          const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
            && locationState.locations[urlParams.locationidentifier].settings
            && locationState.locations[urlParams.locationidentifier].settings.info
            ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
            : ''
          window.gtag('event', 'gt_click', {
            'event_category': `Engage - Messaging - ${guestListLabelByCategory[urlParams.categoryidentifier]} - Image Upload`,
            'event_label': `Upload Image`,
            page_path: `${location.pathname}`,
            page_title: `Engage - Messaging - ${guestListLabelByCategory[urlParams.categoryidentifier]} - ${locationName}`,
            gt_user_id: getUserGAId(userState.user),
            gt_location_id: urlParams.locationidentifier,
            gt_location: locationName,
          });
        } catch (e) {}
      } else {
        // setForm(prevForm => ({
        //   ...prevForm,
        //   [name]: '',
        //   [`${name}Error`]: 'Error is uploading.',
        //   [`${name}UploadInProgress`]: false,
        // }));
      }
      setUploadInProgress(false);
    })
      .catch((err) => {
        console.log('uploadFile err--------------------: ',err);
      });
  };

  const onShowProfaneCheckCloseModal = () => {
    setShowProfaneCheckModal(false);
  };

  const processSendMessage = () => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('processSendMessage: ', messageText);
    }
    if (translateInProgress) return;
    if (medium === 'email') {
      if (!props.hasValidEmail) return;
    } else {
      if (!props.hasValidContact) return;
    }
    if (messageText || uploadedPath) {
      if (messageText) {
        const profaneCheck = filter.isProfane(messageText);
        setShowProfaneCheckModal(profaneCheck);
        if (process.env.NODE_ENV !== 'production') {
          console.log('profaneCheck: ', profaneCheck);
        }
        if (profaneCheck) return;
      }
      const params = { // location_id, guest_id, type, msg, lang, medium
        location__id: urlParams.locationidentifier,
        location_id: props.locationId,
        // guest_id: props.guest._id,
        type: props.guestType && props.guestType[props.guestType.length-1] === 's' ? props.guestType.substring(0, props.guestType.length-1) : props.guestType,
        // msg: messageText,
        lang: 'en',
        medium: medium, // medium, // '',
        // new_msg_id: `${props.locationprops.location.location_id}_${new Date().getTime()}`,
        sent_by: userState.user.user_info.uid,
      };
      if (messageText) {
        params['msg'] = messageText;
      }
      if (medium === 'email' && props.guest.latest_subject) {
        params.msg_subject = `Re: ${props.guest.latest_subject}`;
      }
      if (uploadedPath) {
        params['mediaUrl'] = [];
        params['mediaUrl'].push(uploadedPath);
      }
      if (props.guestType === 'guest' || props.guestType === 'past-guest' || props.guestType === 'upcoming-guest') {
        params['guest_id'] = props.guest._id;
      }
      if (props.guestType === 'outside-guest' || props.guestType === 'staff' || props.guestType === 'webtext' || props.guestType === 'gbm') {
        params['outside_guest_id'] = props.guest._id;
      }
      params['new_msg_id'] = `${urlParams.locationidentifier}_${params['guest_id'] || params['outside_guest_id'] || ''}_${new Date().getTime()}`;
      if (selectedTemplateId) params['selected_template_id'] = selectedTemplateId;
      if (selectedTemplateType) params['selected_template_type'] = selectedTemplateType;
      if (process.env.NODE_ENV !== 'production') {
        console.log('sendMessageAction params: ', params);
      }
      dispatch(editDataAction({
        op: 'send_message',
        params,
      }));
      setMessageText('');
      setTypingStatus(false);
      try {
        const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
          && locationState.locations[urlParams.locationidentifier].settings
          && locationState.locations[urlParams.locationidentifier].settings.info
          ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
          : ''
        window.gtag('event', 'gt_click', {
          'event_category': `Engage - Messaging - ${guestListLabelByCategory[urlParams.categoryidentifier]} - Send Message`,
          'event_label': `Send Message`,
          page_path: `${location.pathname}`,
          page_title: `Engage - Messaging - ${guestListLabelByCategory[urlParams.categoryidentifier]} - ${locationName}`,
          gt_user_id: getUserGAId(userState.user),
          gt_location_id: urlParams.locationidentifier,
          gt_location: locationName,
        });
      } catch (e) {}
      setTimeout(() => {
        scrollToBottom();
      }, 200);

      // mark messages as read, if there is anything still unread, since conversation has started
      if (props.guest.messages && props.guest.messages.length) {
        const filteredMessages = props.guest.messages.filter(el => el.type === 1 && !el.read);
        if (filteredMessages.length) {
          const params = {
            location_id: props.guest.location_id,
            username: userState.user.user_info.uid,
          };
          if (props.guestType === 'guest' || props.guestType === 'past-guest' || props.guestType === 'upcoming-guest') {
            params['guest_id'] = props.guest._id;
          }
          if (props.guestType === 'outside-guest' || props.guestType === 'staff' || props.guestType === 'webtext' || props.guestType === 'gbm') {
            params['outside_guest_id'] = props.guest._id;
          }
          params['guest_type'] = props.guestType;
          // params['all'] = true;
          params['msg_id_list'] = filteredMessages.map(el => el._id);
          params['session_id'] = window.gtSessionId;
          // props.dispatch(markMessageAsReadAction({
          //   params,
          // }));
        }
      }

    }
  };

  const onSendMessageClick = (e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onSendMessageClick: ', messageText);
    }
    if (props.isReadOnly) return;

    if (messageText || uploadedPath) {
      processSendMessage();
      setUploadedPath('');
      setUploadedFileType('');
      setUploadedFileName('');
    }
  };

  const onUploadedImageClick = (e) => {
    setShowImagePreviewModal(true);
  };
  const onRemoveAttachMediaClick = (e) => {
    setUploadedPath('');
    setUploadedFileType('');
    setUploadedFileName('');
    setImagePreviewModalHideConfirm(false);
  };
  const onImagePreviewModalCloseClick = (e) => {
    setShowImagePreviewModal(false);
    setUploadError('');
    if (!imagePreviewModalHideConfirm) {
      setUploadedPath('');
    }
  };
  const onImagePreviewModalConfirmClick = (e) => {
    setShowImagePreviewModal(false);
    setUploadError('');
    setImagePreviewModalHideConfirm(true);
  };

  const onWhatsappKnowMoreClick = (e) => {
    try { window.Intercom('showArticle', 8992398); } catch(e) {}
  }

  /* End - Image Modal */

  const renderImagePreviewModal = () => {
    return (
      <Modal>
        <div id="cModelId" className="cModal imgPrvMdl">
          <div className="ctnt" ref={imagePreviewModalRef}>
            <ImagePreviewModal
              image={uploadedPath}
              onConfirmClick={onImagePreviewModalConfirmClick}
              hideConfirm={imagePreviewModalHideConfirm}
              onCancelClick={onImagePreviewModalCloseClick}
              inProgress={uploadInProgress}
              uploadError={uploadError}
              replaceFile={
                <Dropzone onDrop={acceptedFiles => onDrop(acceptedFiles)} multiple={false}>
                {({getRootProps, getInputProps}) => (
                  <div {...getRootProps({className: 'dndBox1'})}>
                    <input {...getInputProps()} />
                    <ToolTipWrapper placement="bottom" overlayClassName="sbTooltip" overlay={'Choose a different file'}>
                      <span className="replace">{uploadError ? 'Retry' : 'Replace Image' }</span>
                      {/*<button className="reverse" onClick={onAttachMediaClick}>
                        Replace Image
                      </button>*/}
                    </ToolTipWrapper>
                  </div>
                  )}
                </Dropzone>
              }
            />
          </div>
        </div>
      </Modal>
    );
  };


  const renderWhatsappOutsideWindowMessage = () => {
    let displayMsg = 'You are out of 24-hour service window, please use a starter template.';
    if (!props.guest.messages || !props.guest.messages.length) {
      displayMsg = 'Start the conversation with a starter message.';
    }
    return (
      <div className="wOutWinMsg">
        <span><i className="fa fa-clock" /></span>
        <p>{displayMsg}</p>
        <span className="whatsappKnwMor" onClick={onWhatsappKnowMoreClick}>
          {/* <i className="fas fa-circle-info" /> */}
          Know More
        </span>
      </div>
    );
  };

  const onShowTestSideModal = (mt) => {
    setShowTestSideModal(true);
    setSideModalData(mt);
  };
  const onShowTestSideCloseModal = (e) => {
    setShowTestSideModal(false);
    setSideModalData(null);
  };

  const renderTestSideModal = () => {
    if (!showTestSideModal) return;
    if (!sideModalData) return;
    return (
      <GenericSideModal
        modalType="messageTemplateGeneric"
        className="mtGen"
        data={{
          ...sideModalData,
          opName: 'edit',
        }}
        extData={{
          title: 'Edit Message Template',
          settings: props.settings
        }}
        setModalVisible={onShowTestSideCloseModal}
        inProgress={false}
        showCancel={false}
        affirmativeButtonLabel={'OK'}
        disableOutsideClick={true}
      />
    )
  };

  const renderOptOutBox = () => {
    return (
      <div className='optOutBoxWrap'>
        <p>Guest has opted out of receiving messages!</p>
      </div>
    );
  }

  if (props.guest.opt_out) {
    return renderOptOutBox();
  }

  const { message_templates, approved_whatsapp_templates } = props.settings.engage;

  // const textareaDisabled = whatsappStarterNeeded || false; // nkcheck
  let textareaDisabled = false;
  if (props.isReadOnly) textareaDisabled = true;

  // !whatsappStarterNeeded && !whatsappStarterMessageActive - nkcheck
  let emojiTt =  'Add emoji';
  // 'Add emoji - not allowed when whatsapp starter message is required'
  let translateTt =  'Translate message';
  // 'Translate message - not allowed when whatsapp starter message is required'}
  let mediumTt = `Message will be sent using ${ENGAGE_MEDIUM_TO_LABEL[medium]}`;
  if (medium === 'sms' || medium === 'whatsapp' || medium === 'email' || medium === 'webapp' ) {
    mediumTt = `${mediumTt}${props.commChannels && props.commChannels.length > 1 ? ', click to change.' : ''}`;
  }

  let sendBtnTt = 'Click to send';
  if (medium === 'email') {
    if (!props.hasValidEmail) {
      sendBtnTt = 'Invalid email!';
    }
  } else {
    if (props.hasValidContact) {
      if (!messageText) {
        if (uploadedPath) {
          sendBtnTt = 'Send media only!';
        } else {
          sendBtnTt = 'Message is empty!';
        }
      }
    } else {
      sendBtnTt = 'Invalid contact number!';
    }
  }

  let hasValidDestination = false;
  if (medium === 'email') {
    hasValidDestination = props.hasValidEmail;
  } else {
    hasValidDestination = props.hasValidContact;
  }

  let maxTemplatesToShow = 3;
  if (windowState.window.windowWidth > 1023) {
    maxTemplatesToShow = 4;
  }
  if (windowState.window.windowWidth > 1365) {
    maxTemplatesToShow = 8;
  }

  // let templateList = [];
  // if (!whatsappStarterNeeded) {
  //   if (message_templates && message_templates.list && message_templates.list.length > 0) {
  //     message_templates.list.filter(el => !message_templates.keyValue[el].is_removed)
  //     .forEach((item) => {
  //       const mt = message_templates.keyValue[item];
  //       if (mt) templateList.push({ ...mt, templateType: 'generic' });
  //     });
  //   }
  // }
  // // if (whatsappStarterNeeded || withinWhatsappWindow) {
  //   if (props.hasWhatsapp && approved_whatsapp_templates && approved_whatsapp_templates.length > 0) {
  //     approved_whatsapp_templates
  //     .filter(elem => elem && elem.type === 'starter')
  //     .forEach((item) => {
  //       if (item) templateList.push({ ...item, templateType: 'whatsapp' });
  //     })
  //   }
  // // }
  // if (process.env.NODE_ENV !== 'production') {
  //   console.log('SendMessage templateList: ', templateList);
  // }
  // templateList = templateList.sort((a, b) => {
  //   if (a.count && b.count) { return b.count - a.count; };
  //   if (a.count && !b.count) { return -1; };
  //   if (!a.count && b.count) { return 1; };
  //   return 0;
  // })
  // .slice(0,maxTemplatesToShow);
  if (process.env.NODE_ENV !== 'production') {
    console.log('SendMessage templateList after sort: ', templateList);
  }

  if (process.env.NODE_ENV !== 'production') {
    console.log('boxHeight : ', boxHeight);
    console.log('refBoxHeight : ', refBoxHeight);
    console.log('SendMessageBox medium: ', medium);
    console.log('SendMessageBox uploadedPath: ', uploadedPath);
    console.log('SendMessageBox uploadedFileName: ', uploadedFileName);
    console.log('SendMessageBox userState.user.user_info: ', userState.user.user_info);

  }

  return (
    <>
    { whatsappStarterNeeded && renderWhatsappOutsideWindowMessage() }
    <Rnd
      className={'smB'}
      size={{height: boxHeight}}
      style={{}}
      disableDragging={true}
      enableResizing={{
        top: true,
      }}
      maxHeight={400}
      // minHeight={220}
      minHeight={boxHeight}
      onResize={(e, direction, ref, delta, position) => {
        if (process.env.NODE_ENV !== 'production') {
          console.log('onResize position: ', position);
          console.log('onResize ref: ', ref.style.height);
          console.log('onResize delta: ', delta);
        }
        setRefBoxHeight(ref.style.height);
      }}
    >
    {/*<div className={'smB' + (inputExpanded ? ` ${inputExpanded}` : '')}>*/}
      <div className="rszIndc">
        <span><i className="fas fa-grip"></i></span>
        <span><i className="fas fa-grip"></i></span>
        <span><i className="fas fa-grip"></i></span>
      </div>
      <div className="mtOp">
        <div className={'line1' + (!templateList || !templateList.length ? ' mb0' : '')}>
          <div className="lt">
            <span>Message Templates</span>
            <div className="mtMnWr">
              <div className={'dd ' + (mtMenuOpen ? 'open' : '')} ref={mtMenuRef}>
                <button className="dbtn" onClick={onOpenMessageTemplatesMenu}>
                  <ToolTipWrapper placement="left" overlayClassName="sbTooltip"
                    overlay={"All Templates"}
                  >
                  <span><i className="fas fa-angle-right"></i></span>
                  </ToolTipWrapper>
                </button>
                <div className="ddc mtSel">
                  <div className="hdr">
                    <div className="search">
                      <input type="text" onChange={onMtSelectorInputChange} value={mtSearchTerm}
                        placeholder="Search"
                        onKeyDown={onMtSearchTermKeyDown}
                      />
                    </div>
                    <Link to={`/settings/locations/${urlParams.locationidentifier}/templates/message-templates`}
                      state={{ screenBeforeSettingsName: productIdToLabels[product], screenBeforeSettingsPath: location.pathname }}
                    >Manage All</Link>
                    {/*<span onClick={onMtMenuManageAllClick}>Manage All</span>*/}
                  </div>
                  <div className="llWr">
                    {
                      templateList &&
                      templateList.length > 0 &&
                      templateList.map((item) => {
                        const mt = { ...item };
                        if (mtSearchTerm && mtSearchTerm.length > 1) {
                          const reg = new RegExp(mtSearchTerm, "i")
                          if (
                            (mt.title && mt.title.search(reg) > -1) ||
                            (mt.msg && mt.msg.search(reg) > -1)
                          ) {
                            return (
                              <MessageTemplateRow
                                key={`mtr_${mt._id}`}
                                template={mt} onButtonClick={mt.templateType === "whatsapp" ? onWhatsappTemplateClick : onMtMenuSelect}
                                onEditClick={onShowTestSideModal}
                                withinWhatsappWindow={withinWhatsappWindow}
                                conversationStarted={props.guest.messages && props.guest.messages.length ? true : false}
                                medium={medium}
                                templateType={mt.templateType}
                                isReadOnly={props.isReadOnly}
                              />
                            )
                          }
                          return null;
                        }
                        return (
                          <MessageTemplateRow
                            key={`mtr_${mt._id}`}
                            template={mt} onButtonClick={mt.templateType === "whatsapp" ? onWhatsappTemplateClick : onMtMenuSelect}
                            onEditClick={onShowTestSideModal}
                            withinWhatsappWindow={withinWhatsappWindow}
                            conversationStarted={props.guest.messages && props.guest.messages.length ? true : false}
                            medium={medium}
                            templateType={mt.templateType}
                            isReadOnly={props.isReadOnly}
                          />
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="rt">
          </div>
        </div>
        {
          templateList &&
          templateList.length > 0 &&
          <div className="line2">
            {
              templateList &&
              templateList.length > 0 &&
              templateList.slice(0,maxTemplatesToShow).map((item) => {
                const mt = { ...item };
                return (
                  <MessageTemplateButton
                    key={`mtb_${mt._id}`}
                    template={mt} onButtonClick={mt.templateType === "whatsapp" ? onWhatsappTemplateClick : onTemplateClick}
                    withinWhatsappWindow={withinWhatsappWindow}
                    conversationStarted={props.guest.messages && props.guest.messages.length ? true : false}
                    medium={medium}
                    templateType={mt.templateType}
                    isReadOnly={props.isReadOnly}
                  />
                )
              })
            }
          </div>
        }
      </div>
      <div className="mcAr">
        {
          inputPrepInProgress &&
          <div className="gPnlLdng"><span><i className="fas fa-spinner fa-spin"></i></span></div>
        }
        <textarea value={messageText} onChange={onChange} onBlur={onBlur} onFocus={onFocus} placeholder="Type your message..." rows="1" ref={messageEl}
          onKeyDown={onMessageTextKeyDown}
          disabled={textareaDisabled}
        >
        </textarea>
        <span className="expInp" onClick={inputExpanded ? onContractInputClick : onExpandInputClick}>
          {
            inputExpanded
            ?
              <ToolTipWrapper placement="left" overlayClassName="sbTooltip" overlay={"Contract message area"}>
                <i className="fas fa-angle-double-down"></i>
              </ToolTipWrapper>
            :
              <ToolTipWrapper placement="left" overlayClassName="sbTooltip" overlay={"Expand message area"}>
                <i className="fas fa-angle-double-up"></i>
              </ToolTipWrapper>
          }
        </span>
      </div>
      <div className="mcOp">
        <div className="lt">
          <div className="opIt em">
            <div className={'dd ' + (emojiSelectorOpen ? 'open' : '')} ref={emojiSelectorRef}>
              <button className="dbtn" onClick={onOpenEmojiSelectorClick}>
                <ToolTipWrapper placement="left" overlayClassName="sbTooltip"
                  overlay={emojiTt}
                >
                <img src={emojiIcon} />
                </ToolTipWrapper>
              </button>
              <div className="ddc">
                {
                  emojiSelectorOpen &&
                  <div className="emojiBox">
                    <Picker data={emojiData}
                      onEmojiSelect={(emoji, e) => onEmojiClick(emoji, e)}
                    />
                    {/*<Picker
                      style={pickerStyle}
                      set='apple'
                      emojiSize={24}
                      sheetSize={32}
                      darkMode={false}
                      title=""
                      showPreview={false}
                      onEmojiSelect={(emoji, e) => onEmojiClick(emoji, e)}
                    />*/}
                  </div>
                }
              </div>
            </div>
          </div>
          <div className="opIt trn">
            <div className={'dd ' + (languageSelectorOpen ? 'open' : '')} ref={languageSelectorRef}>
              <ToolTipWrapper placement="left" overlayClassName="sbTooltip"
                overlay={translateTt}
              >
              <button className="dbtn" onClick={onTranslateLanguageSelectorOpenClick}>
                {
                  translateInProgress
                  ? <i className="fa fa-spinner fa-spin"></i>
                  : <img src={translateIcon} />
                }
              </button>
              </ToolTipWrapper>
              <div className="ddc lngSel">
                <div className="search">
                  <input type="text" onChange={onLanguageSelectorInputChange} value={langaugeSearchTerm} placeholder="Search" />
                </div>
                <div className="llWr">
                  {
                    !langaugeSearchTerm &&
                    <div className="recentlyUsedTitle">
                      Recently Used
                    </div>
                  }
                  {
                    !langaugeSearchTerm &&
                    <div className="recentlyUsedList">
                      {
                        userState.user.user_info && userState.user.user_info.profile &&
                        userState.user.user_info.profile.locations &&
                        userState.user.user_info.profile.locations[urlParams.locationidentifier] &&
                        userState.user.user_info.profile.locations[urlParams.locationidentifier].recent_lang &&
                        Object.keys(userState.user.user_info.profile.locations[urlParams.locationidentifier].recent_lang).length > 0
                        ?
                          Object.keys(userState.user.user_info.profile.locations[urlParams.locationidentifier].recent_lang)
                            .sort((a,b) => userState.user.user_info.profile.locations[urlParams.locationidentifier].recent_lang[b] - userState.user.user_info.profile.locations[urlParams.locationidentifier].recent_lang[a])
                            .map((item) => {
                            const lang = LANGUAGE_LIST_KEYS[item];
                            return (
                              <p onClick={() => onLanguageSelect(item)}>{lang}</p>
                            )
                          })
                        : null
                      }
                    </div>
                  }
                  <div className="allTitle">
                    All
                  </div>
                  <div className="allList">
                    {
                      Object.keys(LANGUAGE_LIST_KEYS).map((item) => {
                        const lang = LANGUAGE_LIST_KEYS[item];
                        if (langaugeSearchTerm && langaugeSearchTerm.length > 1) {
                          const reg = new RegExp(langaugeSearchTerm, "i")
                          if (
                            (lang && lang.search(reg) > -1)
                          ) {
                            return (
                              <p onClick={() => onLanguageSelect(item)}>{lang}</p>
                            )
                          }
                          return null;
                        }
                        return (
                          <p onClick={() => onLanguageSelect(item)}>{lang}</p>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          {
            props.hasMms && !whatsappStarterNeeded && !whatsappStarterMessageActive && !props.isReadOnly &&
            <div className="opIt img">
              <Dropzone onDrop={acceptedFiles => onDrop(acceptedFiles)} multiple={false}>
                {({getRootProps, getInputProps}) => (
                  <div {...getRootProps({className: 'dndBox1'})}>
                    <input {...getInputProps()} />
                    <ToolTipWrapper placement="left" overlayClassName="sbTooltip" overlay={'Attach media'}>
                    <span onClick={onAttachMediaClick}>
                      <i className="fas fa-image"></i>
                    </span>
                    </ToolTipWrapper>
                  </div>
                  )}
              </Dropzone>
            </div>
          }
          {
            uploadedPath &&
            <div className="opIt imgthb">
              <ToolTipWrapper placement="right" overlayClassName="sbTooltip" overlay={uploadedFileName}>
                <img src={uploadedPath} onClick={onUploadedImageClick} />
              </ToolTipWrapper>
              <ToolTipWrapper placement="right" overlayClassName="sbTooltip" overlay={"Remove media"}>
                <div className="close" onClick={onRemoveAttachMediaClick}><i className="far fa-times-circle"></i></div>
              </ToolTipWrapper>
            </div>
          }
        </div>
        <div className="rt">
          <div className="opIt mdm">
            <div className={'dd ' + (mediumSelectorOpen ? 'open' : '')} ref={mediumSelectorRef}>
              <ToolTipWrapper placement="left" overlayClassName="sbTooltip"
                overlay={mediumTt}
              >
              <button className={'dbtn' + (props.commChannels && props.commChannels.length > 1 ? ' multi' : '')} onClick={onMediumSelectorOpenClick}>
                { medium === 'sms' && <img src={smsIcon} /> }
                { medium === 'whatsapp' && <img src={whatsappIcon} /> }
                { medium === 'email' && <i className="fa-solid fa-envelope"></i> }
                { medium === 'webapp' && <i className="fa-solid fa-mobile-screen-button"></i> }
                { medium === 'bm' && <img className="gbm" src={gbmIcon} /> }
              </button>
              </ToolTipWrapper>
              {
                (medium === 'sms' || medium === 'whatsapp' || medium === 'email' || medium === 'webapp') &&
                props.commChannels && props.commChannels.length > 1 &&
                <div className={'ddc ' + (`c${props.commChannels.length}`)}>
                  { props.commChannels.indexOf('sms') > -1 && <p onClick={onMediumSelectorOptionSMSClick}><img src={smsIcon} /> SMS</p> }
                  { props.commChannels.indexOf('whatsapp') > -1 && <p onClick={onMediumSelectorOptionWhatsappClick}><img src={whatsappIcon} /> WhatsApp</p> }
                  { props.commChannels.indexOf('email') > -1 && <p onClick={onMediumSelectorOptionEmailClick}><i className="fa-solid fa-envelope"></i> Email</p> }
                  { props.commChannels.indexOf('webapp') > -1 && <p onClick={onMediumSelectorOptionWebAppClick}><i className="fa-solid fa-mobile-screen-button"></i> WebApp</p> }
                </div>
              }
            </div>
          </div>
          <div className={"opIt snd " + ((!messageText && !uploadedPath) || !hasValidDestination ? 'inactive ' : '')}>
            <ToolTipWrapper placement="left" overlayClassName="sbTooltip" overlay={sendBtnTt}>
              <p onClick={onSendMessageClick}>Send</p>
            </ToolTipWrapper>
          </div>
        </div>
      </div>
      { showProfaneCheckModal &&
          <GenericAlertModal
            modalType="CheckOut"
            title={`Inappropriate language detected`}
            bodyRowOne={'Please revise your message'}
            bodyRowNote=""
            setModalVisible={onShowProfaneCheckCloseModal}
            onAffirmativeClick={() => onShowProfaneCheckCloseModal()}
            inProgress={false}
            // currentStatus={guest.checkOutStatus}
            showCancel={false}
            affirmativeButtonLabel={'OK'}
            disableOutsideClick={true}
          />
        }
      { (showImagePreviewModal || uploadError) && renderImagePreviewModal() }
      { renderTestSideModal() }
    {/*</div>*/}
    </Rnd>
    </>
  );
}

export default SendMessageBox;
