import { useSelector, useDispatch } from "react-redux";
import ReactDOM from 'react-dom';
import { useState, useEffect, useCallback, useRef } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import { parsePhoneNumber } from 'react-phone-number-input';
import copy from 'copy-to-clipboard';

import ToolTipWrapper from '../../../../Misc/ToolTipWrapper';
import useOnClickOutside from '../../../../Misc/OutsideClickHook';
import GenericAlertModal from '../../../../Misc/GenericAlertModal';
import GuestConversationBox from './GuestConversationBox';
import SendMessageBox from './SendMessageBox';
import CheckInModal from './CheckInModal';
import AddGuestSingle from '../../../AddGuest/AddGuestSingle';
import AddNewLabelModal from './AddNewLabelModal';

import { receiveSnack, removeSnack } from '../../../../../redux/actions/snacksActions';
import { getDataAction, getDataClearAction } from '../../../../../redux/actions/getDataActions';
import { editDataAction, editDataClearAction } from '../../../../../redux/actions/editDataActions';
import { editGuestRemoveEntryAction } from '../../../../../redux/actions/miscEngageActions';

import { categoryToStoreGuestLabel, getFullNameForDisplay, getAvataarName, contactDisplay, isContactValid,
  formatDateStrToStr, formatDate, guestTransactionStatusToStayLabel, validateEmail,
  getNameWithContact, guestTypeToGenericLabel, guestListLabelByCategory, getUserGAId } from '../../../../../helpers';

import emailIcon from '../../../../../img/icons/email.svg';
import emailIGreycon from '../../../../../img/icons/email-grey.svg';
import remarksIcon from '../../../../../img/icons/notes.svg';
import remarksGreyIcon from '../../../../../img/icons/notes-grey.svg';
import checkInIcon from '../../../../../img/icons/check-out.svg';
import checkOutIcon from '../../../../../img/icons/check-out.svg';
import moreIcon from '../../../../../img/icons/more.svg';
import labelIcon from '../../../../../img/icons/label.svg';
import pinIcon from '../../../../../img/icons/pin.svg';
import infoIcon from '../../../../../img/icons/info.svg';
import closeIcon from '../../../../../img/icons/close.svg';

import './style.css';

const GI_TWO_LINE_THRESHOLD = 1366;
const GI_CHECK_IN_TEXT_THRESHOLD = 1366;

const modalRoot = document.getElementById('modal-root');
function Modal(props) {
  const [el, setEl] = useState(document.createElement('div'));
  useEffect(() => {
    // this.el = document.createElement('div');
    // setEl(document.createElement('div'));
    modalRoot.appendChild(el);
    return function cleanup() {
      modalRoot.removeChild(el);
    }
  }, []);
  return ReactDOM.createPortal(
    props.children,
    el,
  );
}


function ChooseLabelEntry(props) {
  const [isSelected, setIsSelected] = useState(false);
  useEffect(() => {
    setIsSelected(props.isSelected);
  }, [props.isSelected]);
  const onChange = (e) => {
    const { name, value } = e.target;
    if (process.env.NODE_ENV !== 'production') {
      console.log('onChange: ', value);
    }
    // setIsSelected(isSelected => !isSelected);
  };
  const onEntryClick = (e) => {
    setIsSelected(isSelected => !isSelected);
    props.onLabelClick(props.data);
  }
  let cStyle = {};
  cStyle['backgroundColor'] = props.data.color;
  return (
    <div className="entry" onClick={onEntryClick}>
      <div className="left">
        <div className="colorBox" style={cStyle} />
        {props.data.label}
      </div>
      <input type="checkbox" name={props.data.label} onChange={onChange} value={isSelected ? 'on' : 'off'} checked={isSelected} />
    </div>
  );
}


function GuestPanel(props) {
  const windowState = useSelector((state) => state.windowReducer);
  const userState = useSelector((state) => state.userReducer);
  const appState = useSelector((state) => state.appReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const location = useLocation();
  let urlParams = useParams();

  const [hasValidContact, setHasValidContact] = useState(false);
  const [hasValidEmail, setHasValidEmail] = useState(false);

  const [newName, setNewName] = useState('');
  const [addNameActive, setAddNameActive] = useState(false);

  const [guestInfoEmail, setGuestInfoEmail] = useState('');
  const [guestInfoEmailChanged, setGuestInfoEmailChanged] = useState(false);
  const [guestInfoEmailError, setGuestInfoEmailError] = useState('');

  const [guestInfoRemarks, setGuestInfoRemarks] = useState('');
  const [guestInfoRemarksChanged, setGuestInfoRemarksChanged] = useState(false);

  const [showEmailPanel, setShowEmailPanel] = useState(false);
  const [showRemarksPanel, setShowRemarksPanel] = useState(false);
  const [showGuestMenuPanel, setShowGuestMenuPanel] = useState(false);

  const [showGuestProfile, setShowGuestProfile] = useState(false);
  const [showGuestProfileFinal, setShowGuestProfileFinal] = useState(false);

  const [suggestedResponse, setSuggestedResponse] = useState('');


  const emailPanelRef = useRef();
  useOnClickOutside(emailPanelRef, useCallback(() => { setShowEmailPanel(false)}));

  const notesPanelRef = useRef();
  useOnClickOutside(notesPanelRef, useCallback(() => setShowRemarksPanel(false)));

  const guestMenuPanelRef = useRef();
  useOnClickOutside(guestMenuPanelRef, useCallback(() => setShowGuestMenuPanel(false)));

  const [showEditGuestModal, setShowEditGuestModal] = useState(false);
  const editGuestModalRef = useRef();
  useOnClickOutside(editGuestModalRef, useCallback(() => {
    // if (!isTablet) { // nkcheck
      onEditGuestModalCloseClick();
    // }
  }));

  const [showCheckInModal, setShowCheckInModal] = useState(false);
  const [showCheckOutModal, setShowCheckOutModal] = useState(false);
  const [showDisableCheckOutMessageModal, setShowDisableCheckOutMessageModal] = useState(false);
  const [showOptOutModal, setShowOptOutModal] = useState(false);
  const [showReplyRequiredModal, setShowReplyRequiredModal] = useState(false);
  const [showCancelReservationModal, setShowCancelReservationModal] = useState(false);
  const [showMarkAsStaffModal, setShowMarkAsStaffModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  const [showRegenerateRemoteLockModal, setShowRegenerateRemoteLockModal] = useState(false);
  const [showRevokeRemoteLockModal, setShowRevokeRemoteLockModal] = useState(false);


  const [showLabelsMenu, setShowLabelsMenu] = useState(false);
  const [selectedLabels, setSelectedLabels] = useState([]);
  const [selectedLabelsObj, setSelectedLabelsObj] = useState({});
  const [labelsMenuChanged, setLabelsMenuChanged] = useState(false);
  const labelsMenuRef = useRef();
  useOnClickOutside(labelsMenuRef, useCallback(() => {
    setShowLabelsMenu(false);
    if (process.env.NODE_ENV !== 'production') {
      console.log('labelsMenuRef');
    }
    if (props.guest && props.guest.labels) {
      setSelectedLabels(props.guest.labels || []);
      let obj = {};
      props.guest.labels.forEach((item) => {
        obj[item._id] = item;
      });
      setSelectedLabelsObj(obj);
      setLabelsMenuChanged(false);
    } else {
      setSelectedLabels([]);
      setSelectedLabelsObj({});
      setLabelsMenuChanged(false);
    }
  }));

  const [showAddNewLabelModal, setShowAddNewLabelModal] = useState(false);

  const [showSuggestedResponse, setShowSuggestedResponse] = useState(false);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('GuestPanel props: ', props);
    }
  }, []);

  useEffect(() => {
    let isValidContact = false;
    if (props.guest.contact) {
      try {
        if (process.env.NODE_ENV !== 'production') {
          console.log('validateContact props.guest.contact: ', props.guest.contact);
        }
        let tContact = props.guest.contact.replace(/\D/g,'');
        if (tContact) tContact = `+${tContact}`;
        const pContactNumber = parsePhoneNumber(tContact);
        if (process.env.NODE_ENV !== 'production') {
          console.log('validateContact pContactNumber: ', pContactNumber);
        }
        if (pContactNumber && isContactValid(pContactNumber.countryCallingCode, pContactNumber.nationalNumber)) {
          isValidContact = true;
        }
      } catch (e) {
        if (process.env.NODE_ENV !== 'production') {
          console.log('contact error: ', e);
        }
      }
    }
    if (props.guest.source === 'bm') {
      isValidContact = true;
    }
    setHasValidContact(isValidContact);
  }, [props.guest.contact]);

  useEffect(() => {
    let isValidEmail = false;
    if (props.guest.email) {
      try {
        if (process.env.NODE_ENV !== 'production') {
          console.log('validateEmail props.guest.email: ', props.guest.email);
        }
        isValidEmail = validateEmail(props.guest.email);
      } catch (e) {
        if (process.env.NODE_ENV !== 'production') {
          console.log('email validate error: ', e);
        }
      }
    }
    setHasValidEmail(isValidEmail);
  }, [props.guest.email]);

  // edit successful
  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('useEffect props.guest: ', props.guest);
    }
    if (!props.guest.editCheckInInProgress && props.guest.editCheckInStatus &&
      props.guest.editCheckInStatus.success
    ) {
      setShowCheckInModal(false);
      navigate(`/engage/locations/${urlParams.locationidentifier}/messages/guests/${props.guest._id}`)
    }
    if (!props.guest.editCheckOutInProgress && props.guest.editCheckOutStatus &&
      props.guest.editCheckOutStatus.success
    ) {
      setShowCheckOutModal(false);
      if (props.guest.editCheckOutFollowGuest) {
        navigate(`/engage/locations/${urlParams.locationidentifier}/messages/past-guests/${props.guest._id}`)
      } else {
        // do not go to past guests, stay on guests
        const { categoryidentifier, guestidentifier } = urlParams;
        const params = {};
        params.location__id = urlParams.locationidentifier;
        params.guest_type = props.guestType;
        params['guest_id'] = guestidentifier;
        dispatch(editGuestRemoveEntryAction({
          params,
        }));
        navigate(`/engage/locations/${urlParams.locationidentifier}/messages/guests`)
      }
    }
    if (!props.guest.editDisableCheckMsgInProgress && props.guest.editDisableCheckMsgStatus &&
      props.guest.editDisableCheckMsgStatus.success
    ) {
      setShowDisableCheckOutMessageModal(false);
    }
    if (!props.guest.editOptOutInProgress && props.guest.editOptOutStatus &&
      props.guest.editOptOutStatus.success
    ) {
      setShowOptOutModal(false);
    }
    if (!props.guest.editReplyRequiredInProgress && props.guest.editReplyRequiredStatus &&
      props.guest.editReplyRequiredStatus.success
    ) {
      setShowReplyRequiredModal(false);
    }
    if (!props.guest.cancelReservationInProgress && props.guest.cancelReservationStatus &&
      props.guest.cancelReservationStatus.success
    ) {
      setShowCancelReservationModal(false);
    }
    if (!props.guest.editMarkAsStaffInProgress && props.guest.editMarkAsStaffStatus &&
      props.guest.editMarkAsStaffStatus.success
    ) {
      setShowMarkAsStaffModal(false);
      if (props.guest.is_staff) {
        navigate(`/engage/locations/${urlParams.locationidentifier}/messages/staff/${props.guest._id}`)
      } else {
        navigate(`/engage/locations/${urlParams.locationidentifier}/messages/outside-guests/${props.guest._id}`)
      }
    }
    if (!props.guest.editRemoveInProgress && props.guest.editRemoveStatus &&
      props.guest.editRemoveStatus.success
    ) {
      setShowRemoveModal(false);
      navigate(`/engage/locations/${urlParams.locationidentifier}/messages/${urlParams.categoryidentifier}`)
    }
    if (!props.guest.regenerateRemoteLockKeyInProgress && props.guest.regenerateRemoteLockKeyStatus &&
      props.guest.regenerateRemoteLockKeyStatus.success
    ) {
      setShowRegenerateRemoteLockModal(false);
    }
    if (!props.guest.regenerateRemoteLockKeyInProgress && props.guest.regenerateRemoteLockKeyStatus &&
      props.guest.regenerateRemoteLockKeyStatus.success
    ) {
      setShowRevokeRemoteLockModal(false);
    }
    if (!props.guest.editLabelsInProgress && props.guest.editLabelsStatus &&
      props.guest.editLabelsStatus.success
    ) {
      setLabelsMenuChanged(false);
    }
    if (!props.guest.getSuggestedResponseInProgress && props.guest.getSuggestedResponseStatus &&
      props.guest.getSuggestedResponseStatus.success
    ) {
      setShowSuggestedResponse(true);
      const params = {};
      params.location__id = urlParams.locationidentifier;
      params.guest_type = props.guestType;
      if (props.guestType === 'guest' || props.guestType === 'past-guest' || props.guestType === 'upcoming-guest') {
        params['guest_id'] = guestidentifier;
      }
      if (props.guestType === 'outside-guest' || props.guestType === 'staff' || props.guestType === 'webtext' || props.guestType === 'gbm') {
        params['outside_guest_id'] = guestidentifier;
      }
      dispatch(getDataClearAction({
        op: 'get_ai_model_result',
        params,
      }));
    }
    if (!props.guest.editInProgress && props.guest.editStatus &&
      props.guest.editStatus.success
    ) {
      setAddNameActive(false);
    }
  }, [props.guest]);


  useEffect(() => {
    setSelectedLabels(props.guest.labels || []);
    let obj = {};
    if (props.guest.labels) {
      props.guest.labels.forEach((item) => {
        obj[item._id] = item;
      });
    }
    setSelectedLabelsObj(obj);
    setShowSuggestedResponse(false);
    setAddNameActive(false);
    setNewName('');
  }, [urlParams.guestidentifier]);


  useEffect(() => {
    setSelectedLabels(props.guest.labels || []);
    let obj = {};
    if (props.guest.labels) {
      props.guest.labels.forEach((item) => {
        obj[item._id] = item;
      });
    }
    setSelectedLabelsObj(obj);
  }, [props.guest.labels]);

  useEffect(() => {
    // if (process.env.NODE_ENV !== 'production') {
    //   console.log('labels addInProgress: ', props.settings.engage.labels.addInProgress);
    // }
    if (props.settings.engage.labels && !props.settings.engage.labels.addInProgress && props.settings.engage.labels.addStatus &&
      props.settings.engage.labels.addStatus.success) {
      setShowAddNewLabelModal(false);
    }
  }, [props.settings.engage.labels]);


  const onChange = (e) => {
    const { name, value } = e.target;
    if (name === 'guestInfoEmail') {
      setGuestInfoEmail(value);
      setGuestInfoEmailChanged(true);
      if (value) {
        setGuestInfoEmailError(!validateEmail(value));
      } else {
        setGuestInfoEmailError(false);
      }
    }
    if (name === 'guestInfoRemarks') {
      setGuestInfoRemarks(value);
      setGuestInfoRemarksChanged(true);
    }
    if (name === "newName") {
      setNewName(value);
    }
  };

  const onEmailClick = (e) => {
    setShowEmailPanel(showEmailPanel => !showEmailPanel);
    setGuestInfoEmail(props.guest.email || '');
    setGuestInfoEmailError(false);
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : ''
      window.gtag('event', 'gt_click', {
        'event_category': `Engage - Messaging - ${guestListLabelByCategory[urlParams.categoryidentifier]} - Open Email`,
        'event_label': `Open Email`,
        page_path: `${location.pathname}`,
        page_title: `Engage - Messaging - ${guestListLabelByCategory[urlParams.categoryidentifier]} - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  };
  const onRemarksClick = (e) => {
    setShowRemarksPanel(showRemarksPanel => !showRemarksPanel);
    setGuestInfoRemarks(props.guest.remarks || '');
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : ''
      window.gtag('event', 'gt_click', {
        'event_category': `Engage - Messaging - ${guestListLabelByCategory[urlParams.categoryidentifier]} - Open Remarks`,
        'event_label': `Open Remarks`,
        page_path: `${location.pathname}`,
        page_title: `Engage - Messaging - ${guestListLabelByCategory[urlParams.categoryidentifier]} - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  };
  const onGuestMenuClick = (e) => {
    setShowGuestMenuPanel(showGuestMenuPanel => !showGuestMenuPanel);
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : ''
      window.gtag('event', 'gt_click', {
        'event_category': `Engage - Messaging - ${guestListLabelByCategory[urlParams.categoryidentifier]} - Open Guest Menu`,
        'event_label': `Open Guest Menu`,
        page_path: `${location.pathname}`,
        page_title: `Engage - Messaging - ${guestListLabelByCategory[urlParams.categoryidentifier]} - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  };

  const onGuestInfoEmailCopyClick = (e) => {
    if (!guestInfoEmail) return;
    copy(guestInfoEmail);
    const id = new Date().getTime();
    dispatch(receiveSnack({
      snack: {
        id,
        msg: 'Email copied.',
      },
    }));
    // remove the snack
    setTimeout(() => {
      dispatch(removeSnack({
        id,
      }));
    }, 2000);
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : ''
      window.gtag('event', 'gt_click', {
        'event_category': `Engage - Messaging - ${guestListLabelByCategory[urlParams.categoryidentifier]} - Copy Email`,
        'event_label': `Copy Email`,
        page_path: `${location.pathname}`,
        page_title: `Engage - Messaging - ${guestListLabelByCategory[urlParams.categoryidentifier]} - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  };
  const onGuestInfoRemarksCopyClick = (e) => {
    if (!guestInfoRemarks) return;
    copy(guestInfoRemarks);
    const id = new Date().getTime();
    dispatch(receiveSnack({
      snack: {
        id,
        msg: 'Remarks copied.',
      },
    }));
    // remove the snack
    setTimeout(() => {
      dispatch(removeSnack({
        id,
      }));
    }, 2000);
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : ''
      window.gtag('event', 'gt_click', {
        'event_category': `Engage - Messaging - ${guestListLabelByCategory[urlParams.categoryidentifier]} - Copy Remarks`,
        'event_label': `Copy Remarks`,
        page_path: `${location.pathname}`,
        page_title: `Engage - Messaging - ${guestListLabelByCategory[urlParams.categoryidentifier]} - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  };


  const onGuestInfoEmailSaveClick = (e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('guestInfoEmailChanged: ', guestInfoEmailChanged);
    }
    if (props.isReadOnly) return;
    if (!guestInfoEmailChanged) return;
    if (props.guest.editInProgress) return;
    if (guestInfoEmail) {
      if (!validateEmail(guestInfoEmail)) return;
    }
    const { categoryidentifier, guestidentifier } = urlParams;
    const params = {};
    params.mode = 'edit';
    params.location__id = urlParams.locationidentifier;
    params.guest_type = props.guestType;
    params.email = guestInfoEmail;
    if (props.guestType === 'guest' || props.guestType === 'past-guest' || props.guestType === 'upcoming-guest') {
      params['guest_id'] = guestidentifier;
      dispatch(editDataAction({
        op: 'edit_guest',
        show_alert: true,
        params,
      }));
    }
    if (props.guestType === 'outside-guest' || props.guestType === 'staff' || props.guestType === 'webtext' || props.guestType === 'gbm'
    ) { // nkcheck
      params['outside_guest_id'] = guestidentifier;
      dispatch(editDataAction({
        op: 'edit_outside_guest',
        show_alert: true,
        init: true,
        params,
      }));
    }
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : ''
      window.gtag('event', 'gt_click', {
        'event_category': `Engage - Messaging - ${guestListLabelByCategory[urlParams.categoryidentifier]} - Save Email`,
        'event_label': `Save Email`,
        page_path: `${location.pathname}`,
        page_title: `Engage - Messaging - ${guestListLabelByCategory[urlParams.categoryidentifier]} - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  };

  const onGuestInfoRemarksSaveClick = (e) => {
    if (props.isReadOnly) return;
    if (!guestInfoRemarksChanged) return;
    if (props.guest.editInProgress) return;
    const { categoryidentifier, guestidentifier } = urlParams;
    const params = {};
    params.mode = 'edit';
    params.location__id = urlParams.locationidentifier;
    params.guest_type = props.guestType;
    params.remarks = guestInfoRemarks;
    if (props.guestType === 'guest' || props.guestType === 'past-guest' || props.guestType === 'upcoming-guest') {
      params['guest_id'] = guestidentifier;
      dispatch(editDataAction({
        op: 'edit_guest',
        show_alert: true,
        params,
      }));
    }
    if (props.guestType === 'outside-guest' || props.guestType === 'staff' || props.guestType === 'webtext' || props.guestType === 'gbm') {
      params['outside_guest_id'] = guestidentifier;
      dispatch(editDataAction({
        op: 'edit_outside_guest',
        show_alert: true,
        init: true,
        params,
      }));
    }
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : ''
      window.gtag('event', 'gt_click', {
        'event_category': `Engage - Messaging - ${guestListLabelByCategory[urlParams.categoryidentifier]} - Save Remarks`,
        'event_label': `Save Remarks`,
        page_path: `${location.pathname}`,
        page_title: `Engage - Messaging - ${guestListLabelByCategory[urlParams.categoryidentifier]} - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  };

  const onGuestProfileClick = (e) => {
    setShowGuestProfile(showGuestProfile => !showGuestProfile);
    setTimeout(() => {
      setShowGuestProfileFinal(showGuestProfileFinal => !showGuestProfileFinal);
    }, 300);
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : ''
      window.gtag('event', 'gt_click', {
        'event_category': `Engage - Messaging - ${guestListLabelByCategory[urlParams.categoryidentifier]} - Open Guest Profile`,
        'event_label': `Open Guest Profile`,
        page_path: `${location.pathname}`,
        page_title: `Engage - Messaging - ${guestListLabelByCategory[urlParams.categoryidentifier]} - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  };

  const onEditGuestClick = (e) => {
    setShowEditGuestModal(true);
  };
  const onEditGuestModalCloseClick = (pathParam) => {
    setShowEditGuestModal(false);
    // clearGuestEditStatus(); // nkcheck
    if (process.env.NODE_ENV !== 'production') {
      console.log('onEditGuestModalCloseClick pathParam: ', pathParam);
    }
    if (pathParam) {
      navigate(pathParam);
    }
  };

  const onDisableCheckOutMsgClick = (e) => {
    setShowDisableCheckOutMessageModal(true);
  };
  const onShowDisableCheckOutMessageCloseModal = (value) => {
    setShowDisableCheckOutMessageModal(false);
  };
  const onDisableCheckOutMessageConfirmClick = (guest) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onDisableCheckOutMessageConfirmClick: ', guest);
    }
    if (props.guest.editInProgress || props.guest.editDisableCheckMsgInProgress) return;
    const { categoryidentifier, guestidentifier } = urlParams;
    const params = {};
    params.mode = 'edit';
    params.location__id = urlParams.locationidentifier;
    params.guest_type = props.guestType;
    params.checkout_msg_disabled = !props.guest.checkout_msg_disabled;
    params.edit_type = 'checkout_msg_disabled';
    if (props.guestType === 'guest' || props.guestType === 'past-guest' || props.guestType === 'upcoming-guest') {
      params['guest_id'] = guestidentifier;
      dispatch(editDataAction({
        op: 'edit_guest',
        show_alert: true,
        params,
      }));
    }
  };

  const onOptOutClick = (e) => {
    setShowOptOutModal(true);
  };
  const onShowOptOutCloseModal = (value) => {
    setShowOptOutModal(false);
  };
  const onOptOutConfirmClick = (guest) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onOptOutConfirmClick: ', guest);
    }
    if (props.guest.editInProgress || props.guest.editOptOutInProgress) return;
    const { categoryidentifier, guestidentifier } = urlParams;
    const params = {};
    params.mode = 'edit';
    params.location__id = urlParams.locationidentifier;
    params.guest_type = props.guestType;
    params.opt_out = !props.guest.opt_out;
    params.edit_type = 'opt_out';
    if (props.guestType === 'guest' || props.guestType === 'past-guest' || props.guestType === 'upcoming-guest') {
      params['guest_id'] = guestidentifier;
      dispatch(editDataAction({
        op: 'edit_guest',
        show_alert: true,
        params,
      }));
    }
    if (props.guestType === 'outside-guest' || props.guestType === 'staff' || props.guestType === 'webtext') {
      params['outside_guest_id'] = guestidentifier;
      dispatch(editDataAction({
        op: 'edit_outside_guest',
        show_alert: true,
        init: true,
        params,
      }));
    }
  };


  const onReplyRequiredClick = (e) => {
    setShowReplyRequiredModal(true);
  };
  const onShowReplyRequiredCloseModal = (value) => {
    setShowReplyRequiredModal(false);
  };
  const onReplyRequiredConfirmClick = (guest) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onReplyRequiredConfirmClick: ', guest);
    }
    if (props.guest.editInProgress || props.guest.editReplyRequiredInProgress) return;
    const { categoryidentifier, guestidentifier } = urlParams;
    const params = {};
    params.mode = 'edit';
    params.location__id = urlParams.locationidentifier;
    params.guest_type = props.guestType;
    params.reply_required = !props.guest.hotel_reply_required;
    params.edit_type = 'reply_required';
    if (props.guestType === 'guest' || props.guestType === 'past-guest' || props.guestType === 'upcoming-guest') {
      params['guest_id'] = guestidentifier;
      dispatch(editDataAction({
        op: 'edit_guest',
        show_alert: true,
        params,
      }));
    }
    if (props.guestType === 'outside-guest' || props.guestType === 'staff' || props.guestType === 'webtext') {
      params['outside_guest_id'] = guestidentifier;
      dispatch(editDataAction({
        op: 'edit_outside_guest',
        show_alert: true,
        init: true,
        params,
      }));
    }
  };


  const onRegenerateRemoteLockKey = (e) => {
    setShowRegenerateRemoteLockModal(true);
  };
  const onShowRegenerateRemoteLockKeyCloseModal = (value) => {
    setShowRegenerateRemoteLockModal(false);
  };
  const onRegenerateRemoteLockKeyConfirmClick = (guest) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onRegenerateRemoteLockKeyConfirmClick: ', guest);
    }
    if (props.guest.editInProgress || props.guest.regenerateRemoteLockKeyInProgress) return;
    const { categoryidentifier, guestidentifier } = urlParams;
    const params = {};
    params.mode = 'edit';
    params.location__id = urlParams.locationidentifier;
    params.guest_type = props.guestType;
    params['guest_id'] = guestidentifier;
    params.edit_type = 'regenerate_remote_lock_key';
    params.regenerate_remote_lock_key = true;
    dispatch(editDataAction({
      op: 'edit_guest',
      show_alert: true,
      params,
    }));
  };


  const onRevokeRemoteLockKey = (e) => {
    setShowRevokeRemoteLockModal(true);
  };
  const onShowRevokeRemoteLockKeyCloseModal = (value) => {
    setShowRevokeRemoteLockModal(false);
  };
  const onRevokeRemoteLockKeyConfirmClick = (guest) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onRevokeRemoteLockKeyConfirmClick: ', guest);
    }
    if (props.guest.editInProgress || props.guest.revokeRemoteLockKeyInProgress) return;
    const { categoryidentifier, guestidentifier } = urlParams;
    const params = {};
    params.mode = 'edit';
    params.location__id = urlParams.locationidentifier;
    params.guest_type = props.guestType;
    params['guest_id'] = guestidentifier;
    params.edit_type = 'revoke_remote_lock_key';
    params.revoke_remote_lock_key = true;
    dispatch(editDataAction({
      op: 'edit_guest',
      show_alert: true,
      params,
      
    }));
  };


  const onCancelReservationClick = (e) => {
    setShowCancelReservationModal(true);
  };
  const onShowCancelReservationCloseModal = (value) => {
    setShowCancelReservationModal(false);
  };
  const onCancelReservationConfirmClick = (guest) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onCancelReservationConfirmClick: ', guest);
    }
    if (props.guest.editInProgress || props.guest.cancelReservationInProgress) return;
    const { categoryidentifier, guestidentifier } = urlParams;
    const params = {};
    params.mode = 'edit';
    params.location__id = urlParams.locationidentifier;
    params.guest_type = props.guestType;
    params['guest_id'] = guestidentifier;
    params.edit_type = 'cancel_reservation';
    params.cancel_reservation = true;
    dispatch(editDataAction({
      op: 'edit_guest',
      show_alert: true,
      params,
    }));
  };


  const onMarkAsStaffClick = (e) => {
    setShowMarkAsStaffModal(true);
  };
  const onShowMarkAsStaffCloseModal = (value) => {
    setShowMarkAsStaffModal(false);
  };
  const onMarkAsStaffConfirmClick = (guest) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onMarkAsStaffConfirmClick: ', guest);
    }
    if (props.guest.editInProgress || props.guest.editMarkAsStaffInProgress) return;
    const { categoryidentifier, guestidentifier } = urlParams;
    const params = {};
    params.mode = 'edit';
    params.location__id = urlParams.locationidentifier;
    params.guest_type = props.guestType;
    params['outside_guest_id'] = guestidentifier;
    params.edit_type = 'mark_as_staff';
    params.is_staff = !props.guest.is_staff;
    dispatch(editDataAction({
      op: 'edit_outside_guest',
      show_alert: true,
      init: true,
      params,
    }));
  };


  const onRemoveClick = (e) => {
    setShowRemoveModal(true);
  };
  const onShowRemoveCloseModal = (value) => {
    setShowRemoveModal(false);
  };
  const onRemoveConfirmClick = (guest) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onRemoveConfirmClick: ', guest);
    }
    if (props.guest.editInProgress || props.guest.editRemoveInProgress) return;
    const { categoryidentifier, guestidentifier } = urlParams;
    const params = {};
    params.mode = 'edit';
    params.location__id = urlParams.locationidentifier;
    params.guest_type = props.guestType;
    params['outside_guest_id'] = guestidentifier;
    params.edit_type = 'remove';
    params.remove = true;
    dispatch(editDataAction({
      op: 'edit_outside_guest',
      show_alert: true,
      init: true,
      params,
    }));
  };


  const onCheckInIconClick = (e) => {
    if (props.isReadOnly) return;
    setShowCheckInModal(true);
  };
  const onShowCheckInCloseModal = (value) => {
    setShowCheckInModal(false);
  }
  const onCheckInConfirmClick = (checkInParams) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onCheckInConfirmClick: ', props.guestType, ' # ', props.guest);
      console.log('onCheckInConfirmClick checkInParams: ', checkInParams);
    }
    if (props.isReadOnly) return;

    if (props.guest.editInProgress || props.guest.editCheckInInProgress) return;
    const { categoryidentifier, guestidentifier } = urlParams;
    const params = {};
    params.mode = 'edit';
    params.location__id = urlParams.locationidentifier;
    params.guest_type = props.guestType;
    params['guest_id'] = guestidentifier;
    params.edit_type = 'check_in_now';
    params.check_in_now = true;
    if (checkInParams.check_out_date) { params.check_out_date = checkInParams.check_out_date; }
    if (checkInParams.room_number) { params.room_number = checkInParams.room_number; }
    if (process.env.NODE_ENV !== 'production') {
      console.log('onCheckInConfirmClick params: ', params);
    }
    dispatch(editDataAction({
      op: 'edit_guest',
      show_alert: true,
      params,
    }));
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : ''
      window.gtag('event', 'gt_click', {
        'event_category': `Engage - Messaging - ${guestListLabelByCategory[urlParams.categoryidentifier]} - Checkin`,
        'event_label': `Checkin`,
        page_path: `${location.pathname}`,
        page_title: `Engage - Messaging - ${guestListLabelByCategory[urlParams.categoryidentifier]} - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  };

  const onCheckOutIconClick = (e) => {
    if (props.isReadOnly) return;
    setShowCheckOutModal(true);
  };
  const onShowCheckOutCloseModal = (value) => {
    setShowCheckOutModal(false);
  }
  const onCheckOutConfirmClick = (guest, followGuest = true) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onCheckOutConfirmClick: ', guest);
    }
    if (props.isReadOnly) return;

    if (props.guest.editInProgress || props.guest.editCheckOutInProgress) return;
    const { categoryidentifier, guestidentifier } = urlParams;
    const params = {};
    params.mode = 'edit';
    params.location__id = urlParams.locationidentifier;
    params.guest_type = props.guestType;
    params['guest_id'] = guestidentifier;
    params.edit_type = 'check_out_now';
    params.follow_guest = followGuest; // true
    params.check_out_now = true;
    if (process.env.NODE_ENV !== 'production') {
      console.log('onCheckOutConfirmClick params: ', params);
    }
    dispatch(editDataAction({
      op: 'edit_guest',
      show_alert: true,
      params,
    }));
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : ''
      window.gtag('event', 'gt_click', {
        'event_category': `Engage - Messaging - ${guestListLabelByCategory[urlParams.categoryidentifier]} - Checkout`,
        'event_label': `Checkout`,
        page_path: `${location.pathname}`,
        page_title: `Engage - Messaging - ${guestListLabelByCategory[urlParams.categoryidentifier]} - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  };


  const onLabelsMenuClick = (e) => {
    setShowLabelsMenu(showLabelsMenu => !showLabelsMenu);
  };
  const onLabelClick = (label) => {
    let cLabels = [ ...selectedLabels ];
    const foundLabel = cLabels.find(el => el._id === label._id);
    if (!foundLabel) {
      cLabels.push({ ...label, is_selected: true });
    } else {
      if (foundLabel.is_selected) {
        cLabels = cLabels.filter(el => el._id !== label._id);
      } else {
        cLabels = cLabels.map((item) => {
          item.is_selected = !item.is_selected;
          return item;
        });
      }
    }
    if (process.env.NODE_ENV !== 'production') {
      console.log('onLabelClick cLabels: ', cLabels);
    }
    setSelectedLabels(cLabels);
    let obj = {};
    cLabels.forEach((item) => {
      obj[item._id] = item;
    });
    setSelectedLabelsObj(obj);
    setLabelsMenuChanged(true);
  };
  const onLabelsApplyClick = (e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onLabelsApplyClick selectedLabels: ', selectedLabels);
    }
    const params = {};
    params.mode = 'edit';
    params.location__id = urlParams.locationidentifier;
    params.guest_type = props.guestType;
    params.labels = selectedLabels.map((item) => {
      return {
        _id: item._id,
        is_selected: true,
        label: item.label,
        color: item.color,
      };
    });

    let op = '';
    if (props.guestType === 'guest' || props.guestType === 'past-guest' || props.guestType === 'upcoming-guest') {
      op = 'edit_guest';
      params.guest_id = guestidentifier;
    }
    if (props.guestType === 'outside-guest' || props.guestType === 'staff' || props.guestType === 'webtext' || props.guestType === 'gbm') {
      op = 'edit_outside_guest';
      params.outside_guest_id = guestidentifier;
    }
    dispatch(editDataAction({
      op,
      show_alert: true,
      params,
    }));
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : ''
      window.gtag('event', 'gt_click', {
        'event_category': `Engage - Messaging - ${guestListLabelByCategory[urlParams.categoryidentifier]} - Update Guest Labels`,
        'event_label': `Apply Labels`,
        page_path: `${location.pathname}`,
        page_title: `Engage - Messaging - ${guestListLabelByCategory[urlParams.categoryidentifier]} - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  };
  const onAddNewLabelClick = (e) => {
    setShowAddNewLabelModal(true);
  };
  const onManageLabelsClick = (e) => {
    navigate(`/settings/locations/${locationidentifier}/labels`);
  };
  const onAddNewLabelConfirmClick = (newLabelParams) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onAddNewLabelConfirmClick: ', props.guestType, ' # ', props.guest);
      console.log('onAddNewLabelConfirmClick newLabelParams: ', newLabelParams);
    }

    const { categoryidentifier, guestidentifier } = urlParams;
    if (props.settings.engage.labels.addInProgress) return;

    const params = {};
    params.mode = 'add';
    params.location__id = urlParams.locationidentifier;
    params.label = newLabelParams.label;
    params.color = newLabelParams.color;
    if (process.env.NODE_ENV !== 'production') {
      console.log('params: ', params);
    }
    dispatch(editDataAction({
      op: 'edit_engage_guest_label',
      show_alert: true,
      alert_msg: 'Added successfully',
      params,
    }));
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : ''
      window.gtag('event', 'gt_click', {
        'event_category': `Engage - Messaging - ${guestListLabelByCategory[urlParams.categoryidentifier]} - Add Label`,
        'event_label': `Add Label`,
        page_path: `${location.pathname}`,
        page_title: `Engage - Messaging - ${guestListLabelByCategory[urlParams.categoryidentifier]} - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  };


  const onSuggestResponseClick = (e) => {
    if (props.guest.getSuggestedResponseInProgress) return;
    const params = {};
    params.location__id = urlParams.locationidentifier;
    params.guest_type = props.guestType;
    if (props.guestType === 'guest' || props.guestType === 'past-guest' || props.guestType === 'upcoming-guest') {
      params['guest_id'] = guestidentifier;
    }
    if (props.guestType === 'outside-guest' || props.guestType === 'staff' || props.guestType === 'webtext' || props.guestType === 'gbm') {
      params['outside_guest_id'] = guestidentifier;
    }
    dispatch(getDataAction({
      op: 'get_ai_model_result',
      params,
    }));
  };
  const onSuggestedResponseUseClick = (e) => {
    setSuggestedResponse(props.guest.generated_response);
  };
  const onSuggestResponseCloseClick = (e) => {
    setShowSuggestedResponse(false);
    const params = {};
    params.location__id = urlParams.locationidentifier;
    params.guest_type = props.guestType;
    if (props.guestType === 'guest' || props.guestType === 'past-guest' || props.guestType === 'upcoming-guest') {
      params['guest_id'] = guestidentifier;
    }
    if (props.guestType === 'outside-guest' || props.guestType === 'staff' || props.guestType === 'webtext' || props.guestType === 'gbm') {
      params['outside_guest_id'] = guestidentifier;
    }
    dispatch(getDataClearAction({
      op: 'get_ai_model_result',
      params,
    }));
  };

  const onAddNameClick = (e) => {
    setAddNameActive(true);
    if (props.guestType === 'outside-guest' || props.guestType === 'staff') {
      let tempName = props.guest.name || '';
      if (props.guest.first_name) tempName = props.guest.first_name;
      setNewName(tempName);
    }
  };
  const onNewNameChange = (e) => {
    const { name, value } = e.target;
    setNewName(value);
  };
  const onAddNameSave = (e) => {
    if (newName) {
      if (props.guestType === 'outside-guest' || props.guestType === 'staff') {
        const { categoryidentifier, guestidentifier } = urlParams;
        const params = {};
        params.mode = 'edit';
        params.location__id = urlParams.locationidentifier;
        params.guest_type = props.guestType;
        params['outside_guest_id'] = guestidentifier;
        params.name = newName;
        dispatch(editDataAction({
          op: 'edit_outside_guest',
          show_alert: true,
          init: true,
          params,
        }));
      }
    } else {
      setAddNameActive(false);
    }
  };

  const renderAddNewLabelModal = () => {
    return (
      <Modal>
        <div id="cModelId" className="cModal alModal">
          <div className="content">
            <AddNewLabelModal onCancelClick={setShowAddNewLabelModal} guest={props.guest}
              onConfirmClick={onAddNewLabelConfirmClick}
              addInProgress={props.settings.engage.labels.addInProgress}
              addStatus={props.settings.engage.labels.addStatus}
            />
          </div>
        </div>
      </Modal>
    );
  };


  const renderEditGuestModal = (guest, settings, engage) => {
    return (
      <Modal>
        <div id="cModelId" className="cModal addGuestModal">
          <div className="content" ref={editGuestModalRef}>
            <AddGuestSingle mode="edit" guest={guest} guestType={props.guestType}
              settings={settings}
              engage={engage}
              onCancelClick={onEditGuestModalCloseClick}
              isReadOnly={props.isReadOnly}
            />
          </div>
        </div>
      </Modal>
    );
  };

  const renderCheckInModal = () => {
    return (
      <Modal>
        <div id="cModelId" className="cModal mtModal">
          <div className="content">
            <CheckInModal onCancelClick={setShowCheckInModal} guest={props.guest}
              onConfirmClick={onCheckInConfirmClick}
              checkInProgress={props.guest.editCheckInInProgress}
              checkInStatus={props.guest.editCheckInStatus}
            />
          </div>
        </div>
      </Modal>
    );
  };

  const renderGuestContact = (guest, locInfo) => {
    if (guest.source === 'bm') {
      return (
        <div className="cntc gbm">Google Business Message</div>
      );
    }
    if (guest.contact) {
      return (
        <div className="cntc">
          {
            !hasValidContact ?
            <ToolTipWrapper placement="left" overlayClassName="sbTooltip" overlay={'Invalid contact number!'}>
              <span className="inValid"><i className="fas fa-exclamation-triangle"></i></span>
            </ToolTipWrapper>
            : null
          }
          { contactDisplay(guest.contact, userState.user.user_info, locInfo.hide_contact) }
        </div>
      );
    }
    return (
      <div className="contact missing">
        <ToolTipWrapper placement="left" overlayClassName="sbTooltip" overlay={'Missing contact number!'}>
          <span className="inValid"><i className="fas fa-exclamation-triangle"></i> Contact Missing</span>
        </ToolTipWrapper>
      </div>
    );
    return null;
  };

  const renderStayInfo = (guest, locInfo) => {
    if (guest.is_staff) return null;
    if (guest.source === 'bm') return null;
    if (props.guestType === 'outside-guest') return null;
    let roomLabel = guest.room_number;
    const roomNumberStr = guest.room_number;
    if (guest.room_type) roomLabel = `${roomLabel} - ${guest.room_type}`;
    const roomTypeStr = guest.room_type || '';
    let rooms = guest.rooms;
    if (!roomLabel) roomLabel = '-';
    const stayLabel = guestTransactionStatusToStayLabel[guest.transaction_type];
    const checkInDateStr = formatDateStrToStr(guest.check_in_date_local_str) || formatDate(guest.check_in_date);
    const checkOutDateStr = formatDateStrToStr(guest.check_out_date_local_str) || formatDate(guest.check_out_date);
    const hasRemoteLock = locInfo.has_remote_lock ? true : false;
    return (
      <div className="stI">
        <div className="itm rm">
          <ToolTipWrapper placement="bottom" overlayClassName="sbTooltip"
            overlay={
              <span>
                Room Number: {roomNumberStr}<br /> Type: {roomTypeStr || '-'}
                {
                  hasRemoteLock ?
                  <><br/>Keycode: {guest.keycode}</>
                  : null
                }
                {
                  rooms && rooms.length ?
                  <>
                    <br/>
                    <br/>
                    {
                      rooms.map((rm) => {
                        return (
                          <>
                            Room Number: {rm.room_number || ''}<br /> Type: {rm.room_type || '-'}
                          </>
                        )
                      })
                    }
                  </>
                  : null
                }
              </span>
            }
          >
            <div className="tp">
              {roomLabel}
            </div>
          </ToolTipWrapper>
          <div className="bt">
            {stayLabel}
          </div>
        </div>
        <div className="itm dt">
          <div className="tp">
            {checkInDateStr}
          </div>
          <div className="bt">
            Check-In Date
          </div>
        </div>
        <div className="itm dt">
          <div className="tp">
            {checkOutDateStr}
          </div>
          <div className="bt">
            Check-Out Date
          </div>
        </div>
      </div>
    );
  };

  const renderGuestActions = (guest) => {
    let hasPmsIntegration = false, isRemoteLockEnabled = false;
    if (userState && userState.user && userState.user.user_info && userState.user.user_info.locations &&
      userState.user.user_info.locations[urlParams.locationidentifier]
    ) {
      hasPmsIntegration = userState.user.user_info.locations[urlParams.locationidentifier].has_pms_integration || false;
      isRemoteLockEnabled = userState.user.user_info.locations[urlParams.locationidentifier].is_remote_lock_enabled || false;
    }
    const isReservation = (guest.transaction_type === 'Reservation' || guest.transaction_type === 'Booking') || false;
    const isCheckedIn = guest.transaction_type === 'CheckIn' || false;
    return (
      <>
        <div className="enB">
          <div className={'dd eb' + (showEmailPanel ? ' open' : '')} ref={emailPanelRef}>
            <ToolTipWrapper placement="bottom" overlayClassName="sbTooltip" overlay={guest.email || 'Email'}>
            <button className="dbtn">
              <span className={guest.email ? 'active' : ''}><img src={guest.email ? emailIcon : emailIGreycon} className="actionIcon" onClick={onEmailClick} /></span>
            </button>
            </ToolTipWrapper>
            <div className="ddc">
              <div className="rOne">
                <p>Email</p>
                <span className={(guestInfoEmail) ? '' : 'inactive'} onClick={onGuestInfoEmailCopyClick}>
                  <ToolTipWrapper placement="bottom" overlayClassName="sbTooltip" overlay={guestInfoEmail ? 'Click to copy' : 'Nothing to copy'}>
                    <i className="fa fa-copy" />
                  </ToolTipWrapper>
                </span>
              </div>
              <div className="rTwo">
                <div className={'left ' + (guestInfoEmailError ? 'error' : '')}>
                  <input type="text" placeholder="Add Email" value={guestInfoEmail} name="guestInfoEmail" onChange={onChange} />
                  { guestInfoEmailError && <p className="error">Invalid email</p> }
                </div>
                <span onClick={onGuestInfoEmailSaveClick}>
                  {
                    guest.editInProgress ? <i className="fa fa-spinner fa-spin" /> : <i className="fa fa-check" />
                  }
                </span>
              </div>
            </div>
          </div>
          <div ref={notesPanelRef} className={'dd nb' + (showRemarksPanel ? ' open' : '')}>
            <button className="dbtn" data-tip data-for="tt_gi_remarks">
              <span className={guest.remarks ? 'active' : ''}>
              <ToolTipWrapper placement="bottom" overlayClassName="sbTooltip" overlay={guest.remarks || 'Notes'}>
                <img src={guest.remarks ? remarksIcon : remarksGreyIcon} className="actionIcon" onClick={onRemarksClick} />
              </ToolTipWrapper>
              </span>
            </button>
            <div className="ddc">
              <div className="rOne">
                <p>Notes</p>
                <span className={(guestInfoRemarks) ? '' : 'inactive'} onClick={onGuestInfoRemarksCopyClick}>
                  <ToolTipWrapper placement="bottom" overlayClassName="sbTooltip" overlay={guestInfoRemarks ? 'Click to copy' : 'Nothing to copy'}>
                  <i className="fa fa-copy" />
                  </ToolTipWrapper>
                </span>
              </div>
              <div className="rTwo">
                <textarea rows="2" placeholder="Add Notes" value={guestInfoRemarks} name="guestInfoRemarks" onChange={onChange} />
                <span onClick={onGuestInfoRemarksSaveClick}>
                  {
                    guest.editInProgress ? <i className="fa fa-spinner fa-spin" /> : <i className="fa fa-check" />
                  }
                </span>
              </div>
            </div>
          </div>
        </div>
        {
          isReservation &&
          <div className={'ciB ' + (windowState.window.windowWidth <= GI_CHECK_IN_TEXT_THRESHOLD ? 'wT' : '')}>
            <ToolTipWrapper placement="bottom" overlayClassName="sbTooltip" overlay={'Check-In'} disable={windowState.window.windowWidth <= GI_CHECK_IN_TEXT_THRESHOLD}>
              <span onClick={onCheckInIconClick}>
                <img src={checkInIcon} className="actionIcon" />
                {
                  windowState.window.windowWidth <= GI_CHECK_IN_TEXT_THRESHOLD &&
                  'Check-In Now'
                }
              </span>
            </ToolTipWrapper>
          </div>
        }
        {
          isCheckedIn &&
          <div className="coB">
            <span onClick={onCheckOutIconClick}>
              <ToolTipWrapper placement="bottom" overlayClassName="sbTooltip" overlay={'Check-Out'}>
                <i className="fa fa-sign-out-alt" />
              </ToolTipWrapper>
            </span>
          </div>
        }
        {/* {
          (props.guestType === 'guest' || props.guestType === 'past-guest' || props.guestType === 'upcoming-guest') &&
          <div className="infB">
            <span onClick={onGuestProfileClick}>
              <ToolTipWrapper placement="bottom" overlayClassName="sbTooltip" overlay={'Guest Profile'}>
                <img src={infoIcon} />
              </ToolTipWrapper>
            </span>
          </div>
        } */}
        {
          !props.isReadOnly &&
          (categoryidentifier === 'guests' || categoryidentifier === 'upcoming-guests' ||
            categoryidentifier === 'past-guests' ||
            categoryidentifier === 'outside-guests' || categoryidentifier === 'webtext' ||
            categoryidentifier === 'staff' ||
            categoryidentifier === 'gbm'
          ) &&
          <div ref={guestMenuPanelRef} className={'dd mnB ' + (showGuestMenuPanel ? 'open' : '')}>
            <button className="dbtn" onClick={onGuestMenuClick}>
              <span><img src={moreIcon} className="actionIcon" /></span>
            </button>
            <div className="ddc">
              {
                (categoryidentifier === 'outside-guests' || categoryidentifier === 'staff') &&
                <p onClick={onRemoveClick}>Remove</p>
              }
              {
                (categoryidentifier === 'outside-guests' || categoryidentifier === 'gbm') &&
                <p onClick={onMarkAsStaffClick}>Mark as Staff</p>
              }
              {
                categoryidentifier === 'staff' && !props.guest.is_trial_entry &&
                <p onClick={onMarkAsStaffClick}>Not a Staff</p>
              }
              {
                (categoryidentifier === 'guests' || categoryidentifier === 'upcoming-guests') &&
                <p onClick={onEditGuestClick}>Update Guest</p>
              }
              {
                (categoryidentifier === 'guests' || categoryidentifier === 'upcoming-guests') &&
                <p onClick={onDisableCheckOutMsgClick}>
                  { guest.checkout_msg_disabled ? 'Enable ' : 'Disable ' }Check-Out Msg
                </p>
              }
              {
                (categoryidentifier === 'guests' || categoryidentifier === 'past-guests' || categoryidentifier === 'upcoming-guests' ||
                  categoryidentifier === 'outside-guests' || categoryidentifier === 'webtext' || categoryidentifier === 'staff' || categoryidentifier === 'gbm'
                ) &&
                <p onClick={onOptOutClick}>
                  { guest.opt_out ? 'Opt-In ' : 'Opt-Out ' }
                </p>
              }
              {
                guest.hotel_reply_required_txt &&
                (categoryidentifier === 'guests' || categoryidentifier === 'past-guests' || categoryidentifier === 'upcoming-guests' ||
                  categoryidentifier === 'outside-guests' || categoryidentifier === 'webtext' || categoryidentifier === 'staff' || categoryidentifier === 'gbm'
                ) &&
                <p onClick={onReplyRequiredClick}>
                  { guest.hotel_reply_required ? 'Mark as Reply Not Required ' : 'Mark as Reply Required ' }
                </p>
              }
              {
                (isRemoteLockEnabled && categoryidentifier === 'guests') &&
                <p onClick={onRegenerateRemoteLockKey}>{guest.keycode ? 'Regenerate Remote Lock Key' : 'Generate Remote Lock Key'}</p>
              }
              {
                (isRemoteLockEnabled && guest.keycode && categoryidentifier === 'guests') &&
                <p onClick={onRevokeRemoteLockKey}>Revoke Remote Lock Key</p>
              }
              {
                (!hasPmsIntegration && categoryidentifier === 'upcoming-guests' && props.guest.transaction_type !== 'Cancel') &&
                <p onClick={onCancelReservationClick}>Cancel Reservation</p>
              }
            </div>
          </div>
        }
      </>
    );
  };

  const renderGuestInfo = (guest, locInfo) => {
    let avataarName = getAvataarName(guest);
    const displayName = getFullNameForDisplay(guest);
    return (
      <div className={'giB' + (windowState.window.windowWidth > GI_TWO_LINE_THRESHOLD ? '' : ' split')}>
        <div className="pri">
          <div className="lt">
            <div className="avt">
              {avataarName}
            </div>
            <div className={'nm' + (props.guestType === 'outside-guest' || props.guestType === 'staff' ? ' extraWidth' : '')}>
              {
                (props.guestType === 'outside-guest' || props.guestType === 'staff')
                ?
                  <div className="tp">
                    {
                      (props.guestType === 'outside-guest' || props.guestType === 'staff')
                      &&
                      <>
                        {
                          addNameActive
                          ?
                          <div className="nwNmBx">
                            <input type="text" value={newName} onChange={onNewNameChange} name="newName" />
                            {
                              props.guest.editInProgress ? <i className="fas fa-spinner fa-spin"></i> : <i className={'fa ' + (newName ? "fa-check" : "fa-times")} onClick={onAddNameSave}/>
                            }
                          </div>
                          :
                            <>
                            {
                              displayName
                              ? <><span className="adNm" >{displayName}</span><i onClick={onAddNameClick} className="fa-solid fa-pencil"/></>
                              :<span className="adNm udrln" onClick={onAddNameClick}>Add name</span>
                            }
                            </>
                        }
                      </>
                    }
                  </div>
                : <div className="tp">{displayName}</div>
              }
              <div className="bt">
                { renderGuestContact(guest, locInfo) }
              </div>
            </div>
            {
              windowState.window.windowWidth > GI_TWO_LINE_THRESHOLD &&
              renderStayInfo(guest, locInfo)
            }
          </div>
          <div className="rt">
            { renderGuestActions(guest) }
          </div>
        </div>
        {
          windowState.window.windowWidth <= GI_TWO_LINE_THRESHOLD &&
          <div className="sec">
            { renderStayInfo(guest, locInfo) }
          </div>
        }
      </div>
    );
  };

  const { user } = userState;
  if (!user) return null;

  const { locationidentifier } = urlParams;
  if (!locationidentifier) return null;

  const locInfo = user.user_info && user.user_info.locations && user.user_info.locations[locationidentifier]
    ? { ...user.user_info.locations[locationidentifier] } : null;
  if (!locInfo) return null;

  const { categoryidentifier, guestidentifier } = urlParams;
  if (!categoryidentifier || !guestidentifier) return null;

  const storeGuestTypeLabel = categoryToStoreGuestLabel[categoryidentifier];
  if (!storeGuestTypeLabel) return null;

  if (process.env.NODE_ENV !== 'production') {
    console.log('GuestPanel storeGuestTypeLabel: ', storeGuestTypeLabel);
    console.log('GuestPanel showCheckInModal: ', showCheckInModal);
    console.log('GuestConvPanel selectedLabels: ', selectedLabels);
  }

  const { guest } = props;
  // let gData = locationState.locations[locationidentifier] && locationState.locations[locationidentifier].engage
  //   && locationState.locations[locationidentifier].engage[storeGuestTypeLabel]
  //   && locationState.locations[locationidentifier].engage[storeGuestTypeLabel].keyValue &&
  //   locationState.locations[locationidentifier].engage[storeGuestTypeLabel].keyValue[guestidentifier]
  //   ? locationState.locations[locationidentifier].engage[storeGuestTypeLabel].keyValue[guestidentifier]
  //   : null;
  if (process.env.NODE_ENV !== 'production') {
    console.log('GuestPanel guest: ', guest);
    console.log('GuestPanel showSuggestedResponse: ', showSuggestedResponse);
  }
  if (!guest) return null;

  if (!locationState.locations[locationidentifier]) return null;
  const { settings, engage } = locationState.locations[locationidentifier];
  if (!settings || !engage) return null;

  let checkOutModalInfo = 'Guest will receive the departure message soon';
    if (guest.opt_out) { checkOutModalInfo = 'Guest will not receive the departure message as they have opted-out'; }
    if (guest.checkout_msg_disabled) { checkOutModalInfo = 'Guest will not receive the departure message as it has been disabled for this guest'; }

  const { labels } = props.settings.engage;
  let guestLabels = [];
  if (labels && labels.keyValue && props.guest.labels && props.guest.labels.length) {
    guestLabels = props.guest.labels.filter(item => labels.keyValue[item._id] && !labels.keyValue[item._id].is_removed);
  }

  let showSuggestResponse = false;
  if (props.guest.messages && props.guest.messages.length && props.guest.messages[0].type === 1) showSuggestResponse = true;
  // if (!showSuggestResponse && guest.hotel_reply_required) showSuggestResponse = true;
  // showSuggestResponse = true;

  const loading = guest.getInProgress || false;

  let cStyle= {};
  cStyle.width = props.guestListBoxWidth;
  return (
    <div className="gPnl" style={cStyle}>
      { loading && <div className="gPnlLdng"><span><i className="fas fa-spinner fa-spin"></i></span></div> }
      { renderGuestInfo(guest, locInfo) }
      <div className={'bc' + (windowState.window.windowWidth > GI_TWO_LINE_THRESHOLD ? '' : ' split')}>
        <div className="conv">
          <div className="lbB">
            <div className="labelsBox">
              <div ref={labelsMenuRef} className={'dropdown menuBox ' + (showLabelsMenu ? 'open' : '')}>
                <ToolTipWrapper placement="left" overlayClassName={'sbTooltip'}
                  overlay={'Add/Remove labels'}
                >
                  <button className="dropbtn" onClick={onLabelsMenuClick}>
                    <img src={labelIcon} />
                  </button>
                </ToolTipWrapper>
                <div className="dropdown-content">
                  {
                    labels && labels.list && labels.list.length
                    ?
                      <div className="chooseLabels">
                        {
                          labels.list.map((item) => {
                            const guestLabel = props.guest.labels ? props.guest.labels.find(el => el._id === item) : null;
                            return (
                              <ChooseLabelEntry
                                // isSelected={guestLabel ? guestLabel.is_selected : false}
                                isSelected={selectedLabelsObj[item] ? selectedLabelsObj[item].is_selected : false}
                                data={labels.keyValue[item]}
                                // data={selectedLabelsObj[item]}
                                onLabelClick={onLabelClick}
                              />
                            );
                          })
                        }
                      </div>
                    : null
                  }
                  {
                    labels && labels.list && labels.list.length
                    ?
                      <div className={'applyLabels ' + (labelsMenuChanged ? 'active' : '')} onClick={onLabelsApplyClick}>
                        Apply
                        {
                          props.guest.editInProgress &&
                          <i className="fas fa-spinner fa-spin"></i>
                        }
                      </div>
                    : null
                  }
                  <div className="addLabel" onClick={onAddNewLabelClick}>
                    + Add Label
                  </div>
                  <div className="manageLabel" onClick={onManageLabelsClick}>
                    Manage Labels
                  </div>
                </div>
              </div>
            </div>
            <div className="labelsDisplayBox">
              {
                labels && labels.list && guestLabels.length
                ?
                  <>
                    <div className="left">
                      Labels:
                    </div>
                    <div className="right">
                      {
                        guestLabels.map((item) => {
                          // const guestLabel = props.guest.labels ? props.guest.labels.find(el => el._id === item) : null;
                          if (!labels.keyValue || !labels.keyValue[item._id] || !labels.keyValue[item._id].label) return null;
                          let cStyle = { 'backgroundColor': '#c8d3fc' };
                          if (labels.keyValue && labels.keyValue[item._id]) {
                            cStyle['backgroundColor'] = labels.keyValue[item._id].color;
                          } else {
                            cStyle['backgroundColor'] = item.color
                          }
                          return (
                            <div className="entry" style={cStyle}>
                              {labels.keyValue[item._id] ? labels.keyValue[item._id].label : item.label}
                            </div>
                          );
                        })
                      }
                    </div>
                  </>
                : null
              }
            </div>
          </div>
          <GuestConversationBox guest={guest} locationidentifier={urlParams.locationidentifier} guestType={props.guestType}
            settings={settings}
            isReadOnly={props.isReadOnly}
          />
          {
            showSuggestResponse && !guest.opt_out &&
            <div className={'sugRspBox' + (showSuggestedResponse ? ' hasResp' : '')}>
              <div className="tpRw">
                <div className="lblWr">
                  <ToolTipWrapper placement="bottom" overlayClassName="sbTooltip" overlay={'Click to generate suggested response by guestAI'}>
                    <span className="lbl" onClick={onSuggestResponseClick}>
                      {
                        showSuggestedResponse && guest.generated_response
                        ? 'Regenerate suggested response'
                        : 'Suggest response'
                      }
                    </span>
                  </ToolTipWrapper>
                  { guest.getSuggestedResponseInProgress && <i className="fa fa-spinner fa-spin" /> }

                </div>
                {
                  showSuggestedResponse &&
                  <div className="clsBx">
                    {
                      guest.generated_response &&
                      <span onClick={onSuggestResponseCloseClick}><img src={closeIcon} /></span>
                    }
                  </div>
                }
              </div>
              {
                showSuggestedResponse &&
                guest.generated_response &&
                <div className="respRw">
                  <div className="lt">
                    {guest.generated_response}
                  </div>
                  <div className="rt">
                    <span onClick={onSuggestedResponseUseClick}>Use</span>
                  </div>
                </div>
              }
            </div>
          }
          <SendMessageBox {...props} settings={settings}
            locationId={settings.engage.location_id}
            hasValidContact={hasValidContact}
            hasValidEmail={hasValidEmail}
            isReadOnly={props.isReadOnly}
            suggestedResponse={suggestedResponse}
          />
        </div>
        {
          <div className={'gPr' + (showGuestProfile ? ' show' : '')}>
            {
              showGuestProfileFinal &&
              <div className="inrB">Guest Profile</div>
            }
          </div>
        }
      </div>
      { showEditGuestModal && renderEditGuestModal(guest, settings, engage) }
      { showAddNewLabelModal && renderAddNewLabelModal() }
      { showCheckInModal && renderCheckInModal() }
      { showCheckOutModal &&
        <GenericAlertModal
          modalType="CheckOut"
          title={`Do you want to check-out the guest (${guest.first_name}) now?`}
          bodyRowOne={checkOutModalInfo}
          bodyRowNote=""
          setModalVisible={onShowCheckOutCloseModal}
          affirmativeButtonLabel="Confirm and continue"
          onAffirmativeClick={() => onCheckOutConfirmClick(guest)}
          affirmativeSecondaryButtonLabel="Confirm & check-out others"
          onAffirmativeSecondaryClick={() => onCheckOutConfirmClick(guest, false)}
          inProgress={guest.editCheckOutInProgress}
          // currentStatus={guest.checkOutStatus}
          showCancel={true}
        />
      }
      { showDisableCheckOutMessageModal &&
        <GenericAlertModal
          modalType="DisableCheckOutMessage"
          bodyRowOne={`Do you want to ${guest.checkout_msg_disabled ? 'enable' : 'disable'} the check-out message for Guest (${guest.first_name}) ?`}
          setModalVisible={onShowDisableCheckOutMessageCloseModal}
          onAffirmativeClick={() => onDisableCheckOutMessageConfirmClick(guest)}
          inProgress={guest.editDisableCheckMsgInProgress}
          // currentStatus={guest.editDisableCheckMsgStatus}
          showCancel={true}
        />
      }
      { showOptOutModal &&
        <GenericAlertModal
          modalType="OptOut"
          bodyRowOne={`Do you want to ${guest.opt_out ? 'Opt-In' : 'Opt-Out'}  ${guest.first_name || guest.name || guest.contact} for messages?`}
          setModalVisible={onShowOptOutCloseModal}
          onAffirmativeClick={() => onOptOutConfirmClick(guest)}
          inProgress={guest.editOptOutInProgress}
          // currentStatus={guest.editOptOutStatus}
          showCancel={true}
        />
      }
      { showReplyRequiredModal &&
        <GenericAlertModal
          modalType="OptOut"
          bodyRowOne={`Do you want to ${guest.hotel_reply_required ? 'mark as Reply Not Required' : 'mark as Reply Required'} ?`}
          setModalVisible={onShowReplyRequiredCloseModal}
          onAffirmativeClick={() => onReplyRequiredConfirmClick(guest)}
          inProgress={guest.editReplyRequiredInProgress}
          // currentStatus={guest.editOptOutStatus}
          showCancel={true}
        />
      }
      { showCancelReservationModal &&
        <GenericAlertModal
          modalType="CancelReservation"
          bodyRowOne={`Do you want to cancel the reservation for Guest (${guest.first_name}) ?`}
          setModalVisible={onShowCancelReservationCloseModal}
          onAffirmativeClick={() => onCancelReservationConfirmClick(guest)}
          inProgress={guest.cancelReservationInProgress}
          // currentStatus={guest.editDisableCheckMsgStatus}
          showCancel={true}
        />
      }
      { showMarkAsStaffModal &&
        <GenericAlertModal
          modalType="MarkAsStaff"
          bodyRowOne={`Do you want to ${guest.is_staff ? 'unmark' : 'mark'}  ${getNameWithContact(guest)} as Staff?`}
          setModalVisible={onShowMarkAsStaffCloseModal}
          onAffirmativeClick={() => onMarkAsStaffConfirmClick(guest)}
          inProgress={guest.editMarkAsStaffInProgress}
          // currentStatus={guest.editMarkAsStaffStatus}
          showCancel={true}
        />
      }
      { showRemoveModal &&
        <GenericAlertModal
          modalType="Remove"
          bodyRowOne={`Do you want to remove the ${guestTypeToGenericLabel[props.guestType]} (${getNameWithContact(guest)}) ?`}
          setModalVisible={onShowRemoveCloseModal}
          onAffirmativeClick={() => onRemoveConfirmClick(guest)}
          inProgress={guest.editRemoveInProgress}
          // currentStatus={guest.editDisableCheckMsgStatus}
          showCancel={true}
        />
      }
      { showRegenerateRemoteLockModal &&
        <GenericAlertModal
          modalType="CancelReservation"
          bodyRowOne={`Do you want to ${guest.keycode ? 'regenerate' : 'generate'} the Remote Lock Key for Guest (${guest.first_name}) ?`}
          setModalVisible={onShowRegenerateRemoteLockKeyCloseModal}
          onAffirmativeClick={() => onRegenerateRemoteLockKeyConfirmClick(guest)}
          inProgress={guest.regenerateRemoteLockKeyInProgress}
          // currentStatus={guest.editDisableCheckMsgStatus}
          showCancel={true}
        />
      }
      { showRevokeRemoteLockModal && guest.keycode &&
        <GenericAlertModal
          modalType="CancelReservation"
          bodyRowOne={`Do you want to revoke the Remote Lock Key for Guest (${guest.first_name}) ?`}
          setModalVisible={onShowRevokeRemoteLockKeyCloseModal}
          onAffirmativeClick={() => onRevokeRemoteLockKeyConfirmClick(guest)}
          inProgress={guest.revokeRemoteLockKeyInProgress}
          // currentStatus={guest.editDisableCheckMsgStatus}
          showCancel={true}
        />
      }

    </div>
  );
}

export default GuestPanel;
