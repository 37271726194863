import { genericItemTypeToKeyName, formatDate, OTA_SOURCE_TO_NAME_FORMAL_MAP, FEEDBACK_SOURCE_TO_LABEL } from '../../helpers';

const download = require('downloadjs');

const csvHeaders = [
  'First name',
  'Contact number',
  'Email',
  'Number of nights',
  'Check-In date',
  'Check-Out date',
  'Visit count',
  'Total nights',
];

const csvHeadersReviews = [
  'Ota',
  'Rating',
  'Rating Out of 5',
  'Review Date',
  'Review',
];

const csvHeadersFeedbackOverview = [
  'Feedback Request Sent Date',
  'Name',
  'Contact',
  'Opened Selector Page',
  'Positive Clicked',
  'Negative Clicked',
  'Tripadvisor Clicked',
  'Google Clicked',
  'Feedback Text',
];

export const initReputation = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) {
    return state;
  }

  let reputation = state.locations[location__id] && state.locations[location__id].reputation ? { ...state.locations[location__id].reputation } : {};
  // for dashboard
  reputation = {
    ...reputation,
    dashboard: reputation.dashboard
    ? { ...reputation.dashboard }
    : {},
  };
  // for reviews
  reputation = {
    ...reputation,
    reviews: reputation.reviews
    ? { ...reputation.reviews }
    : {},
  };
  // for comp
  reputation = {
    ...reputation,
    comp: reputation.comp
    ? { ...reputation.comp }
    : {},
  };
  // for sentiments
  reputation = {
    ...reputation,
    sentiments: reputation.sentiments
    ? { ...reputation.sentiments }
    : {},
  };
  // for feedbacks
  reputation = {
    ...reputation,
    feedbacks: reputation.feedbacks
    ? {
      ...reputation.feedbacks,
      overview_data: reputation.feedbacks.overview_data ? { ...reputation.feedbacks.overview_data } : {},
      sent_data: reputation.feedbacks.sent_data ? { ...reputation.feedbacks.sent_data } : {},
      upload_history_data: reputation.feedbacks.upload_history_data ? { ...reputation.feedbacks.upload_history_data } : {},
      upload: reputation.feedbacks.upload ? { ...reputation.feedbacks.upload } : {},
    }
    : {
      overview_data: {},
      sent_data: {},
      upload_history_data: {},
      upload: {},
    },
  };
  // for guestlist
  reputation = {
    ...reputation,
    guestlist: reputation.guestlist
    ? {
      ...reputation.guestlist,
      overview_data: reputation.guestlist.overview_data ? { ...reputation.guestlist.overview_data } : {},
      data_dump: reputation.guestlist.data_dump ? { ...reputation.guestlist.data_dump } : {},
    }
    : {
      overview_data: {},
      data_dump: {},
    },
  };
  // for instant invite dashboard
  reputation = {
    ...reputation,
    instant_invite: reputation.instant_invite
    ? { ...reputation.instant_invite }
    : {},
  };
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: state.locations[location__id]
      ?
        {
          ...state.locations[location__id],
          reputation: {
            ...reputation,
          },
        }
      :
        {
          reputation: {
            ...reputation,
          }
        },
    },
  };
  return state;
};

/***************************************** Get Dashboard Data ****************************/

export const getDashboardData = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          dashboard: {
            ...state.locations[location__id].reputation.dashboard,
            getInProgress: true, getStatus: {},
          },
        },
      },
    },
  };
};

export const getDashboardDataSuccess = (state, action) => {
  const { data, params: { location__id } } = action.payload;
  if (!location__id) return state;
  let { dashboard } = data;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          dashboard: {
            // ...state.locations[location__id].reputation.dashboard,
            ...dashboard,
            getInProgress: false, getStatus: { success: true },
          },
        },
      },
    },
  };
};

export const getDashboardDataError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id } = params;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          dashboard: {
            ...state.locations[location__id].reputation.dashboard,
            getInProgress: false, getStatus: { error: true },
            error,
            errorMessage,
          },
        },
      },
    },
  };
};

/***************************************** Add Dashboard Review to Reviews ****************************/

export const addReviewToReviews = (state, action) => {
  const { params: { location__id, review } } = action.payload;
  if (!location__id || !review) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          reviews: {
            ...state.locations[location__id].reputation.reviews,
            keyValue: state.locations[location__id].reputation.reviews.keyValue
              ? {
                ...state.locations[location__id].reputation.reviews.keyValue,
                [review._id]: state.locations[location__id].reputation.reviews.keyValue[review._id]
                  ? {
                    ...state.locations[location__id].reputation.reviews.keyValue[review._id],
                  }
                  : {
                    ...review,
                  }
              }
              : {
                [review._id]: { ...review },
              }
          },
        },
      },
    },
  };
};



/* Get Reviews Data */


/***************************************** Get Reviews Data ****************************/

export const getReviewsData = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          reviews: {
            ...state.locations[location__id].reputation.reviews,
            getInProgress: true, getStatus: {},
          },
        },
      },
    },
  };
};

export const getReviewsDataSuccess = (state, action) => {
  const { data, params: { location__id } } = action.payload;
  if (!location__id) return state;
  let { reviews_data } = data;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          reviews: {
            ...state.locations[location__id].reputation.reviews,
            ...reviews_data,
            getInProgress: false, getStatus: { success: true },
          },
        },
      },
    },
  };
};

export const getReviewsDataError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id } = params;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          reviews: {
            ...state.locations[location__id].reputation.reviews,
            getInProgress: false, getStatus: { error: true },
            error,
            errorMessage,
          },
        },
      },
    },
  };
};


/***************************************** Get Reviews Dump ****************************/

export const getReviewsDump = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          reviews: {
            ...state.locations[location__id].reputation.reviews,
            getReviewsDumpInProgress: true, getReviewsDumpStatus: {},
          },
        },
      },
    },
  };
};

export const getReviewsDumpSuccess = (state, action) => {
  const { data, params } = action.payload;
  const { location__id } = params;
  if (!location__id) return state;
  let { list, csv_headers } = data;
  if (list && list.length && csv_headers && csv_headers.length) {
    let csvData = '';
    // csvData = csvHeadersReviews.join(',') + '\r\n';
    csvData = csv_headers.join(',') + '\r\n';
    list.forEach((review) => {
      if (review) {
        let entry = null;
        let cText = review.comment || '';
        if (review.comment_negative) cText = `${cText} ${review.comment_negative}`;
        cText = cText.replaceAll(',', ';');
        cText = cText.replaceAll('\n', '; ');
        cText = cText.replaceAll('\r', '; ');
        try {
          if (csv_headers[1] === 'Unit Code') {
            entry = [
              review.lmpm_location_id,
              review.unit_code,
              review.location_name,
              OTA_SOURCE_TO_NAME_FORMAL_MAP[review.source],
              review.rating,
              review.rating_out_of_5,
              formatDate(review.review_date),
              cText,
            ];
          } else {
            entry = [
              OTA_SOURCE_TO_NAME_FORMAL_MAP[review.source],
              review.rating,
              review.rating_out_of_5,
              formatDate(review.review_date),
              cText,
            ];
          }
        } catch (e) {
          //
        }
        if (entry) { csvData += entry.join(',') + '\r\n'; }
      }
    });
    if (process.env.NODE_ENV !== 'production') {
      console.log('csvData: ', csvData);
    }
    download(new Blob([csvData]), params.filename, 'text/plain');
  }

  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          reviews: {
            ...state.locations[location__id].reputation.reviews,
            getReviewsDumpInProgress: false, getReviewsDumpStatus: { success: true },
          },
        },
      },
    },
  };
};

export const getReviewsDumpError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id } = params;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          reviews: {
            ...state.locations[location__id].reputation.reviews,
            getReviewsDumpInProgress: false, getReviewsDumpStatus: { error: true },
            error,
            errorMessage,
          },
        },
      },
    },
  };
};



/* Get Comp Data */


/***************************************** Get Comp Data ****************************/

export const getCompData = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          comp: {
            ...state.locations[location__id].reputation.comp,
            getInProgress: true, getStatus: {},
          },
        },
      },
    },
  };
};

export const getCompDataSuccess = (state, action) => {
  const { data, params: { location__id } } = action.payload;
  if (!location__id) return state;
  let { comp_data } = data;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          comp: {
            ...state.locations[location__id].reputation.comp,
            ...comp_data,
            getInProgress: false, getStatus: { success: true },
          },
        },
      },
    },
  };
};

export const getCompDataError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id } = params;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          comp: {
            ...state.locations[location__id].reputation.comp,
            getInProgress: false, getStatus: { error: true },
            error,
            errorMessage,
          },
        },
      },
    },
  };
};



/***************************************** Get Sentiments Data ****************************/

export const getSentimentsData = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          sentiments: {
            ...state.locations[location__id].reputation.sentiments,
            getInProgress: true, getStatus: {},
          },
        },
      },
    },
  };
};

export const getSentimentsDataSuccess = (state, action) => {
  const { data, params: { location__id } } = action.payload;
  if (!location__id) return state;
  let { sentiments_data } = data;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          sentiments: {
            ...state.locations[location__id].reputation.sentiments,
            ...sentiments_data,
            getInProgress: false, getStatus: { success: true },
          },
        },
      },
    },
  };
};

export const getSentimentsDataError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id } = params;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          sentiments: {
            ...state.locations[location__id].reputation.sentiments,
            getInProgress: false, getStatus: { error: true },
            error,
            errorMessage,
          },
        },
      },
    },
  };
};



/***************************************** Get Feedback Overview Data ****************************/

export const getFeedbackOverviewData = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          feedbacks: {
            ...state.locations[location__id].reputation.feedbacks,
            overview_data: {
              ...state.locations[location__id].reputation.feedbacks.overview_data,
              getInProgress: true, getStatus: {},
            },
          },
        },
      },
    },
  };
};

export const getFeedbackOverviewDataSuccess = (state, action) => {
  const { data, params: { location__id } } = action.payload;
  if (!location__id) return state;
  let { feedback_overview_data } = data;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          feedbacks: {
            ...state.locations[location__id].reputation.feedbacks,
            overview_data: {
              ...state.locations[location__id].reputation.feedbacks.overview_data,
              ...feedback_overview_data,
              getInProgress: false, getStatus: { success: true },
            },
          },
        },
      },
    },
  };
};

export const getFeedbackOverviewDataError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id } = params;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          feedbacks: {
            ...state.locations[location__id].reputation.feedbacks,
            overview_data: {
              ...state.locations[location__id].reputation.feedbacks.overview_data,
              getInProgress: false, getStatus: { error: true },
              error,
              errorMessage,
            },
          },
        },
      },
    },
  };
};


/***************************************** Get Feedback Overview Dump ****************************/

export const getFeedbackOverviewDump = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          feedbacks: {
            ...state.locations[location__id].reputation.feedbacks,
            getFeedbackOverviewDumpInProgress: true, getFeedbackOverviewDumpStatus: {},
          },
        },
      },
    },
  };
};

export const getFeedbackOverviewDumpSuccess = (state, action) => {
  const { data, params } = action.payload;
  const { location__id } = params;
  if (!location__id) return state;
  let { overall, csv_headers } = data;
  if (overall && overall.list && overall.list.length && csv_headers && csv_headers.length) {
    let csvData = '';
    // csvData = csvHeadersFeedbackOverview.join(',') + '\r\n';
    csvData = csv_headers.join(',') + '\r\n';
    overall.list.forEach((feedback) => {
      if (feedback) {
        let entry = null;
        let feedbackDateSent = feedback.date_departure_message_sent || '';
        if (!feedbackDateSent && feedback.date_created) feedbackDateSent = feedback.date_created;
        if (!feedbackDateSent && feedback.check_out_feedback_email && feedback.check_out_feedback_email.date_email_sent_at) feedbackDateSent = feedback.check_out_feedback_email.date_email_sent_at;
        let name = feedback.name || '';
        if (!name) {
          if (feedback.first_name) name = feedback.first_name;
          if (feedback.last_name) name = `${name} ${feedback.last_name}`;
        }
        let cText = feedback.check_out_feedback_email && feedback.check_out_feedback_email.feedback_text ? feedback.check_out_feedback_email.feedback_text || '' : '';
        cText = cText.replaceAll(',', ';');
        cText = cText.replaceAll('\n', '; ');
        cText = cText.replaceAll('\r', '; ');
        let openedSelectorPage = feedback.check_out_feedback_email && feedback.check_out_feedback_email.date_selector_page_opened ? true : false;
        let positiveClicked = feedback.check_out_feedback_email && feedback.check_out_feedback_email.date_response_positive_clicked_at ? true : false;
        let negativeClicked = feedback.check_out_feedback_email && feedback.check_out_feedback_email.date_response_negative_clicked_at ? true : false;
        let tripadvisorClicked = feedback.check_out_feedback_email && feedback.check_out_feedback_email.date_clicked_tripadvisor ? true : false;
        let googleClicked = feedback.check_out_feedback_email && feedback.check_out_feedback_email.date_clicked_google ? true : false;
        try {
          entry = [
            feedbackDateSent ? formatDate(feedbackDateSent) : '',
            name,
            feedback.contact,
            feedback.email,
            // formatDate(review.review_date),
            openedSelectorPage,
            positiveClicked,
            negativeClicked,
            tripadvisorClicked,
            googleClicked,
            cText,
          ];
        } catch (e) {
          // console.log('csv  e: ', e);
        }
        if (entry) { csvData += entry.join(',') + '\r\n'; }
      }
    });
    if (process.env.NODE_ENV !== 'production') {
      console.log('csvData: ', csvData);
    }
    download(new Blob([csvData]), params.filename, 'text/plain');
  }

  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          feedbacks: {
            ...state.locations[location__id].reputation.feedbacks,
            getFeedbackOverviewDumpInProgress: false, getFeedbackOverviewDumpStatus: { success: true },
          },
        },
      },
    },
  };
};

export const getFeedbackOverviewDumpError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id } = params;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          feedbacks: {
            ...state.locations[location__id].reputation.feedbacks,
            getFeedbackOverviewDumpInProgress: false, getFeedbackOverviewDumpStatus: { error: true },
            error,
            errorMessage,
          },
        },
      },
    },
  };
};



/***************************************** Get Feedback Request Sent Data ****************************/

export const getFeedbackSentData = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          feedbacks: {
            ...state.locations[location__id].reputation.feedbacks,
            sent_data: {
              ...state.locations[location__id].reputation.feedbacks.sent_data,
              getInProgress: true, getStatus: {},
            },
          },
        },
      },
    },
  };
};

export const getFeedbackSentDataSuccess = (state, action) => {
  const { data, params: { location__id } } = action.payload;
  if (!location__id) return state;
  let { feedback_sent_data } = data;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          feedbacks: {
            ...state.locations[location__id].reputation.feedbacks,
            sent_data: {
              ...state.locations[location__id].reputation.feedbacks.sent_data,
              ...feedback_sent_data,
              getInProgress: false, getStatus: { success: true },
            },
          },
        },
      },
    },
  };
};

export const getFeedbackSentDataError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id } = params;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          feedbacks: {
            ...state.locations[location__id].reputation.feedbacks,
            sent_data: {
              ...state.locations[location__id].reputation.feedbacks.sent_data,
              getInProgress: false, getStatus: { error: true },
              error,
              errorMessage,
            },
          },
        },
      },
    },
  };
};



/***************************************** Get Feedback Sent Dump ****************************/

export const getFeedbackSentDump = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          feedbacks: {
            ...state.locations[location__id].reputation.feedbacks,
            getFeedbackSentDumpInProgress: true, getFeedbackSentDumpStatus: {},
          },
        },
      },
    },
  };
};

export const getFeedbackSentDumpSuccess = (state, action) => {
  const { data, params } = action.payload;
  const { location__id } = params;
  if (!location__id) return state;
  let { feedback_sent_data, csv_headers } = data;
  if (feedback_sent_data && feedback_sent_data.sent && feedback_sent_data.sent.list && feedback_sent_data.sent.list.length && csv_headers && csv_headers.length) {
    let csvData = '';
    // csvData = csvHeadersFeedbackOverview.join(',') + '\r\n';
    csvData = csv_headers.join(',') + '\r\n';
    feedback_sent_data.sent.list.forEach((feedback) => {
      if (feedback) {
        let entry = null;
        let feedbackCollectionSource = FEEDBACK_SOURCE_TO_LABEL[feedback.collection_source];
        let feedbackDateSent = feedback.date_departure_message_sent || '';
        if (!feedbackDateSent && feedback.date_created) feedbackDateSent = feedback.date_created;
        if (!feedbackDateSent && feedback.check_out_feedback_email && feedback.check_out_feedback_email.date_email_sent_at) feedbackDateSent = feedback.check_out_feedback_email.date_email_sent_at;
        let name = feedback.name || '';
        if (!name) {
          if (feedback.first_name) name = feedback.first_name;
          if (feedback.last_name) name = `${name}, ${feedback.last_name}`;
        }
        name = name.replaceAll(',', ' ');
        let cText = feedback.check_out_feedback_email && feedback.check_out_feedback_email.feedback_text ? feedback.check_out_feedback_email.feedback_text || '' : '';
        cText = cText.replaceAll(',', ';');
        cText = cText.replaceAll('\n', '; ');
        cText = cText.replaceAll('\r', '; ');
        let sentBy = feedback.created_by || '';
        let openedSelectorPage = feedback.check_out_feedback_email && feedback.check_out_feedback_email.date_selector_page_opened ? true : false;
        let positiveClicked = feedback.check_out_feedback_email && feedback.check_out_feedback_email.date_response_positive_clicked_at ? true : false;
        let negativeClicked = feedback.check_out_feedback_email && feedback.check_out_feedback_email.date_response_negative_clicked_at ? true : false;
        let tripadvisorClicked = feedback.check_out_feedback_email && feedback.check_out_feedback_email.date_clicked_tripadvisor ? true : false;
        let googleClicked = feedback.check_out_feedback_email && feedback.check_out_feedback_email.date_clicked_google ? true : false;
        try {
          entry = [
            feedbackCollectionSource,
            feedbackDateSent ? formatDate(feedbackDateSent) : '',
            feedback.type || '',
            name,
            feedback.contact,
            sentBy,
            openedSelectorPage,
            positiveClicked,
            negativeClicked,
            tripadvisorClicked,
            googleClicked,
            cText,
          ];
        } catch (e) {
          // console.log('csv  e: ', e);
        }
        if (entry) { csvData += entry.join(',') + '\r\n'; }
      }
    });
    if (process.env.NODE_ENV !== 'production') {
      console.log('csvData: ', csvData);
    }
    download(new Blob([csvData]), params.filename, 'text/plain');
  }

  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          feedbacks: {
            ...state.locations[location__id].reputation.feedbacks,
            getFeedbackSentDumpInProgress: false, getFeedbackSentDumpSuccess: { success: true },
          },
        },
      },
    },
  };
};

export const getFeedbackSentDumpError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id } = params;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          feedbacks: {
            ...state.locations[location__id].reputation.feedbacks,
            getFeedbackSentDumpInProgress: false, getFeedbackSentDumpSuccess: { error: true },
            error,
            errorMessage,
          },
        },
      },
    },
  };
};




/***************************************** Get Feedback Upload History Data ****************************/

export const getFeedbackUploadHistoryData = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          feedbacks: {
            ...state.locations[location__id].reputation.feedbacks,
            upload_history_data: {
              ...state.locations[location__id].reputation.feedbacks.upload_history_data,
              getInProgress: true, getStatus: {},
            },
          },
        },
      },
    },
  };
};

export const getFeedbackUploadHistoryDataSuccess = (state, action) => {
  const { data, params: { location__id } } = action.payload;
  if (!location__id) return state;
  let { history_data } = data;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          feedbacks: {
            ...state.locations[location__id].reputation.feedbacks,
            upload_history_data: {
              ...state.locations[location__id].reputation.feedbacks.upload_history_data,
              ...history_data,
              getInProgress: false, getStatus: { success: true },
            },
          },
        },
      },
    },
  };
};

export const getFeedbackUploadHistoryDataError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id } = params;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          feedbacks: {
            ...state.locations[location__id].reputation.feedbacks,
            upload_history_data: {
              ...state.locations[location__id].reputation.feedbacks.upload_history_data,
              getInProgress: false, getStatus: { error: true },
              error,
              errorMessage,
            },
          },
        },
      },
    },
  };
};


/***************************************** Upload Feedback Emails ****************************/

export const uploadFeedbackEmailsClear = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          feedbacks: {
            ...state.locations[location__id].reputation.feedbacks,
            upload: {
              ...state.locations[location__id].reputation.feedbacks.upload,
              uploadInProgress: false, uploadStatus: {},
            },
          },
        },
      },
    },
  };
};

export const uploadFeedbackEmails = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          feedbacks: {
            ...state.locations[location__id].reputation.feedbacks,
            upload: {
              ...state.locations[location__id].reputation.feedbacks.upload,
              uploadInProgress: true, uploadStatus: {},
            },
          },
        },
      },
    },
  };
};

export const uploadFeedbackEmailsSuccess = (state, action) => {
  const { data, params: { location__id } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          feedbacks: {
            ...state.locations[location__id].reputation.feedbacks,
            upload: {
              ...state.locations[location__id].reputation.feedbacks.upload,
              uploadInProgress: false, uploadStatus: { success: true },
            },
          },
        },
      },
    },
  };
};

export const uploadFeedbackEmailsError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id } = params;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          feedbacks: {
            ...state.locations[location__id].reputation.feedbacks,
            upload: {
              ...state.locations[location__id].reputation.feedbacks.upload,
              uploadInProgress: false, uploadStatus: { error: true },
              error,
              errorMessage,
            },
          },
        },
      },
    },
  };
};


/***************************************** Get GuestList Overview Data ****************************/

export const getGuestListOverviewData = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          guestlist: {
            ...state.locations[location__id].reputation.guestlist,
            overview_data: {
              ...state.locations[location__id].reputation.guestlist.overview_data,
              getInProgress: true, getStatus: {},
            },
          },
        },
      },
    },
  };
};

export const getGuestListOverviewDataSuccess = (state, action) => {
  const { data, params: { location__id } } = action.payload;
  if (!location__id) return state;
  let { guestlist_overview_data } = data;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          guestlist: {
            ...state.locations[location__id].reputation.guestlist,
            overview_data: {
              ...state.locations[location__id].reputation.guestlist.overview_data,
              ...guestlist_overview_data,
              getInProgress: false, getStatus: { success: true },
            },
          },
        },
      },
    },
  };
};

export const getGuestListOverviewDataError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id } = params;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          guestlist: {
            ...state.locations[location__id].reputation.guestlist,
            overview_data: {
              ...state.locations[location__id].reputation.guestlist.overview_data,
              getInProgress: false, getStatus: { error: true },
              error,
              errorMessage,
            },
          },
        },
      },
    },
  };
};



/***************************************** Get GuestList Data Dump ****************************/

export const getGuestListDump = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          guestlist: {
            ...state.locations[location__id].reputation.guestlist,
            data_dump: {
              ...state.locations[location__id].reputation.guestlist.data_dump,
              getInProgress: true, getStatus: {},
            },
          },
        },
      },
    },
  };
};

export const getGuestListDumpSuccess = (state, action) => {
  const { data, params: { location__id, filename } } = action.payload;
  if (!location__id) return state;
  const { data_dump } = data;
  if (process.env.NODE_ENV !== 'production') {
    console.log('download data_dump: ', data_dump);
  }
  if (data_dump && data_dump.length) {
    let csvData = '';
    csvData = csvHeaders.join(',') + '\r\n';
    data_dump.forEach((guest) => {
      if (guest) {
        let entry = null;
        let total_visits = 1;
        let total_nights = 0;
        if (guest.repeat_count) { total_visits = guest.repeat_count; }
        // if (guest.repeat_visits && guest.repeat_visits.length > 0) {
        //   for (let i = 0; i < guest.repeat_visits.length; i += 1) {
        //     total_nights += getDaysCount(guest.repeat_visits[i].check_in_date, guest.repeat_visits[i].check_out_date);
        //   }
        // } else {
        //   total_nights = guest.nights_stayed;
        // }
        try {
          entry = [
            guest.first_name.replace(',', ' '),
            guest.contact,
            guest.email,
            guest.nights,
            formatDate(guest.check_in_date),
            formatDate(guest.check_out_date),
            guest.repeats,
            guest.total_nights,
          ];
        } catch (e) {
          if (process.env.NODE_ENV !== 'production') {
            console.log('download e: ', e);
          }
        }
        if (entry) { csvData += entry.join(',') + '\r\n'; }
      }
    });
    if (process.env.NODE_ENV !== 'production') {
      console.log('csvData: ', csvData);
    }
    download(csvData, filename, 'text/plain');
  }
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          guestlist: {
            ...state.locations[location__id].reputation.guestlist,
            data_dump: {
              ...state.locations[location__id].reputation.guestlist.data_dump,
              // ...data_dump,
              getInProgress: false, getStatus: { success: true },
            },
          },
        },
      },
    },
  };
};

export const getGuestListDumpError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id } = params;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          guestlist: {
            ...state.locations[location__id].reputation.guestlist,
            data_dump: {
              ...state.locations[location__id].reputation.guestlist.data_dump,
              getInProgress: false, getStatus: { error: true },
              error,
              errorMessage,
            },
          },
        },
      },
    },
  };
};


/***************************************** Get Instnt Invite Dashboard Data ****************************/

export const getInstantInviteDashboardData = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          instant_invite: {
            ...state.locations[location__id].reputation.instant_invite,
            getInProgress: true, getStatus: {},
          },
        },
      },
    },
  };
};

export const getInstantInviteDashboardDataSuccess = (state, action) => {
  const { data, params: { location__id } } = action.payload;
  if (!location__id) return state;
  let { instant_invite_dashboard } = data;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          instant_invite: {
            ...state.locations[location__id].reputation.instant_invite,
            ...instant_invite_dashboard,
            getInProgress: false, getStatus: { success: true },
          },
        },
      },
    },
  };
};

export const getInstantInviteDashboardDataError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id } = params;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          instant_invite: {
            ...state.locations[location__id].reputation.instant_invite,
            getInProgress: false, getStatus: { error: true },
            error,
            errorMessage,
          },
        },
      },
    },
  };
};



/* *************** Start - Edit Review **************** */

export const editReviewClear = (state, action) => {
  const { params: { location__id, review__id, edit_type } } = action.payload;
  if (!location__id || !review__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          reviews: {
            ...state.locations[location__id].reputation.reviews,
            keyValue: {
              ...state.locations[location__id].reputation.reviews.keyValue,
              [review__id]: {
                ...state.locations[location__id].reputation.reviews.keyValue[review__id],
                editInProgress: false,
                editStatus: {},
                editApproveInProgress: edit_type === 'approve' ? false : undefined,
                editMoreInfoInProgress: edit_type === 'more-info' ? false : undefined,
                editSaveInProgress: edit_type === 'save' ? false : undefined,
                editSaveStatus: {},
                editSaveAndRespondInProgress: edit_type === 'save-and-respond' ? false : undefined,
                editSaveAndRespondStatus: {},
                editRedirectToOtaInProgress: edit_type === 'redirected-to-ota' ? false : undefined,
                editRedirectToOtaStatus: {},
                editRespondedOnOtaInProgress: edit_type === 'responded-on-ota' ? false : undefined,
                editRespondedOnOtaStatus: {},
              },
            },
          },
        },
      },
    },
  };
}

export const editReview = (state, action) => {
  const { params: { location__id, review__id, edit_type } } = action.payload;
  if (!location__id || !review__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          reviews: {
            ...state.locations[location__id].reputation.reviews,
            keyValue: {
              ...state.locations[location__id].reputation.reviews.keyValue,
              [review__id]: {
                ...state.locations[location__id].reputation.reviews.keyValue[review__id],
                editInProgress: true,
                editStatus: {},
                editApproveInProgress: edit_type === 'approve' ? true : undefined,
                editMoreInfoInProgress: edit_type === 'more-info' ? true : undefined,
                editSaveInProgress: edit_type === 'save' ? true : undefined,
                editSaveStatus: {},
                editSaveAndRespondInProgress: edit_type === 'save-and-respond' ? true : undefined,
                editSaveAndRespondStatus: {},
                editRedirectToOtaInProgress: edit_type === 'redirected-to-ota' ? true : undefined,
                editRedirectToOtaStatus: {},
                editRespondedOnOtaInProgress: edit_type === 'responded-on-ota' ? true : undefined,
                editRespondedOnOtaStatus: {},
              },
            },
          },
        },
      },
    },
  };
}

export const editReviewSuccess = (state, action) => {
  const { data, params: { location__id, review__id, edit_type } } = action.payload;
  if (!location__id || !review__id) return state;
  const { updated_review } = data;
  let dashboardData = state.locations[location__id].reputation.dashboard ? { ...state.locations[location__id].reputation.dashboard } : {};
  if (edit_type === 'approve') {
    dashboardData = {
      ...dashboardData,
      approval_pending_reviews: dashboardData.approval_pending_reviews
        ? dashboardData.approval_pending_reviews.filter(el => el._id !== review__id)
        : [],
    };
  }
  if (edit_type === 'more-info') {
    dashboardData = {
      ...dashboardData,
      need_info_reviews: dashboardData.need_info_reviews
        ? dashboardData.need_info_reviews.filter(el => el._id !== review__id)
        : [],
    };
  }
  if (edit_type === 'pending-response' || edit_type === 'responded-on-ota') {
    dashboardData = {
      ...dashboardData,
      response_pending_reviews: dashboardData.response_pending_reviews
        ? dashboardData.response_pending_reviews.filter(el => el._id !== review__id)
        : [],
    };
  }
  let reviewStateSplit = state.locations[location__id].reputation && state.locations[location__id].reputation.reviews
    && state.locations[location__id].reputation.reviews.review_state_split
    ? state.locations[location__id].reputation.reviews.review_state_split
    : {};
  if (edit_type === 'more-info') {
    if (reviewStateSplit.need_more_info) {
      reviewStateSplit = {
        ...reviewStateSplit,
        need_more_info: reviewStateSplit.need_more_info - 1,
      };
    }
  }
  if (edit_type === 'responded-on-ota') {
    if (reviewStateSplit.response_required) {
      reviewStateSplit = {
        ...reviewStateSplit,
        response_required: reviewStateSplit.response_required ? reviewStateSplit.response_required - 1 : reviewStateSplit.response_required,
        response_posted: reviewStateSplit.response_posted ? reviewStateSplit.response_posted + 1 : 1,
      };
    }
  }

  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          reviews: {
            ...state.locations[location__id].reputation.reviews,
            keyValue: {
              ...state.locations[location__id].reputation.reviews.keyValue,
              [review__id]: {
                ...state.locations[location__id].reputation.reviews.keyValue[review__id],
                ...updated_review,
                editApproveInProgress: edit_type === 'approve' ? false : undefined,
                editApproveStatus: edit_type === 'approve' ? {
                  success: true,
                } : {},
                editMoreInfoInProgress: edit_type === 'more-info' ? false : undefined,
                editMoreInfoStatus: edit_type === 'more-info' ? {
                  success: true,
                } : {},
                editSaveInProgress: edit_type === 'save' ? false : undefined,
                editSaveStatus: edit_type === 'save' ? {
                  success: true,
                } : {},
                editSaveAndRespondInProgress: edit_type === 'save-and-respond' ? false : undefined,
                editSaveAndRespondStatus: edit_type === 'save-and-respond' ? {
                  success: true,
                } : {},
                editRedirectToOtaInProgress: edit_type === 'redirected-to-ota' ? false : undefined,
                editRedirectToOtaStatus: edit_type === 'redirected-to-ota' ? {
                  success: true,
                } : {},
                editRespondedOnOtaInProgress: edit_type === 'responded-on-ota' ? false : undefined,
                editRespondedOnOtaStatus: edit_type === 'responded-on-ota' ? {
                  success: true,
                } : {},
                self_response_posted: edit_type === 'responded-on-ota'
                  ? false : state.locations[location__id].reputation.reviews.keyValue[review__id].self_response_posted,
              },
            },
            review_state_split: reviewStateSplit,
          },
          dashboard: {
            ...dashboardData,
          },
        },
      },
    },
  };
}

export const editReviewError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id, review__id, edit_type } = params;
  if (!location__id || !review__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          reviews: {
            ...state.locations[location__id].reputation.reviews,
            keyValue: {
              ...state.locations[location__id].reputation.reviews.keyValue,
              [review__id]: {
                ...state.locations[location__id].reputation.reviews.keyValue[review__id],
                editApproveInProgress: edit_type === 'approve' ? false : undefined,
                editApproveStatus: edit_type === 'approve' ? {
                  error: true,
                  message: 'Error in updating',
                } : {},
                editMoreInfoInProgress: edit_type === 'more-info' ? false : undefined,
                editMoreInfoStatus: edit_type === 'more-info' ? {
                  error: true,
                  message: 'Error in updating',
                } : {},
                editSaveInProgress: edit_type === 'save' ? false : undefined,
                editSaveStatus: edit_type === 'save' ? {
                  error: true,
                  message: 'Error in updating',
                } : {},
                editSaveAndRespondInProgress: edit_type === 'save-and-respond' ? false : undefined,
                editSaveAndRespondStatus: edit_type === 'save-and-respond' ? {
                  error: true,
                  message: 'Error in updating',
                } : {},
                editRedirectToOtaInProgress: edit_type === 'redirected-to-ota' ? false : undefined,
                editRedirectToOtaStatus: edit_type === 'redirected-to-ota' ? {
                  error: true,
                  message: 'Error in updating',
                } : {},
                editRespondedOnOtaInProgress: edit_type === 'responded-on-ota' ? false : undefined,
                editRespondedOnOtaStatus: edit_type === 'responded-on-ota' ? {
                  error: true,
                  message: 'Error in updating',
                } : {},
                error,
                errorMessage,
              },
            },
          },
        },
      },
    },
  };
}

/* *************** End - Edit Review **************** */



/* *************** Start - Update Review Translation **************** */

export const updateReviewTranslationClear = (state, action) => {
  const { params: { location__id, review__id } } = action.payload;
  if (!location__id || !review__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          reviews: {
            ...state.locations[location__id].reputation.reviews,
            keyValue: {
              ...state.locations[location__id].reputation.reviews.keyValue,
              [review__id]: {
                ...state.locations[location__id].reputation.reviews.keyValue[review__id],
                translateInProgress: false,
                translateStatus: {},
              },
            },
          },
        },
      },
    },
  };
}

export const updateReviewTranslation = (state, action) => {
  const { params: { location__id, review__id, type, text } } = action.payload;
  if (!location__id || !review__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          reviews: {
            ...state.locations[location__id].reputation.reviews,
            keyValue: {
              ...state.locations[location__id].reputation.reviews.keyValue,
              [review__id]: {
                ...state.locations[location__id].reputation.reviews.keyValue[review__id],
                translateInProgress: true,
                translateStatus: {},
                self_response: type === 'text' ? text : state.locations[location__id].reputation.reviews.keyValue[review__id].self_response,
              },
            },
          },
        },
      },
    },
  };
}

export const updateReviewTranslationSuccess = (state, action) => {
  const { data, params: { location__id, review__id, type } } = action.payload;
  if (!location__id || !review__id) return state;
  const { updated_review, self_response } = data;

  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          reviews: {
            ...state.locations[location__id].reputation.reviews,
            keyValue: {
              ...state.locations[location__id].reputation.reviews.keyValue,
              [review__id]: {
                ...state.locations[location__id].reputation.reviews.keyValue[review__id],
                ...updated_review,
                translateInProgress: false,
                translateStatus: {
                  success: true,
                },
              },
            },
          },
        },
      },
    },
  };
}

export const updateReviewTranslationError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id, review__id, type } = params;
  if (!location__id || !review__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          reviews: {
            ...state.locations[location__id].reputation.reviews,
            keyValue: {
              ...state.locations[location__id].reputation.reviews.keyValue,
              [review__id]: {
                ...state.locations[location__id].reputation.reviews.keyValue[review__id],
                translateInProgress: false,
                translateStatus: {
                  error: true,
                  message: 'Error in translation',
                },
                error,
                errorMessage,
              },
            },
          },
        },
      },
    },
  };
}

/* *************** End - Update Review Translation **************** */



/* *************** Start - Send Instant Invite Feedback Request **************** */

export const sendInstantInviteFeedbackRequestClear = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          instant_invite: {
            ...state.locations[location__id].reputation.instant_invite,
            sendInProgress: false,
            sendStatus: {},
          },
        },
      },
    },
  };
}

export const sendInstantInviteFeedbackRequest = (state, action) => {
  const { params: { location__id, medium, email, contact, template_id, msg } } = action.payload;
  if (!location__id || !medium || !template_id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          instant_invite: {
            ...state.locations[location__id].reputation.instant_invite,
            sendInProgress: true,
            sendStatus: {},
          },
        },
      },
    },
  };
}

export const sendInstantInviteFeedbackRequestSuccess = (state, action) => {
  const { data, params: { location__id, medium, email, contact, template_id, msg } } = action.payload;
  if (!location__id || !medium || !template_id) return state;
  const { feedback_request } = data;

  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          instant_invite: {
            ...state.locations[location__id].reputation.instant_invite,
            sent: state.locations[location__id].reputation.instant_invite.sent
            ? [ { ...feedback_request }, ...state.locations[location__id].reputation.instant_invite.sent ]
            : [ { ...feedback_request }],
            sendInProgress: false,
            sendStatus: {
              success: true,
            },
          },
        },
      },
    },
  };
}

export const sendInstantInviteFeedbackRequestError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id } = params;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          instant_invite: {
            ...state.locations[location__id].reputation.instant_invite,
            sendInProgress: false,
            sendStatus: {
              error: true,
              message: 'Error in sending',
            },
            error,
            errorMessage,
          },
        },
      },
    },
  };
}

/* *************** End - Send Instant Invite Feedback Request **************** */



/* *************** Start - Get Response **************** */

export const getResponseClear = (state, action) => {
  const { params: { location__id, review__id } } = action.payload;
  if (!location__id || !review__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          reviews: {
            ...state.locations[location__id].reputation.reviews,
            keyValue: {
              ...state.locations[location__id].reputation.reviews.keyValue,
              [review__id]: {
                ...state.locations[location__id].reputation.reviews.keyValue[review__id],
                getResponseInProgress: false,
                getResponseStatus: {},
              },
            },
          },
        },
      },
    },
  };
}

export const getResponse = (state, action) => {
  const { params: { location__id, review__id } } = action.payload;
  if (!location__id || !review__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          reviews: {
            ...state.locations[location__id].reputation.reviews,
            keyValue: {
              ...state.locations[location__id].reputation.reviews.keyValue,
              [review__id]: {
                ...state.locations[location__id].reputation.reviews.keyValue[review__id],
                getResponseInProgress: true,
                getResponseStatus: {},
              },
            },
          },
        },
      },
    },
  };
}

export const getResponseSuccess = (state, action) => {
  const { data, params: { location__id, review__id } } = action.payload;
  if (!location__id || !review__id) return state;
  const { generated_response } = data;

  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          reviews: {
            ...state.locations[location__id].reputation.reviews,
            keyValue: {
              ...state.locations[location__id].reputation.reviews.keyValue,
              [review__id]: {
                ...state.locations[location__id].reputation.reviews.keyValue[review__id],
                generated_response,
                getResponseInProgress: false,
                getResponseStatus: {
                  success: true,
                },
              },
            },
          },
        },
      },
    },
  };
}

export const getResponseError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id, review__id } = params;
  if (!location__id || !review__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          reviews: {
            ...state.locations[location__id].reputation.reviews,
            keyValue: {
              ...state.locations[location__id].reputation.reviews.keyValue,
              [review__id]: {
                ...state.locations[location__id].reputation.reviews.keyValue[review__id],
                getResponseInProgress: false,
                getResponseStatus: {
                  error: true,
                },
                error,
                errorMessage,
              },
            },
          },
        },
      },
    },
  };
}

/* *************** End - Get Response **************** */


/* *************** Start - Post response on google **************** */

export const postResponseOnGoogleClear = (state, action) => {
  const { params: { location__id, review__id } } = action.payload;
  if (!location__id || !review__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          reviews: {
            ...state.locations[location__id].reputation.reviews,
            keyValue: {
              ...state.locations[location__id].reputation.reviews.keyValue,
              [review__id]: {
                ...state.locations[location__id].reputation.reviews.keyValue[review__id],
                postResponseOnGoogleInProgress: false,
                postResponseOnGoogleStatus: {},
              },
            },
          },
        },
      },
    },
  };
}

export const postResponseOnGoogle = (state, action) => {
  const { params: { location__id, review__id } } = action.payload;
  if (!location__id || !review__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          reviews: {
            ...state.locations[location__id].reputation.reviews,
            keyValue: {
              ...state.locations[location__id].reputation.reviews.keyValue,
              [review__id]: {
                ...state.locations[location__id].reputation.reviews.keyValue[review__id],
                postResponseOnGoogleInProgress: true,
                postResponseOnGoogleStatus: {},
              },
            },
          },
        },
      },
    },
  };
}

export const postResponseOnGoogleSuccess = (state, action) => {
  const { data, params: { location__id, review__id, edit_type } } = action.payload;
  if (!location__id || !review__id) return state;
  const { updated_review } = data;

  let dashboardData = state.locations[location__id].reputation.dashboard ? { ...state.locations[location__id].reputation.dashboard } : {};
  if (edit_type === 'pending-response') {
    dashboardData = {
      ...dashboardData,
      response_pending_reviews: dashboardData.response_pending_reviews
        ? dashboardData.response_pending_reviews.filter(el => el._id !== review__id)
        : [],
    };
  }

  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          reviews: {
            ...state.locations[location__id].reputation.reviews,
            keyValue: {
              ...state.locations[location__id].reputation.reviews.keyValue,
              [review__id]: {
                ...state.locations[location__id].reputation.reviews.keyValue[review__id],
                ...updated_review,
                postResponseOnGoogleInProgress: false,
                postResponseOnGoogleStatus: {
                  success: true,
                },
              },
            },
          },
          dashboard: {
            ...dashboardData,
          },
        },
      },
    },
  };
}

export const postResponseOnGoogleError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id, review__id } = params;
  if (!location__id || !review__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          reviews: {
            ...state.locations[location__id].reputation.reviews,
            keyValue: {
              ...state.locations[location__id].reputation.reviews.keyValue,
              [review__id]: {
                ...state.locations[location__id].reputation.reviews.keyValue[review__id],
                postResponseOnGoogleInProgress: false,
                postResponseOnGoogleStatus: {
                  error: true,
                  message: 'Error in updating',
                },
                error,
                errorMessage,
              },
            },
          },
        },
      },
    },
  };
}

/* *************** End - Post response on google **************** */




/* *************** Start - Get Response For Feedback **************** */

export const getResponseForFeedbackClear = (state, action) => {
  const { params: { _id, location__id, feedback_task_id } } = action.payload;
  if (!_id || !location__id || !feedback_task_id) return state;
  let newList = state.locations[location__id].reputation.feedbacks &&
    state.locations[location__id].reputation.feedbacks.overview_data &&
    state.locations[location__id].reputation.feedbacks.overview_data.overall
    ? state.locations[location__id].reputation.feedbacks.overview_data.overall.list || []
    : [];
  newList.forEach((el) => {
    if (el._id === _id) {
      el.getResponseInProgress = false;
      el.getResponseStatus = {};
    }
  });
  if (process.env.NODE_ENV !== 'production') {
    console.log('getResponseForFeedbackClear newList: ', newList);
  }
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          feedbacks: {
            ...state.locations[location__id].reputation.feedbacks,
            overview_data: {
              ...state.locations[location__id].reputation.feedbacks.overview_data,
              overall: {
                ...state.locations[location__id].reputation.feedbacks.overview_data.overall,
                list: newList,
              }
            },
          },
        },
      },
    },
  };
}

export const getResponseForFeedback = (state, action) => {
  const { params: { _id, location__id, feedback_task_id } } = action.payload;
  if (!_id || !location__id || !feedback_task_id) return state;
  let newList = state.locations[location__id].reputation.feedbacks &&
    state.locations[location__id].reputation.feedbacks.overview_data &&
    state.locations[location__id].reputation.feedbacks.overview_data.overall
    ? state.locations[location__id].reputation.feedbacks.overview_data.overall.list || []
    : [];
  newList.forEach((el) => {
    if (el._id === _id) {
      el.getResponseInProgress = true;
      el.getResponseStatus = {};
    }
  });
  if (process.env.NODE_ENV !== 'production') {
    console.log('sendCustomFeedbackReply newList: ', newList);
  }
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          feedbacks: {
            ...state.locations[location__id].reputation.feedbacks,
            overview_data: {
              ...state.locations[location__id].reputation.feedbacks.overview_data,
              overall: {
                ...state.locations[location__id].reputation.feedbacks.overview_data.overall,
                list: newList,
              }
            },
          },
        },
      },
    },
  };
}

export const getResponseForFeedbackSuccess = (state, action) => {
  const { data, params: { _id, location__id, feedback_task_id } } = action.payload;
  if (!_id || !location__id || !feedback_task_id) return state;
  const { generated_feedback_reply } = data;
  let newList = state.locations[location__id].reputation.feedbacks &&
    state.locations[location__id].reputation.feedbacks.overview_data &&
    state.locations[location__id].reputation.feedbacks.overview_data.overall
    ? state.locations[location__id].reputation.feedbacks.overview_data.overall.list || []
    : [];
  newList.forEach((el) => {
    if (el._id === _id) {
      el.getResponseInProgress = false;
      el.getResponseStatus = {
        success: true,
      };
      el.generated_feedback_reply = generated_feedback_reply;
    }
  });
  if (process.env.NODE_ENV !== 'production') {
    console.log('sendCustomFeedbackReplySuccess newList: ', newList);
  }
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          feedbacks: {
            ...state.locations[location__id].reputation.feedbacks,
            overview_data: {
              ...state.locations[location__id].reputation.feedbacks.overview_data,
              overall: {
                ...state.locations[location__id].reputation.feedbacks.overview_data.overall,
                list: newList,
              }
            },
          },
        },
      },
    },
  };
}

export const getResponseForFeedbackError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { params: { _id, location__id, feedback_task_id } } = action.payload;
  if (!_id || !location__id || !feedback_task_id) return state;
  let newList = state.locations[location__id].reputation.feedbacks &&
    state.locations[location__id].reputation.feedbacks.overview_data &&
    state.locations[location__id].reputation.feedbacks.overview_data.overall
    ? state.locations[location__id].reputation.feedbacks.overview_data.overall.list || []
    : [];
  newList.forEach((el) => {
    if (el._id === _id) {
      el.getResponseInProgress = false;
      el.getResponseStatus = {
        error: true,
      };
      el.error = error;
      el.errorMessage = errorMessage;
    }
  });
  if (process.env.NODE_ENV !== 'production') {
    console.log('sendCustomFeedbackReplySuccess newList: ', newList);
  }
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          feedbacks: {
            ...state.locations[location__id].reputation.feedbacks,
            overview_data: {
              ...state.locations[location__id].reputation.feedbacks.overview_data,
              overall: {
                ...state.locations[location__id].reputation.feedbacks.overview_data.overall,
                list: newList,
              }
            },
          },
        },
      },
    },
  };
}

/* *************** End - Get Response For Feedback **************** */




/* *************** Start - Send Custom Feedback Reply **************** */

export const sendCustomFeedbackReplyClear = (state, action) => {
  const { params: { _id, location__id, feedback_task_id, reply_text } } = action.payload;
  if (!_id || !location__id || !feedback_task_id) return state;
  let newList = state.locations[location__id].reputation.feedbacks &&
    state.locations[location__id].reputation.feedbacks.overview_data &&
    state.locations[location__id].reputation.feedbacks.overview_data.overall
    ? state.locations[location__id].reputation.feedbacks.overview_data.overall.list || []
    : [];
  newList.forEach((el) => {
    if (el._id === _id) {
      el.sendReplyInProgress = false;
      el.sendReplyStatus = {};
    }
  });
  if (process.env.NODE_ENV !== 'production') {
    console.log('sendCustomFeedbackReplyClear newList: ', newList);
  }
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          feedbacks: {
            ...state.locations[location__id].reputation.feedbacks,
            overview_data: {
              ...state.locations[location__id].reputation.feedbacks.overview_data,
              overall: {
                ...state.locations[location__id].reputation.feedbacks.overview_data.overall,
                list: newList,
              }
            },
          },
        },
      },
    },
  };
}

export const sendCustomFeedbackReply = (state, action) => {
  const { params: { _id, location__id, feedback_task_id, reply_text } } = action.payload;
  if (!_id || !location__id || !feedback_task_id) return state;
  let newList = state.locations[location__id].reputation.feedbacks &&
    state.locations[location__id].reputation.feedbacks.overview_data &&
    state.locations[location__id].reputation.feedbacks.overview_data.overall
    ? state.locations[location__id].reputation.feedbacks.overview_data.overall.list || []
    : [];
  newList.forEach((el) => {
    if (el._id === _id) {
      el.sendReplyInProgress = true;
      el.sendReplyStatus = {};
    }
  });
  if (process.env.NODE_ENV !== 'production') {
    console.log('sendCustomFeedbackReply newList: ', newList);
  }
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          feedbacks: {
            ...state.locations[location__id].reputation.feedbacks,
            overview_data: {
              ...state.locations[location__id].reputation.feedbacks.overview_data,
              overall: {
                ...state.locations[location__id].reputation.feedbacks.overview_data.overall,
                list: newList,
              }
            },
          },
        },
      },
    },
  };
}

export const sendCustomFeedbackReplySuccess = (state, action) => {
  const { data, params: { _id, location__id, feedback_task_id, reply_text } } = action.payload;
  if (!_id || !location__id || !feedback_task_id) return state;
  const { check_out_feedback_email } = data;
  let newList = state.locations[location__id].reputation.feedbacks &&
    state.locations[location__id].reputation.feedbacks.overview_data &&
    state.locations[location__id].reputation.feedbacks.overview_data.overall
    ? state.locations[location__id].reputation.feedbacks.overview_data.overall.list || []
    : [];
  newList.forEach((el) => {
    if (el._id === _id) {
      el.sendReplyInProgress = false;
      el.sendReplyStatus = {
        success: true,
      };
      if (!el.check_out_feedback_email) el.check_out_feedback_email = {};
      el.check_out_feedback_email = { 
        ...el.check_out_feedback_email,
        ...check_out_feedback_email,
      };
    }
  });
  if (process.env.NODE_ENV !== 'production') {
    console.log('sendCustomFeedbackReplySuccess newList: ', newList);
  }
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          feedbacks: {
            ...state.locations[location__id].reputation.feedbacks,
            overview_data: {
              ...state.locations[location__id].reputation.feedbacks.overview_data,
              overall: {
                ...state.locations[location__id].reputation.feedbacks.overview_data.overall,
                list: newList,
              }
            },
          },
        },
      },
    },
  };
}

export const sendCustomFeedbackReplyError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { params: { _id, location__id, feedback_task_id, reply_text } } = action.payload;
  if (!_id || !location__id || !feedback_task_id) return state;
  let newList = state.locations[location__id].reputation.feedbacks &&
    state.locations[location__id].reputation.feedbacks.overview_data &&
    state.locations[location__id].reputation.feedbacks.overview_data.overall
    ? state.locations[location__id].reputation.feedbacks.overview_data.overall.list || []
    : [];
  newList.forEach((el) => {
    if (el._id === _id) {
      el.sendReplyInProgress = false;
      el.sendReplyStatus = {
        error: true,
      };
      el.error = error;
      el.errorMessage = errorMessage;
    }
  });
  if (process.env.NODE_ENV !== 'production') {
    console.log('sendCustomFeedbackReplySuccess newList: ', newList);
  }
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        reputation: {
          ...state.locations[location__id].reputation,
          feedbacks: {
            ...state.locations[location__id].reputation.feedbacks,
            overview_data: {
              ...state.locations[location__id].reputation.feedbacks.overview_data,
              overall: {
                ...state.locations[location__id].reputation.feedbacks.overview_data.overall,
                list: newList,
              }
            },
          },
        },
      },
    },
  };
}

/* *************** End - Send Custom Feedback Reply **************** */


