// @flow

import React, { Component, useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import moment from 'moment';
import Select from 'react-select';
import { Interweave } from 'interweave';

import { editDataAction, editDataClearAction } from '../../../../../../redux/actions/editDataActions';

import useOnClickOutside from '../../../../../Misc/OutsideClickHook';
import GenericAlertModal from '../../../../../Misc/GenericAlertModal';
import { STRS, getUserGAId } from '../../../../../../helpers';

import './style.css';

import closeIcon from '../../../../../../img/icons/close.svg';
import starIcon from '../../../../../../img/icons/star.png';

const gtconfig = require('../../../../../../gtconfig');

const starThresholdOptions = [];
starThresholdOptions.push({ label: '1 Star', value: '1' });
starThresholdOptions.push({ label: '2 Star', value: '2' });
starThresholdOptions.push({ label: '3 Star', value: '3' });
starThresholdOptions.push({ label: '4 Star', value: '4' });
starThresholdOptions.push({ label: '5 Star', value: '5' });


function GenericHotelDataSingle(props) {
  return (
    <div className={'gSettingsRow ' + (props.dataTypeCode || '') + (props.noBorder ? ' noBorder' : '')} onMouseEnter={props.onMEnter} onMouseLeave={props.onMLeave}>
      {
        props.title &&
        <div className="titleRow">
          <p>{props.title}</p>
        </div>
      }
      <div className="contentRow">
        <div className="contentBox">
          { props.desc && <label>{props.desc}</label> }
          <div className="gIpt">
            {
              props.type === "textarea"
              ?
                <textarea
                  className="gInput"
                  value={props.value}
                  onChange={props.onChange}
                  readOnly={props.readOnly}
                />
              :
                <input
                  className="gInput"
                  type="text"
                  value={props.value}
                  onChange={props.onChange}
                  readOnly={props.readOnly}
                />
            }
          </div>
          { props.help && <div className="help">{props.help}</div> }
          {
            props.error &&
            <span className="gErr">{props.error}</span>
          }
        </div>
      </div>
    </div>
  );
}

function CustomizeEmail(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let urlParams = useParams();
  const location = useLocation();

  const [emailSubject, setEmailSubject] = useState('');
  const [emailSubjectError, setEmailSubjectError] = useState('');
  const [highlightEmailSubject, setHighlightEmailSubject] = useState(false);

  const [guestSalutation, setGuestSalutation] = useState('');
  const [highlightGuestSalutation, setHighlightGuestSalutation] = useState(false);

  const [primaryMessage, setPrimaryMessage] = useState('');
  const [primaryMessageError, setPrimaryMessageError] = useState('');
  const [highlightPrimaryMessage, setHighlightPrimaryMessage] = useState(false);

  const [starThresholdValue, setStarThresholdValue] = useState();

  const [hotelMessage, setHotelMessage] = useState('');
  const [hotelMessageError, setHotelMessageError] = useState('');
  const [highlightHotelMessage, setHighlightHotelMessage] = useState(false);

  const [updatedValues, setUpdatedValues] = useState({});

  const [formChanged, setFormChanged] = useState(false);

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      document.title = `Reputation - Feedbacks - Customize - Email - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  useEffect(() => {
    const params = {};
    params.location__id = urlParams.locationidentifier;
    dispatch(editDataClearAction({
      op: 'edit_feedback_settings',
      params,
    }));
    setInitialValues();
  }, []);

  const setInitialValues = () => {
    const { reputation } = props.settings;
    if (!reputation || !reputation.feedback_settings) return null;
    const { feedback_settings } = reputation;
    setEmailSubject(feedback_settings.email_subject || '');
    setGuestSalutation(feedback_settings.guest_salutation || '');
    setPrimaryMessage(feedback_settings.primary_message || '');
    let tValue = { label: '4 Star', value: 4};
    if (feedback_settings.star_threshold_value) {
      tValue.label = `${feedback_settings.star_threshold_value} Star`;
      tValue.value = parseInt(feedback_settings.star_threshold_value);
    }
    setStarThresholdValue(tValue);
    setHotelMessage(feedback_settings.hotel_message || '');
  }

  const onEmailSubjectChange = (e) => {
    const { name, value } = e.target;
    setEmailSubject(value);
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      email_subject: value,
    }));
    setEmailSubjectError(value ? '' : 'Required');
    setFormChanged(true);
  };
  const onEmailSubjectMouseEnter = (e) => {
    setHighlightEmailSubject(true);
  };
  const onEmailSubjectMouseLeave = (e) => {
    setHighlightEmailSubject(false);
  };

  const onGuestSalutationChange = (e) => {
    const { name, value } = e.target;
    setGuestSalutation(value);
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      guest_salutation: value,
    }));
    // setGuestSalutationError(value ? '' : 'Required');
    setFormChanged(true);
  };
  const onGuestSalutationMouseEnter = (e) => {
    setHighlightGuestSalutation(true);
  };
  const onGuestSalutationMouseLeave = (e) => {
    setHighlightGuestSalutation(false);
  };

  const onPrimaryMessageChange = (e) => {
    const { name, value } = e.target;
    setPrimaryMessage(value);
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      primary_message: value,
    }));
    setPrimaryMessageError(value ? '' : 'Required');
    setFormChanged(true);
  };
  const onPrimaryMessageMouseEnter = (e) => {
    setHighlightPrimaryMessage(true);
  };
  const onPrimaryMessageMouseLeave = (e) => {
    setHighlightPrimaryMessage(false);
  };

  const onHotelMessageChange = (e) => {
    const { name, value } = e.target;
    setHotelMessage(value);
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      hotel_message: value,
    }));
    // setHotelMessageError(value ? '' : 'Required');
    setFormChanged(true);
  };
  const onHotelMessageMouseEnter = (e) => {
    setHighlightHotelMessage(true);
  };
  const onHotelMessageMouseLeave = (e) => {
    setHighlightHotelMessage(false);
  };
  const onStarThresholdChange = (value) => {
    setStarThresholdValue(value);
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      star_threshold_value: value.value,
    }));
    setFormChanged(true);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (formChanged) performSave();
  };

  const performSave = () => {
    const { reputation, engage } = props.settings;
    let feedbackType = reputation && reputation.feedback_settings
      ? reputation.feedback_settings.type : '';
    if (!feedbackType) return;

    let hasError = false;

    setEmailSubjectError(emailSubject ? '' : 'Required');
    if (!emailSubject) hasError = true;

    setPrimaryMessageError(primaryMessage ? '' : 'Required');
    if (!primaryMessage) hasError = true;

    // setHotelMessageError(hotelMessage ? '' : 'Required');
    // if (!hotelMessage) hasError = true;

    if (hasError) return;

    const params = {};
    params.location__id = urlParams.locationidentifier;

    if ('email_subject' in updatedValues) params['email_subject'] = updatedValues.email_subject;
    if ('guest_salutation' in updatedValues) params['guest_salutation'] = updatedValues.guest_salutation;
    if ('primary_message' in updatedValues) params['primary_message'] = updatedValues.primary_message;
    if (feedbackType === 'stars') {
      if ('star_threshold_value' in updatedValues) params['star_threshold_value'] = updatedValues.star_threshold_value;
    }
    if ('hotel_message' in updatedValues) params['hotel_message'] = updatedValues.hotel_message;

    if (process.env.NODE_ENV !== 'production') {
      console.log('performSave params: ', params);
    }
    dispatch(editDataAction({
      op: 'edit_feedback_settings',
      params,
      show_alert: true,
      alert_msg: 'Updated successfully',
      show_alert_timeout: 2000,
      show_error_alert: true,
      error_alert_msg: 'Error in updating',
    }));
    setFormChanged(false);
  };

  const renderSubject = (feedback_settings) => {
    return (
      <GenericHotelDataSingle
        dataTypeCode="emailSubject"
        title="Email Subject"
        desc=""
        value={emailSubject}
        onChange={onEmailSubjectChange}
        error={emailSubjectError}
        onMEnter={onEmailSubjectMouseEnter}
        onMLeave={onEmailSubjectMouseLeave}
      />
    );
  };
  const renderGuestSalutation = (feedback_settings) => {
    return (
      <GenericHotelDataSingle
        dataTypeCode="guestSalutation"
        title="Guest Salutation"
        desc=""
        type="textarea"
        help="Use {{name}} to dynamically replace it with Guest name"
        value={guestSalutation}
        onChange={onGuestSalutationChange}
        error={false}
        onMEnter={onGuestSalutationMouseEnter}
        onMLeave={onGuestSalutationMouseLeave}
      />
    );
  };
  const renderPrimaryMessage = (feedback_settings) => {
    return (
      <GenericHotelDataSingle
        dataTypeCode="primaryMessage"
        title="Primary Message"
        desc=""
        type="textarea"
        value={primaryMessage}
        onChange={onPrimaryMessageChange}
        error={primaryMessageError}
        onMEnter={onPrimaryMessageMouseEnter}
        onMLeave={onPrimaryMessageMouseLeave}
      />
    );
  };

  const renderStarThresholdForPositive = (feedback_settings) => {
    return (
      <div className={'gSettingsRow'}>
        <div className="titleRow">
          <p>Star threshold for taking the reviewer to positive feedback path</p>
        </div>
        <div className="contentRow">
          <div className="contentBox">
            <Select
              options={starThresholdOptions}
              onChange={onStarThresholdChange}
              value={starThresholdValue}
            />
            <div className="help">
              Stars selected lower than above value will be taken to negative feedback path
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderHotelMessage = (feedback_settings) => {
    return (
      <GenericHotelDataSingle
        dataTypeCode="hotelMessage"
        title="Secondary Message"
        desc=""
        type="textarea"
        value={hotelMessage}
        onChange={onHotelMessageChange}
        error={hotelMessageError}
        onMEnter={onHotelMessageMouseEnter}
        onMLeave={onHotelMessageMouseLeave}
      />
    );
  };



  const renderSave = (feedback_settings) => {
    if (props.isReadOnly) {
      return (
        <div className="gBCtRw rdOlyRw stndAln">
          <div className="gBtnReadOnly">{STRS.READ_ONLY_DESC}</div>
        </div>
      );
    }
    return (
      <div className="gSettingsRow gSaveButton">
        <button
          type="submit"
          className={'gBtn' + (formChanged ? '' : ' disabled')}
        >
          Save Changes
          { feedback_settings.editInProgress && <i className="fa fa-spinner fa-spin" /> }
        </button>
      </div>
    );
  };

  const renderSettings = (feedback_settings) => {
    return (
      <>
        <form onSubmit={onSubmit}>
        { renderSubject(feedback_settings) }
        { renderGuestSalutation(feedback_settings) }
        { renderPrimaryMessage(feedback_settings) }
        { renderHotelMessage(feedback_settings) }
        { feedback_settings && feedback_settings.type === 'stars' ? renderStarThresholdForPositive(feedback_settings) : null }
        { renderSave(feedback_settings) }
        </form>
      </>
    );
  };

  const renderEmailPreview = (feedback_settings, engage_settings, location_info) => {
    return (
      <div className="emailPreviewWrap">
        <p>Email Preview</p>
        {/*<div className="devicesBox">
          <span>Desktop</span>
          <span>Mobile</span>
        </div>*/}
        <div className="emailPreviewBox">
          <div className="labelValueBox fromNameBox">
            <span className="label">From Name</span>
            <span className="value">{engage_settings.hotel_name || ''}</span>
          </div>
          <div className="labelValueBox fromEmailBox">
            <span className="label">From Email</span>
            <span className="value">{
                location_info.from_email_for_feedback
                ? `${location_info.from_email_for_feedback}@guesttouch.com`
                : ''
              }
            </span>
          </div>
          <div className="labelValueBox replyToBox">
            <span className="label">Reply To</span>
            {
              gtconfig.IS_GT
              ?
                <span className="value">
                  {
                    location_info.from_email_for_feedback
                    ? `${location_info.from_email_for_feedback}@mail.guesttouch.com`
                    : ''
                  }
                </span>
              :
                <>
                  {
                    gtconfig.APP_ID === 'gt-unified-prosperhotels' &&
                    <span className="value">
                      {
                        location_info.from_email_for_feedback
                        ? `${location_info.from_email_for_feedback}@mail.prosperhotels.reviews`
                        : ''
                      }
                    </span>
                  }
                </>
            }
          </div>
          <div className="labelValueBox subjectBox">
            <span className="label">Subject</span>
            <span className={'value' + (highlightEmailSubject ? ' highlight' : '')}>{emailSubject || feedback_settings.email_subject || ''}</span>
          </div>
          <div className="emailBodyBox">
            <div className="headerBox">
              { engage_settings.hotel_logo_url && <img src={engage_settings.hotel_logo_url} /> }
              <p>{engage_settings.hotel_name || ''}</p>
            </div>
            <div className="salBox">
              <p className={'guestSalutation' + (highlightGuestSalutation ? ' highlight' : '')}><Interweave content={guestSalutation || feedback_settings.guest_salutation} /></p>
            </div>
            <div className="middleBox">
              <p className={'primaryMessage' + (highlightPrimaryMessage ? ' highlight' : '')}><Interweave content={primaryMessage || feedback_settings.primary_message} /></p>
              {
                feedback_settings.type === 'smiley' &&
                <div className="moodBox">
                  <img src={gtconfig.FEEDBACK_HAPPY} />
                  <img src={gtconfig.FEEDBACK_SAD} />
                </div>
              }
              {
                feedback_settings.type === 'stars' &&
                <div className="starBox">
                  <div className="stars">
                    <img className={'starIcon'} src={starIcon}></img>
                    <img className={'starIcon'} src={starIcon}></img>
                    <img className={'starIcon'} src={starIcon}></img>
                    <img className={'starIcon'} src={starIcon}></img>
                    <img className={'starIcon'} src={starIcon}></img>
                  </div>
                </div>
              }
              {
                feedback_settings.type === 'nps' &&
                <div className="npsBox">
                  <div className="top">
                    {
                      Array.from(Array(10).keys()).map((item) => {
                        return (
                          <div className={'npsNumber'}>{item+1}</div>
                        );
                      })
                    }
                  </div>
                  <div className="bottom">
                    <span>Not Likely</span>
                    <span>Very Likely</span>
                  </div>
                </div>
              }
            </div>
            <div className="footerBox">
              <p className={'hotelMessage' + (highlightHotelMessage ? ' highlight' : '')}><Interweave content={hotelMessage || feedback_settings.hotel_message} /></p>
              <p className="address">{engage_settings.hotel_address || ''}</p>
              <p className="contact">
                <span className="link">{engage_settings.hotel_number || ''}</span>
                <span className="link">{engage_settings.hotel_website || ''}</span>
              </p>
              <p className="unsubscribe">
                <span className="link">Unsubscribe</span> from future emails like this
              </p>
              {
                gtconfig.IS_GT &&
                <p className="poweredBy">
                  Powered by <span className="link">{gtconfig.APP_NAME_CONTACT}</span>
                </p>
              }
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderContent = (feedback_settings, engage_settings, location_info) => {
    return (
        <div className="innerBox">
          <div className="topBox">
            <div className="titleBox">
              <h4>Customize Email{props.isReadOnly && <> [{STRS.READ_ONLY_LABEL}]</>}</h4>
            </div>
          </div>
          <div className="contentBox">
            <div className="left">
              { renderSettings(feedback_settings) }
            </div>
            <div className="right">
              { renderEmailPreview(feedback_settings, engage_settings, location_info) }
            </div>
          </div>
        </div>
    )
  }

  if (process.env.NODE_ENV !== 'production') {
    console.log('CustomizeEmail props: ', props);
  }

  const { locationInfo } = props;
  const { reputation, engage } = props.settings;
  if (!reputation || !reputation.feedback_settings) return null;
  const { feedback_settings } = reputation;

  return (
    <div className="gPScrlWrp flHt mk">
      <div className="gPg fdbCustEmlPg">
        { renderContent(feedback_settings, engage || {}, locationInfo) }
      </div>
    </div>
  );
}

export default CustomizeEmail;
