import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import { SegmentedMessage } from 'sms-segments-calculator';
import moment from 'moment';
import emojiData from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import Select from 'react-select';
import Dropzone, {useDropzone} from 'react-dropzone';
import axios from 'axios';

import ToolTipWrapper from '../../../../Misc/ToolTipWrapper';
import CustomTimePicker from '../../../../Misc/CustomTimePicker';

import { MSGTYPE_TO_STR } from '../../../../../helpers';
import emojiIcon from '../../../../../img/icons/emoji.svg';

import './style.css';

const sendAfterTimes = [];
sendAfterTimes.push({ label: '30 Secs', value: '30 Secs' });
sendAfterTimes.push({ label: '2 Mins', value: '2 Mins' });
sendAfterTimes.push({ label: '10 Mins', value: '10 Mins' });
sendAfterTimes.push({ label: '30 Mins', value: '30 Mins' });
sendAfterTimes.push({ label: '60 Mins', value: '60 Mins' });
sendAfterTimes.push({ label: '24 Hrs', value: '24 Hrs' });

const sendBeforeDays = [];
sendBeforeDays.push({ label: 'Same Day', value: 'Same Day' });
sendBeforeDays.push({ label: '1 Day', value: '1 Day' });
sendBeforeDays.push({ label: '2 Days', value: '2 Days' });
sendBeforeDays.push({ label: '3 Days', value: '3 Days' });
sendBeforeDays.push({ label: '4 Days', value: '4 Days' });
sendBeforeDays.push({ label: '5 Days', value: '5 Days' });
sendBeforeDays.push({ label: '10 Days', value: '10 Days' });


function PreDefinedMessage(props) {
  const user = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [hasMessage, setHasMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [sendAfterValue, setSendAfterValue] = useState([
    { label: '30 Secs', value: '30 Secs' }
  ]);
  const [sendBeforeValue, setSendBeforeValue] = useState([
    { label: '1 Day', value: '1 Day' }
  ]);
  const [sendAt, setSendAt] = useState('');
  const [sendAtError, setSendAtError] = useState(false);

  const [approvedTemplateOptions, setApprovedTemplateOptions] = useState([]);
  const [selectedApprovedTemplate, setSelectedApprovedTemplate] = useState({});

  const messageEl = useRef(null);

  const updateTemplateSelect = () => {
    const { approved_whatsapp_templates, added_whatsapp_templates } = props.settings.engage;
    if (props.type === 'preArrivalMessage' ||
      props.type === 'welcomeMessage' ||
      props.type === 'repeatWelcomeMessage' ||
      props.type === 'departureMessage' ||
      props.type === 'automatedNonBusinessHoursMessage' ||
      props.type === 'webchatWhatsappStarterMessage' ||
      props.type === 'webchatWhatsappNbhStarterMessage'
    ) {
      let tempList = [];
      if (approved_whatsapp_templates && approved_whatsapp_templates.length) {
        let filteredList = approved_whatsapp_templates.filter(el => el.type === MSGTYPE_TO_STR[props.type]);
        if (props.type === 'welcomeMessage' ||
          props.type === 'repeatWelcomeMessage'
        ) {
          filteredList = approved_whatsapp_templates.filter(el => (el.type === MSGTYPE_TO_STR['welcomeMessage'] ||
            el.type === MSGTYPE_TO_STR['repeatWelcomeMessage']
            ));
        }
        if (filteredList.length) {
          tempList = filteredList.map((el) => {
            return { label: el.title, value: el._id };
          });
          const foundMsg = filteredList.find(el => el._id === props.messageId);
          if (foundMsg) {
            setSelectedApprovedTemplate({
              label: foundMsg.title, value: foundMsg.msg,
            });
          }
        }
      }
      if (added_whatsapp_templates && added_whatsapp_templates.list && added_whatsapp_templates.list.length &&
        added_whatsapp_templates.keyValue
      ) {
        let filteredList = added_whatsapp_templates.list.filter(el => added_whatsapp_templates.keyValue[el] &&
          added_whatsapp_templates.keyValue[el].type === MSGTYPE_TO_STR[props.type]);
        if (props.type === 'welcomeMessage' ||
          props.type === 'repeatWelcomeMessage'
        ) {
          filteredList = added_whatsapp_templates.list.filter(el => added_whatsapp_templates.keyValue[el] &&
            (added_whatsapp_templates.keyValue[el].type === MSGTYPE_TO_STR['welcomeMessage'] ||
            added_whatsapp_templates.keyValue[el].type === MSGTYPE_TO_STR['repeatWelcomeMessage']
            ));
        }
        filteredList = filteredList.map(el => added_whatsapp_templates.keyValue[el]);
        if (filteredList.length) {
          tempList = [
            ...tempList,
            ...filteredList.map((el) => {
              return { label: `[ Added - Approval pending ] ${el.title}`, value: `added-${el._id}` };
            }),
          ];
        }
      }
      tempList.push({ label: '[ Add a new template ]', value: 'add-new' });
      setApprovedTemplateOptions(tempList);
    }
  };

  useEffect(() => {
    updateTemplateSelect();
  }, [props.messageId]);

  useEffect(() => {
    if (props.settings.engage.added_whatsapp_templates &&
      props.settings.engage.added_whatsapp_templates.addStatus &&
      props.settings.engage.added_whatsapp_templates.addStatus.success
    ) {
      updateTemplateSelect();
    }
  }, [props.settings.engage.added_whatsapp_templates]);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('props.hasMessage: ', props.hasMessage, ' # ', props.type);
    }
    setHasMessage(props.hasMessage)
  }, [props.hasMessage]);

  useEffect(() => {
    setMessage(props.message);
  }, [props.message]);
  useEffect(() => {
    setSendAfterValue({ label: props.sendAfter, value: props.sendAfter });
  }, [props.sendAfter]);

  useEffect(() => {
    if (props.sendBefore === '0 Day') {
      setSendBeforeValue({ label: 'Same Day', value: 'Same Day' });
    } else {
      setSendBeforeValue({ label: props.sendBefore, value: props.sendBefore });
    }
  }, [props.sendBefore]);
  useEffect(() => {
    setSendAt(props.sendAt ? new Date(moment(props.sendAt)) : '');
  }, [props.sendAt]);



  const onHasMessageChange = (e) => {
    const { name, checked } = e.target;
    setHasMessage(checked);
    props.onHasMessageChange(checked);
  }

  const onSelectedApprovedTemplatedChange = (value) => {
    if (value.value.indexOf('added-') > -1 && props.onShowAddedTemplateClick) {
      const { added_whatsapp_templates } = props.settings.engage;
      const foundTemplate = added_whatsapp_templates.keyValue ? added_whatsapp_templates.keyValue[value.value.split('added-')[1]] : null;
      if (foundTemplate) {
        props.onShowAddedTemplateClick(foundTemplate);
      }
      return;
    }
    if (value.value === 'add-new' && props.onAddNewTemplateClick) {
      props.onAddNewTemplateClick(props.type);
      return;
    }
    setSelectedApprovedTemplate(value);
    props.onMessageIdChange(value.value);
  };

  const onSendAfterChange = (value) => {
    setSendAfterValue(value);
    props.onSendAfterChange(value);
  };
  const onSendBeforeChange = (value) => {
    setSendBeforeValue(value);
    if (value.value === 'Same Day') {
      props.onSendBeforeChange({ label: 'Same Day', value: '0 Day' });
      const checkInTime = moment(props.checkInTime, 'H:mm A');
      if (moment(sendAt).isSameOrBefore(checkInTime)) {
        setSendAtError(false);
        props.onSendAtErrorChange('');
      } else {
        setSendAtError(true);
        props.onSendAtErrorChange('error');
      }
    } else {
      props.onSendBeforeChange(value);
      setSendAtError(false);
      props.onSendAtErrorChange('');
    }
  };
  const onSendAtTimeChange = (selectedTime) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onSendAtTimeChange: ', selectedTime);
      console.log('onSendAtTimeChange checkInTime: ', props.checkInTime);
    }
    const checkInTime = moment(props.checkInTime, 'H:mm A');
    if (process.env.NODE_ENV !== 'production') {
      console.log('checkInTime: ', checkInTime.format());
      console.log('sendBeforeValue: ', sendBeforeValue);
    }
    if (sendBeforeValue.value === 'Same Day') {
      if (moment(selectedTime, 'hh:mm A').isSameOrBefore(checkInTime)) {
        setSendAtError(false);
        props.onSendAtErrorChange('');
      } else {
        setSendAtError(true);
        props.onSendAtErrorChange('error');
      }
    }
    if (process.env.NODE_ENV !== 'production') {
      console.log('onSendAtTimeChange:: ', new Date(moment(selectedTime, 'hh:mm A')));
    }
    setSendAt(selectedTime ? new Date(moment(selectedTime, 'hh:mm A')) : null);
    props.onSendAtTimeChange(moment(selectedTime, 'hh:mm A'));
  };

  const isValidSendAtDate = (current) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('isValidSendAtDate: ', props.checkInTime);
    }
    let checkInTime = moment(props.checkInTime, 'H:mm A');
    if (process.env.NODE_ENV !== 'production') {
      console.log('checkInTime: ', checkInTime.format());
    }
    return current.isBefore(moment(checkInTime));
  };

  if (process.env.NODE_ENV !== 'production') {
    console.log('PreDefinedMessage sendAt: ', sendAt);
  }

  return (
    <div className={'pDefMsg pDefWhatsapp ' + (props.className ? props.className : '')}>
      {
        props.type === 'preArrivalMessage' || props.type === 'welcomeMessage' || props.type === 'repeatWelcomeMessage' || props.type === 'departureMessage' ||
        props.type === 'webchatWhatsappStarterMessage' || props.type === 'webchatWhatsappNbhStarterMessage'
        ?
          <>
            <div className="pDRw hdr">
              {props.heading}
            </div>
            <div className="pDRw hasTgl">
              <div className="lt">
                <div className="title">
                  {props.title}
                </div>
                <div className="desc">
                  {props.desc}
                </div>
              </div>
              {
                props.type === 'preArrivalMessage' || props.type === 'welcomeMessage' || props.type === 'repeatWelcomeMessage' || props.type === 'departureMessage'
                ?
                  <div className="rt">
                    <div className="gToggleWrap">
                      <div className="gToggle">
                        <input type="checkbox" id={'hasMessage_'+props.type} checked={hasMessage} onChange={onHasMessageChange}/><label htmlFor={'hasMessage_'+props.type}>Toggle</label>
                      </div>
                    </div>
                  </div>
                : null
              }
            </div>
          </>
        : null
      }
      {
        hasMessage &&
        <>
          <div className="pDRw msgRw">
            <label className="gLbl">{props.dropdownLabel || 'Select Approved Template'}</label>
            <div className="selctBx">
              <Select
                options={approvedTemplateOptions}
                value={selectedApprovedTemplate}
                onChange={onSelectedApprovedTemplatedChange}
                className={hasMessage && !selectedApprovedTemplate.value ? 'hasError' : ''}
              />
            </div>
            <div className="gIpt">
              <textarea
                className={'gIpt ' + (hasMessage && !message ? 'error' : '')}
                // onChange={onMessageChange}
                placeholder="Message..."
                value={message}
                rows="4"
                ref={messageEl}
                readOnly
              />
            </div>
          </div>
          {
            props.type === 'preArrivalMessage'
            ?
              <div className="pDRw sndAtRw">
                <div className="tp">
                  <div className="lt">
                    <label className="gLbl">Send Before</label>
                    <Select
                      options={sendBeforeDays}
                      value={sendBeforeValue}
                      onChange={onSendBeforeChange}
                      className={hasMessage && !sendBeforeValue.value ? 'hasError' : ''}
                    />
                  </div>
                  <div className="rt">
                    <label className="gLbl">Send At</label>
                    {
                      sendAtError &&
                      <span className="sendAtError">For same day, it cannot be later than 10 AM</span>
                    }
                    <CustomTimePicker
                      value={moment(sendAt).format('hh:mm A')}
                      onChange={onSendAtTimeChange}
                      every15Min={true}
                    />
                  </div>
                </div>
                <div className="hlpMsg">
                  {props.dynamicLabel}
                </div>
              </div>
            : null
          }
          {
            props.type === 'welcomeMessage' || props.type === 'repeatWelcomeMessage' || props.type === 'departureMessage'
            ?
              <div className="pDRw sndAftRw">
                <div className="tp">
                  <div className="lt">
                    <label className="gLbl">Send After</label>
                    <Select
                      options={sendAfterTimes}
                      value={sendAfterValue}
                      onChange={onSendAfterChange}
                      className={hasMessage && !sendAfterValue.value ? 'hasError' : ''}
                    />
                  </div>
                </div>
                <div className="hlpMsg">
                  {props.dynamicLabel}
                </div>
              </div>
            : null
          }
        </>
      }
    </div>
  );
}

export default PreDefinedMessage;
