
import React, { Component, useState, useEffect, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { useSelector, useDispatch } from "react-redux";
import { withRouter, Link, Route, Switch, useParams } from 'react-router-dom';
import { Button, DatePicker } from 'antd';
import moment from 'moment';
import Select from 'react-select';
import queryString from 'query-string';
import Highlighter from "react-highlight-words";
import axios from 'axios';
import { Interweave } from 'interweave';
import { auth } from '../../../../../firebase';

// import { auth } from '../../../firebase';

import { editDataAction, editDataClearAction } from '../../../../../redux/actions/editDataActions';

import { DATE_DURATION_OPTIONS, formatDate, FEEDBACK_SOURCE_TO_LABEL, sendEmailAlert, LANGUAGE_LIST_KEYS } from '../../../../../helpers';

// import SentimentMentions from './SentimentMentions';

import useOnClickOutside from '../../../../Misc/OutsideClickHook';

import moreIcon from '../../../../../img/icons/more.svg';
import happyIcon from '../../../../../img/icons/happy.svg';
import sadIcon from '../../../../../img/icons/sad.svg';
import translateIcon from '../../../../../img/icons/translate.svg';

import './style.css';

import 'antd/dist/antd.css';

const { RangePicker } = DatePicker;

const gtconfig = require('../../../../../gtconfig');


function SingleFeedback(props) {
  const dispatch = useDispatch();
  let urlParams = useParams();

  const [replyText, setReplyText] = useState('');
  const [replyTextError, setReplyTextError] = useState('');
  const [showReplyTextBox, setShowReplyTextBox] = useState(false);
  const [showRepliedText, setShowRepliedText] = useState(false);
  const [showGuestInfo, setShowGuestInfo] = useState(false);



  const guestInfoRef = useRef();
  useOnClickOutside(guestInfoRef, useCallback(() => setShowGuestInfo(false)));

  const [languageSelectorOpen, setLanguageSelectorOpen] = useState(false);
  const [langaugeSearchTerm, setLangaugeSearchTerm] = useState('');

  const languageSelectorRef = useRef();
  useOnClickOutside(languageSelectorRef, useCallback(() => setLanguageSelectorOpen(false)));

  const [selectedTranslationLang, setSelectedTranslationLang] = useState('');
  const [showOriginal, setShowOriginal] = useState(true);
  const [translateInProgress, setTranslateInProgress] = useState(false);

  const [translatedFeedbackText, setTranslatedFeedbackText] = useState('');


  const { locationidentifier } = props;

  useEffect(() => {
    if (props.data.generated_feedback_reply) setReplyText(props.data.generated_feedback_reply);
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('SingleFeedback changed: ', props.data);
    }
    if (!props.data.getResponseInProgress && props.data.getResponseStatus && props.data.getResponseStatus.success) {
      if (props.data.generated_feedback_reply) setReplyText(props.data.generated_feedback_reply);
      dispatch(editDataClearAction({
        op: 'send_custom_feedback_reply',
        params: {
          _id: props.data._id,
          location__id: urlParams.locationidentifier,
          feedback_task_id: props.data.feedback_task_id,
        }
      }));
    }
  }, [props.data.getResponseInProgress]);

  const onReplyClick = (e) => {
    setShowReplyTextBox(true);
  };
  const onSendReplyClick = (e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onSendReplyClick replyText: ', replyText);
    }
    if (!props.data._id) return;
    if (props.data.sendReplyInProgress) return;
    if (props.data.getResponseInProgress) return;
    if (!props.data.feedback_task_id) return;
    if (!replyText) {
      setReplyTextError('Required');
      return;
    }
    setReplyTextError('');
    dispatch(editDataAction({
      op: 'send_custom_feedback_reply',
      params: {
        _id: props.data._id,
        location__id: urlParams.locationidentifier,
        feedback_task_id: props.data.feedback_task_id,
        reply_text: replyText,
      }
    }));
  };
  const onGenerateClick = (e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onGenerateClick replyText: ', replyText);
    }
    if (!props.data._id) return;
    if (props.data.getResponseInProgress) return;
    if (props.data.sendReplyInProgress) return;
    if (!props.data.feedback_task_id) return;
    dispatch(editDataAction({
      op: 'get_response_for_feedback',
      params: {
        _id: props.data._id,
        location__id: urlParams.locationidentifier,
        feedback_task_id: props.data.feedback_task_id,
      }
    }));
  };
  const onSendReplyLaterClick = (e) => {
    setShowReplyTextBox(false);
  };
  const onReplyTextChange = (e) => {
    const { name, value } = e.target;
    setReplyText(value);
    setReplyTextError(value ? '' : 'Required');
  };
  const onShowRepliedTextClick = (e) => {
    setShowRepliedText(showRepliedText => !showRepliedText);
  };
  const onGuestInfoClick = (e) => {
    setShowGuestInfo(showGuestInfo => !showGuestInfo);
  };

  const onTranslateClick = (text) => {
    getTextTranslation({
      location__id: locationidentifier,
      textArray: [text],
      destLang: 'es',
    });
  };

  const getTextTranslation = (params) => {
    try {
      auth.currentUser
        .getIdToken(false)
        .then((idToken) => {
          const url = gtconfig.DATA_BACKEND_URL;
          const opdata = {};
          // opdata.params = params;
          opdata.params = {
            ...params,
            relogin_identifier: window.gtCId,
          };
          const postData = {
            appId: gtconfig.APP_ID,
            idToken,
            op: 'get_text_translation',
            opdata,
          };
          if (process.env.NODE_ENV !== 'production') {
            console.log('getTextTranslation postData: ', postData);
          }

          const headers = {
            'Content-type': 'application/json',
            'x-api-key': gtconfig.DATA_BACKEND_URL_API_KEY,
          };
          const axiosData = {
            gtps: postData,
            y5sr50nwwsgvpy1bwmpe6gk1ht1ylfuia5kgqhmo: 'gwl4vuou8nn62igshznr5ub4oxic76gk1ht1yl3vxkosw7yid',
          };

          setTranslateInProgress(true);

          axios
            .post(url, axiosData, { headers })
            .then((response) => {
              if (process.env.NODE_ENV !== 'production') {
                console.log('getTextTranslation response: ', response.data);
              }
              setTranslateInProgress(false);
              const { status } = response.data;
              const { data } = response.data;
              if (status === 'success' && data) {
                if (process.env.NODE_ENV !== 'production') {
                  console.log('getTextTranslation data: ', data);
                }
                if (data.translated_text_array && data.translated_text_array.length) {
                  setTranslatedFeedbackText(data.translated_text_array[0]);
                  setShowOriginal(false);
                }
              } else {
                if (process.env.NODE_ENV !== 'production') {
                  console.log('getTextTranslation error: ', response);
                }
                sendEmailAlert({
                  app: gtconfig.APP_NAME,
                  subject: `${gtconfig.APP_NAME}(${gtconfig.APP_ID}) - getTextTranslation Feedback Error - ${auth.currentUser.uid}`,
                  message: JSON.stringify({ postData, responseData: response.data }),
                  error: JSON.stringify(response.data),
                });
              }
            })
            .catch((err) => {
              // axios error
              if (process.env.NODE_ENV !== 'production') {
                console.log('getTextTranslation err--------------------: ', err);
                console.log('getTextTranslation err.response --------------------: ', err.response);
              }
              sendEmailAlert({
                app: gtconfig.APP_NAME,
                subject: `${gtconfig.APP_NAME}(${gtconfig.APP_ID}) - getTextTranslation Feedback Error - axios - ${auth.currentUser.uid}`,
                message: JSON.stringify({ url, postData }),
                error: JSON.stringify({ err , config: err.config }),
              });
            })
            .catch((err) => {
            // firebase error
              if (process.env.NODE_ENV !== 'production') {
                console.log('getTextTranslation firebase err--------------------: ', err);
              }
              sendEmailAlert({
                app: gtconfig.APP_NAME,
                subject: `${gtconfig.APP_NAME}(${gtconfig.APP_ID}) - getTextTranslation Feedback Error - firebase`,
                message: 'Firebase error',
                error: err.toString(),
              });
            });
        });
    } catch (e) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('getTextTranslation e: ', e);
      }
    }
  };


  /* translate row */

  const onTranslateLanguageSelectorOpenClick = (e) => {
    if (translateInProgress) return;
    setLanguageSelectorOpen(languageSelectorOpen => !languageSelectorOpen);
  };

  const onLanguageSelectorInputChange = (e) => {
    const { name, value } = e.target;
    setLangaugeSearchTerm(value);
  };

  const onLanguageSelect = (lang, text) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onLanguageSelect: ', lang);
    }
    let translationLang = props.userState.user.user_info && props.userState.user.user_info.profile &&
    props.userState.user.user_info.profile.translation_lang ? props.userState.user.user_info.profile.translation_lang : '';
    if (process.env.NODE_ENV !== 'production') {
      console.log('onLanguageSelect translationLang: ', translationLang);
    }
    setSelectedTranslationLang(lang);
    setLanguageSelectorOpen(false);

    getTextTranslation({
      location__id: locationidentifier,
      textArray: [text],
      destLang: lang,
    });
  };

  const onTranslateToTranslationLangClick = (text) => {
    let translationLang = props.userState.user.user_info && props.userState.user.user_info.profile &&
      props.userState.user.user_info.profile.translation_lang ? props.userState.user.user_info.profile.translation_lang : '';
    if (!translationLang) return;
    setSelectedTranslationLang(translationLang);

    getTextTranslation({
      location__id: locationidentifier,
      textArray: [text],
      destLang: translationLang,
    });

  };

  const onShowOriginalClick = (e) => {
    setShowOriginal(true);
  };

  /* end - translate row */

  const renderReplyTextBox = (check_out_feedback_email, fData) => {
    if (!check_out_feedback_email.feedback_text) return null;
    if (!showReplyTextBox) {
      return (
        <div className="submitRow">
          <div className="buttonWrap">
            <div className={'gButton'} onClick={onReplyClick}>
              <span>
                Reply
              </span>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="replyTextBox">
        <div className="vBar">
        </div>
        <div className="content">
          <textarea
            value={replyText}
            onChange={onReplyTextChange}
            placeholder="Enter reply..."
          />
          {
            replyTextError && <div className="gErr">{replyTextError}</div>
          }
          <div className="submitRow">
            <div className="lt">
              <div className={'gButton ' + (!replyText ? 'inactive' : '')} onClick={onSendReplyClick}>
                <span>Send Reply
                { fData.sendReplyInProgress && <span><i className="fa fa-spinner fa-spin" /></span> }
                </span>
              </div>
              <div className={'gButton '} onClick={onSendReplyLaterClick}>
                <span>Close</span>
              </div>
            </div>
            <div className="rt">
              <div className={'gButton '} onClick={onGenerateClick}>
                <span>Generate
                { fData.getResponseInProgress && <span><i className="fa fa-spinner fa-spin" /></span> }
                </span>
              </div>
            </div>
          </div>
          <p className='rInf'>Guest name will be added in the sent email.</p>
        </div>
      </div>
    );
  };

  const renderRepliedTextBox = (check_out_feedback_email) => {
    return (
      <div className="repliedTextBox">
        <div className="vBar">
        </div>
        <div className="content">
          <div className="topRow">
            {/*<div className="yourReply">
              Your Reply
            </div>*/}
            <div className="showReply" onClick={onShowRepliedTextClick}>
              {`${showRepliedText ? 'Hide' : 'Replied, show'} reply`}
            </div>
          </div>
          {
            showRepliedText &&
            <div className="textRow">
              {check_out_feedback_email.reply_text}
            </div>
          }
          On {formatDate(check_out_feedback_email.date_reply_email_sent_at)}
        </div>
      </div>
    );
  }

  const renderGuestInfo = (guestInfo) => {
    if (guestInfo.collection_source === 'direct') return null;
    return (
      <div ref={guestInfoRef} className={'dropdown guestInfo ' + (showGuestInfo ? 'open' : '')}>
        <div className="dropbtn" onClick={onGuestInfoClick}>
          <span>Guest Details<i className="fas fa-chevron-right"></i></span>
        </div>
        <div className="dropdown-content">
          <div className="sFGuestInfoBox">
            <div className="closeRow">
              <span onClick={onGuestInfoClick}>Close</span>
            </div>
            <div className="nameBox">
              <p className="name">{guestInfo.first_name}</p>
              <p className="visits"><span>Total visits:</span> 1</p>
            </div>
            <div className="contactBox">
              <p className="email">Room Number: {guestInfo.room_number || '-'}</p>
              <p className="email">@ {guestInfo.email || '-'}</p>
              <p className="contact"><i className="fas fa-mobile-alt"></i> {guestInfo.contact || '-'}</p>
            </div>
            <div className="datesBox">
              <p className="checkInDate"><span>Check-in Date</span>{formatDate(guestInfo.check_in_date)}</p>
              <p className="checkOutDate"><span>Check-out Date</span>{formatDate(guestInfo.check_out_date)}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const renderReplyBox = (check_out_feedback_email, fData) => {
    if (!fData.feedback_task_id || !check_out_feedback_email.email_msg_id) return null;
    return (
      <div className="replyBox">
        {
          check_out_feedback_email.date_reply_email_sent_at
          ? renderRepliedTextBox(check_out_feedback_email)
          : renderReplyTextBox(check_out_feedback_email, fData)
        }
      </div>
    );
  };

  const renderTranslateToLang = (translationLang, text) => {
    return null; // nkcheck
    if (!showOriginal) return null;
    if (translationLang) {
      return (
        <div className="translateToLang" onClick={() => onTranslateToTranslationLangClick(text)}>
          <span>
            { translateInProgress ? 'Translating' : 'Translate' }
            {
              translationLang && ` to ${LANGUAGE_LIST_KEYS[translateInProgress ? selectedTranslationLang : translationLang]}`
            }
          </span>
        </div>
      );
    }
    return null;
  };

  const renderLangDropdownCta = (translationLang, text) => {
    return (
      <div className="translateIcon">
        <div className={'dropdown ' + (languageSelectorOpen ? 'open' : '')} ref={languageSelectorRef}>
          <button className="dropbtn" onClick={onTranslateLanguageSelectorOpenClick}>
            {/*
              translateInProgress
              ? <i className="fa fa-spinner fa-spin"></i>
              : <i className="fas fa-language"></i>
            */}
            {
              !showOriginal
              ?
                <span className="translatedToLang">
                  {translateInProgress ? 'Translating' : 'Translated'}
                  {` to `}
                  {LANGUAGE_LIST_KEYS[selectedTranslationLang]}
                </span>
              :
                <>
                  <span>
                    {
                      translationLang ? `${translateInProgress ? 'Translating' : 'Translate'}` : `${translateInProgress ? 'Translating' : 'Translate'}`
                    }
                  </span>
                </>
            }
          </button>
          <div className="dropdown-content gLngSlctr">
            <div className="search">
              <input type ="text" onChange={onLanguageSelectorInputChange} value={langaugeSearchTerm} placeholder="Search" />
            </div>
            <div className="langListWrap">
              {
                !langaugeSearchTerm &&
                <div className="recentlyUsedTitle">
                  Recently Used
                </div>
              }
              {
                !langaugeSearchTerm &&
                <div className="recentlyUsedList">
                  {
                    props.userState.user.user_info && props.userState.user.user_info.profile &&
                    props.userState.user.user_info.profile.recent_lang &&
                    Object.keys(props.userState.user.user_info.profile.recent_lang).length > 0
                    ?
                      Object.keys(props.userState.user.user_info.profile.recent_lang)
                        .sort((a,b) => props.userState.user.user_info.profile.recent_lang[b] - props.userState.user.user_info.profile.recent_lang[a])
                        .map((item) => {
                        const lang = LANGUAGE_LIST_KEYS[item];
                        return (
                          <p onClick={() => onLanguageSelect(item, text)}>{lang}</p>
                        )
                      })
                    : null
                  }
                </div>
              }
              <div className="allTitle">
                All
              </div>
              <div className="allList">
                {
                  Object.keys(LANGUAGE_LIST_KEYS).map((item) => {
                    const lang = LANGUAGE_LIST_KEYS[item];
                    if (langaugeSearchTerm && langaugeSearchTerm.length > 1) {
                      const reg = new RegExp(langaugeSearchTerm, "i")
                      if (
                        (lang && lang.search(reg) > -1)
                      ) {
                        return (
                          <p onClick={() => onLanguageSelect(item, text)}>{lang}</p>
                        )
                      }
                      return null;
                    }
                    return (
                      <p onClick={() => onLanguageSelect(item, text)}>{lang}</p>
                    )
                  })
                }
              </div>
            </div>
            <div className="langSettings">
              <span><Link to={`/locations/${locationidentifier}/settings/language`}>Language settings</Link></span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderTranslateRow = (text) => {
    let translationLang = props.userState.user.user_info && props.userState.user.user_info.profile &&
    props.userState.user.user_info.profile.translation_lang ? props.userState.user.user_info.profile.translation_lang : '';
    return (
      <div className="translateRow">
        <div className="translateIconDiv">
          <span><img src={translateIcon} /></span>
        </div>
        { renderTranslateToLang(translationLang, text) }
        {/*
          translationLang &&
          <div className="translateToLang" onClick={onTranslateToTranslationLangClick}>
            <span>
              Translate
              {
                translationLang && ` to ${LANGUAGE_LIST_KEYS[translationLang]}`
              }
            </span>
          </div>
        */}
        { renderLangDropdownCta(translationLang, text) }

        {
          !showOriginal &&
          <div className="viewOriginal" onClick={onShowOriginalClick}>
            Show Original
          </div>
        }
      </div>
    );
  };


  let { first_name, last_name, name, email, contact, room_number,
    check_in_date, check_out_date,
    check_out_feedback_email, date_departure_message_sent,
    collection_source, medium, 
    by_user,
  } = props.data;
  if (!check_out_feedback_email) return null;
  if (check_out_feedback_email.check_out_feedback_email) {
    ({ check_out_feedback_email } = check_out_feedback_email);
  }
  if (process.env.NODE_ENV !== 'production') {
    console.log('SingleFeedback props.data: ', props.data);
    console.log('SingleFeedback check_out_feedback_email: ', check_out_feedback_email);
  }
  if (!check_out_feedback_email) return null;
  let nameDisplay = first_name || name;
  if (nameDisplay) { nameDisplay = `${nameDisplay} ( ${email || contact || ''} )`; }
  else { nameDisplay = email || contact || ''; }
  if (process.env.NODE_ENV !== 'production') {
    console.log('SingleFeedback nameDisplay: ', nameDisplay);
  }
  const dt = check_out_feedback_email.date_feedback_text || check_out_feedback_email.date_response_negative_clicked_at || check_out_feedback_email.date_response_positive_clicked_at;
  let source = 'Email';
  if (check_out_feedback_email.entry_id) source = 'Email';
  if (date_departure_message_sent) source = 'SMS';
  if (medium === 'sms') source = 'SMS';
  if (collection_source === 'direct') source = 'Direct';
  return (
    <div className="singleEntry" id={`fo_sf_${props.data._id}`}>
      <div className="left">
        {
          check_out_feedback_email.date_response_positive_clicked_at &&
          <img src={happyIcon} />
        }
        {
          check_out_feedback_email.date_response_negative_clicked_at &&
          <img src={sadIcon} />
        }
      </div>
      <div className="right">
        <div className="guestInfo">
          <div className="left">
            <div className="name">
              {nameDisplay}
            </div>
            <div className="extra">
              <div className="date">
                On {formatDate(dt)} via
              </div>
              <div className="source">
                {source}
              </div>
            </div>
            {
              room_number &&
              <div className="roomNum">
                Room number: {room_number}
              </div>
            }
            {
              (check_out_feedback_email.clicked_google || check_out_feedback_email.clicked_tripadvisor ||
                check_out_feedback_email.clicked_holidaycheck ||
                check_out_feedback_email.clicked_booking
              ) &&
              <div className="directedTo">
                Directed to
                {
                  check_out_feedback_email.clicked_google
                  ? <span> Google<img src={gtconfig.GOOGLE_LOGO} /></span> : ''
                }
                {
                  check_out_feedback_email.clicked_tripadvisor
                  ? <span> Tripadvisor<img src={gtconfig.TRIPADVISOR_LOGO} /></span> : ''
                }
                {
                  check_out_feedback_email.clicked_holidaycheck
                  ? <span> HolidayCheck<img src={gtconfig.HOLIDAYCHECK_LOGO} /></span> : ''
                }
                {
                  check_out_feedback_email.clicked_booking
                  ? <span> Booking.com<img src={gtconfig.BOOKING_LOGO} /></span> : ''
                }
              </div>
            }
            {
              collection_source &&
              <div className="collectionSource">
                {`Via ${FEEDBACK_SOURCE_TO_LABEL[collection_source]}`}{`${by_user ? ' sent by ' + by_user : ''}`}
              </div>
            }
          </div>
          <div className="right">
            { renderGuestInfo({first_name, last_name, name, email, contact, room_number, check_in_date, check_out_date, collection_source }) }
          </div>
        </div>
        {
          check_out_feedback_email.positive_answers && Object.keys(check_out_feedback_email.positive_answers).length > 0
          ?
            <>
              {
                Object.keys(check_out_feedback_email.positive_answers).map((item) => {
                  const entry = check_out_feedback_email.positive_answers[item];
                  var starStyle = { "--rating": entry.star_rating || '' };
                  return (
                    <div className="answs pos">
                      { entry.question && entry.question.question && <p className="qstn">{entry.question.question}</p> }
                      { entry.star_rating && <p className="ans">
                        <div className="stars">
                          <div className="reviewStars" style={starStyle}></div>
                        </div>
                      </p> }
                      { entry.text_input && <p className="ans">{entry.text_input}</p> }
                    </div>
                  )
                })
              }
            </>
          : null
        }
        {
          check_out_feedback_email.negative_answers && Object.keys(check_out_feedback_email.negative_answers).length > 0
          ?
            <>
              {
                Object.keys(check_out_feedback_email.negative_answers).map((item) => {
                  const entry = check_out_feedback_email.negative_answers[item];
                  var starStyle = { "--rating": entry.star_rating || '' };
                  return (
                    <div className="answs pos">
                      { entry.question && entry.question.question && <p className="qstn">{entry.question.question}</p> }
                      { entry.star_rating && <p className="ans">
                        <div className="stars">
                          <div className="reviewStars" style={starStyle}></div>
                        </div>
                      </p> }
                      { entry.text_input && <p className="ans">{entry.text_input}</p> }
                    </div>
                  )
                })
              }
            </>
          : null
        }
        {
          showOriginal &&
          check_out_feedback_email.feedback_text &&
          <div className="feedbackText">
            {
              props.filterKeyword
              ?
                <Highlighter
                  highlightClassName="highlightClass"
                  searchWords={[props.filterKeyword]}
                  autoEscape={true}
                  textToHighlight={check_out_feedback_email.feedback_text}
                />
              :
                check_out_feedback_email.feedback_text
            }
          </div>
        }
        {/*<div className="translateFeedbackText">
          <p onClick={() => onTranslateClick(check_out_feedback_email.feedback_text)}>Translate</p>
        </div>*/}
        {
          !showOriginal &&
          translatedFeedbackText &&
          <div className="feedbackText">
            {
              <Interweave content={translatedFeedbackText} />
            }
          </div>
        }
        {
          props.hasTranslate && check_out_feedback_email.feedback_text &&
          renderTranslateRow(check_out_feedback_email.feedback_text)
        }
        { renderReplyBox(check_out_feedback_email, props.data) }
      </div>
      {
        translateInProgress &&
        <div className="translateInProgress"><span><i className="fa fa-spinner fa-spin" /></span></div>
      }
    </div>
  );
}


export default SingleFeedback;
