import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useEffect, useCallback } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Select from 'react-select';
import PhoneInput from 'react-phone-number-input';
import {useDropzone} from 'react-dropzone';
import axios from 'axios';

import { editDataAction, editDataClearAction } from '../../../../redux/actions/editDataActions';
import { validateUrl, STRS, isLocationAdminAndAbove } from '../../../../helpers';

import avataarIcon from '../../../../img/icons/avataar.svg';

import './style.css';

const gtconfig = require('../../../../gtconfig');

const DEFAULT_AUTO_REPLY = 'Hi this is an automated message, We are currently off business hours for text messaging. You may receive a reply later or during our business hours. Please reach out to front desk or our staff if we can be any help. Thank you.';

const checkInTimes = [];
checkInTimes.push({ label: '12 Noon', value: '12 Noon' });
checkInTimes.push({ label: '12:30 PM', value: '12:30 PM' });
checkInTimes.push({ label: '1:00 PM', value: '1:00 PM' });
checkInTimes.push({ label: '1:30 PM', value: '1:30 PM' });
checkInTimes.push({ label: '2:00 PM', value: '2:00 PM' });
checkInTimes.push({ label: '2:30 PM', value: '2:30 PM' });
checkInTimes.push({ label: '3:00 PM', value: '3:00 PM' });
checkInTimes.push({ label: '3:30 PM', value: '3:30 PM' });
checkInTimes.push({ label: '4:00 PM', value: '4:00 PM' });
checkInTimes.push({ label: '4:30 PM', value: '4:30 PM' });
checkInTimes.push({ label: '5:00 PM', value: '5:00 PM' });
checkInTimes.push({ label: '5:30 PM', value: '5:30 PM' });
checkInTimes.push({ label: '6:00 PM', value: '6:00 PM' });

const checkOutTimes = [];
checkOutTimes.push({ label: '10:00 AM', value: '10:00 AM' });
checkOutTimes.push({ label: '10:30 AM', value: '10:30 AM' });
checkOutTimes.push({ label: '11:00 AM', value: '11:00 AM' });
checkOutTimes.push({ label: '11:30 AM', value: '11:30 AM' });
checkOutTimes.push({ label: '12 Noon', value: '12 Noon' });
checkOutTimes.push({ label: '12:30 PM', value: '12:30 PM' });
checkOutTimes.push({ label: '1:00 PM', value: '1:00 PM' });

const breakfastStartTimes = [];
breakfastStartTimes.push({ label: '6:00 AM', value: '6:00 AM' });
breakfastStartTimes.push({ label: '6:30 AM', value: '6:30 AM' });
breakfastStartTimes.push({ label: '7:00 AM', value: '7:00 AM' });
breakfastStartTimes.push({ label: '7:30 AM', value: '7:30 AM' });
breakfastStartTimes.push({ label: '8:00 AM', value: '8:00 AM' });
breakfastStartTimes.push({ label: '8:30 AM', value: '8:30 AM' });
breakfastStartTimes.push({ label: '9:00 AM', value: '9:00 AM' });

const breakfastEndTimes = [];
breakfastEndTimes.push({ label: '9:00 AM', value: '9:00 AM' });
breakfastEndTimes.push({ label: '9:30 AM', value: '9:30 AM' });
breakfastEndTimes.push({ label: '10:00 AM', value: '10:00 AM' });
breakfastEndTimes.push({ label: '10:30 AM', value: '10:30 AM' });
breakfastEndTimes.push({ label: '11:00 AM', value: '11:00 AM' });
breakfastEndTimes.push({ label: '11:30 AM', value: '11:30 AM' });
breakfastEndTimes.push({ label: '12 Noon', value: '12 Noon' });

const escalationAfterTimes = [];
escalationAfterTimes.push({ label: '3 Mins', value: '3 Mins' });
escalationAfterTimes.push({ label: '5 Mins', value: '5 Mins' });
escalationAfterTimes.push({ label: '10 Mins', value: '10 Mins' });
escalationAfterTimes.push({ label: '15 Mins', value: '15 Mins' });
escalationAfterTimes.push({ label: '20 Mins', value: '20 Mins' });
escalationAfterTimes.push({ label: '30 Mins', value: '30 Mins' });


function GenericHotelDataSingle(props) {
  return (
    <div className={'gBCtRw ' + (props.dataTypeCode || '') + (props.noBorder ? ' noBorder' : '')}>
      <div className="lt">
        {
          props.title &&
          <div className="title">
            {props.title}
          </div>
        }
        <div className="desc">
          {props.desc}
        </div>
        <div className="gIpt">
          <input
            className="gInput"
            type="text"
            value={props.value}
            onChange={props.onChange}
            readOnly={props.readOnly}
          />
          {
            props.error &&
            <span className="error">{props.error}</span>
          }
        </div>
      </div>
    </div>
  );
}

function General(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [hotelName, setHotelName] = useState('');
  const [hotelNameError, setHotelNameError] = useState('');

  const [hotelNumber, setHotelNumber] = useState('');
  const [hotelNumberError, setHotelNumberError] = useState('');

  const [smsNumber, setSmsNumber] = useState(false);

  const [hotelAddress, setHotelAddress] = useState('');
  const [hotelAddressError, setHotelAddressError] = useState('');

  const [addressParts, setAddressParts] = useState({});
  const [addressPartsError, setAddressPartsError] = useState({});

  const [website, setWebsite] = useState('');
  const [websiteError, setWebsiteError] = useState('');

  const [checkInTimeValue, setCheckInTimeValue] = useState([
    { label: '1:00 PM', value: '1:00 PM' }
  ]);
  const [checkOutTimeValue, setCheckOutTimeValue] = useState([
    { label: '11:00 AM', value: '11:00 AM' }
  ]);

  const [hasBreakfast, setHasBreakfast] = useState(false);
  const [breakfastStartTimeValue, setBreakfastStartTimeValue] = useState([
    { label: '8:00 AM', value: '8:00 AM' }
  ]);
  const [breakfastEndTimeValue, setBreakfastEndTimeValue] = useState([
    { label: '10:00 AM', value: '10:00 AM' }
  ]);
  const [wifiCode, setWifiCode] = useState('');

  const [uploadedPath, setUploadedPath] = useState('');
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [showUploadErrorModal, setShowUploadErrorModal] = useState(false);
  const [uploadError, setUploadError] = useState('');
  const [uploadedPathError, setUploadedPathError] = useState('');

  const [hotelLogoWidth, setHotelLogoWidth] = useState('');
  const [hotelLogoHeight, setHotelLogoHeight] = useState('');
  const [hotelLogoContentType, setHotelLogoContentType] = useState('');


  const [updatedValues, setUpdatedValues] = useState({});

  const [formChanged, setFormChanged] = useState(false);

  const [automatedWhatsappMessage, setAutomatedWhatsappMessage] = useState({
    message: '',
    messageId: '',
    sendAfter: '',
  });
  const [selectedAddedWhatsappTemplate, setSelectedAddedWhatsappTemplate] = useState(null);
  const [showAddWhatsappTemplateModal, setShowAddWhatsappTemplateModal] = useState(false);
  const addTemplateModalRef = useRef();

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      document.title = `Settings - Hotel Details - General - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: userState.user.uid,
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  const setInitialValues = () => {
    const { settings: { engage } } = props;
    if (!engage) return;
    setCheckInTimeValue({ label: engage.check_in_time, value: engage.check_in_time });
    setCheckOutTimeValue({ label: engage.check_out_time, value: engage.check_out_time });
    setHasBreakfast(engage.has_breakfast || false);
    setBreakfastEndTimeValue({ label: engage.breakfast_start_time, value: engage.breakfast_start_time });
    setBreakfastEndTimeValue({ label: engage.breakfast_end_time, value: engage.breakfast_end_time });
    setWifiCode(engage.wifi_code || '');
    setHotelName(engage.hotel_name || '');
    setHotelNumber(engage.hotel_number || '');
    setSmsNumber(props.locationInfo.sms_number || '');
    setHotelAddress(engage.hotel_address || '');
    setWebsite(engage.hotel_website || '');
    const temp = {};
    temp.hotel_city = engage.hotel_city || '';
    temp.hotel_state = engage.hotel_state || '';
    temp.hotel_country = engage.hotel_country || '';
    temp.hotel_zipcode = engage.hotel_zipcode || '';
    setAddressParts({
      ...temp
    });
    setUploadedPath(engage.hotel_logo_url || '');
    setHotelNameError(engage.hotel_name ? '' : 'Required');
    setHotelNumberError(engage.hotel_number ? '' : 'Required');
    setWebsiteError(engage.hotel_website ? '' : 'Required');
    setHotelAddressError(engage.hotel_address ? '' : 'Required');
    let tempError = {};
    tempError.hotel_city = engage.hotel_city ? '' : 'Required';
    tempError.hotel_state = engage.hotel_state ? '' : 'Required';
    tempError.hotel_country = engage.hotel_country ? '' : 'Required';
    tempError.hotel_zipcode = engage.hotel_zipcode ? '' : 'Required';
    setAddressPartsError({
      ...tempError,
    });
    setUploadedPathError(engage.hotel_logo_url ? '' : 'Required');
    setFormChanged(false);
  };


  useEffect(() => {
    setInitialValues();
  }, []);

  useEffect(() => {
    if (props.settings.engage &&
      !props.settings.engage.editInProgress &&
      props.settings.engage.editStatus &&
      props.settings.engage.editStatus.success
    ) {
      setFormChanged(false);
      setUpdatedValues({});
    }
  }, [props.settings.engage]);


  const onCheckInTimeChange = (value) => {
    setCheckInTimeValue(value);
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      check_in_time: value.value,
    }));
    setFormChanged(true);
  };
  const onCheckOutTimeChange = (value) => {
    setCheckOutTimeValue(value);
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      check_out_time: value.value,
    }));
    setFormChanged(true);
  };

  const onHasBreakfastChange = (e) => {
    const { name, checked } = e.target;
    setHasBreakfast(checked);
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      has_breakfast: checked,
    }));
    setFormChanged(true);
  }
  const onBreakfastStartTimeChange = (value) => {
    setBreakfastStartTimeValue(value);
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      breakfast_start_time: value.value,
    }));
    setFormChanged(true);
  };
  const onBreakfastEndTimeChange = (value) => {
    setBreakfastEndTimeValue(value);
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      breakfast_end_time: value.value,
    }));
    setFormChanged(true);
  };

  const onWifiCodeChange = (e) => {
    const { name, value } = e.target;
    setWifiCode(value);
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      wifi_code: value,
    }));
    setFormChanged(true);
  };

  const onWebsiteChange = (e) => {
    const { name, value } = e.target;
    setWebsite(value);
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      website: value,
    }));
    if (value) {
      setWebsiteError(validateUrl(value) ? '' : 'Invalid url');
    } else {
      setWebsiteError('Required');
    }
    setFormChanged(true);
  };
  const onHotelNameChange = (e) => {
    const { name, value } = e.target;
    setHotelName(value);
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      hotel_name: value,
    }));
    setHotelNameError(value ? '' : 'Required');
    setFormChanged(true);
  };
  const onHotelNumberChange = (value) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onHotelNumberChange: ', value);
    }
    setHotelNumber(value);
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      hotel_number: value,
    }));
    setHotelNumberError(value ? '' : 'Required');
    setFormChanged(true);
  };
  const onHotelAddressChange = (e) => {
    const { name, value } = e.target;
    setHotelAddress(value);
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      hotel_address: value,
    }));
    setHotelAddressError(value ? '' : 'Required');
    setFormChanged(true);
  };
  const onAddressPartsChange = (e) => {
    const { name, value } = e.target;
    setAddressParts({
      ...addressParts,
      [`hotel_${name}`]: value,
    });
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      [`hotel_${name}`]: value,
    }));
    setAddressPartsError({
      ...addressPartsError,
      [`hotel_${name}`]: value ? '' : 'Required',
    });
    setFormChanged(true);
  };


  const onDrop = useCallback((acceptedFiles) => {

    // Do something with the files
    if (process.env.NODE_ENV !== 'production') {
      console.log('acceptedFiles: ',acceptedFiles);
      // console.log('rejectedFiles: ',rejectedFiles);
    }
    if (acceptedFiles.length===0) {
      // swal('Oops', 'The file is not valid.', 'error');
      return;
    }
    if (acceptedFiles.length === 1) {
      // setMode('dnd');
      // setManualInput('');
      acceptedFiles.forEach((file) => {

        if ( /\.(jpe?g|png)$/i.test(file.name) === false ) {
          setUploadError(`Image must be of PNG or JPG type.`);
          setShowUploadErrorModal(true);
          return false;
        }

        if (file.size >= 1024 * 1024 ) {
          setUploadError(`Image must be smaller than 1MB in size.`);
          setShowUploadErrorModal(true);
          return false;
        }

        setHotelLogoContentType(file.type);

        // setFileName(file.name);
        const reader = new FileReader();
        reader.onload = (e) => {
          const fileAsBinaryString = reader.result;
          // do whatever you want with the file content
          if (process.env.NODE_ENV !== 'production') {
            // console.log('fileAsBinaryString: ',fileAsBinaryString);
            console.log('e.target.result: ',e.target.result);
          }
          //Initiate the JavaScript Image object.
          var image = new Image();

          //Set the Base64 string return from FileReader as source.


          //Validate the File Height and Width.
          image.onload = function () {
            var height = this.height;
            var width = this.width;
            setHotelLogoWidth(width);
            setHotelLogoHeight(height);
            if (process.env.NODE_ENV !== 'production') {
              console.log('height: ', height);
              console.log('width: ', width);
            }
            // if (height !== 1024 || width != 1024) {
            //   setUploadError(`Image must be 1024px X 1024px. Tried one is ${height}px X ${width}px`);
            //   setShowUploadErrorModal(true);
            //   return false;
            // }
            uploadFile(file);
            return true;
          };
          image.src = e.target.result;
          if (fileAsBinaryString) {
            // uploadFile(file);
            if (fileAsBinaryString.indexOf(',') === -1) {
              // No commas found
              // swal('Oops', 'Not a CSV ( Comma Seperated File ).', 'warning');

              return;
            }

            // let pResult = parseTextWithEmailAndName(fileAsBinaryString, SPLIT_FOR_CSV);
            // if (process.env.NODE_ENV !== 'production') {
            //   console.log('parseResult: ', parseResult);
            // }
            // setParseResult(pResult);
            // this.parseTextWithEmailAndName(fileAsBinaryString, SPLIT_FOR_CSV);
            // this.setState(prevState => ({ dragAndDropActive: true }));

          }
        };
        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');

        reader.readAsDataURL(file);
      });
    } else {
      // swal('One at a time.', 'Please upload one file at a time.', 'warning');

    }
  }, []);
  const {getRootProps, getInputProps, isDragActive} = useDropzone({ onDrop });

  const uploadFile = (file) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('uploadFile: ', file);
    }
    setUploadedPath('');
    setUploadInProgress(true);

    let filename = file.name.replace(/\s/g,'');
    let fileExtension = 'jpg';
    let fileNameOnly = filename;
    let tArr = filename.split('.');
    if (tArr.length >= 2) {
      fileNameOnly = tArr[0];
      fileExtension = tArr[tArr.length-1];
    }
    if (process.env.NODE_ENV !== 'production') {
      console.log('fileNameOnly: ', fileNameOnly);
      console.log('fileExtension: ', fileExtension);
    }

    let postData = new FormData();
    postData.append('file', file, file.name);
    postData.append('name', filename);
    postData.append('new_name', `${fileNameOnly}.${fileExtension}`);
    // postData.append('uid', props.userprops.user.uid);
    postData.append('location_id', props.locationInfo.location_id);
    postData.append('identifier', filename);
    // let myhtl = {}, sizes = [];
    // sizes.push({ width: 400, suffix: 'll', size_identifier: 'size1' });
    // sizes.push({ width: 200, suffix: 'sll', size_identifier: 'size2' });
    // myhtl['sizes'] = sizes;
    // postData.append('myhtl', JSON.stringify(myhtl));

    // setForm(prevForm => ({
    //   ...prevForm,
    //   [`${name}UploadInProgress`]: true,
    // }));


    // //get data from database
    let url = gtconfig.IMG_UPLOAD_URL;
    const header = { 'gt-access-token' : "o4LmjvNtU15A8a2FMzikK3cNhpVXFGEK3V78zAIa", 'Content-Type': 'multipart/form-data' };
    // const headers = {
    //   'Content-type': 'multipart/form-data',
    //   'x-api-key': "o4LmjvNtU15A8a2FMzikK3cNhpVXFGEK3V78zAIa",
    // };
    if (process.env.NODE_ENV !== 'production') {
      console.log('uploadFile called api');
    }
    axios.post(url, postData, { headers: header }).then((response) => {
      // setUploadInProgress(false);
      if (process.env.NODE_ENV !== 'production') {
        console.log('uploadFile response: ', response['data']);
      }
      const { status } = response.data;
      const { data } = response.data;
      // if (status === 'success' && data && data.name && data.uploads && Array.isArray(data.uploads) && data.uploads.length) {
      if (status === 'success' && data && data.name && data.path) {
        // let foundEntry = data.uploads.find(el => el.size_identifier === 'orig');
        // if (foundEntry && foundEntry.path) {
        //   setUploadedPath(foundEntry.path);
        // }
        setUploadedPath(data.path);
        setFormChanged(true);
        setUploadedPathError('');
      } else {
      }
      setUploadInProgress(false);
    })
      .catch((err) => {
        console.log('uploadFile err--------------------: ',err);
      });

  };
  const onShowUploadErrorCloseModal = (e) => {
    setShowUploadErrorModal(false);
    setUploadError('');
    setUploadedPath('');
    setHotelLogoWidth('');
    setHotelLogoHeight('');
    setUploadInProgress(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (formChanged) performSave();
  };

  const performSave = () => {
    let hasError = false;

    if (website) {
      setWebsiteError(validateUrl(website) ? '' : 'Invalid url');
      if (!validateUrl(website)) hasError = true;
    } else {
      setWebsiteError('Required');
      hasError = true;
    }
    setHotelNameError(hotelName ? '' : 'Required');
    if (!hotelName) hasError = true;

    setHotelNumberError(hotelNumber ? '' : 'Required');
    if (!hotelNumber) hasError = true;

    setHotelAddressError(hotelAddress ? '' : 'Required');
    if (!hotelAddress) hasError = true;

    setAddressPartsError({
      'hotel_city': addressParts.hotel_city ? '' : 'Required',
      'hotel_state': addressParts.hotel_state ? '' : 'Required',
      'hotel_country': addressParts.hotel_country ? '' : 'Required',
      'hotel_zipcode': addressParts.hotel_zipcode ? '' : 'Required',
    });
    if (!addressParts.hotel_city || !addressParts.hotel_state || !addressParts.hotel_country || !addressParts.hotel_zipcode) hasError = true;

    if (hasError) return;

    const params = {
      location__id: urlParams.locationidentifier,
    };
    if ('check_in_time' in updatedValues) params['check_in_time'] = updatedValues.check_in_time;
    if ('check_out_time' in updatedValues) params['check_out_time'] = updatedValues.check_out_time;
    if ('has_breakfast' in updatedValues) params['has_breakfast'] = updatedValues.has_breakfast;
    if ('breakfast_start_time' in updatedValues) params['breakfast_start_time'] = updatedValues.breakfast_start_time;
    if ('breakfast_end_time' in updatedValues) params['breakfast_end_time'] = updatedValues.breakfast_end_time;
    if ('wifi_code' in updatedValues) params['wifi_code'] = updatedValues.wifi_code;

    if ('hotel_name' in updatedValues) params['hotel_name'] = updatedValues.hotel_name;
    if ('hotel_number' in updatedValues) params['hotel_number'] = updatedValues.hotel_number;
    if ('hotel_address' in updatedValues) params['hotel_address'] = updatedValues.hotel_address;
    if ('hotel_city' in updatedValues) params['hotel_city'] = updatedValues.hotel_city;
    if ('hotel_state' in updatedValues) params['hotel_state'] = updatedValues.hotel_state;
    if ('hotel_country' in updatedValues) params['hotel_country'] = updatedValues.hotel_country;
    if ('hotel_zipcode' in updatedValues) params['hotel_zipcode'] = updatedValues.hotel_zipcode;
    if ('website' in updatedValues) params['hotel_website'] = updatedValues.website;
    if (uploadedPath) params['hotel_logo_url'] = uploadedPath;
    if (hotelLogoWidth) params['hotel_logo_width'] = hotelLogoWidth;
    if (hotelLogoHeight) params['hotel_logo_height'] = hotelLogoHeight;
    if (hotelLogoContentType) params['hotel_logo_content_type'] = hotelLogoContentType;

    if (process.env.NODE_ENV !== 'production') {
      console.log('performSave params: ', params);
    }
    // params.source = 'engage';
    dispatch(editDataAction({
      op: 'edit_settings',
      show_alert: true,
      params,
    }));
    setFormChanged(false);
  };

  const renderCheckInOutTimes = () => {
    return (
      <>
        <div className="gBCtRw timeTitle">
          <div className="lt">
            <p className="title">Check-in and check-out time</p>
            <p className="desc">Change guests check-in and check-out time.</p>
          </div>
        </div>
        <div className="gBCtRw timeValues">
          <div className="lt">
            <label className="gSLbl">Check-In Time</label>
            <Select
              options={checkInTimes}
              onChange={onCheckInTimeChange}
              value={checkInTimeValue}
            />
          </div>
          <div className="rt">
            <label className="gSLbl">Check-Out Time</label>
            <Select
              options={checkOutTimes}
              onChange={onCheckOutTimeChange}
              value={checkOutTimeValue}
            />
          </div>
        </div>
      </>
    );
  };

  const renderHasBreakfast = () => {
    return (
      <>
        <div className={'gBCtRw hasBreakfast hasTgl' + (hasBreakfast ? ' hasTimeValues' : '')}>
          <div className="lt">
            <p className="title">Do you offer breakfast?</p>
            <p className="desc">Use the toggle to set breakfast hours.</p>
          </div>
          <div className="rt">
            <div className="gToggleWrap">
              <div className="gToggle">
                <input type="checkbox" id="hasBreakfast" checked={hasBreakfast} onChange={onHasBreakfastChange}/><label htmlFor="hasBreakfast">Toggle</label>
              </div>
            </div>
          </div>
        </div>
        {
          hasBreakfast &&
          <div className="gBCtRw timeValues">
            <div className="lt">
              <label className="gSLbl">Start Time</label>
              <Select
                options={breakfastStartTimes}
                value={breakfastStartTimeValue}
                onChange={onBreakfastStartTimeChange}
              />
            </div>
            <div className="rt">
              <div className="endTimeBox">
                <label className="gSLbl">End Time</label>
                <Select
                  options={breakfastEndTimes}
                  value={breakfastEndTimeValue}
                  onChange={onBreakfastEndTimeChange}
                />
              </div>
            </div>
          </div>
        }
      </>
    );
  };

  const renderWifiInfo = () => {
    return (
      <div className="gBCtRw wifiInfo">
        <div className="lt">
          <p className="title">Wifi information</p>
          <p className="desc">Add your WiFi password here. This will be used in messages/emails if set.</p>
          <div className="gIpt">
            <input
              type="text"
              value={wifiCode}
              onChange={onWifiCodeChange}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderHotelName = () => {
    return (
      <GenericHotelDataSingle
        dataTypeCode="hotelName"
        title="Hotel Name"
        desc="Add your hotel's name here."
        value={hotelName}
        onChange={onHotelNameChange}
        error={hotelNameError}
      />
    );
  };

  const renderHotelNumber = () => {
    return (
      <div className="gBCtRw wifiInfo">
        <div className="lt">
          <p className="title">Hotel Number</p>
          <p className="desc">Add your hotel's phone number here.</p>
          <div className={'gIpt ' + (hotelNumberError ? 'error' : '')}>
            <PhoneInput
              international
              defaultCountry="US"
              placeholder="Enter phone number"
              displayInitialValueAsLocalNumber={false}
              value={hotelNumber}
              // onCountryChange={onCountryChange}
              // ref={contactRef}
              onChange={onHotelNumberChange}/>
          </div>
        </div>
      </div>
    );
  };

  const renderEngageNumber = () => {
    return (
      <GenericHotelDataSingle
        dataTypeCode="smsNumber"
        title="Engage messaging number"
        desc="This is your texting number."
        value={smsNumber}
        readOnly={true}
      />
    );
  };

  const renderHotelWebsite = () => {
    return (
      <GenericHotelDataSingle
        dataTypeCode="hotelName"
        title="Hotel Website"
        desc="Add your hotel's website address here."
        value={website}
        onChange={onWebsiteChange}
        error={websiteError}
      />
    );
  };

  const renderHotelAddress = () => {
    return (
      <>
        <GenericHotelDataSingle
          dataTypeCode="hotelAddress"
          title="Hotel Address"
          desc="Address"
          value={hotelAddress}
          onChange={onHotelAddressChange}
          error={hotelAddressError}
          noBorder={true}
        />
      </>
    );
  };

  const renderAddressParts = () => {
    return (
      <>
        <div className="gBCtRw addressParts">
          <div className="lt">
            <label className="gLbl">City</label>
            <div className="gIpt">
              <input
                className="gInput"
                type="text"
                value={addressParts.hotel_city}
                name="city"
                onChange={onAddressPartsChange}
              />
            </div>
            {
              addressPartsError.hotel_city &&
              <span className="error">{addressPartsError.hotel_city}</span>
            }
          </div>
          <div className="rt">
            <label className="gLbl">State</label>
            <div className="gIpt">
              <input
                className="gInput"
                type="text"
                value={addressParts.hotel_state}
                name="state"
                onChange={onAddressPartsChange}
              />
            </div>
            {
              addressPartsError.hotel_state &&
              <span className="error">{addressPartsError.hotel_state}</span>
            }
          </div>
        </div>
        <div className="gBCtRw addressParts bt">
          <div className="lt">
            <label className="gLbl">Country</label>
            <div className="gIpt">
              <input
                className="gInput"
                type="text"
                value={addressParts.hotel_country}
                name="country"
                onChange={onAddressPartsChange}
              />
            </div>
            {
              addressPartsError.hotel_country &&
              <span className="error">{addressPartsError.hotel_country}</span>
            }
          </div>
          <div className="rt">
            <label className="gLbl">Zip Code</label>
            <div className="gIpt">
              <input
                className="gInput"
                type="text"
                value={addressParts.hotel_zipcode}
                name="zipcode"
                onChange={onAddressPartsChange}
              />
            </div>
            {
              addressPartsError.hotel_zipcode &&
              <span className="error">{addressPartsError.hotel_zipcode}</span>
            }
          </div>
        </div>
      </>
    );
  };

  const renderHotelBannerImg = () => {
    if (uploadedPath) return <img src={uploadedPath} />;
    if (uploadInProgress) return <span><i className="fa fa-spinner fa-spin" /></span>;
    return <img src={avataarIcon} />;
  }

  const renderHotelBanner = () => {
    return (
      <>
        <div className={'gBCtRw logoBox'}>
          <div className="lt">
            <p className="title">Hotel Logo / Banner</p>
            <p className="desc">Set an image that can be used in the email.</p>
          </div>
        </div>
        <div className={'gBCtRw logoBoxImg'}>
          <div className="lt">
            <div {...getRootProps({className: 'dndBox'})}>
              <input {...getInputProps()} />
              <div className="profileImgBox">
                { renderHotelBannerImg() }
              </div>
              <div className="info">
                <div className="top">Drag your image here or <div className="gButton"><span>Click to Upload</span></div></div>
                <p className="supportInfo">
                  Supported formats: PNG, JPG. Size must be smaller than 1MB.
                </p>
                {/*<p className="supportInfo">
                  In conversations, logos display as 1024 px diameter circles. Make sure that your logo displays well as a circle.
                </p>*/}
              </div>
            </div>
            {
              uploadedPathError &&
              <p className="error">Required</p>
            }
          </div>
        </div>
      </>
    );
  };


  if (process.env.NODE_ENV !== 'production') {
    console.log('General: ', props, ' # ', props);
  }

  return (
    <div className="gPScrlWrp hDGen flHt">
      <div className="gPWrp hDGen">
        <div className="gPTle title">
          Hotel Details{props.isReadOnly && <> [{STRS.READ_ONLY_LABEL}]</>}
        </div>
        <div className="gBCtnt">
          { renderCheckInOutTimes() }
          { renderHasBreakfast() }
          { renderWifiInfo() }
          { renderHotelName() }
          { renderHotelNumber() }
          { renderEngageNumber() }
          { renderHotelWebsite() }
          { renderHotelAddress() }
          { renderAddressParts() }
          { renderHotelBanner() }
          {
            !props.isReadOnly &&
            <>
            {
              isLocationAdminAndAbove(props.accessLevel) &&
              <div className="gBCtRw btnRw">
                <button type="submit" className={'gBtn ok ' + (formChanged ? '' : 'disabled ')} onClick={onSubmit} >
                  Save Changes
                  {
                    props.settings.editInProgress && <i className="fa fa-spinner fa-spin" />
                  }
                </button>
              </div>
            }
            </>
          }
          {
            props.isReadOnly &&
            <div className="gBCtRw rdOlyRw">
              <div className="gBtnReadOnly">{STRS.READ_ONLY_DESC}</div>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default General;
