import { genericItemTypeToKeyName } from '../../helpers';

/***************************************** Get transaction email stats ****************************/

export const getTransactionEmailStats = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        marketing: {
          ...state.locations[location__id].marketing,
          transactionEmails: {
            ...state.locations[location__id].marketing.transactionEmails,
            getStatsInProgress: true, getStatsStatus: {},
          },
        },
      },
    },
  };
};

export const getTransactionEmailStatsSuccess = (state, action) => {
  const { data, params: { location__id } } = action.payload;
  if (!location__id) return state;
  let { stats } = data;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        marketing: {
          ...state.locations[location__id].marketing,
          transactionEmails: {
            ...state.locations[location__id].marketing.transactionEmails,
            stats: stats || {},
            getStatsInProgress: false, getStatsStatus: {
              success: true,
            },
          },
        },
      },
    },
  };
};

export const getTransactionEmailStatsError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id } = params;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        marketing: {
          ...state.locations[location__id].marketing,
          transactionEmails: {
            ...state.locations[location__id].marketing.transactionEmails,
            getStatsInProgress: false, getStatsStatus: {
              error: true,
            },
          },
        },
      },
    },
  };
};


/* Get transaction emails */

export const getTransactionEmails = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        marketing: {
          ...state.locations[location__id].marketing,
          transactionEmails: {
            ...state.locations[location__id].marketing.transactionEmails,
            transaction_emails: state.locations[location__id].marketing.transactionEmails.transaction_emails
            ?
              {
                ...state.locations[location__id].marketing.transactionEmails.transaction_emails,
                getInProgress: true, getStatus: {},
              }
            : {
                getInProgress: true, getStatus: {},
              },
          },
        },
      },
    },
  };
};

export const getTransactionEmailsSuccess = (state, action) => {
  const { data, params: { location__id } } = action.payload;
  if (!location__id) return state;
  let { transaction_emails, email_templates } = data;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        marketing: {
          ...state.locations[location__id].marketing,
          transactionEmails: {
            ...state.locations[location__id].marketing.transactionEmails,
            transaction_emails: {
              ...state.locations[location__id].marketing.transactionEmails.transaction_emails,
              ...transaction_emails,
              getInProgress: false, getStatus: {
                success: true,
              },
            },
            email_templates,
          },
        },
      },
    },
  };
};

export const getTransactionEmailsError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id } = params;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        marketing: {
          ...state.locations[location__id].marketing,
          transactionEmails: {
            ...state.locations[location__id].marketing.transactionEmails,
            transaction_emails: {
              ...state.locations[location__id].marketing.transactionEmails.transaction_emails,
              getInProgress: false, getStatus: {
                error: true,
              },
              error,
              errorMessage,
            },
          },
        },
      },
    },
  };
};


/***************** Generic Transaction Emails List Item **********************/

export const editGenericTEListItemClear = (state, action, itemType) => {
  const { params: { location__id, mode, email_template_id, transaction_email_id } } = action.payload;
  if (!location__id) return state;
  let keyName = genericItemTypeToKeyName[itemType]; // transaction_emails, email_templates
  if (!keyName) return state;
  let itemId = '';

  if (mode === 'edit' || mode === 'remove') {
    if (itemType === 'transaction_email') {
      itemId = transaction_email_id;
    }
    if (itemType === 'email_template') {
      itemId = email_template_id;
    }
    if (!itemId) return state;

    let newStatus = {};
    if (mode === 'edit') { newStatus = { editInProgress: false, editStatus: {} }; }
    if (mode === 'remove') { newStatus = { removeInProgress: false, removeStatus: {} }; }

    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          marketing: {
            ...state.locations[location__id].marketing,
            transactionEmails: {
              ...state.locations[location__id].marketing.transactionEmails,
              [keyName]: {
                ...state.locations[location__id].marketing.transactionEmails[keyName],
                keyValue: {
                  ...state.locations[location__id].marketing.transactionEmails[keyName].keyValue,
                  [itemId]: {
                    ...state.locations[location__id].marketing.transactionEmails[keyName].keyValue[itemId],
                    ...newStatus,
                  },
                },
              },
            },
          },
        },
      },
    };
  }
  if (mode === 'add' || mode === 'copy') {
    let newStatus = {};
    if (mode === 'add') { newStatus = { addInProgress: false, addStatus: {} }; }
    if (mode === 'copy') { newStatus = { copyInProgress: false, copyStatus: {} }; }
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          marketing: {
            ...state.locations[location__id].marketing,
            transactionEmails: {
              ...state.locations[location__id].marketing.transactionEmails,
              [keyName]: {
                ...state.locations[location__id].marketing.transactionEmails[keyName],
                ...newStatus,
              },
            },
          },
        },
      },
    };
  }
  return state;
};

export const editGenericTEListItem = (state, action, itemType) => {
  const { params: { location__id, mode, email_template_id, transaction_email_id } } = action.payload;
  if (!location__id) return state;
  let keyName = genericItemTypeToKeyName[itemType]; // message_templates, labels
  if (!keyName) return state;
  let itemId = '';

  if (mode === 'edit' || mode === 'remove') {
    if (itemType === 'transaction_email') {
      itemId = transaction_email_id;
    }
    if (itemType === 'email_template') {
      itemId = email_template_id;
    }
    if (!itemId) return state;

    if (process.env.NODE_ENV !== 'production') {
      console.log(`editGenericTEListItem itemId: `, itemId);
    }

    let newStatus = {};
    if (mode === 'edit') { newStatus = { editInProgress: true, editStatus: {} }; }
    if (mode === 'remove') { newStatus = { removeInProgress: true, removeStatus: {} }; }

    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          marketing: {
            ...state.locations[location__id].marketing,
            transactionEmails: {
              ...state.locations[location__id].marketing.transactionEmails,
              [keyName]: {
                ...state.locations[location__id].marketing.transactionEmails[keyName],
                keyValue: {
                  ...state.locations[location__id].marketing.transactionEmails[keyName].keyValue,
                  [itemId]: {
                    ...state.locations[location__id].marketing.transactionEmails[keyName].keyValue[itemId],
                    ...newStatus,
                  },
                },
              },
            },
          },
        },
      },
    };
  }
  if (mode === 'add' || mode === 'copy') {
    let newStatus = {};
    if (mode === 'add') { newStatus = { addInProgress: true, addStatus: {} }; }
    if (mode === 'copy') { newStatus = { copyInProgress: true, copyStatus: {} }; }
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          marketing: {
            ...state.locations[location__id].marketing,
            transactionEmails: {
              ...state.locations[location__id].marketing.transactionEmails,
              [keyName]: {
                ...state.locations[location__id].marketing.transactionEmails[keyName],
                ...newStatus,
              },
            },
          },
        },
      },
    };
  }
  return state;
};

export const editGenericTEListItemSuccess = (state, action, itemType) => {
  const { data, params: { location__id, mode, transaction_email_id, email_template_id } } = action.payload;
  if (!location__id) return state;
  let keyName = genericItemTypeToKeyName[itemType]; // message_templates, labels
  if (!keyName) return state;
  let itemId = '';

  const { email_template_data, transaction_email_data } = data;
  if (mode === 'edit' || mode === 'remove') {
    let return_data = null;
    if (itemType === 'transaction_email') {
      itemId = transaction_email_id;
      return_data = transaction_email_data;
    }
    if (itemType === 'email_template') {
      itemId = email_template_id;
      return_data = email_template_data;
    }
    if (!itemId) return state;

    let newStatus = {};
    if (mode === 'edit') { newStatus = { editInProgress: false, editStatus: { success : true } }; }
    if (mode === 'remove') { newStatus = { removeInProgress: false, removeStatus: { success: true } }; }

    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          marketing: {
            ...state.locations[location__id].marketing,
            transactionEmails: {
              ...state.locations[location__id].marketing.transactionEmails,
              [keyName]: {
                ...state.locations[location__id].marketing.transactionEmails[keyName],
                list: mode === 'remove'
                ? state.locations[location__id].marketing.transactionEmails[keyName].list.filter(el => el !== itemId)
                : [ ...state.locations[location__id].marketing.transactionEmails[keyName].list ],
                keyValue: {
                  ...state.locations[location__id].marketing.transactionEmails[keyName].keyValue,
                  [itemId]: {
                    ...state.locations[location__id].marketing.transactionEmails[keyName].keyValue[itemId],
                    ...return_data,
                    ...newStatus,
                  },
                },
              },
            },
          },
        },
      },
    };
  }
  if (mode === 'add' || mode === 'copy') {
    let newStatus = {};
    let return_data = null;
    if (itemType === 'transaction_email') {
      return_data = transaction_email_data;
    }
    if (itemType === 'email_template') {
      return_data = email_template_data;
    }
    if (!return_data || !return_data._id) return state;
    if (mode === 'add') { newStatus = { addInProgress: false, addStatus: { success: true, data: { ...return_data } } }; }
    if (mode === 'copy') { newStatus = { copyInProgress: false, copyStatus: { success: true, data: { ...return_data } } }; }
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          marketing: {
            ...state.locations[location__id].marketing,
            transactionEmails: {
              ...state.locations[location__id].marketing.transactionEmails,
              [keyName]: {
                ...state.locations[location__id].marketing.transactionEmails[keyName],
                list: [return_data._id, ...state.locations[location__id].marketing.transactionEmails[keyName].list],
                keyValue: {
                  ...state.locations[location__id].marketing.transactionEmails[keyName].keyValue,
                  [return_data._id]: {
                    ...return_data,
                  },
                },
                ...newStatus,
              },
            },
          },
        },
      },
    };
  }
  return state;
};

export const editGenericTEListItemError = (state, action, itemType) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id, mode, transaction_email_id, email_template_id } = params;

  let keyName = genericItemTypeToKeyName[itemType]; // message_templates, labels
  if (!keyName) return state;
  let itemId = '';

  if (mode === 'edit' || mode === 'remove') {
    if (itemType === 'transaction_email') {
      itemId = transaction_email_id;
    }
    if (itemType === 'email_template') {
      itemId = email_template_id;
    }
    if (!itemId) return state;

    let newStatus = {};
    if (mode === 'edit') { newStatus = { editInProgress: false, editStatus: { error : true } }; }
    if (mode === 'remove') { newStatus = { removeInProgress: false, removeStatus: { error: true } }; }

    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          marketing: {
            ...state.locations[location__id].marketing,
            transactionEmails: {
              ...state.locations[location__id].marketing.transactionEmails,
              [keyName]: {
                ...state.locations[location__id].marketing.transactionEmails[keyName],
                keyValue: {
                  ...state.locations[location__id].marketing.transactionEmails[keyName].keyValue,
                  [itemId]: {
                    ...state.locations[location__id].marketing.transactionEmails[keyName].keyValue[itemId],
                    ...newStatus,
                    error,
                    errorMessage,
                  },
                },
              },
            },
          },
        },
      },
    };
  }
  if (mode === 'add' || mode === 'copy') {
    let newStatus = {};
    if (mode === 'add') { newStatus = { addInProgress: false, addStatus: { error: true } }; }
    if (mode === 'copy') { newStatus = { copyInProgress: false, copyStatus: { error: true } }; }
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          marketing: {
            ...state.locations[location__id].marketing,
            transactionEmails: {
              ...state.locations[location__id].marketing.transactionEmails,
              [keyName]: {
                ...state.locations[location__id].marketing.transactionEmails[keyName],
                ...newStatus,
                error,
                errorMessage,
              },
            },
          },
        },
      },
    };
  }
  return state;
};

/*************************** End of Generic List Item *******************************/

/* Email Template - Send Test Email */

export const emailTemplateSendTestEmailClear = (state, action) => {
  const { params: { location__id, email_template_id } } = action.payload;
  if (!location__id || !email_template_id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        marketing: {
          ...state.locations[location__id].marketing,
          transactionEmails: {
            ...state.locations[location__id].marketing.transactionEmails,
            email_templates: {
              ...state.locations[location__id].marketing.transactionEmails.email_templates,
              keyValue: {
                ...state.locations[location__id].marketing.transactionEmails.email_templates.keyValue,
                [email_template_id]: {
                  ...state.locations[location__id].marketing.transactionEmails.email_templates.keyValue[email_template_id],
                  sendTestEmailInProgress: false,
                  sendTestEmailStatus: {},
                },
              },
            },
          },
        },
      },
    },
  };
}

export const emailTemplateSendTestEmail = (state, action) => {
  const { params: { location__id, email_template_id } } = action.payload;
  if (!location__id || !email_template_id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        marketing: {
          ...state.locations[location__id].marketing,
          transactionEmails: {
            ...state.locations[location__id].marketing.transactionEmails,
            email_templates: {
              ...state.locations[location__id].marketing.transactionEmails.email_templates,
              keyValue: {
                ...state.locations[location__id].marketing.transactionEmails.email_templates.keyValue,
                [email_template_id]: {
                  ...state.locations[location__id].marketing.transactionEmails.email_templates.keyValue[email_template_id],
                  sendTestEmailInProgress: true,
                  sendTestEmailStatus: {},
                },
              },
            },
          },
        },
      },
    },
  };
}

export const emailTemplateSendTestEmailSuccess = (state, action) => {
  const { params: { location__id, email_template_id } } = action.payload;
  if (!location__id || !email_template_id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        marketing: {
          ...state.locations[location__id].marketing,
          transactionEmails: {
            ...state.locations[location__id].marketing.transactionEmails,
            email_templates: {
              ...state.locations[location__id].marketing.transactionEmails.email_templates,
              keyValue: {
                ...state.locations[location__id].marketing.transactionEmails.email_templates.keyValue,
                [email_template_id]: {
                  ...state.locations[location__id].marketing.transactionEmails.email_templates.keyValue[email_template_id],
                  sendTestEmailInProgress: false,
                  sendTestEmailStatus: {
                    success: true,
                  },
                },
              },
            },
          },
        },
      },
    },
  };
}

export const emailTemplateSendTestEmailError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id, email_template_id } = params;
  if (!location__id || !email_template_id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        marketing: {
          ...state.locations[location__id].marketing,
          transactionEmails: {
            ...state.locations[location__id].marketing.transactionEmails,
            email_templates: {
              ...state.locations[location__id].marketing.transactionEmails.email_templates,
              keyValue: {
                ...state.locations[location__id].marketing.transactionEmails.email_templates.keyValue,
                [email_template_id]: {
                  ...state.locations[location__id].marketing.transactionEmails.email_templates.keyValue[email_template_id],
                  sendTestEmailInProgress: false,
                  sendTestEmailStatus: {
                    error: true,
                  },
                  error,
                  errorMessage,
                },
              },
            },
          },
        },
      },
    },
  };
}


/* Get transaction emails history */

export const getTransactionEmailsHistory = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        marketing: {
          ...state.locations[location__id].marketing,
          transactionEmails: {
            ...state.locations[location__id].marketing.transactionEmails,
            history: state.locations[location__id].marketing.transactionEmails.history
            ?
              {
                ...state.locations[location__id].marketing.transactionEmails.history,
                getInProgress: true, getStatus: {},
              }
            : {
                getInProgress: true, getStatus: {},
              },
          },
        },
      },
    },
  };
};

export const getTransactionEmailsHistorySuccess = (state, action) => {
  const { data, params: { location__id } } = action.payload;
  if (!location__id) return state;
  let { transaction_email_history, total_count } = data;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        marketing: {
          ...state.locations[location__id].marketing,
          transactionEmails: {
            ...state.locations[location__id].marketing.transactionEmails,
            history: {
              ...state.locations[location__id].marketing.transactionEmails.history,
              ...transaction_email_history,
              getInProgress: false, getStatus: {
                success: true,
              },
              total_count,
            },
          },
        },
      },
    },
  };
};

export const getTransactionEmailsHistoryError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id } = params;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        marketing: {
          ...state.locations[location__id].marketing,
          transactionEmails: {
            ...state.locations[location__id].marketing.transactionEmails,
            history: {
              ...state.locations[location__id].marketing.transactionEmails.history,
              getInProgress: false, getStatus: {
                error: true,
              },
              error,
              errorMessage,
            },
          },
        },
      },
    },
  };
};


/* Resend transaction email */

export const resendTransactionEmailClear = (state, action) => {
  const { params: { location__id, transaction_email_task_id } } = action.payload;
  if (!location__id || !transaction_email_task_id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        marketing: {
          ...state.locations[location__id].marketing,
          transactionEmails: {
            ...state.locations[location__id].marketing.transactionEmails,
            history: {
              ...state.locations[location__id].marketing.transactionEmails.history,
              keyValue: {
                ...state.locations[location__id].marketing.transactionEmails.history.keyValue,
                [transaction_email_task_id]: {
                  ...state.locations[location__id].marketing.transactionEmails.history.keyValue[transaction_email_task_id],
                  resendInProgress: false, resendStatus: {},
                },
              },
            },
          },
        },
      },
    },
  };
};

export const resendTransactionEmail = (state, action) => {
  const { params: { location__id, transaction_email_task_id } } = action.payload;
  if (!location__id || !transaction_email_task_id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        marketing: {
          ...state.locations[location__id].marketing,
          transactionEmails: {
            ...state.locations[location__id].marketing.transactionEmails,
            history: {
              ...state.locations[location__id].marketing.transactionEmails.history,
              keyValue: {
                ...state.locations[location__id].marketing.transactionEmails.history.keyValue,
                [transaction_email_task_id]: {
                  ...state.locations[location__id].marketing.transactionEmails.history.keyValue[transaction_email_task_id],
                  resendInProgress: true, resendStatus: {},
                },
              },
            },
          },
        },
      },
    },
  };
};

export const resendTransactionEmailSuccess = (state, action) => {
  const { data, params: { location__id, transaction_email_task_id } } = action.payload;
  if (!location__id || !transaction_email_task_id) return state;
  let { transaction_email_task_data } = data;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        marketing: {
          ...state.locations[location__id].marketing,
          transactionEmails: {
            ...state.locations[location__id].marketing.transactionEmails,
            history: {
              ...state.locations[location__id].marketing.transactionEmails.history,
              keyValue: {
                ...state.locations[location__id].marketing.transactionEmails.history.keyValue,
                [transaction_email_task_id]: {
                  ...state.locations[location__id].marketing.transactionEmails.history.keyValue[transaction_email_task_id],
                  ...transaction_email_task_data,
                  resendInProgress: false, resendStatus: { success: true },
                },
              },
            },
          },
        },
      },
    },
  };
};

export const resendTransactionEmailError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id, transaction_email_task_id } = params;
  if (!location__id || !transaction_email_task_id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        marketing: {
          ...state.locations[location__id].marketing,
          transactionEmails: {
            ...state.locations[location__id].marketing.transactionEmails,
            history: {
              ...state.locations[location__id].marketing.transactionEmails.history,
              keyValue: {
                ...state.locations[location__id].marketing.transactionEmails.history.keyValue,
                [transaction_email_task_id]: {
                  ...state.locations[location__id].marketing.transactionEmails.history.keyValue[transaction_email_task_id],
                  resendInProgress: false, resendStatus: { error: true },
                  error,
                  errorMessage,
                },
              },
            },
          },
        },
      },
    },
  };
};



/* Send Custom transaction email */

export const sendCustomTransactionEmailClear = (state, action) => {
  const { params: { location__id, transaction_email_id, guest_id, guest_list } } = action.payload;
  if (!location__id || !transaction_email_id || (!guest_id && !guest_list)) return state;
  if (guest_id) {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          marketing: {
            ...state.locations[location__id].marketing,
            transactionEmails: {
              ...state.locations[location__id].marketing.transactionEmails,
              reservations: {
                ...state.locations[location__id].marketing.transactionEmails.reservations,
                keyValue: {
                  ...state.locations[location__id].marketing.transactionEmails.reservations.keyValue,
                  [guest_id]: {
                    ...state.locations[location__id].marketing.transactionEmails.reservations.keyValue[guest_id],
                    sendCustomInProgress: false, sendCustomStatus: {},
                  },
                },
              },
            },
          },
        },
      },
    };
  }
  if (guest_list) {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          marketing: {
            ...state.locations[location__id].marketing,
            transactionEmails: {
              ...state.locations[location__id].marketing.transactionEmails,
              reservations: {
                ...state.locations[location__id].marketing.transactionEmails.reservations,
                sendCustomMultipleInProgress: false, sendCustomMultipleStatus: {},
              },
            },
          },
        },
      },
    };
  }
  return state;
};

export const sendCustomTransactionEmail = (state, action) => {
  const { params: { location__id, transaction_email_id, guest_id, guest_list } } = action.payload;
  if (!location__id || !transaction_email_id || (!guest_id && !guest_list)) return state;
  if (guest_id) {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          marketing: {
            ...state.locations[location__id].marketing,
            transactionEmails: {
              ...state.locations[location__id].marketing.transactionEmails,
              reservations: {
                ...state.locations[location__id].marketing.transactionEmails.reservations,
                keyValue: {
                  ...state.locations[location__id].marketing.transactionEmails.reservations.keyValue,
                  [guest_id]: {
                    ...state.locations[location__id].marketing.transactionEmails.reservations.keyValue[guest_id],
                    sendCustomInProgress: true, sendCustomStatus: {},
                  },
                },
              },
            },
          },
        },
      },
    };
  }
  if (guest_list) {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          marketing: {
            ...state.locations[location__id].marketing,
            transactionEmails: {
              ...state.locations[location__id].marketing.transactionEmails,
              reservations: {
                ...state.locations[location__id].marketing.transactionEmails.reservations,
                sendCustomMultipleInProgress: true, sendCustomMultipleStatus: {},
              },
            },
          },
        },
      },
    };
  }
  return state;
};

export const sendCustomTransactionEmailSuccess = (state, action) => {
  const { data, params: { location__id, transaction_email_id, guest_id, guest_list } } = action.payload;
  if (!location__id || !transaction_email_id || (!guest_id && !guest_list)) return state;
  let { custom_transaction_email_task_data, custom_transaction_email_task_list_data } = data;
  if (guest_id) {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          marketing: {
            ...state.locations[location__id].marketing,
            transactionEmails: {
              ...state.locations[location__id].marketing.transactionEmails,
              reservations: {
                ...state.locations[location__id].marketing.transactionEmails.reservations,
                keyValue: {
                  ...state.locations[location__id].marketing.transactionEmails.reservations.keyValue,
                  [guest_id]: {
                    ...state.locations[location__id].marketing.transactionEmails.reservations.keyValue[guest_id],
                    transaction_email_tasks: state.locations[location__id].marketing.transactionEmails.reservations.keyValue[guest_id].transaction_email_tasks
                    ? [
                      custom_transaction_email_task_data,
                      ...state.locations[location__id].marketing.transactionEmails.reservations.keyValue[guest_id].transaction_email_tasks,
                    ]
                    : [
                      custom_transaction_email_task_data,
                    ],
                    sendCustomInProgress: false, sendCustomStatus: { success: true },
                  },
                },
              },
            },
          },
        },
      },
    };
  }
  if (guest_list) {
    let tempKeyValue = { ...state.locations[location__id].marketing.transactionEmails.reservations.keyValue };
    if (custom_transaction_email_task_list_data && custom_transaction_email_task_list_data.length) {
      custom_transaction_email_task_list_data.forEach((item) => {
        const guestId = item.guest_id;
        if (tempKeyValue[guestId]) {
          if (tempKeyValue[guestId].transaction_email_tasks) {
            tempKeyValue[guestId].transaction_email_tasks = [
              item,
              ...tempKeyValue[guestId].transaction_email_tasks,
            ];
          } else {
            tempKeyValue[guestId].transaction_email_tasks = [item];
          }
        }
      });
    }
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          marketing: {
            ...state.locations[location__id].marketing,
            transactionEmails: {
              ...state.locations[location__id].marketing.transactionEmails,
              reservations: {
                ...state.locations[location__id].marketing.transactionEmails.reservations,
                keyValue: {
                  // ...state.locations[location__id].marketing.transactionEmails.reservations.keyValue,
                  ...tempKeyValue,
                },
                sendCustomMultipleInProgress: false, sendCustomMultipleStatus: { success: true },
              },
            },
          },
        },
      },
    };
  }
  return state;
};

export const sendCustomTransactionEmailError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id, transaction_email_id, guest_id, guest_list } = params;
  if (!location__id || !transaction_email_id || (!guest_id && !guest_list)) return state;
  if (guest_id) {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          marketing: {
            ...state.locations[location__id].marketing,
            transactionEmails: {
              ...state.locations[location__id].marketing.transactionEmails,
              reservations: {
                ...state.locations[location__id].marketing.transactionEmails.reservations,
                keyValue: {
                  ...state.locations[location__id].marketing.transactionEmails.reservations.keyValue,
                  [guest_id]: {
                    ...state.locations[location__id].marketing.transactionEmails.reservations.keyValue[guest_id],
                    sendCustomInProgress: false, sendCustomStatus: { error: true },
                    error,
                    errorMessage,
                  },
                },
              },
            },
          },
        },
      },
    };
  }
  if (guest_list) {
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          marketing: {
            ...state.locations[location__id].marketing,
            transactionEmails: {
              ...state.locations[location__id].marketing.transactionEmails,
              reservations: {
                ...state.locations[location__id].marketing.transactionEmails.reservations,
                sendCustomMultipleInProgress: false, sendCustomMultipleStatus: { error: true },
                error,
                errorMessage,
              },
            },
          },
        },
      },
    };
  }
  return state;
};




/* Get transaction emails reservations */

export const getTransactionEmailsReservations = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        marketing: {
          ...state.locations[location__id].marketing,
          transactionEmails: {
            ...state.locations[location__id].marketing.transactionEmails,
            reservations: state.locations[location__id].marketing.transactionEmails.reservations
            ?
              {
                ...state.locations[location__id].marketing.transactionEmails.reservations,
                getInProgress: true, getStatus: {},
              }
            : {
                getInProgress: true, getStatus: {},
              },
          },
        },
      },
    },
  };
};

export const getTransactionEmailsReservationsSuccess = (state, action) => {
  const { data, params: { location__id } } = action.payload;
  if (!location__id) return state;
  let { reservations, total_count, transaction_emails } = data;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        marketing: {
          ...state.locations[location__id].marketing,
          transactionEmails: {
            ...state.locations[location__id].marketing.transactionEmails,
            reservations: {
              ...state.locations[location__id].marketing.transactionEmails.reservations,
              ...reservations,
              getInProgress: false, getStatus: {
                success: true,
              },
              total_count,
            },
            transaction_emails: state.locations[location__id].marketing.transactionEmails.transaction_emails
            ?
              {
                ...state.locations[location__id].marketing.transactionEmails.transaction_emails,
                ...transaction_emails,
              }
            : {
                ...transaction_emails,
              }
          },
        },
      },
    },
  };
};

export const getTransactionEmailsReservationsError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id } = params;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        marketing: {
          ...state.locations[location__id].marketing,
          transactionEmails: {
            ...state.locations[location__id].marketing.transactionEmails,
            reservations: {
              ...state.locations[location__id].marketing.transactionEmails.reservations,
              getInProgress: false, getStatus: {
                error: true,
              },
              error,
              errorMessage,
            },
          },
        },
      },
    },
  };
};
