import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Select from 'react-select';
import { Button, DatePicker } from 'antd';
import moment from 'moment';
import { Rnd } from "react-rnd";

import SingleTransactionEmail from '../SingleTransactionEmail';
import ToolTipWrapper from '../../../Misc/ToolTipWrapper';

import { getDataAction } from '../../../../redux/actions/getDataActions';

import { TRANSACTION_EMAIL_TYPE_TO_LABEL, STRS } from '../../../../helpers';

import statusBookingIcon from '../../../../img/icons/status-booking.svg';
import statusBookingActiveIcon from '../../../../img/icons/status-booking-active.svg';
import statusPreArrivalIcon from '../../../../img/icons/status-pre-arrival.svg';
import statusPreArrivalActiveIcon from '../../../../img/icons/status-pre-arrival-active.svg';
import statusCheckInIcon from '../../../../img/icons/status-check-in.svg';
import statusCheckInActiveIcon from '../../../../img/icons/status-check-in-active.svg';
import statusCheckOutIcon from '../../../../img/icons/status-check-out.svg';
import statusCheckOutActiveIcon from '../../../../img/icons/status-check-out-active.svg';

import '../style.css';

function Stage(props) {
  const { stage, locationidentifier, active } = props;
  return (
    <Link to={`/marketing/locations/${locationidentifier}/transaction-emails/configure/${stage.id}`}
      className={(active ? 'active' : '')}
    >
      <div className="lt">
        {
          stage.icon &&
          <img src={active ? stage.iconActive : stage.icon} />
        }
        {
          stage.faIcon &&
          <>
            {
              !active
              ? <i className={'faGt ' + (stage.faIcon)} />
              : <i className={'faGt active ' + (stage.faIconActive)} />
            }
          </>
        }
      </div>
      <div className="rt">
        <p className="title">{stage.name}</p>
        <p className="desc">{stage.desc}</p>
      </div>
    </Link>
  );
}

function Configure(props) {
  const appState = useSelector((state) => state.appReducer);
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const windowState = useSelector((state) => state.windowReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [showAddNew, setShowAddNew] = useState(false);

  const [boxWidth, setBoxWidth] = useState(240);
  const [list, setList] = useState([]);

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      document.title = `Marketing - Transaction Emails - Manage - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: userState.user.uid,
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  useEffect(() => {
    let listLocal = [];
    listLocal.push({ 'id': 'booking', 'name': 'Booking', 'desc': 'Booking confirmation email', 'icon': statusBookingIcon, 'iconActive': statusBookingActiveIcon });
    listLocal.push({ 'id': 'pre-arrival', 'name': 'Pre-Arrival', 'desc': 'Pre-arrival email', 'icon': statusPreArrivalIcon, 'iconActive': statusPreArrivalActiveIcon });
    listLocal.push({ 'id': 'check-in', 'name': 'Check-In', 'desc': 'Check-In email', 'icon': statusCheckInIcon, 'iconActive': statusCheckInActiveIcon });
    listLocal.push({ 'id': 'check-out', 'name': 'Check-Out', 'desc': 'Check-Out email', 'icon': statusCheckOutIcon, 'iconActive': statusCheckOutActiveIcon });
    // listLocal.push({ 'id': 'custom', 'name': 'Custom', 'desc': 'Custom email', 'icon': statusCheckOutIcon, 'iconActive': statusCheckOutActiveIcon });
    listLocal.push({ 'id': 'custom', 'name': 'Custom', 'desc': 'Custom email', 'faIcon': 'fa fa-table', 'faIconActive': 'fa fa-table' });
    setList(listLocal);
  }, []);

  useEffect(() => {
    setShowAddNew(false);
  }, [urlParams.stage]);

  useEffect(() => {
    dispatch(getDataAction({
      op: 'get_transaction_emails',
      params: {
        location__id: urlParams.locationidentifier,
        getEmailTemplates: true,
        getRooms: true,
        getRateCodes: true,
        getBookingSources: true,
        getSenderNames: true,
        getSenderEmails: true,
      },
    }));
  }, [urlParams.locationidentifier]); // urlParams.locationidentifier

  const onSomeChange = (change) => {
    // setSomeChange(change);
  };

  const onAddNewClick = (e) => {
    if (props.isReadOnly) return;
    setShowAddNew(true);
  };
  const onCancelAddNew = (e) => {
    setShowAddNew(false);
  }

  const onAddSuccess = (e) => {
    setShowAddNew(false);
  }

  const { locationidentifier, stage } = urlParams;

  const { transactionEmails } = props.marketing;
  if (!transactionEmails) return null;

  const { transaction_emails } = transactionEmails;
  if (!transaction_emails) return null;

  let defaultData = {};
  let customList = [];
  if (stage) {
    const { list, keyValue } = transaction_emails;
    const filteredList = list ? list.filter(el => keyValue[el] && keyValue[el].type === stage && keyValue[el].is_default) : [];
    if (filteredList.length) {
      defaultData = keyValue[filteredList[0]];
    }
    customList = list ? list.filter(el => keyValue[el] && keyValue[el].type === stage && keyValue[el].is_custom) : [];
    customList.map(el => keyValue[el]);
    // customList.sort((a,b) => {
    //   const dt_a = keyValue[a].date_updated || keyValue[a].date_created;
    //   const dt_b = keyValue[b].date_updated || keyValue[b].date_created;
    //   return new Date(dt_a).getTime() - new Date(dt_b).getTime();
    // });
  }

  if (process.env.NODE_ENV !== 'production') {
    console.log('Configure customList: ',customList);
    console.log('Configure defaultData: ',defaultData);
  }

  return (
    <>
      <Rnd
        className={'teStgLst'}
        size={{width: boxWidth}}
        style={{}}
        disableDragging={true}
        enableResizing={{
          right: true,
        }}
        maxWidth={300}
        minWidth={180}
        onResize={(e, direction, ref, delta, position) => {
          setBoxWidth(ref.style.width);
        }}
      >
        <div className="rszIndc">
          <span><i className="fas fa-grip-vertical"></i></span>
          <span><i className="fas fa-grip-vertical"></i></span>
          <span><i className="fas fa-grip-vertical"></i></span>
        </div>
        <div className="hdr">
          Transaction Emails
        </div>
        <div className="stgLst">
          {
            list.map((stage) => {
              let active = location.pathname.indexOf(`/marketing/locations/${locationidentifier}/transaction-emails/configure/${stage.id}`) > -1;
              return (
                <Stage key={`te_stg_${stage.id}`} stage={stage} locationidentifier={locationidentifier} active={active} />
              )
            })
          }
        </div>
      </Rnd>
      {
        stage &&
        <div className="gPScrlWrp teCnfg">
          <div className="gPWrp">
            <div className="gPTle">
              {TRANSACTION_EMAIL_TYPE_TO_LABEL[stage]}{props.isReadOnly && <> [{STRS.READ_ONLY_LABEL}]</>}
            </div>
            <div className="gBCtnt">
              {
                (stage === 'booking' || stage === 'pre-arrival' || stage === 'check-in' || stage === 'check-out') &&
                <div className="gBCtRw teRw" key={`ste_def_${stage}`}>
                  <SingleTransactionEmail

                    mode={defaultData && defaultData._id ? "edit" : "add"}
                    isDefault={true}
                    type={stage}
                    title={`Send a ${stage} email to the guest?`}
                    desc={`Set an automated personalized ${stage} email to guests.`}
                    emailSelectorTitle={`${stage} Email Template`}
                    data={transaction_emails.keyValue ? transaction_emails.keyValue[defaultData._id] : {}}
                    editInProgress={defaultData && defaultData._id ? transaction_emails.keyValue[defaultData._id].editInProgress : transaction_emails.addInProgress}
                    editStatus={defaultData && defaultData._id ? transaction_emails.keyValue[defaultData._id].editStatus : transaction_emails.addStatus}
                    checkInTime={'10:00 AM'}
                    dynamicLabel={<p>Use braces brackets to add {"{{firstname}}"} or {"{{lastname}}"} of guests to personalize the {stage} message.</p>}
                    onSomeChange={onSomeChange}
                    onAddSuccess={onAddSuccess}
                    locationInfo={props.locationInfo}
                    transactionEmails={transactionEmails}
                    settings={props.settings}
                    isReadOnly={props.isReadOnly}
                  />
                </div>
              }
              {
                customList.length
                ?
                  customList.map((item) => {
                    if (!transaction_emails.keyValue || !transaction_emails.keyValue[item] || transaction_emails.keyValue[item].is_removed) return null;
                    return (
                      <div className="gBCtRw teRw" key={`ste_${stage}_${item}`}>
                        <SingleTransactionEmail
                          mode={"edit"}
                          isCustom={true}
                          type={stage}
                          title={`Send a ${stage} email to the guest?`}
                          description={`Set an automated personalized ${stage} email to guests.`}
                          // emailSelectorTitle={`${props.type} Email Template`}
                          emailSelectorTitle={`Email Template`}
                          data={transaction_emails.keyValue ? transaction_emails.keyValue[item] : {}}
                          editInProgress={transaction_emails.keyValue[item].editInProgress}
                          editStatus={transaction_emails.keyValue[item].editStatus}
                          checkInTime={'10:00 AM'}
                          dynamicLabel={<p>Use braces brackets to add {"{{firstname}}"} or {"{{lastname}}"} of guests to personalize the {stage} message.</p>}
                          onSomeChange={onSomeChange}
                          onAddSuccess={onAddSuccess}
                          locationInfo={props.locationInfo}
                          transactionEmails={transactionEmails}
                          settings={props.settings}
                          isReadOnly={props.isReadOnly}
                        />
                      </div>
                    );
                  })
                : null
              }
              {
                showAddNew &&
                <div className="gBCtRw teRw" key={`ste_new_${stage}`}>
                  <SingleTransactionEmail
                    mode="add"
                    isCustom={true}
                    onCancelAddNew={onCancelAddNew}
                    type={stage}
                    title={`Send a ${stage} email to the guest?`}
                    description={`Set an automated personalized ${stage} email to guests.`}
                    emailSelectorTitle={`${stage} Email Template`}
                    data={{}}
                    editInProgress={transaction_emails.addInProgress}
                    editStatus={transaction_emails.addStatus}
                    checkInTime={'10:00 AM'}
                    locationInfo={props.locationInfo}
                    dynamicLabel={<p>Use braces brackets to add {"{{firstname}}"} or {"{{lastname}}"} of guests to personalize the {stage} message.</p>}
                    onSomeChange={onSomeChange}
                    onAddSuccess={onAddSuccess}
                    locationInfo={props.locationInfo}
                    transactionEmails={transactionEmails}
                    settings={props.settings}
                  />
                </div>
              }
              {
                (stage === 'booking' || stage === 'pre-arrival' || stage === 'check-in' || stage === 'check-out') &&
                <div className="gBCtRw addNewRw">
                  <p onClick={onAddNewClick}>
                    Create and send targeted email based on rate code, room type, and more.
                  </p>
                </div>
              }
              {
                (stage === 'custom') &&
                <div className="gBCtRw addNewRw">
                  <p onClick={onAddNewClick}>
                    Create custom emails and send targeted email based on rate code, room type, and more.
                  </p>
                </div>
              }
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default Configure;
