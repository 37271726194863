// @flow

import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useRef, useCallback } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import ReactDOM from 'react-dom';
import { Button, DatePicker } from 'antd';
import moment from 'moment';
import Select from 'react-select';
import queryString from 'query-string';

import { setCommonParams } from '../../../../redux/actions/appActions';
import { getDataAction } from '../../../../redux/actions/getDataActions';

import { DATE_DURATION_OPTIONS, DATE_DURATION_OPTIONS_MAP, getUserGAId } from '../../../../helpers';

import SentimentMentions from './SentimentMentions';

import SentimentScoreChart from '../../Charts/SentimentScoreChart';
import SentimentTrendChart from '../../Charts/SentimentTrendChart';
import SentimentMentionChart from '../../Charts/SentimentMentionChart';

import useOnClickOutside from '../../../Misc/OutsideClickHook';
import ToolTipWrapper from '../../../Misc/ToolTipWrapper';

import './style.css';

import 'antd/dist/antd.css';

const { RangePicker } = DatePicker;

const gtconfig = require('../../../../gtconfig');

const modalRoot = document.getElementById('modal-root');
function Modal(props) {
  const [el, setEl] = useState(document.createElement('div'));
  useEffect(() => {
    // this.el = document.createElement('div');
    // setEl(document.createElement('div'));
    modalRoot.appendChild(el);
    return function cleanup() {
      modalRoot.removeChild(el);
    }
  }, []);
  return ReactDOM.createPortal(
    props.children,
    el,
  );
}

const lastMonthOptions = [];
lastMonthOptions.push({ label: 'Select', value: 'select' });
lastMonthOptions.push({ label: 'Last 7 days', value: 'last-7-days' });
lastMonthOptions.push({ label: 'Last 15 days', value: 'last-15-days' });
lastMonthOptions.push({ label: 'Last 1 Month', value: 'last-1-month' });
lastMonthOptions.push({ label: 'Last 3 Months', value: 'last-3-months' });
lastMonthOptions.push({ label: 'Last 6 Months', value: 'last-6-months' });
lastMonthOptions.push({ label: 'Last 12 Months', value: 'last-12-months' });


function Sentiments(props) {
  const appState = useSelector((state) => state.appReducer);
  const userState = useSelector((state) => state.userReducer);
  const windowState = useSelector((state) => state.windowReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  const location = useLocation();
  let navigate = useNavigate();
  let urlParams = useParams();

  const [dateRangeLastMonthValue, setDateRangeLastMonthValue] = useState(
    { label: 'Last 1 Month', value: 'last-1-month' }
  );
  const [dateFilterStartDate, setDateFilterStartDate] = useState(new Date(moment().subtract(1, 'month')));
  const [dateFilterEndDate, setDateFilterEndDate] = useState(new Date());
  const [dateRangeActive, setDateRangeActive] = useState(false);

  const [showMentionDetailsModal, setShowMentionDetailsModal] = useState(false);
  const mentionDetailsModalRef = useRef();
  useOnClickOutside(mentionDetailsModalRef, useCallback(() => onMentionDetailsModalCloseClick()));
  const [mentionDetailsData, setMentionDetailsData] = useState(null);
  const [mentionDetailsDefault, setMentionDetailsDefault] = useState(null);

  const [hasTranslate, setHasTranslate] = useState(false);

  const getSentimentData = (startDate, endDate) => {
    if (props.isGroup) {
      dispatch(getDataAction({
        op: 'get_group_sentiments_data',
        params: {
          group_id: urlParams.groupidentifier,
          start_date: moment(startDate).format('YYYY-MM-DD'),
          end_date: moment(endDate).format('YYYY-MM-DD'),
        },
      }));
    } else {
      dispatch(getDataAction({
        op: 'get_sentiments_data',
        params: {
          location__id: urlParams.locationidentifier,
          start_date: moment(startDate).format('YYYY-MM-DD'),
          end_date: moment(endDate).format('YYYY-MM-DD'),
        },
      }));
    }
  };

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && urlParams.locationidentifier
        && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      document.title = `Reputation - Sentiments - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier || '',
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  useEffect(() => {
    if (!props.isGroup) {
      const { has_reputation_translate } = props.locationInfo;
      setHasTranslate(has_reputation_translate);

      const { commonParams } = appState;
      let startDate = commonParams && commonParams.startDate ? new Date(commonParams.startDate) : new Date(moment().subtract(1, 'month'));
      let endDate = commonParams && commonParams.endDate ? new Date(commonParams.endDate) : new Date();
      let rangeKeyword = commonParams && commonParams.rangeKeyword ? commonParams.rangeKeyword : 'last-1-month';
      setDateFilterStartDate(startDate);
      setDateFilterEndDate(endDate);
      setDateRangeLastMonthValue({ label: DATE_DURATION_OPTIONS_MAP[rangeKeyword], value: rangeKeyword });
      getSentimentData(startDate, endDate);
    }

  }, [urlParams.locationidentifier]);

  useEffect(() => {
    if (props.isGroup) {
      const { has_reputation_translate } = props.locationInfo;
      setHasTranslate(has_reputation_translate);

      const { commonParams } = appState;
      let startDate = commonParams && commonParams.startDate ? new Date(commonParams.startDate) : new Date(moment().subtract(1, 'month'));
      let endDate = commonParams && commonParams.endDate ? new Date(commonParams.endDate) : new Date();
      let rangeKeyword = commonParams && commonParams.rangeKeyword ? commonParams.rangeKeyword : 'last-1-month';
      setDateFilterStartDate(startDate);
      setDateFilterEndDate(endDate);
      setDateRangeLastMonthValue({ label: DATE_DURATION_OPTIONS_MAP[rangeKeyword], value: rangeKeyword });
      getSentimentData(startDate, endDate);
    }

  }, [urlParams.groupidentifier]);

  useEffect(() => {
    const { show_details } = queryString.parse(location.search);
    if (process.env.NODE_ENV !== 'production') {
      console.log('show_details: ', show_details);
    }
    if (show_details) {
      setShowMentionDetailsModal(true);
    }
  }, [location.search]);

  const onMentionDetailsModalCloseClick = (e) => {
    setShowMentionDetailsModal(false);
  }

  const onDateRangeChange = (dates, dateStrings) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onDateRangeChange dates: ', dates);
      console.log('onDateRangeChange dateStrings: ', dateStrings);
    }
    const { sentiments } = props.reputation;
    if (!sentiments || sentiments.getInProgress) return;

    setDateRangeActive(true);
    setDateFilterStartDate(new Date(dates[0]));
    setDateFilterEndDate(new Date(dates[1]));
    dispatch(setCommonParams({
      params: {
        rangeKeyword: '',
        startDate: new Date(dates[0]),
        endDate: new Date(dates[1]),
      },
    }));
    getSentimentData(dates[0], dates[1]);
    // analytics event reporting
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : ''
      window.gtag('event', 'gt_click', {
        'event_category': `Reputation - Sentiments - Date Change Range`,
        'event_label': `Date Change Range - ${dateStrings}`,
        page_path: `${location.pathname}`,
        page_title: `Reputation - Sentiments - ${locationName}`,
        'gt_location_id': urlParams.locationidentifier || '',
        'gt_location': locationName,
        'gt_user_id': getUserGAId(userState.user),
      });
    } catch (e) {}
  };

  const onDateRangeLastMonthChange = (value) => {
    const { sentiments } = props.reputation;
    if (!sentiments || sentiments.getInProgress) return;

    setDateRangeActive(false);
    setDateRangeLastMonthValue(value);
    const rangeValue = value.value;
    if (rangeValue.indexOf('-day') > -1) {
      let period = rangeValue.split('-day')[0];
      if (period.indexOf('last-') > -1) period = period.split('last-')[1];
      setDateFilterStartDate(new Date(moment().subtract(period, 'days')))
      setDateFilterEndDate(new Date());
      dispatch(setCommonParams({
        params: {
          rangeKeyword: rangeValue,
          startDate: new Date(moment().subtract(period, 'days')),
          endDate: new Date(),
        },
      }));
      getSentimentData(new Date(moment().subtract(period, 'days')), new Date());
    }
    if (rangeValue.indexOf('-month') > -1) {
      let period = rangeValue.split('-month')[0];
      if (period.indexOf('last-') > -1) period = period.split('last-')[1];
      setDateFilterStartDate(new Date(moment().subtract(period, 'month')))
      setDateFilterEndDate(new Date());
      dispatch(setCommonParams({
        params: {
          rangeKeyword: rangeValue,
          startDate: new Date(moment().subtract(period, 'month')),
          endDate: new Date(),
        },
      }));
      getSentimentData(new Date(moment().subtract(period, 'month')), new Date());
    }
    // analytics event reporting
    try {
      const locationName = locationState.locations && urlParams.locationidentifier
        && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].location_data
        ? locationState.locations[urlParams.locationidentifier].location_data.name || ''
        : ''
      window.gtag('event', 'gt_click', {
        'event_category': `Reputation - Sentiments - Date Change Last`,
        'event_label': `Date Change Last - ${rangeValue}`,
        page_path: `${location.pathname}`,
        page_title: `Reputation - Sentiments - ${locationName}`,
        'gt_location_id': urlParams.locationidentifier || '',
        'gt_location': locationName,
        'gt_user_id': getUserGAId(userState.user),
      });
    } catch (e) {}
  };

  const onMentionDetailsClick = (params) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onMentionDetailsClick: ', params);
    }
    if (!params.data.total_mentions && !params.data.mentions) return;
    setMentionDetailsData(params.data);
    setMentionDetailsDefault(params.default);
    setShowMentionDetailsModal(true);
    // analytics event reporting
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].location_data
        ? locationState.locations[urlParams.locationidentifier].location_data.name || ''
        : ''
      window.gtag('event', 'gt_click', {
        'event_category': `Reputation - Sentiments - Mentions`,
        'event_label': `Mentions - ${params.data.name}`,
        page_path: `${location.pathname}`,
        page_title: `Reputation - Sentiments - ${locationName}`,
        'gt_location_id': urlParams.locationidentifier,
        'gt_location': locationName,
        'gt_user_id': getUserGAId(userState.user),
      });
    } catch (e) {}
  };

  const onAspectClick = (aspect) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onAspectClick: ', aspect);
    }
    // analytics event reporting
    try {
      const locationName = locationState.locations && urlParams.locationidentifier
        && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].location_data
        ? locationState.locations[urlParams.locationidentifier].location_data.name || ''
        : ''
      window.gtag('event', 'gt_click', {
        'event_category': `Reputation - Sentiments - Trend - Aspects`,
        'event_label': `Aspects - ${aspect}`,
        page_path: `${location.pathname}`,
        page_title: `Reputation - Sentiments - ${locationName}`,
        'gt_location_id': urlParams.locationidentifier || '',
        'gt_location': locationName,
        'gt_user_id': getUserGAId(userState.user),
      });
    } catch (e) {}
  };

  const onTrendsTimePeriodClick = (timePeriod) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onTrendsTimePeriodClick: ', timePeriod);
    }
    // analytics event reporting
    try {
      const locationName = locationState.locations && urlParams.locationidentifier
        && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].location_data
        ? locationState.locations[urlParams.locationidentifier].location_data.name || ''
        : ''
      window.gtag('event', 'gt_click', {
        'event_category': `Reputation - Sentiments - Trend - Time Period`,
        'event_label': `Trend - Time Period - ${timePeriod}`,
        page_path: `${location.pathname}`,
        page_title: `Reputation - Sentiments - ${locationName}`,
        'gt_location_id': urlParams.locationidentifier || '',
        'gt_location': locationName,
        'gt_user_id': getUserGAId(userState.user),
      });
    } catch (e) {}
  };

  const renderDateFilterRow = () => {
    return (
      <div className="dtFltBx">
        <div className="left">
          <div className="title">
            {
              props.isGroup && urlParams.groupidentifier && props.groupName &&
              <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'}
                overlay={"Group Name, click to go to Group Overview"}
              >
                <Link to={`/reputation/groups/${urlParams.groupidentifier}/dashboard`}><span>{props.groupName}</span></Link>
              </ToolTipWrapper>
            }
            Sentiments
          </div>
        </div>
        <div className="dateFilter">
          <div className="dateFilterLastMonth">
            <Select
              className="dateFilterLastMonthSelect"
              options={lastMonthOptions}
              onChange={onDateRangeLastMonthChange}
              value={dateRangeLastMonthValue}
            />
          </div>
          <div className="dateFilterRange">
            <RangePicker
              value={[moment(dateFilterStartDate), moment(dateFilterEndDate)]}
              onChange={onDateRangeChange}
              format="DD MMM YYYY"
            />
          </div>
        </div>
      </div>
    );
  };

  const renderMentionDetailsModal = () => {
    // const { show_details } = queryString.parse(location.search);
    // if (!show_details) return null;
    return (
      <Modal>
        <div id="cModelId" className="sdMdl mentionDetailsModal">
          <div className="ctnt" ref={mentionDetailsModalRef}>
            <SentimentMentions
              onCancelClick={onMentionDetailsModalCloseClick}
              data={mentionDetailsData}
              sentiments={props.reputation.sentiments}
              default={mentionDetailsDefault}
              mode="modal"
              hasTranslate={hasTranslate}
              locationidentifier={urlParams.locationidentifier || ''}
              nkcheck={{}}
              userState={userState}
            />
          </div>
        </div>
      </Modal>
    );
  };

  const renderSentiments = (sentiments) => {
    const { locationidentifier } = urlParams;
    return (
      <>
        <div className="innerBox">
          {
            sentiments &&
            <SentimentScoreChart
              width={(windowState.window.windowWidth - 196 - 1 - 40 - 60 - 285 + 16 )}
              height={260}
              data={sentiments}
              onMentionDetailsClick={onMentionDetailsClick}
            />
          }
          {
            sentiments &&
            <SentimentTrendChart
              windowState={windowState}
              width={(windowState.window.windowWidth - 196 - 1 - 40 - 60 - 285 + 16 )}
              height={260}
              data={sentiments}
              onMentionDetailsClick={onMentionDetailsClick}
              onAspectClick={onAspectClick}
              onTimePeriodClick={onTrendsTimePeriodClick}
            />
          }
          {
            sentiments &&
            <SentimentMentionChart
              width={(windowState.window.windowWidth - 196 - 1 - 40 - 60 - 285 + 16 )}
              height={260}
              data={sentiments}
              onMentionDetailsClick={onMentionDetailsClick}
            />
          }
        </div>
      </>
    )
  }

  if (process.env.NODE_ENV !== 'production') {
    console.log('Sentiments props: ', props);
    console.log('Sentiments groupidentifier: ', urlParams.groupidentifier);
  }

  const { sentiments } = props.reputation;
  if (!sentiments) return null;

  return (
    <>
      {/*
        sentiments.getInProgress &&
        <div className="gPnlLdng">
          <i className="fa fa-spinner fa-spin" />
        </div>
      */}
      {
        sentiments.getInProgress &&
        <div className="gPnlSplLdng">
          <div className="innerWrap">
            <i className="fa fa-spinner fa-spin" />
            <img src={gtconfig.REPUTATION_LOADER_URL} />
          </div>
        </div>
      }
      <div className="gPScrlWrp flHt mk">
        <div className="gPg sentPg">
          { renderDateFilterRow() }
          { renderSentiments(sentiments) }
        </div>
      </div>
      { showMentionDetailsModal && renderMentionDetailsModal() }
    </>
  );
}

export default Sentiments;
